import React from 'react';
import { WEBPAGE_URL_IMAGES, FB_FANPAGE_URL, YOUTUBE_FANPAGE_URL, PAGE } from '../globals.js';
import SubscribeForm from './Forms/SubscribeForm';
import FeedbackForm from './Forms/FeedbackForm';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const TM_FILENAME_BASE = () => {
    return (
        <footer id="footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 col-sm-6">
                        <div className="colInfo">
                            <h3 className="nino-colHeading">Articles</h3>
                            <ul className="listArticles">
                                <li layout="row" className="verticalCenter">
                                    <Link className="articleThumb fsr" to={"/" + PAGE.ARTICLE_DA_VINCI}>
                                        <LazyLoadImage src={WEBPAGE_URL_IMAGES + "/our-blog/img-10.webp"} alt="Article 10"
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = `${WEBPAGE_URL_IMAGES}/our-blog/img-10.jpg`
                                            }}
                                        />
                                    </Link>
                                    <div className="info">
                                        <h3 className="articleTitle"><Link to={"/" + PAGE.ARTICLE_DA_VINCI}>Leonardo da Vinci, Draftsman and Illustrator.</Link></h3>
                                        <div className="date">June 3, 2021</div>
                                    </div>
                                </li>
                                <li layout="row" className="verticalCenter">
                                    <Link className="articleThumb fsr" to={"/" + PAGE.ARTICLE_MINIMALISM}>
                                        <LazyLoadImage src={WEBPAGE_URL_IMAGES + "/our-blog/img-9.webp"} alt="Article 9"
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = `${WEBPAGE_URL_IMAGES}/our-blog/img-9.jpg`
                                            }}
                                        />
                                    </Link>
                                    <div className="info">
                                        <h3 className="articleTitle"><Link to={"/" + PAGE.ARTICLE_MINIMALISM}>Minimalism.</Link></h3>
                                        <div className="date">May 30, 2021</div>
                                    </div>
                                </li>
                                <li layout="row" className="verticalCenter">
                                    <Link className="articleThumb fsr" to={"/" + PAGE.ARTICLE_DIGITAL_PAINTING}>
                                        <LazyLoadImage src={WEBPAGE_URL_IMAGES + "/our-blog/img-8.webp"} alt="Article 8"
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = `${WEBPAGE_URL_IMAGES}/our-blog/img-8.jpg`
                                            }}
                                        />
                                    </Link>
                                    <div className="info">
                                        <h3 className="articleTitle"><Link to={"/" + PAGE.ARTICLE_DIGITAL_PAINTING}>Digital Painting: Sign of Our Times.</Link></h3>
                                        <div className="date">May 23, 2021</div>
                                    </div>
                                </li>
                                <li layout="row" className="verticalCenter">
                                    <Link className="articleThumb fsr" to={"/" + PAGE.ARTICLE_HOW_COLOR_WORKS_IN_ART}>
                                        <LazyLoadImage src={WEBPAGE_URL_IMAGES + "/our-blog/img-7.webp"} alt="Article 7"
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = `${WEBPAGE_URL_IMAGES}/our-blog/img-7.jpg`
                                            }}
                                        />
                                    </Link>
                                    <div className="info">
                                        <h3 className="articleTitle"><Link to={"/" + PAGE.ARTICLE_HOW_COLOR_WORKS_IN_ART}>How Color Works in Art.</Link></h3>
                                        <div className="date">May 19, 2021</div>
                                    </div>
                                </li>
                                <li layout="row" className="verticalCenter">
                                    <Link className="articleThumb fsr" to={"/" + PAGE.ARTICLE_PORTRAITURE_AN_EVER_EXPANDING_FORM}>
                                        <LazyLoadImage src={WEBPAGE_URL_IMAGES + "/our-blog/img-6.webp"} alt="Article 6"
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = `${WEBPAGE_URL_IMAGES}/our-blog/img-6.jpg`
                                            }}
                                        />
                                    </Link>
                                    <div className="info">
                                        <h3 className="articleTitle"><Link to={"/" + PAGE.ARTICLE_PORTRAITURE_AN_EVER_EXPANDING_FORM}>Portraiture: An Ever-Expanding Form.</Link></h3>
                                        <div className="date">May 18, 2021</div>
                                    </div>
                                </li>
                                <li layout="row" className="verticalCenter">
                                    <Link className="articleThumb fsr" to={"/" + PAGE.ARTICLE_LIGHT_AND_SHADOW}>
                                        <LazyLoadImage src={WEBPAGE_URL_IMAGES + "/our-blog/img-5.webp"} alt="Article 5"
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = `${WEBPAGE_URL_IMAGES}/our-blog/img-5.jpg`
                                            }}
                                        />
                                    </Link>
                                    <div className="info">
                                        <h3 className="articleTitle"><Link to={"/" + PAGE.ARTICLE_LIGHT_AND_SHADOW}>Light And Shadow.</Link></h3>
                                        <div className="date">May 17, 2021</div>
                                    </div>
                                </li>
                                <li layout="row" className="verticalCenter">
                                    <Link className="articleThumb fsr" to={"/" + PAGE.ARTICLE_DRAWING_TECHNIQUE}>
                                        <LazyLoadImage src={WEBPAGE_URL_IMAGES + "/our-blog/img-4.webp"} alt="Article 4"
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = `${WEBPAGE_URL_IMAGES}/our-blog/img-4.jpg`
                                            }}
                                        />
                                    </Link>
                                    <div className="info">
                                        <h3 className="articleTitle"><Link to={"/" + PAGE.ARTICLE_DRAWING_TECHNIQUE}>Drawing Technique.</Link></h3>
                                        <div className="date">March 3, 2021</div>
                                    </div>
                                </li>
                                <li layout="row" className="verticalCenter">

                                    <Link className="articleThumb fsr" to={"/" + PAGE.ARTICLE}>
                                        <LazyLoadImage src={WEBPAGE_URL_IMAGES + "/our-blog/img-1.webp"} alt="Article 1"
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = `${WEBPAGE_URL_IMAGES}/our-blog/img-1.jpg`
                                            }}
                                        />
                                    </Link>
                                    <div className="info">
                                        <h3 className="articleTitle"><Link to={"/" + PAGE.ARTICLE}>Drawing. Drawing is Everything.</Link></h3>
                                        <div className="date">March 18, 2020</div>
                                    </div>
                                </li>
                                <li layout="row" className="verticalCenter">
                                    <Link className="articleThumb fsr" to={"/" + PAGE.ARTICLE_SECOND}>
                                        <LazyLoadImage src={WEBPAGE_URL_IMAGES + "/our-blog/img-2.webp"} alt="Article 2"
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = `${WEBPAGE_URL_IMAGES}/our-blog/img-2.jpg`
                                            }}
                                        />
                                    </Link>
                                    <div className="info">
                                        <h3 className="articleTitle"><Link to={"/" + PAGE.ARTICLE_SECOND}>Everything you need to know about Impressionism</Link></h3>
                                        <div className="date">April 10, 2020</div>
                                    </div>
                                </li>
                                <li layout="row" className="verticalCenter">
                                    <Link className="articleThumb fsr" to={"/" + PAGE.ARTICLE_HOW_TO_DRAW_AN_EYE}>
                                        <LazyLoadImage src={WEBPAGE_URL_IMAGES + "/our-blog/img-3.webp"} alt="Article how to draw an eye"
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = `${WEBPAGE_URL_IMAGES}/our-blog/img-3.jpg`
                                            }}
                                        />
                                    </Link>
                                    <div className="info">
                                        <h3 className="articleTitle"><Link to={"/" + PAGE.ARTICLE_HOW_TO_DRAW_AN_EYE}>How to draw an eye</Link></h3>
                                        <div className="date">June 15, 2020</div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="colInfo">
                            <h3 className="nino-colHeading">YouTube</h3>
                            <iframe width="100%" height="270px"
                                src="https://www.youtube.com/embed/vPZmgaJoZPI"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                title="How to draw an eye? video tutorial, easy face draw">
                            </iframe>
                        </div>
                        <FeedbackForm></FeedbackForm>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="colInfo">
                            <h3 className="nino-colHeading">Follow Us</h3>
                            <div className="nino-followUs">
                                {/* <div className="totalFollow"><span>15k</span> followers</div> */}
                                <div className="socialNetwork">
                                    <a href={FB_FANPAGE_URL} className="nino-icon"><i className="mdi mdi-facebook"></i></a>
                                    {/* <a href="" className="nino-icon"><i className="mdi mdi-twitter"></i></a> */}
                                    {/* <a href="" className="nino-icon"><i className="mdi mdi-instagram"></i></a> */}
                                    {/* <a href="" className="nino-icon"><i className="mdi mdi-pinterest"></i></a>
                                    <a href="" className="nino-icon"><i className="mdi mdi-google-plus"></i></a> */}
                                    <a href={YOUTUBE_FANPAGE_URL} className="nino-icon"><i className="mdi mdi-youtube-play"></i></a>
                                    {/* <a href="" className="nino-icon"><i className="mdi mdi-dribbble"></i></a>
                                    <a href="" className="nino-icon"><i className="mdi mdi-tumblr"></i></a> */}
                                </div>
                            </div>
                            <SubscribeForm></SubscribeForm>
                            <div className="row">
                                <div className="need-help col-xs-6">
                                    <h3 className="nino-colHeading">Contribution</h3>
                                    <Link to={PAGE.CONTRIBUTION}>Teach online and share your knowledge with people all over the world</Link>
                                </div>
                                <div className="need-help col-xs-6">
                                    <h3 className="nino-colHeading">Need help?</h3>
                                    <Link to={"/help"}>FAQ</Link>
                                </div>
                            </div>

                            <br />
                            <br />
                            <h3 className="nino-colHeading">Contact</h3>
                            <ul>
                                <li>
                                    <span className="glyphicon glyphicon-envelope" aria-hidden="true"></span> contact@easyfacedraw.com
                                </li>
                                {/* <li>
                                    <span className="glyphicon glyphicon-earphone" aria-hidden="true"></span> (+48) 888 770 168
                                </li> */}
                                <li>
                                    Krucza 5/11 <br />
                                    Warszawa 00-548 <br />
                                    Poland
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="nino-copyright">Copyright &copy; 2019 <a target="_blank" href="http://www.easyfacedraw.com/"
                    title="EasyFaceDraw.com - Top quality drawing tutorials for everyone">EasyFaceDraw.com</a>.
				All Rights Reserved. <br />
                 <Link to={"/" + PAGE.PRIVACY_POLICY}>  Privacy Policy.  </Link>
                    {/* <Link to={"/" + PAGE.COOKIES_POLICY}>  Cookies Policy.  </Link> */}
                    <Link to={"/" + PAGE.RULES}>  Rules.  </Link>
                </div>
                <br />
                <br />
                <br />
                <br />

            </div>
        </footer>
    );
}

export default TM_FILENAME_BASE;
