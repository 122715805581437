import React from 'react';
import { Link } from 'react-router-dom';
import { sendEventToGoogleAnalytics } from '../functions';

const HeaderCarousel = () => {
  return (
    <section id="nino-slider" className="carousel slide container" data-ride="carousel">

      {/* <div className="carousel-inner" role="listbox"> */}
      <div role="listbox">
        {/* <div className="item active">
          <h2 className="nino-sectionHeading">
            <span className="nino-subHeading">Choose what you like</span>
            Learn drawing<br /> with us
						    </h2>
          <a href="#" className="nino-btn">Read more</a>
        </div> */}
        <div className="item active">
          <h2 className="nino-sectionHeading">
            {/* <span className="nino-subHeading">Take our video lessons</span> */}
            Learn how to <br /> draw a face
						</h2>
          <div className="call-to-action-header">
            {/* <Link to={`/player/2`} className="action-btn" onClick={() => sendEventToGoogleAnalytics("Watch for free", "header" )}></Link> */}
            <Link to={`/player/2`} className="action-btn" onClick={() => sendEventToGoogleAnalytics("Watch now", "header" )}></Link>

          </div>

          {/* <Link className="nino-btn read-more-btn" to="/readmore">
              Read more
            </Link> */}
        </div>
        {/* <div className="item">
          <h2 className="nino-sectionHeading">
            <span className="nino-subHeading">Try yourself</span>
            Draw your <br />amazing art
						</h2>
          <a href="#" className="nino-btn">Read more</a>
        </div>
        <div className="item">
          <h2 className="nino-sectionHeading">
            <span className="nino-subHeading">Send your result to us</span>
            We'll give <br />you expertise
						</h2>
          <a href="#" className="nino-btn">Read more</a>
        </div> */}
      </div>

      <ol className="carousel-indicators clearfix">
        {/* <li data-target="#nino-slider" data-slide-to="0" className="active">
          <div className="inner">
            <span className="number">01</span> choose tutorial
						</div>
        </li>
        <li data-target="#nino-slider" data-slide-to="1">
          <div className="inner">
            <span className="number">02</span> watch videos
						</div>
        </li>
        <li data-target="#nino-slider" data-slide-to="2">
          <div className="inner">
            <span className="number">03</span> try yourself
						</div>
        </li>
        <li data-target="#nino-slider" data-slide-to="3">
          <div className="inner">
            <span className="number">04</span> send as your result
						</div>
        </li> */}
      </ol>
    </section>
  );
}

export default HeaderCarousel;
