import React, { Component } from 'react';
import Header from "./Header.jsx";
import { Link } from 'react-router-dom'
import { PAGE, WEBPAGE_URL_IMAGES } from '../globals';
import ImageModal from './ImageModal';
import { sendEventToGoogleAnalytics } from '../functions';
import { Helmet } from "react-helmet";

class ArticleThePlayOfLightAndShadow extends Component {

    render() {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>The Play of Light and Shadow</title>
                    <link rel="canonical" href="https://www.easyfacedraw.com/the-play-of-light-and-shadow" />
                    <meta name="description" content="The interplay of light and shadow is the core of all visual art, from simple drawings to
motion pictures." />
                    <meta name="keywords" content="light, shadow, drawing, play of light, play of shadow" />

                </Helmet>
                <Header {...this.props} ></Header>
                <article>
                    <section id="nino-whatWeDo">
                        <div className="container">
                            <h1 className="nino-sectionHeading">
                                <span className="nino-subHeading big-font">The Play of Light and Shadow</span>

                            </h1>
                            <h2 className="article-author">
                                Peter Letzelter-Smith
                                <br />
                                May 17, 2021
                                </h2>
                            <br />

                            <p className="nino-sectionDesc read-more-description">
                                The interplay of light and shadow is the core of all visual art, from simple drawings to
                                motion pictures. Without being able to see the impact of light as it strikes — and
                                subsequently, its counterpart being shaped by — an object, there would be nothing but
                                flat, one-dimensional imagery. Nothing but positive and negative space lacking variation.
	                        </p>
                            <p className="nino-sectionDesc read-more-description">
                                This is, of course, exactly how the human eye absorbs the optical information of
                                everyday existence, which is why any visual work that does not capture light and shadow
                                seems immature and simple. A child’s stick figure drawing, though charming, lacks the
                                visual stimulation to endure on its own. And though occasionally artists create works
                                stripped down to only line drawing, this is generally for effect.
                             </p>
                            <p className="nino-sectionDesc read-more-description">
                                Learning to see not only what is seen, but how that object redefines the visual space
                                around and behind it — and capturing that totality — is one of the crucial steps of an
                                artist’s training.
                            </p>
                            <p className="nino-sectionDesc read-more-description">
                                Some artists revel in the play of light and shadow and build their oeuvre by exploring it.
                            </p>
                            <p className="nino-sectionDesc read-more-description">
                                The Dutch master Rembrandt’s work is steeped in the manipulation of light and
                                subsequent shadow. The drama and mystique of his work, which he brings to even the
                                most staid of his portraits, is deeply informed by manipulating light sources into complex
                                webs of shadow.
                            </p>
                            <p className="nino-sectionDesc read-more-description">
                                This can be seen starkly in <a href="https://www.wbur.org/lastseen/2018/09/15/christ-in-the-storm-on-the-sea-of-galilee">The Storm on the Sea of Galilee</a> (though seeing this painting
                                in person is sadly impossible due to its still-unsolved theft in 1990 from the Isabella
                                Stewart Gardner Museum in Boston). The motivating light source of the sun streams
                                down through a small opening in storm clouds, dramatically casting shadows across the
                                sails of the tempest-tossed boat on which cling Jesus and the disciples. It’s the depth and
                                drama of the shadow play that, along with the haphazard angle of the mast, creates a
                                sense of foreboding and danger that is both deeply naturalistic and highly postured.
                            </p>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="- Rembrandt van Rijn"
                                    title="Christ In The Storm On The Sea Of Galilee"
                                    src={`${WEBPAGE_URL_IMAGES}/article/5/1.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Rembrandt van Rijn, Christ In The Storm On The Sea Of Galilee</p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                                But even in the portraits he is most famous for, Rembrandt used angled-lighting
                                motivation to create dramatic shadowing. His <a href="https://www.nationalgallery.org.uk/paintings/rembrandt-self-portrait-at-the-age-of-63">Self Portrait at the Age of 63</a>  has the same
                                light streaming down from the upper-left corner of the frame, casting one side of his face
                                in direct light while leaving the other side barely distinguishable from the background.
                                Like the danger of the storm on the Sea of Galilee, the inherent threat of aging is drawn
                                out by the dramatic lighting of what would otherwise be a simple composition.
                            </p>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="- Rembrandt van Rijn"
                                    title="Self Portrait at the Age of 63"
                                    src={`${WEBPAGE_URL_IMAGES}/article/5/2.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Rembrandt van Rijn, Self Portrait at the Age of 63</p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                                This kind of manipulation of shadow and light has been embraced by artists throughout
                                epochs and movements. A modern master of shadow and light is Edward Hopper, many
                                of whose best-known works — like Rembrandt’s — are of people surrounded by a
                                darkness from which shadows to take form.
                            </p>
                            <p className="nino-sectionDesc read-more-description">
                                His masterpieces, like <a href="https://www.artic.edu/artworks/111628/nighthawks">Nighthawks</a> or <a href="https://emuseum.desmoinesartcenter.org/objects/41752/automat">Automat</a>, are the culmination of Hopper’s
                                fascination with — and exploration of — the dance of light and shadow. In his simple
                                pencil drawing from 1921, <a href="https://collection.cmoa.org/objects/c38ba8a1-9897-43e8-bcaf-2308db885eec">Night Shadows</a>, there’s a clear exploration of using point-of-
                                view angle and stark shadowing to create a narrative within a simple scene of a man
                                walking down the street. But the extreme shadowing of the man himself — and whatever
                                casts a hard shadow in front of him — creates a sense of movement and even expectation
                                in the frame.
                            </p>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="- Edward Hopper"
                                    title="Nighthawks"
                                    src={`${WEBPAGE_URL_IMAGES}/article/5/3.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Edward Hopper, Nighthawks</p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                                And night scenes were not the only ones in which Hopper experimented with light and
                                shadow. His <a href="https://www.columbusmuseum.org//wp-content/themes/cma/collection/assets.php?path=/media/images/1954.031.JPG">Morning Sun</a>, from late in his career in 1952, captures a woman sitting in an
                                unadorned room facing the sun full-on as it throws shadows across the walls and bed on
                                which she sits. The frame of the window casts sharp, geometric shadows that are
                                contrasted by the softer, less distinct one cast by the woman herself onto the bed behind
                                her. There’s a longing that suffuses the painting as the act of facing the morning’s light
                                directly is dramatized by the clear movement of that light into the room — as represented
                                by the shadows that fill it.
                            </p>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="- Edward Hopper"
                                    title="Morning Sun"
                                    src={`${WEBPAGE_URL_IMAGES}/article/5/4.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Edward Hopper, Morning Sun</p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                                Any perusal of photography and filmmaking, both of which feature the use of light and
                                shadow to heighten imagery, gives countless examples of this interplay being used to
                                shape our perceptions.
                            </p>
                            <p className="nino-sectionDesc read-more-description">
                                The highly stylized work of Alfred Hitchcock is replete with the use of light and shadow
                                to build suspense and menace, as in the famous <a href="http://">“A Glass of Milk”</a>  scene in Suspicion that
                                bathes Cary Grant in a series of shadows, to the outright terror of the conclusion of
                                <a href="https://www.youtube.com/watch?v=xWHYmNrAFlI&ab_channel=Movieclips"> Psycho</a> as shadows are thrown about haphazardly by the basement’s swinging light bulb.
                            </p>
                            <p className="nino-sectionDesc read-more-description">
                                But one of the 20th century’s most famous images, Robert Capa’s photograph <a href="https://www.metmuseum.org/art/collection/search/283315">The
                                Falling Soldier</a>, is a wholly naturalistic example of the power of light and shadow. Taken
                                in 1936, it shows a loyalist soldier in the Spanish Civil War at the moment when he is
                                struck by the bullet that kills him — beginning his fall back into his own shadow. It’s a
                                simple, stark, and disturbing image, in part because it captures simply and without artifice
                                the reality of light and shadow at a pivotal moment.
                            </p>

                            {/* <Link to={`/${PAGE.SHOWTUTORIALS}`} className="action-btn" onClick={() => sendEventToGoogleAnalytics("Watch for free", "article")}></Link> */}
                            <Link to={`/${PAGE.SHOWTUTORIALS}`} className="action-btn" onClick={() => sendEventToGoogleAnalytics("Watch now", "article")}></Link>

                        </div>
                    </section>
                </article>
            </div>
        );
    }
}

export default ArticleThePlayOfLightAndShadow;
