import React, { Component } from 'react';
import Header from "./Header.jsx";
import FormController from "./Forms/FormController";
import ResetPasswordTemplate from './Forms/ResetPasswordTemplate';

class ResetPassword extends Component {
    render() {
        return (
            <div>
                <Header {...this.props}></Header>
                <section id="nino-services">
                    <div className="container">
                        <FormController {...this.props}>
                                <ResetPasswordTemplate></ResetPasswordTemplate>
                        </FormController>
                    </div>
                </section>
            </div>
        );
    }
}

export default ResetPassword;
