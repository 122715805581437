import React, { Component } from 'react';
import Header from "./Header.jsx";
import { Link } from 'react-router-dom'
import { PAGE, WEBPAGE_URL_IMAGES } from '../globals';
import ImageModal from './ImageModal';
import { sendEventToGoogleAnalytics } from '../functions';
import { Helmet } from "react-helmet";

class ArticleDrawingTechnique extends Component {

    render() {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Learn about Impressionism and Impressionist Artists</title>
                    <link rel="canonical" href="https://www.easyfacedraw.com/article-everything-you-need-to-know-about-impressionism" />
                    <meta name="description" content="Learn about the history of Impressionism and the Impressionist Artists, such as Monet, Degas, Renoir, and Cassatt." />
                    <meta name="keywords" content="impressionist art, art history, impressionism, impressionist" />

                </Helmet>
                <Header {...this.props} ></Header>
                <article>
                    <section id="nino-whatWeDo">
                        <div className="container">
                            <h1 className="nino-sectionHeading">
                                <span className="nino-subHeading big-font">It’s Always A Good Time to Refine <br />One’s Drawing Technique</span>

                            </h1>
                            <h2 className="article-author">
                                Peter Letzelter-Smith
                                <br />
                                March 3, 2021
                                </h2>
                            <br />


                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author=""
                                    title=""
                                    src={`${WEBPAGE_URL_IMAGES}/article/4/1.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p></p>
                            </div>

                            <p className="nino-sectionDesc read-more-description">
                                Like any craft — from mastering a sport to apprenticing as a chef — technique is the foundation. Mastering core skills are the first step.
			                </p>
                            <p className="nino-sectionDesc read-more-description">
                                Drawing is no different. It’s always a good time to deeply learn, or brush up on, its
                                essential techniques. And, let’s face it, drawing is one way to fill up the days during this
                            era of social distancing.			            </p>
                            <p className="nino-sectionDesc read-more-description">
                                The fact is, staying on top of pencil drawing techniques can aid in working a wide array
                                of other mediums, from painting to computer-aided design. Drawing provides a way of
                                visualizing across a range of disciplines.
                            </p>

                            <h3 className="nino-sectionHeading">
                                First Things First
                           </h3>
                            <p className="nino-sectionDesc read-more-description">
                                Choosing one’s tools — and learning to handle them comfortably and with purpose — is
                                the first step. One aspect of that is doing so in a way that is sustainable, that doesn’t lead
                                to pain and the need to stop for physical reasons.
                            </p>
                            <p className="nino-sectionDesc read-more-description">
                                So, grip is important. Concentrate on relaxing the hand and forearm when drawing to
                                avoid tension and fatigue; realize that it’s not just the fingers doing the work, but the
                                entire arm all the way up to the shoulder. Spread out the workload.
                            </p>
                            <p className="nino-sectionDesc read-more-description">
                                Also realize that the point of the pencil is often not what should be making contact with
                                the paper, but rather the side of the lead is what’s needed to create a wide variety of softer
                                shadings. This will also lessen the need to sharpen your pencil repeatedly.
                            </p>
                            <p className="nino-sectionDesc read-more-description">
                                Which brings us to pencils themselves. There’s a <a href="https://mymodernmet.com/best-drawing-pencils/">wide variety of pencils</a> and mastering
                                those best suited for your drawing requirements is simply work that has to be put in —
                                though experimenting with different types can lead to breakthroughs.
                            </p>
                            <p className="nino-sectionDesc read-more-description">
                                And don’t forget that erasing is the yin to drawing’s yang. Like pencils, there are a
                                <a href="https://ourpastimes.com/different-types-of-erasers-12377362.html"> variety of types</a> that are specialized for different tasks. No one just draws without at least
                                occasionally needing to go back in time and cover their tracks.
                            </p>
                            <h3 className="nino-sectionHeading">
                                Basic Techniques
                           </h3>
                            <p className="nino-sectionDesc read-more-description">
                                The most basic drawing stroke is the back-and-forth (which you learned before you
                                could write since it’s basic to crafting letters). But variation — basically the amount of
                                pressure applied — can make something even this simple capable of capturing a wide
                                variety of seen (or imagined) reality. Leonardo’s Wing Construction with Engineering
                                Design is an example of basic back-and-forth lines of varying darkness creating a simple,
                                yet vibrant, image.
                            </p>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="- Leonardo da Vinci"
                                    title="Wing Construction"
                                    src={`${WEBPAGE_URL_IMAGES}/article/4/2.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Leonardo da Vinci, Wing Construction</p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                                Hatching multiplies the simple back-and-forth stroke, increasing power via repetition.
                                The technique is just using parallel lines to create shading and contours. The closer the
                                lines to one another, the denser the image, the farther apart the lighter the touch. Though
                                a woodcut and not a pencil drawing, Albrecht Dürer’s The Men’s Bath House (especially
                                apparent in the stone wall at the bottom of the work) is a master class of using hatching to
                                create dimension and visual realism to a two-dimensional medium.
                            </p>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="- Albrecht Dürer"
                                    title="The Men’s Bath House"
                                    src={`${WEBPAGE_URL_IMAGES}/article/4/3.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Albrecht Dürer, The Men’s Bath House</p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                                A variation on a theme, cross-hatching is the use of both parallel and perpendicular
                                lines. Their intersection provides more complexity than simple hatching. Rembrandt’s
                                etching Self-Portrait In a Cap, Wide-Eyed and Open-Mouthed is a clear example of
                                cross-hatching technique — one that also displays its striking power. The work is
                                virtually nothing but lines, but together they create a vibrant capturing of the artist in a
                                moment of whimsy.
                            </p>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="- Rembrandt"
                                    title="Self-Portrait In a Cap"
                                    src={`${WEBPAGE_URL_IMAGES}/article/4/4.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Rembrandt, Self-Portrait In a Cap</p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                                Although line drawing dominates Rembrandt’s self-portrait, there is just a hint of
                                scribbling in the work. This technique, also known as gesture drawing, emphasizes
                                capturing the innate energy of the subject as opposed to a more realistic visual
                                representation. It’s associated with drawing “fast” (though that can be a misnomer) and is
                                often used to do preliminary sketches for works in other mediums. Michelangelo’s The
                                Crucifixion with Two Mourners is an example of detail being sacrificed in order to
                                capture the core emotion of a scene.
                            </p>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="- Michelangelo"
                                    title="The Crucifixion with Two Mourners"
                                    src={`${WEBPAGE_URL_IMAGES}/article/4/5.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Michelangelo, The Crucifixion with Two Mourners</p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                                On the opposite end of the scale, stippling is the process of using only overcrowded dots
                                to build out an image. Most famously used by Georges-Pierre Seurat in his epic painting
                                A Sunday on La Grande Jatte—1884, it’s also a technique long used in drawing, dating
                                back to its invention by the 16th-century Italian artist Giulio Campagnola. His engraving
                                The Young Shepherd is an example of the power of the technique.
                            </p>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="- Georges-Pierre Seurat"
                                    title="A Sunday on La Grande Jatte—1884"
                                    src={`${WEBPAGE_URL_IMAGES}/article/4/6.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Georges-Pierre Seurat, A Sunday on La Grande Jatte—1884</p>
                            </div>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="- Georges-Pierre Seurat"
                                    title="The Young Shepherd"
                                    src={`${WEBPAGE_URL_IMAGES}/article/4/7.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Georges-Pierre Seurat, The Young Shepherd</p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                                A less precise, but far more expansive, way of drawing is blending. It can be achieved in
                                any number of ways. A tortillon is a blending stump, shaped like a pencil and made of
                                rolled paper, that can soften drawn lines by smudging them (adding depth and a sense of
                                shadow). Cotton swabs work too. Many artists have simply used their fingers to do this,
                                while using charcoal to retrace more finely drawn lines is another option. There are
                                modern masters of drawing, such as <a href="https://www.dzimirsky.com/">Dirk Dzimirsky</a> or <a href="http://adonnak.com/">Adonna Khare,</a> who use an
                                amazing array of blending techniques to create expansive, photo-realistic imagery.
                            </p>

                            <h3 className="nino-sectionHeading">
                                Ways to Approach a Drawing
                           </h3>
                            <p className="nino-sectionDesc read-more-description">
                                Whatever specific drawing techniques are brought to bear, there are also several
                                approaches to shape any specific drawing session.
                            </p>
                            <p className="nino-sectionDesc read-more-description">
                                One is to start with an underdrawing, usually using a scribbling base, that is done with
                                the idea of quickly capturing the emotion of the subject — then drawing over this with
                                harder, more precise lines. An underdrawing should be done lightly so that it can be
                                easily erased as darker lines are drawn atop the foundation. Eventually the mixing of dark
                                and light lines — the basis of all high-quality pencil drawing — becomes second nature.
                            </p>
                            <p className="nino-sectionDesc read-more-description">
                                As part of this type of “capturing the essence” approach, a blind contour drawing is
                                simply preventing yourself from looking down at the paper while drawing. It’s a
                                technique that lessens judgemental assessment of the quality of one’s drawing and,
                                instead, concentrates on what is actually in front of one’s eyes. The results are not always
                                “good,” but the exercise builds confidence and lessens self-consciousness.
                            </p>

                            <h3 className="nino-sectionHeading">
                                Apprentice Thyself
                           </h3>


                            <p className="nino-sectionDesc read-more-description">
                                One of the best ways to learn drawing techniques is to closely study the artists who have
                                come before. Especially looking at the sketches that famous artists produced while
                                working on larger projects — Leonardo’s Notebooks for example — gives access to both
                                core techniques and how famous artists worked more loosely when not bearing down to
                                produce work for public display. Instead, they are letting their minds explore a theme or
                                object — something anyone can do.
                            </p>

                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="- Leonardo da Vinci"
                                    title="Notebooks"
                                    src={`${WEBPAGE_URL_IMAGES}/article/4/8.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Leonardo da Vinci, Notebooks</p>
                            </div>
                            {/* <Link to={`/${PAGE.SHOWTUTORIALS}`} className="action-btn" onClick={() => sendEventToGoogleAnalytics("Watch for free", "article")}></Link> */}
                            <Link to={`/${PAGE.SHOWTUTORIALS}`} className="action-btn" onClick={() => sendEventToGoogleAnalytics("Watch now", "article")}></Link>

                        </div>
                    </section>
                </article>
            </div>
        );
    }
}

export default ArticleDrawingTechnique;
