import React, { Component } from 'react';
import Header from "./Header.jsx";
import { Link } from 'react-router-dom'
import { PAGE, WEBPAGE_URL_IMAGES } from '../globals';
import ImageModal from './ImageModal';
import { sendEventToGoogleAnalytics } from '../functions';
import { Helmet } from "react-helmet";

class ArticleMinimalism extends Component {

    render() {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Minimalism</title>
                    <link rel="canonical" href="https://www.easyfacedraw.com/article-minimalism" />
                    <meta name="description" content="Minimalism is an extreme form of Abstract Art developed in the United States, specifically in New York,
during the 1960s. The style was a reaction to art being too academic and stale." />
                    <meta name="keywords" content="minimalism, painting, computer drawing, drawing tutorial, video tutorials, how to draw" />
                </Helmet>
                <Header {...this.props} ></Header>
                <article>
                    <section id="nino-whatWeDo">
                        <div className="container">
                            <h1 className="nino-sectionHeading">
                                <span className="nino-subHeading big-font">Minimalism</span>
                            </h1>
                            <h2 className="article-author">
                                Claudia Preza
                                <br />
                                May 30, 2021
                                </h2>
                            <h3 className="nino-sectionHeading">
                                What is Minimalism?
                            </h3>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="Ellsworth Kelly"
                                    title="(left) Red on Red, 2001, oil on canvas and (right) Black Triangle with White, 1976, oil on canvas."
                                    src={`${WEBPAGE_URL_IMAGES}/article/9/1.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>
                                    Gallery view of two Ellsworth Kelly (American, 1923-2015) paintings: (left) Red on Red, 2001, oil on canvas and
                                    (right) Black Triangle with White, 1976, oil on canvas. The Doris and Donald Fisher Collection at the San Francisco
                                    Museum of Modern Art, California. Photo by russellstreet.
                                </p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                                Minimalism is an extreme form of Abstract Art developed in the United States, specifically in New York,
                                during the 1960s. The style was a reaction to art being too academic and stale. Minimalist artists sought
                                to remove any personal narrative from their artworks. Characterized by simple geometric shapes,
                                Minimalism focuses on the square and rectangle and resembles factory-built commodities.
                            </p>
                            <h3 className="nino-sectionHeading">
                                The Development of Minimalism
                            </h3>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="Frank Stella"
                                    title="Getty Tomb."
                                    src={`${WEBPAGE_URL_IMAGES}/article/9/3.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>
                                    Frank Stella (American, 1936-Present), Getty Tomb, 1959, black enamel on canvas. Collection of Los Angeles
                                    County Museum of Art, California. Photo by rocor.
                                </p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                                The artistic style first emerged with Frank Stella’s monochrome rectangular paintings in the late 1950s.
                                Stella’s exhibition at the Museum of Modern Art in New York marked a point in art history when artists
                                began to reject gestural and expressive art. Furthermore, Stella challenged the separation of painting
                                and sculpture by rejecting Abstract Expressionism. This sort of thinking and ideas flourished in the 1960s
                                and 1970s.
                            </p>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="Donald Judd"
                                    title="Untitled (Stack)."
                                    src={`${WEBPAGE_URL_IMAGES}/article/9/4.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>
                                    Donald Judd (American, 1928-1994), Untitled (Stack), 1968-69, stainless steel and amber Plexiglas. Photo by Oliver Kurmis.
                                </p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                                Before being called Minimalism, this new art movement went by various names including ABC Art,
                                Reductive Art, literalism, or systemic painting. It was until later that artists embraced the term as it was
                                the best way to describe artists’ minimal use of colors, shapes, lines, and textures.
                            </p>
                            <h3 className="nino-sectionHeading">
                                Minimalism In-Depth
                            </h3>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="Dan Flavin"
                                    title="Untitled (Marfa project)."
                                    src={`${WEBPAGE_URL_IMAGES}/article/9/5.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>
                                    Dan Flavin (American, 1933-1996), Untitled (Marfa project), 1996, colored fluorescent light. Collection of Chinati
                                    Foundation, Marfa, Texas. Photo by informedmindstravel.
                                </p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                                Minimalism extends the idea that art does not imitate the real-world. By rejecting the idea that art is a
                                unique creation, it removes the distraction of the artist’s reflection. Minimalism does not represent an
                                outside reality; it seeks to make viewers react and respond only to what is directly in front of them. It
                                highlights the literal features of artworks such as weight, height, gravity, agility, and light. Frank Stella
                                said “what you see is what you see” to describe their literal and objective approach to art.
                            </p>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="Donald Judd"
                                    title="Untitled (Core Piece)."
                                    src={`${WEBPAGE_URL_IMAGES}/article/9/6.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>
                                    Donald Judd (American, 1928-1994), Untitled (Core Piece), 1969, fluorescent Plexiglas and stainless steel. Photo by
                                    Oliver Kurmis.
                                </p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                                Minimalism challenged the status quo of how art was made and viewed. It opposed giving art objects
                                importance and rejected the traditional notions of paintings and sculptures being distinct practices.
                                These aspects led to a rigid and elitist art world and posed limitations while privileging painters.
                            </p>
                            <h3 className="nino-sectionHeading">
                                Minimalist sculptures
                            </h3>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="Agnes Martin"
                                    title="Untitled #12."
                                    src={`${WEBPAGE_URL_IMAGES}/article/9/7.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>
                                    Agnes Martin (American, 1912-2004), Untitled #12, 1977, India ink, graphite, and gesso on canvas. Collection of Art
                                    Institute of Chicago. Photo by Sharon Mollerus.
                                </p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                                Most Minimalist paintings used the hard-edge technique to achieve large, simplified, geometric forms
                                on a flat surface. Lines were precise and contours were razor-sharp. The unprimed canvas stained with
                                unmodulated color rejects lyrical and mathematical composition. Hard-edge painting embodied
                                anonymous construction and simplicity of an object. Minimalist painters included Frank Stella, Ellsworth
                                Kelly, Agnes Martin, and Robert Ryman.
                            </p>
                            <h3 className="nino-sectionHeading">
                                Minimalist sculptures
                            </h3>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="Tony Smith"
                                    title="Smug."
                                    src={`${WEBPAGE_URL_IMAGES}/article/9/8.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>
                                    Tony Smith (American, 1912-1980), Smug, 1973, aluminum painted black. Collection of Glenstone Museum,
                                    Potomac, Maryland. Photo by Ron Cogswell.
                                </p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                                Similarly, to Minimalist paintings, sculptures were objective, inexpressive, and nonreferential. The
                                sculptures were monumental and used simple geometric forms made from fiberglass, plastic, sheet
                                metal, or aluminum. To highlight the raw materials used, artists often left their work unpainted.
                                However, if painted, the colors were solid, bright, and industrial.
                            </p>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="Donald Judd"
                                    title="Donald Judd (American, 1933-1996), 15 untitled works in concrete, 1980-84. Collection of Chinati Foundation,
                                    Marfa, Texas. Photo by Nan Palmero."
                                    src={`${WEBPAGE_URL_IMAGES}/article/9/9.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>
                                    Donald Judd (American, 1933-1996), 15 untitled works in concrete, 1980-84. Collection of Chinati Foundation,
                                    Marfa, Texas. Photo by Nan Palmero.
                                </p>
                            </div>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="Donald Judd"
                                    title="Donald Judd (American, 1928-1994), 100 untitled works in mill
                                    aluminum."
                                    src={`${WEBPAGE_URL_IMAGES}/article/9/10.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>
                                    Right: Donald Judd (American, 1928-1994), 100 untitled works in mill
                                    aluminum, 1982-86. Collection of Chinati Foundation, Marfa, Texas. Photo by Paul Joseph.
                                </p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                                The sculptures directly engaged with the space it occupied. Artists carefully placed their works to either
                                emphasize or reveal architectural details. Most Minimalist works often exhibited on walls, in corners, or
                                directly on the floor to highlight the artists&#39; awareness of space. By employing innovative placement,
                                artists rejected the use of pedestals. Furthermore, Minimalist sculpture invited viewer interaction, giving
                                the physical space as much importance as the object itself.
                            </p>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="Carl Andre"
                                    title="9th Cedar Corner."
                                    src={`${WEBPAGE_URL_IMAGES}/article/9/11.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>
                                    Carl Andre (American, 1935-Present), 9th Cedar Corner, 2007, Western red cedar. The Doris and Donald Fisher
                                    Collection at the San Francisco Museum of Modern Art, California. Photo by rocor.
                                </p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                                Some of the leading figures of Minimalist sculpture were Donald Judd and Carl Andre. Judd pioneered
                                the use of how constructed objects could alter the space they created. On the other hand, Andre
                                focused on the use of ordered linear and grid format. Other Minimalist artists working primarily with
                                sculpture included Sol LeWitt, Robert Morris, Anthony Caro, and Tony Smith.
                            </p>
                            <h3 className="nino-sectionHeading">
                                Minimalist Light Installations
                            </h3>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="Dan Flavin"
                                    title="Untitled (Marfa project)."
                                    src={`${WEBPAGE_URL_IMAGES}/article/9/12.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>
                                    Dan Flavin (American, 1933-1996), Untitled (Marfa project), 1996, colored fluorescent light. Collection of Chinati
                                    Foundation, Marfa, Texas. Photo by Jonas Lamis.
                                </p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                                An important aspect of Minimalism was the use of fluorescent lights to convert spaces into color zones.
                                Rather than focusing on the materials as in sculpture, the focus was the light rather than the light tubes
                                used. American artist Dan Flavin was the prominent figure of the Minimalist light movement. Flavin’s
                                installations and sculptures created with his signature commercial fluorescent light fixtures
                                revolutionized light’s role in art.
                            </p>
                            <h3 className="nino-sectionHeading">
                            Other Characteristics
                            </h3>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="Frank Stella"
                                    title="Ctesiphon I."
                                    src={`${WEBPAGE_URL_IMAGES}/article/9/13.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>
                                Frank Stella (American, 1936-Present), Ctesiphon I, 1968, polymer and polymer fluorescent paint on canvas.
Collection of The Museum of Contemporary Art, Los Angeles, California. Photo by Roman Königshofer.
                                </p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                            Minimalism continues to be a highly purified form of beauty representing order, simplicity, and
harmony. It employs single or repeated geometric forms to produce impersonal artworks lacking
expression and void of emotion. Their reliance on store-bought or factory manufactured materials such
as bricks, tiles, fluorescent bulbs to create a neutral piece remains a popular practice today.
                            </p>
                            {/* <Link to={`/${PAGE.SHOWTUTORIALS}`} className="action-btn" onClick={() => sendEventToGoogleAnalytics("Watch for free", "article")}></Link> */}
                            <Link to={`/${PAGE.SHOWTUTORIALS}`} className="action-btn" onClick={() => sendEventToGoogleAnalytics("Watch now", "article")}></Link>

                        </div>
                    </section>
                </article>
            </div>
        );
    }
}

export default ArticleMinimalism;
