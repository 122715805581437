import React, { Component } from 'react';
import ShareButtons from "./ShareButtons.jsx";
import { WEBPAGE_URL_IMAGES, WEBPAGE_URL, FB_FANPAGE_URL, YOUTUBE_FANPAGE_URL } from '../globals';
import SubscribeForm from './Forms/SubscribeForm';
import {
    FacebookIcon
} from 'react-share';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export class UnderConstructionSite extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showForm: false,
            hhLeft: 0,
            mmLeft: 0,
            ssLeft: 0,
            ddLeft: 0
        }
        // this.updateTimeLeft = this.updateTimeLeft.bind(this);
        this.formShow = this.formShow.bind(this);
        this.handleUserInput = this.handleUserInput.bind(this);
    }
    // updateTimeLeft() {
    //     const webStartPage = "02/28/2020 23:59";
    //     var date1 = new Date(webStartPage).getTime();
    //     var date2 = Date.now();
    //     var diff = date1 - date2;
    //     var msec = diff;
    //     var dd = Math.floor(msec / 1000 / 60 / 60 / 24);
    //     msec -= dd * 1000 * 60 * 60 * 24;
    //     var hh = Math.floor(msec / 1000 / 60 / 60);
    //     msec -= hh * 1000 * 60 * 60;
    //     var mm = Math.floor(msec / 1000 / 60);
    //     msec -= mm * 1000 * 60;
    //     var ss = Math.floor(msec / 1000);
    //     msec -= ss * 1000;

    //     dd = (dd < 0 ? 0 : dd)
    //     hh = (hh < 0 ? 0 : hh)
    //     mm = (mm < 0 ? 0 : mm)
    //     ss = (ss < 0 ? 0 : ss)
    //     this.setState({
    //         hhLeft: hh,
    //         mmLeft: mm,
    //         ssLeft: ss,
    //         ddLeft: dd
    //     })
    // }

    // intervalID = 0;
    formShow() {
        this.setState({
            showForm: true
        });
    }
    handleUserInput(e) {
        if (!this.state.freezeForm) {
            const name = e.target.name;
            const value = e.target.value;
            this.setState({ [name]: value });
        }
        if (this.state.accessWebsiteLogin == "quizony" &&
            this.state.accessWebsitePassword == "easy_face_dra") {
            this.props.showPage();
        }
    }
    // componentWillMount() {
    //     this.updateTimeLeft();
    // }
    // componentDidMount() {
    //     this.intervalID = setInterval(() => this.updateTimeLeft(), 1000);
    // }
    // componentWillUnmount() {
    //     clearInterval(this.intervalID);
    // }
    render() {
        return (
            <React.Fragment>
                <div className="under-construction-page">
                    <div className="background">
                        <div className="container">
                            <div className="row">
                                {/* <div className="col-md-6"> */}
                                <LazyLoadImage id="main-logo" src={WEBPAGE_URL_IMAGES + "/logo-black.webp"}
                                    alt="EASY FACE DRAW logo"
                                    onError={(e) => { e.target.onerror = null; e.target.src = WEBPAGE_URL_IMAGES + "/logo-small.png" }} />
                                <h6>Comming soon</h6>
                                <p style={{ fontSize: "16px", textAlign: "center", margin: "20px 0 10px" }}>We are creating website designed for drawing beginners.</p>
                                {/* </div> */}
                                {/* <div className="col-md-6"></div> */}
                            </div>
                            <div className="row">
                                {/* <div className="col-md-12"> */}
                                <center>
                                    <iframe width="100%" height="335px"
                                        src="https://www.youtube.com/embed/vPZmgaJoZPI"
                                        srcDoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/vPZmgaJoZPI?autoplay=1><img src=https://img.youtube.com/vi/vPZmgaJoZPI/hqdefault.jpg alt='How to draw an eye? video tutorial, easy face draw'><span>▶</span></a>"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        title="How to draw an eye? video tutorial, easy face draw">
                                    </iframe>
                                </center>

                                <div className="subscribe-wrapper">
                                    <p style={{ fontSize: "16px", textAlign: "center", margin: "20px 0 10px" }}>Sign up now to get early notification of our launch date!</p>
                                    <SubscribeForm></SubscribeForm>
                                </div>
                                {/* </div> */}
                                {/* <div className="col-md-6">
                                    <div className="count-down">
                                        <p>Time to launch</p>
                                        <div>
                                            {this.state.ddLeft}
                                            <p>Days</p>
                                        </div>
                                        <div>
                                            {this.state.hhLeft}
                                            <p>Hours</p>
                                        </div>
                                        <div>
                                            {this.state.mmLeft}
                                            <p>Min</p>
                                        </div>
                                        <div>
                                            {this.state.ssLeft}
                                            <p>Sec</p>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div className="row">
                                {/* <div className="col-md-6"> */}
                                <div className="footer">
                                    <center>
                                        <p>Follow us</p>
                                        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
                                        <a href={YOUTUBE_FANPAGE_URL} >
                                            <i className="fa fa-youtube-play" style={{ fontSize: '53px', color: 'red', display: 'inline-block', padding: '0 10px' }} ></i>
                                        </a>
                                        <a href={FB_FANPAGE_URL} style={{ display: 'inline-block', padding: '10px', transform: 'translateY(5px)' }}>
                                            <FacebookIcon size={50} round={true}></FacebookIcon>
                                        </a>
                                        <br/>
                                        <br/>
                                        <h3 className="faq-help">
                                            contact@easyfacedraw.com
                                        </h3>

                                        {/* <ShareButtons
                                                title="EasyFaceDraw.com"
                                                description="EasyFaceDraw.com is a website designed for drawing beginners, this comprehensive class is perfect for people who want to learn a valuable new skill but want to do it from the comfort of their own homes. Just check our video tutorials :)"
                                                quote="EasyFaceDraw.com is a website designed for drawing beginners, this comprehensive class is perfect for people who want to learn a valuable new skill but want to do it from the comfort of their own homes"
                                                hashtag={["drawing", "video", "tutorial", "art"]}
                                                media={WEBPAGE_URL_IMAGES + "/logo.webp"}
                                                url={WEBPAGE_URL}
                                            ></ShareButtons> */}
                                    </center>
                                </div>
                                {/* </div> */}
                            </div>
                            <a href="#" style={{ position: "fixed", bottom: "0", left: "0", opacity: "0" }} onClick={() => this.formShow()}>Early access</a>
                        </div>
                    </div>
                </div>

                {this.state.showForm && <div id="nino-loginForm" className={"container user-form-container open"}>
                    <div className="row">
                        <div className="col-md-offset-4 col-md-4 user-form">
                            <h3>Get access</h3>
                            <form>
                                <div className="form-group">
                                    <input onChange={(event) => this.handleUserInput(event)} name="accessWebsiteLogin" type="text" placeholder="Login" className="form-control" required />
                                </div>
                                <div className="form-group">
                                    <input onChange={(event) => this.handleUserInput(event)} name="accessWebsitePassword" type="password" placeholder="Password" className="form-control" required />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>}
            </React.Fragment >

        );
    }
}

export default UnderConstructionSite;
