import React, { Component } from 'react';
import {
    WEBPAGE_URL_IMAGES
} from '../../globals.js';
// import { sendEventToGoogleAnalytics } from '../../functions';

const COOKIES_COOKIE_KEY = "COOKIES_COOKIE_KEY";
const COOKIES = [
    {
        name: "Necessary",
        text: "they enable the correct use of the Services on the Website, without their use, the website will not function properly."
    },
    {
        name: "Functional",
        text: "they enable remembering the Website functionalities preferred by the User, in particular the languages ​​and given consents."
    },
    // {
    //     name: "Advertising",
    //     text: "they enable the delivery of advertising content tailored to the User's preferences."
    // },
    {
        name: "Analytical",
        text: "they enable tracking activity on the Website and creating statistics on the use of the Website."
    }
]

class CookiesPopup extends Component {

    constructor() {
        super();
        const cookiesStatus = COOKIES.reduce((acc, item) => {
            acc[item.name] = false;
            return acc;
        }, {});
        this.state = {
            visible: false,
            cookies: null,
            cookiesStatus: { ...cookiesStatus }
        }
        this.acceptAllCookiesClick = this.acceptAllCookiesClick.bind(this);
        this.acceptSelectedCookies = this.acceptSelectedCookies.bind(this);
        this.checkboxClick = this.checkboxClick.bind(this);
        this.saveCookies = this.saveCookies.bind(this);
    }
    componentDidMount() {
        const cookies = localStorage.getItem(COOKIES_COOKIE_KEY);

        if (cookies) {
            this.setState(
                {
                    cookies: cookies,
                    visible: false
                }
            )
        }
        else {
            this.setState(
                {
                    visible: true
                }
            )
        }
    }
    saveCookies(cookiesArray) {
        localStorage.setItem(COOKIES_COOKIE_KEY, JSON.stringify(cookiesArray));
        this.setState({
            visible: false
        })
    }
    acceptAllCookiesClick() {
        const cookiesKeys = COOKIES.reduce((acc, item) => {
            acc[item.name] = true
            return acc
        }, {});
        this.saveCookies(cookiesKeys);
    }
    acceptSelectedCookies() {
        const cookiesKeys = this.state.cookiesStatus;
        this.saveCookies(cookiesKeys);
    }
    checkboxClick(cookieName) {
        let newCookiesStatus = this.state.cookiesStatus;
        newCookiesStatus[cookieName] = !this.state.cookiesStatus[cookieName];
        this.setState({
            cookiesStatus: newCookiesStatus
        });
    }

    render() {
        let cookiesHTML = COOKIES.reduce((sum, item) =>
            [...sum, <li key={item.name}>
                <input className="checkbox-big" type="checkbox" name={"cookies_" + item.name} onChange={(e) => this.checkboxClick(item.name, e)} />
                <strong>{item.name}</strong> – {item.text}
            </li>],
            [])
        return (
            this.state.visible &&
            <div className="cookies-popup">
                <div className="cookies-popup-container">
                    <p className="cookies-popup-title">Have a Cookie :)</p>
                    <img src={WEBPAGE_URL_IMAGES + "/cookiesImage.png"} alt="cookies" />
                    <p className="cookies-popup-paragraph">
                        "The website uses cookies to adapt the content and advertisements, as well as to create statistical analysis of the use of the Website. If you agree to all cookies, click "I accept all cookies". If you do not agree to all cookies, it is possible to accept only selected cookies by selecting them and then clicking "I accept selected cookies".
                    </p>
                    <br />
                    <ul>
                        {cookiesHTML}
                    </ul>
                    <br />
                    <p>
                        You can change cookie settings in your browser at any time. You can find more details in our Privacy Policy.
                    </p>

                    <button className="action-btn" onClick={this.acceptAllCookiesClick}>
                        I accept all cookies.
                    </button>
                    <br />
                    <button className="white-btn" onClick={this.acceptSelectedCookies}>
                        I accept selected cookies.
                    </button>

                </div>


            </div>
        );
    }
}

export default CookiesPopup;


