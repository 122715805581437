import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { generateHeaderNavigation } from '../../functions.js';

class UserPanel extends Component {

    render() {
        return (
            <div id="nino-userPanel" className={this.props.open ? "open" : ""}>
                <nav>
                    <ul>
                        {generateHeaderNavigation(this)}
                        <li className="empty-list-item hide-on-desktop"></li>
                        <li className="empty-list-item hide-on-desktop"></li>
                        <li className="empty-list-item hide-on-desktop"></li>

                        <li className="user-panel-link">
                            <Link className="overlay" to={"/mycourses"} onClick={() => this.props.closeForms()}>
                                <span className="glyphicon glyphicon-education" aria-hidden="true"></span> My courses
                            </Link>
                        </li>
                        {(!this.props.user.isGoogleUser) &&
                            <li className="user-panel-link">
                                <Link className="overlay" to={"/user"} onClick={() => this.props.closeForms()}>
                                    <span className="glyphicon glyphicon-user" aria-hidden="true"></span> My account
                                </Link>
                            </li>
                        }
                        {this.props.isAdmin &&
                            <li className="user-panel-link" style={{ top: '10%' }}>
                                <Link className="overlay" to={"/imagesreviews"} onClick={() => this.props.closeForms()}>
                                    <span className="glyphicon glyphicon-check" aria-hidden="true"></span> Reviews
                                </Link>
                            </li>
                        }
                        <li className="user-panel-link" onClick={() => this.props.logOutUser()}>
                            <div>
                                <span className="glyphicon glyphicon-off" aria-hidden="true"></span> Log out
                            </div>
                        </li>                        
                    </ul>
                </nav>
                {/* <i className="mdi mdi-close nino-close user-panel-close" onClick={() => this.props.closeForms()}></i> */}
            </div>
        );
    }
}

export default UserPanel;


