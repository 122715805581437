import React, { Component } from 'react';
import Header from "./Header.jsx";
import { Link } from 'react-router-dom'
import { PAGE, WEBPAGE_URL_IMAGES } from '../globals';
import ImageModal from './ImageModal';
import { sendEventToGoogleAnalytics } from '../functions';
import { Helmet } from "react-helmet";

class ArticleHowToDrayAnEye extends Component {

    render() {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>How to Draw an Eye</title>
                    <link rel="canonical" href="https://www.easyfacedraw.com/article-how-to-draw-an-eye" />
                    <meta name="description" content="Learn how to draw an eye by following the steps below!" />
                    <meta name="keywords" content="drawing tutorial, video tutorials, how to draw, eye sketching, eye drawing, eye shading" />
                </Helmet>
                <Header {...this.props} ></Header>
                <article>
                    <section id="nino-whatWeDo">
                        <div className="container">
                            <h1 className="nino-sectionHeading">
                                <span className="nino-subHeading big-font">How to Draw an Eye</span>

                            </h1>
                            <h2 className="article-author">
                                Claudia Preza
                                <br />
                                June 15, 2020
                                </h2>
                            <br />
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author=""
                                    title=""
                                    src={`${WEBPAGE_URL_IMAGES}/article/3/1.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                            </div>
                            <h3 className="nino-sectionHeading">
                                Learn how to draw an eye by following the steps below!
                            </h3>
                            <br />
                            <p className="nino-sectionDesc read-more-description">
                                Before starting, you will need the following drawing tools:
                            </p>
                            <ul className="nino-sectionDesc read-more-description">
                                <li>two different value pencils such as 3B and 5B or 2B and 4B</li>
                                <li>two erasers, one sharpened at the end and a kneadable one to weaken the intensity of graphite</li>
                                <li>a pencil sharpener</li>
                                <li>a couple of sheets of paper</li>
                            </ul>

                            <h3 className="nino-sectionHeading">
                                Warm-Up
                           </h3>
                            <p className="nino-sectionDesc read-more-description">
                                Before drawing the eye, a warm-up is necessary. It is essential to have control of your hand while
                                drawing and by warming up you can achieve that. To warm-up, start by drawing some simple lines. Draw
                                two dots and then connect them. Continue doing so in all different directions while trying to keep the
                                lines as straight as possible. The next step is to draw circles and ellipses. Try to lock the wrist and draw
                                them by just moving in circular motions. The last step is to work on gradients, this would help when
                                shading. Create gradients by pressing down the pencil and then gradually releasing tension. Practice
                                creating detailed and blurry gradients in lines, angles, and circles. Once you have completed these
                                warm-up exercises you are ready to begin sketching!
                            </p>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author=""
                                    title=""
                                    src={`${WEBPAGE_URL_IMAGES}/article/3/2.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                            </div>

                            <h3 className="nino-sectionHeading">
                                Sketching
                            </h3>
                            <p className="nino-sectionDesc read-more-description">
                                The first step for drawing an eye is to sketch the basic shape and outline of the eye. While sketching, use
                                the lightest value pencil, either 2B or 3B. The sketch should include the characteristic points of the eye
                                and general geometrical arrangement. Start by faintly plotting dots and connecting them with light lines.
                                Draw arches to start shaping the rounder sections of the eye such as the eyeball, tear gland, upper and
                                lower eyelids. Create multiple lines to sketch the form and thickness of certain details such as the eye
                                socket and the connection to the nose bridge.
                            </p>
                            <p className="nino-sectionDesc read-more-description">
                                Before sketching the iris and pupil, determine the eye’s direction. If the eye will be looking forward, the
                                iris’ shape is an ellipse close to a circle. After drawing the iris, draw the pupil on the center, keeping in
                                mind there is a flash of light on the eyeball. Sketch a small rectangle to set apart the area that will give
                                the iris and pupil its glossy effect. The final step of sketching is to gently create the shape of the
                                eyebrow. Keep in mind the distance and proportions of the different components of the eye while
                                drawing.
                            </p>


                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author=""
                                    title=""
                                    src={`${WEBPAGE_URL_IMAGES}/article/3/3.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                            </div>

                            <h3 className="nino-sectionHeading">
                                First Shading
                            </h3>
                            <p className="nino-sectionDesc read-more-description">
                                After sketching the next step is to apply light gray and gray values. This step will not be a strong shading.
                                The first shading will add preparatory depth to the drawing. It is important to avoid pressing the pencil
                                down too hard and creating dark values, making it difficult to erase later. Once again you will be using
                                your lightest value pencil. The focus of the first shading is to create shadows under the eyelid, the lower,
                                and upper eyelids.
                            </p>
                            <p className="nino-sectionDesc read-more-description">
                                Lightly shade between the eyebrows and eyelids. The lines created for the lower eyelid should be
                                downward up to the edge of the eyebrow. For the area between the eyelid and eyebrows, the lines
                                should be forward to show the natural arch of the brow bone. Create short lines at the corners of the
                                eye and under the eyebrow to start making the areas darker by delicately applying value. For the inside
                                of the eye, delicately shade the pupil and slightly obscure the iris. Additionally, lightly go over the eye
                                white and skin around the eye. This is important since leaving the eyeball completely white leads to an
                                unrealistic drawing lacking depth. Keep in mind that the areas of the eye with lighter value would be the
                                eye whites, lower eyelids, brow bone, and the inner corner near the tear duct.
                            </p>


                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author=""
                                    title=""
                                    src={`${WEBPAGE_URL_IMAGES}/article/3/4.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                            </div>

                            <h3 className="nino-sectionHeading">
                                Final Shading
                            </h3>
                            <p className="nino-sectionDesc read-more-description">
                                After setting up the initial shading of your drawing, move on to the final shading. This step will bring out
                                the deepest shadows and outlines of the eye. Switch to a darker value pencil, 4B or 5B, as it is easier to
                                create darker values with softer graphite. Since the pencil is naturally darker, there is no need to add to
                                much pressure to the pencil. Use gentle hand movements to create delicate blurred motions and
                                uniformed lines to create tonal transitions. A tip for this section is to use a blank piece of paper under
                                the hand to avoid smudging and staining while shading.
                            </p>
                            <p className="nino-sectionDesc read-more-description">
                                The first section to darken is the upper eyelid. Start shading to create the natural curvature of the eye.
                                The dark shadow should follow continue up to the tear duct. To better blend the darker values, pull the
                                lines up. Keep darkening until you create the hollow of the eyelid. Once done, darken the creases to
                                produce dark edges. Keep in mind that the connection of the eyelid and the eyebrow near the nose will
                                be one of the darker areas of the drawing. Moving on to the eyebrows, create fast and soft lines
                                following the natural direction of the hair. Only slightly darken the eyebrow hair for now.
                            </p>
                            <p className="nino-sectionDesc read-more-description">
                                The next section to work on is the lower eyelid. Start shading under the eye to create the roundness of
                                the eye. The lower part of the eyelid helps create the spherical form of the eye, so keep the lines soft
                                and curved. After achieving the desired shading for the lower portion of the eye, move on to the center.
                                Strengthen and darken the intensity of the iris, noting that the edge should be darker. Repeat for the
                                pupil. Using the kneadable eraser, gently dab where the light hits inside the eye.
                            </p>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author=""
                                    title=""
                                    src={`${WEBPAGE_URL_IMAGES}/article/3/5.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                                Next, start forming the face by focusing on the side of the nose and cheek. Darken the area until
                                creating a gray shade. Blur the place where the eyelid and cheek meet to create a soft transition. Then
                                continue shading the area gently to bring out the light contrast. Afterward, move on to finishing the
                                shading of the eyebrow. Strengthen the shadow under the eyebrow, making sure to leave some areas
                                unshaded to illustrate light. Underline the area of the eyebrow arc by shading at a horizontal angle.
                                After finishing the eyebrow, strengthen and darken the shadows of the inner areas of the eye. Note that
                                the lines and shading should follow the natural angle of the eye shape.
                            </p>
                            <p className="nino-sectionDesc read-more-description">
                                The darkest areas of your drawing will be some sections of the eyebrow, the inner and outer corner of
                                the outer eye, the lower sides of the eyelid, and the outline of the iris. Strengthen these areas to a dark
                                gray or black. By darkening these areas, the high contrast will cause the light in the drawing to come out
                                naturally. Completely blacken the pupil. Create the iris’ details by drawing irregular lines radiating from
                                the center of the iris. The last section to work on is the eyelashes. For the top eyelashes, use dark
                                straight lines at the center and curve them following the natural angle of the eyelid. For the lower
                                eyelashes, do the same but keep them shorter and softer. Draw the reflection of the eyelashes lightly on
                                the light area of the eye. To finish the drawing, include realistic details such as skin bumps on the tear
                                duct and skin. Lastly, strengthen shadows where needed and lightly tap eraser to lighten areas as
                                needed.
                            </p>
                            <p className="nino-sectionDesc read-more-description">
                                You have now successfully drawn an eye!
                            </p>

                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author=""
                                    title=""
                                    src={`${WEBPAGE_URL_IMAGES}/article/3/6.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                            </div>


                            {/* <Link to={`/${PAGE.SHOWTUTORIALS}`} className="action-btn" onClick={() => sendEventToGoogleAnalytics("Watch for free", "article")}></Link> */}
                            <Link to={`/${PAGE.SHOWTUTORIALS}`} className="action-btn" onClick={() => sendEventToGoogleAnalytics("Watch now", "article")}></Link>

                        </div>
                    </section>
                </article>
            </div>
        );
    }
}

export default ArticleHowToDrayAnEye;
