import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import {
    PASSWORD_MIN_LENGTH, PASSWORD_MAX_LENGTH
} from '../../globals.js';
class ResetPasswordTemplate extends Component {
    render() {
        return (
            <React.Fragment>
            {this.props.warningMessage &&
                <React.Fragment>
                    <div className="nino-formInput validation-warning-box"> {this.props.warningMessage} </div>
                </React.Fragment>
            }
            {this.props.successMessage &&
                <React.Fragment>
                    <div className="nino-formInput validation-success-box"> {this.props.successMessage} </div>
                </React.Fragment>
            }
            <form action="" className="form-big">
                
                {!this.props.successMessage && <React.Fragment>
                    {this.props.freezeForm ?
                        <div className="form-loading-circle">
                            <Loader type="TailSpin" color="#95e1d3" height="150" width="150" />
                        </div>
                        :
                        <React.Fragment>
                            <h2 className="nino-sectionHeading">
                                Insert new password
                            </h2>
                            <input value={this.props.password} name="password" minLength={PASSWORD_MIN_LENGTH} maxLength={PASSWORD_MAX_LENGTH} onChange={(event) => this.props.handleUserInput(event)} type="password" placeholder="Password" className="input" required />
                            <input value={this.props.repeatPassword} name="repeatPassword" minLength={PASSWORD_MIN_LENGTH} maxLength={PASSWORD_MAX_LENGTH} onChange={(event) => this.props.handleUserInput(event)} type="password" placeholder="Repeat password" className="input" required />
                            <input onClick={e => this.props.handelButtonSubmit(e, "resetpassword")} type="submit" className="green-btn" value="SUBMIT" />
                        </React.Fragment>
                    }
                </React.Fragment>}
            </form>
            </React.Fragment>
        );
    }
}

export default ResetPasswordTemplate;
