import React, { Component } from 'react';
import Header from "./Header.jsx";
import { Link } from 'react-router-dom'
import { PAGE, WEBPAGE_URL_IMAGES } from '../globals';
import ImageModal from './ImageModal';
import { sendEventToGoogleAnalytics } from '../functions';
import { Helmet } from "react-helmet";

class ArtilcePortraitureAnEverExpandingForm extends Component {

    render() {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Portraiture: An Ever-Expanding Form</title>
                    <link rel="canonical" href="https://www.easyfacedraw.com/article-portraiture-an-ever-expanding-form" />
                    <meta name="description" content="The portrait is an ancient form, one of the first in the history of art." />
                    <meta name="keywords" content="Portraiture, face, face drawing, drawing, An Ever-Expanding Form" />

                </Helmet>
                <Header {...this.props} ></Header>
                <article>
                    <section id="nino-whatWeDo">
                        <div className="container">
                            <h1 className="nino-sectionHeading">
                                <span className="nino-subHeading big-font">Portraiture: An Ever-Expanding Form</span>

                            </h1>
                            <h2 className="article-author">
                                Peter Letzelter-Smith
                                <br />
                                May 18, 2021
                                </h2>
                            <br />

                            <p className="nino-sectionDesc read-more-description">
                                The portrait is an ancient form, one of the first in the history of art. Its beginnings
                                — and most of its subsequent development — are tied to an economic concept coined at
                                the turn of the twentieth century: conspicuous consumption. The concept describes an act
                                that is meant to display power and flaunt wealth.
	                        </p>
                            <p className="nino-sectionDesc read-more-description">
                                Given the amount of time and skill that needed to be dedicated to painting a
                                portrait — and the rarity of materials in the ancient world — having one commissioned
                                was in itself a mark of power. Add to this human vanity driving a desire to be
                                memorialized and the subject of an artifact, and a form elemental to human expression
                                was born almost as soon as humans began organizing themselves into higher cultures.
                             </p>
                            <p className="nino-sectionDesc read-more-description">
                                It’s clear that early civilizations, such as those that developed in ancient Egypt
                                and China, produced the first known examples of portraiture. Political leaders like the
                                pharaohs were not only memorialized but their power projected. This continued through
                                the rise of Greece and the Roman Empire, with a slow expansion from political rulers to
                                other powerful citizens who could commission portraits.
                            </p>
                            <h3 className="nino-sectionHeading">
                                The Humanism of the Renaissance
                           </h3>
                            <p className="nino-sectionDesc read-more-description">
                                Our modern conception of the portrait is anchored in the Renaissance and
                                subsequent Reformation. This is due in part to most portraiture from the ancient world
                                having been lost to the ravages of time, while the now-famous works of the Renaissance
                                still exist to be seen. But the underlying dynamic of portraiture — the desire to project
                                and memorialize wealth and power — was carried on into the more modern world and
                                transformed.
                            </p>
                            <p className="nino-sectionDesc read-more-description">
                                This is best exemplified by the most famous portrait in the world, Leonardo da
                                Vinci’s Mona Lisa. Today it’s likely the single most valuable work of art in the world —
                                insured by the Louvre for US$100 million in 1962 (US$850 million in current dollars) —
                                while at the time of its painting it was the result of what appears to have been a fairly
                                common commission by a wealthy nobleman. The painting is believed to be of Lisa
                                Gherardini, wife of Italian nobleman Francesco del Giocondo. It’s hard to imagine that
                                anyone involved expected it to become a cultural icon centuries later.
                            </p>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="- Leonardo da Vinci"
                                    title="Morning Sun"
                                    src={`${WEBPAGE_URL_IMAGES}/article/6/1.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Leonardo da Vinci, Mona Lisa</p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                                The journey of this work from a straightforward commissioned painting to
                                cultural touchstone is vastly complex. But one aspect is Leonardo’s profound skill in
                                capturing a moment of time as inhabited by an individual. This humanistic power has
                                become the very premise of portraiture.
                            </p>
                            <p className="nino-sectionDesc read-more-description">
                                For centuries after the painting of the Mona Lisa in the early 1500s, portraiture
                                evolved and was explored by individual artists as a way to express an individual — still
                                usually a paying customer — and the social context they inhabited. The examples are far
                                too numerous to list in full.
                            </p>
                            <p className="nino-sectionDesc read-more-description">
                                Rembrandt capturing members of the urban merchant class who fueled the Dutch
                                Golden Age in works like De Staalmeesters (Syndics of the Drapers’ Guild) or Het
                                Joodse bruidje (The Jewish Bride).
                            </p>
                            <p className="nino-sectionDesc read-more-description">
                                Or Francisco Goya, whose borderline sardonic portraits of the Spanish nobility —
                                Portrait of the Duchess of Alba (The Black Duchess) or Manuel Osorio Manrique de
                                Zúñiga (The Boy in Red) — capture the eccentrics found at the top of the social
                                hierarchy.
                            </p>
                            <p className="nino-sectionDesc read-more-description">
                                This expansive appreciation for the individual was one of the deepest inspirations
                                for the Renaissance and its more political cousin, the Reformation. And the portrait — as
                                opposed to, say, the soaring but impersonal heights of Gothic cathedrals — is one of its
                                artistic touchstones. It’s not surprising that the artists who were being hired to capture
                                others quickly internalized this and began to develop the prominent subset of portraiture,
                                the self-portrait.
                            </p>
                            <h3 className="nino-sectionHeading">
                                Creating a Self-Image
                                                       </h3>
                            <p className="nino-sectionDesc read-more-description">
                                This form rapidly became the norm in European painting and has continued into
                                the present day. One of the earliest artists of the modern era — Albrecht Dürer, who died
                                in 1528 — is still, even to modern eyes, one of its masters. He produced only a few self-
                                portraits, but his Self-Portrait With Fur-Trimmed Robe gives viewers one of the most
                                searing stares in the history of art. The intensity of the gaze, bold and full of confidence,
                                is a testament to the individual asserting his place in the world and the power of the
                                creative act.
                            </p>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="- Albrecht Dürer"
                                    title="Self-Portrait With Fur-Trimmed Robe"
                                    src={`${WEBPAGE_URL_IMAGES}/article/6/2.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Albrecht Dürer, Self-Portrait With Fur-Trimmed Robe</p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                                It’s a “look” that has been modeled ever since, including on countless album
                                covers, romantic and attention-seeking. There’s a line in the art of portraiture that leads
                                from it right into the modern-day. A stark example is the best-known work of one modern
                                master of portraiture, the photographer Robert Mapplethorpe, and his Album Cover for
                                Patti Smith, Horses. Or the wide variety of searing self-portraits produced by Frida
                                Kahlo, who deftly incorporated her psychological totems into what has become one of the
                                modern world’s best-known oeuvres with experimental self-portraits like The Two Fridas
                                and The Wounded Deer.
                            </p>
                            <p className="nino-sectionDesc read-more-description">
                                Painters — and then photographers —have been commissioned to capture the
                                essence of the upper classes since the Renaissance. But over the centuries portraiture
                                expanded to not only include artists themselves — via the self-portrait — but also people
                                whose social position did not make them stand out during their lifetime.
                            </p>
                            <p className="nino-sectionDesc read-more-description">
                                One of the most famous of these is Johannes Vermeer The Milkmaid, which
                                captures a woman doing the domestic dirty work required so that the lives of better off
                                can continue in comfort. Unlike his more famous Girl with a Pearl Earring — which is
                                an imaginary figure known as a tronie, in this case one wearing an oriental turban and
                                ostentatious pearl earring — The Milkmaid takes the same kind of artistic care that had
                                been lavished on nobles and the wealthy for centuries and focused it on a more common
                                face.
                            </p>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="- Johannes Vermeer"
                                    title="Girl with a Pearl Earring"
                                    src={`${WEBPAGE_URL_IMAGES}/article/6/3.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Johannes Vermeer, Girl with a Pearl Earring</p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                                This widening of what was the proper subject of portraiture would continue to
                                expand, rapidly so in the twentieth century. Likewise, the psychological intensity of the
                                modern world would become increasingly incorporated into portraiture, as with Kahlo’s
                                work. A painter like Francis Bacon — whose Triptych: Three Studies for Portrait of
                                Lucian Freud captures the warped likeness of another of the century’s most prominent
                                painters — and the gentle strangeness of the iconic photographer Diane Arbus’s work —
                                most famously <a href="https://en.wikipedia.org/wiki/Identical_Twins,_Roselle,_New_Jersey,_1967#/media/File:Identical_Twins,_Roselle,_New_Jersey,_1967.jpg">Identical Twins, Roselle, New Jersey, 1967</a> — brought the ancient form
                                very much into the modern world.
                            </p>


                            {/* <Link to={`/${PAGE.SHOWTUTORIALS}`} className="action-btn" onClick={() => sendEventToGoogleAnalytics("Watch for free", "article")}></Link> */}
                            <Link to={`/${PAGE.SHOWTUTORIALS}`} className="action-btn" onClick={() => sendEventToGoogleAnalytics("Watch now", "article")}></Link>

                        </div>
                    </section>
                </article>
            </div>
        );
    }
}

export default ArtilcePortraitureAnEverExpandingForm;
