import React, { Component } from 'react';
// import PhotoGaleryItem from "./PhotoGaleryItem";
import { WEBPAGE_URL_IMAGES } from '../globals.js';
import ImageModal from './ImageModal';

class PhotosGalery extends Component {
    render() {
        const itemsContent = [
            {
                title: 'How to draw lips',
                author: 'by: Cassandra Leigh'
            },
            {
                title: 'How to draw an eye',
                author: 'by: SharonOyetunde'
            },
            {
                title: 'How to draw an eye',
                author: 'by: Emmaatje28'
            },
            {
                title: 'How to draw an eye',
                author: 'by: Cassandra Leigh'
            },
            {
                title: 'How to draw an eye',
                author: 'by: karolcez'
            },
            {
                title: 'How to draw an eye',
                author: 'by: karolcez'
            },
            {
                title: 'How to draw an eye',
                author: 'by: karolcez'
            },
            {
                title: 'How to draw an eye',
                author: 'by: Rychu'
            },
            {
                title: 'How to draw an eye',
                author: 'by: Slavek69'
            },
            {
                title: 'How to draw an eye',
                author: 'by: MagdaLena'
            },
            {
                title: 'How to draw an eye',
                author: 'by: MetaJohny'
            }
        ];
        // const columnsItems = [];

        // for (let index = itemsContent.length - 1; index >= 0; index--) {
        //     const element = itemsContent[itemsContent.length - 1 - index];
        //     if (!columnsItems[index % 3]) {
        //         columnsItems[index % 3] = [];
        //     }
        //     columnsItems[index % 3].push(
        //         <ImageModal key={index + 1} number={index + 1} title={element.title} author={element.author} imagePrefix="/our-work/img-"></ImageModal>

        //     )
        // }
        const columnsItems = [];

        for (let index = itemsContent.length - 1; index >= 0; index--) {
            const element = itemsContent[itemsContent.length - 1 - index];
            columnsItems.push(
                // <div className="portfolio-column">
                    <ImageModal key={index + 1} number={index + 1} title={element.title} author={element.author} imagePrefix="/our-work/img-"></ImageModal>
                // </div>
            )
        }


        return (
            <section id="nino-portfolio">
                <div className="container">
                    <h2 className="nino-sectionHeading">
                        Our students' art
                    </h2>
                    {/* <div className="portfolio-row">
                        <div key="column_1" className="portfolio-column">
                            {columnsItems[2]}
                        </div>
                        <div key="column_2" className="portfolio-column">
                            {columnsItems[0]}
                        </div>
                        <div key="column_3" className="portfolio-column">
                            {columnsItems[1]}
                            <div className="place-for-your-image">
                                <p>We can place your artwork here</p>
                            </div>
                        </div>
                    </div> */}
                    <div id="portfolio-photos-gallery">
                        {columnsItems}
                    </div>
                </div>
            </section>
        );
    }
}

export default PhotosGalery;
