import React, { Component } from 'react';
import Header from "./Header.jsx";
import { getUserName, getTutorialsFromDb, getImageForReviewUrl, getImagesForReview, sendImageReview } from '../functions';
import Loader from 'react-loader-spinner';
import { MELODY_ICON } from '../globals';
import { LazyLoadImage } from 'react-lazy-load-image-component';

class ImagesReviews extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tutorials: [],
            imgaesForReview: [],
            inProgress: false,
            successMessage: "",
            warningMessage: "",
            imgPreviewSrc: null,
            audioPreviewSrc: null,
            selectedImageFile: null,
            selectedAudioFile: null,
            uploadProgress: 0,
            showSubmitPopup: false
        }
        this.submitHandler = this.submitHandler.bind(this);
        this.submitPopupHandler = this.submitPopupHandler.bind(this);
        // this.myRef = React.createRef();
        this.myRef = [];
        this.readURL = this.readURL.bind(this);
        this.readAudio = this.readAudio.bind(this);
        this.deletePreviewImage = this.deletePreviewImage.bind(this);
        this.deletePreviewAudio = this.deletePreviewAudio.bind(this);
        this.showSubmitPopup = this.showSubmitPopup.bind(this);
        // this.uploadHandler = this.uploadHandler.bind(this);
    }

    componentDidMount() {
        getImagesForReview(this);
        getTutorialsFromDb(this);
    }

    showSubmitPopup(show) {
        this.setState({ showSubmitPopup: show });
    }

    submitPopupHandler() {
        this.showSubmitPopup(false);
        sendImageReview(this, this.state.submitPopup.authorUsername, this.props.user.login,
            this.state.submitPopup.tutorialNumber, this.myRef[this.state.submitPopup.itemIndex].current.value, this.state.selectedImageFile,
            this.state.selectedAudioFile,
            this.props.loginKey, this.props.adminAccessKey).then(() => {
                this.setState({
                    imgPreviewSrc: null,
                    audioPreviewSrc: null,
                    selectedImageFile: null,
                    selectedAudioFile: null,
                });
                getImagesForReview(this);
            });
    }

    submitHandler(tutorialNumber, itemIndex, authorUsername) {
        this.setState({
            submitPopup: {
                tutorialNumber: tutorialNumber,
                itemIndex: itemIndex,
                authorUsername: authorUsername
            }
        });
        this.showSubmitPopup(true);
    }

    readURL(files) {
        let that = this;
        if (files && files[0]) {
            var reader = new FileReader();
            reader.onload = function (e) {
                that.setState({
                    imgPreviewSrc: e.target.result,
                    selectedImageFile: files[0]
                });
            };
            reader.readAsDataURL(files[0]);
        }
    }
    readAudio(files) {
        let that = this;
        if (files && files[0]) {
            that.setState({
                selectedAudioFile: files[0],
                audioPreviewSrc: <React.Fragment>
                    {files[0].name}<br />{(Math.round(files[0].size * 100 / (1024 * 1024))) / (100)} mb
                </React.Fragment>
            });
            // var reader = new FileReader();
            // reader.onload = function (e) {
            //     that.setState({
            //         audioPreviewSrc: e.target.result,
            //         selectedAudioFile: files[0]
            //     });
            // };
            // reader.readAsDataURL(files[0]);
        }
    }
    // uploadHandler() {
    //     uploadImageForReview(this, this.state.selectedFile, this.props.user.login,
    //          this.props.user.name, this.props.user.surname, this.props.tutorialAccessKey, this.props.tutorial.number);
    // }

    deletePreviewImage() {
        this.setState(
            {
                imgPreviewSrc: null,
                selectedImageFile: null
            }
        );
    }
    deletePreviewAudio() {
        this.setState(
            {
                audioPreviewSrc: null,
                selectedAudioFile: null
            }
        );
    }

    render() {
        const submitPopup = <div>
            <div className="popup-wrapper">
            </div>
            <div className="popup">
                <p>Are you sure you want submit your review?</p>
                <button className="btn btn-default" onClick={() => this.showSubmitPopup(false)}>No</button>
                <button className="btn btn-success" onClick={() => this.submitPopupHandler()}>Yes</button>
            </div>
        </div>


        this.myRef = [];
        const listItems = this.state.imgaesForReview.slice(0, 1).map((item, itemIndex) => {
            this.myRef.push(React.createRef());
            let tutorialName = "";
            for (let index = 0; index < this.state.tutorials.length; index++) {
                if (this.state.tutorials[index].number == item.tutorialNumber) {
                    tutorialName = this.state.tutorials[index].title;
                    break;
                }
            }
            return (
                <li key={itemIndex}>
                    <LazyLoadImage src={getImageForReviewUrl(item.username, item.userImage)} alt="Image for review" />
                    <p>
                        <span>Author: {getUserName({ login: item.username, name: item.name, surname: item.surname }).slice(0, 20)}
                            {(getUserName({ login: item.username, name: item.name, surname: item.surname }).length > 20) && '...'}
                        </span>
                        <span>{tutorialName}</span>
                        <span>{item.createDate}</span>
                    </p>
                    <form>
                        <textarea placeholder="Review..." ref={this.myRef[itemIndex]} rows="2"></textarea>
                    </form>
                    <React.Fragment>
                        {this.state.imgPreviewSrc &&
                            <React.Fragment>
                                <i onClick={() => this.deletePreviewImage()} className="mdi mdi-close nino-close img-preview-close" ></i>
                                <LazyLoadImage className="img-preview" src={this.state.imgPreviewSrc} alt="result image preview" />
                            </React.Fragment>
                        }
                        {!this.state.selectedImageFile && <div className="box">
                            <input onChange={(e) => this.readURL(e.target.files)} type="file" name="file-5[]" id="file-5" accept="image/x-png,image/jpeg" className="inputfile inputfile-4" data-multiple-caption="{count} files selected" />
                            <label htmlFor="file-5"><figure><svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17"><path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" /></svg></figure> <span>Image&hellip;</span></label>
                        </div>}
                        {this.state.audioPreviewSrc &&
                            <React.Fragment>
                                <i onClick={() => this.deletePreviewAudio()} className="mdi mdi-close nino-close img-preview-close" ></i>
                                <hr />
                                <span className="glyphicon glyphicon-music" aria-hidden="true"></span> {this.state.audioPreviewSrc}
                                <br />
                                <hr />
                            </React.Fragment>
                        }
                        {!this.state.selectedAudioFile && <div className="box">
                            <input onChange={(e) => this.readAudio(e.target.files)} type="file" name="file-4[]" id="file-4" accept=".mp3" className="inputfile inputfile-4" data-multiple-caption="{count} files selected" />
                            <label htmlFor="file-4">{MELODY_ICON}<span>Audio&hellip;</span></label>
                        </div>}


                    </React.Fragment>
                    <input onClick={() => this.submitHandler(item.tutorialNumber, itemIndex, item.username)} type="button" className="green-btn" value="Submit" />

                    {(!this.state.successMessage && this.state.selectedFile) && <button className="green-btn" onClick={this.uploadHandler}>Upload!</button>}
                    {this.state.warningMessage &&
                        <React.Fragment>
                            <div className="warning-message-box"> {this.state.warningMessage} </div>
                        </React.Fragment>
                    }
                    {this.state.successMessage &&
                        <React.Fragment>
                            <div className="success-message-box"> {this.state.successMessage} </div>
                        </React.Fragment>
                    }
                </li>
            )
        });
        return (
            <div>
                {this.state.showSubmitPopup && submitPopup}
                <Header {...this.props}></Header>
                <section id="nino-services">
                    <div className="container">
                        <h2 className="nino-sectionHeading">
                            <span className="nino-subHeading">Welcome {this.props.user.login}</span>
                            {listItems.length ?
                                (this.state.imgaesForReview.length + " arts for review")
                                :
                                "No arts for review"
                            }
                        </h2>
                        <div className="sectionContent">
                            {
                                this.state.inProgress ?
                                    <React.Fragment>
                                        <h2>Uploading!</h2>
                                        {/* <div className="form-loading-center">
                                    <Loader type="TailSpin" color="#95e1d3" height="150" width="150" />
                                </div> */}
                                        <div className="uploading-file-progress">
                                            <div className="uploading-file-progress-bar">
                                                <div style={{ width: `${this.state.uploadProgress}%` }}></div>
                                            </div>
                                            {`${this.state.uploadProgress} %`}</div>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        {this.state.warningMessage &&
                                            <React.Fragment>
                                                <div className="nino-formInput validation-warning-box"> {this.state.warningMessage} </div>
                                            </React.Fragment>
                                        }
                                        {this.state.successMessage &&
                                            <React.Fragment>
                                                <div className="nino-formInput validation-success-box"> {this.state.successMessage} </div>
                                            </React.Fragment>
                                        }
                                        <div className="images-for-review-list">
                                            <ul>
                                                {listItems}
                                            </ul>
                                        </div>
                                    </React.Fragment>
                            }
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default ImagesReviews;
