import React, { Component } from 'react';
import { WEBPAGE_URL_IMAGES } from '../globals.js';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export class ImageModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        }
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    openModal() {
        this.setState({
            showModal: true
        })
    }

    closeModal() {
        this.setState({
            showModal: false
        })
    }

    render() {
        return (
            <React.Fragment>
                {this.state.showModal ?
                    <div className="image-modal-window">
                        <span className="image-modal-window-title">{`${this.props.title} ${this.props.author}`}</span>
                        <div className="nino-close-wrapper">
                            <i className="mdi mdi-close nino-close" onClick={() => this.closeModal()}></i>
                        </div>
                        <div className="image-modal-window-img-wrapper">
                            <LazyLoadImage src={this.props.src ? this.props.src : `${WEBPAGE_URL_IMAGES}${this.props.imagePrefix}${this.props.number}-big.jpg`}
                                alt={this.props.alt ? this.props.alt : this.props.title} />
                        </div>
                    </div>
                    :
                    <a className="cursor-pointer" onClick={() => this.openModal()}>
                        <div className={this.props.hideHoverAnimation ? "" : "portfolio-image-item"} >
                            <LazyLoadImage src={this.props.src ? (this.props.isSrcSmall ? this.props.src.replace(".jpg", "-small.webp") : this.props.src) : `${WEBPAGE_URL_IMAGES}${this.props.imagePrefix}${this.props.number}.webp`}
                                alt={this.props.alt ? this.props.alt : this.props.title}
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = (this.props.isSrcSmall ? this.props.src.replace(".jpg", "-small.jpg") : `${WEBPAGE_URL_IMAGES}${this.props.imagePrefix}${this.props.number}.jpg`)
                                }} />
                            {
                                (!this.props.hideDescription && (this.props.title || this.props.author)) &&
                                <div className={this.props.hideHoverAnimation ? "" : "nino-portfolio-item"}>
                                    <div>
                                        <h4>{this.props.title}</h4>
                                        <span>{this.props.author}</span>
                                    </div>
                                </div>
                            }
                        </div>
                    </a>
                }
            </React.Fragment>
        );
    }
}

export default ImageModal;
