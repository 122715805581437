import React, { Component } from 'react';
import Header from "./Header.jsx";
import { Link } from 'react-router-dom'
import { PAGE } from '../globals';
import { Helmet } from "react-helmet";

class CookiesPolicy extends Component {

    render() {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Cookie Policy for Easy Face Draw</title>
                    <link rel="canonical" href="https://www.easyfacedraw.com/cookiesPolicy" />
                    <meta name="description" content="Please review our cookie policy, and then join us for our fun and easy art tutorials." />
                    <meta name="keywords" content="drawing tutorial, video art instruction, how to draw, realistic face sketching" />
                </Helmet>
                <Header {...this.props} ></Header>
                <section id="nino-whatWeDo">
                    {/* <div className="container">
                        <h1 className="nino-sectionHeading">
                            Cookie Policy of <a href="www.easyfacedraw.com"> www.easyfacedraw.com</a>
                        </h1>
                        <div className="privacy-policy">
                            <div className='innerText'>

                                This cookie policy of the <a href="www.easyfacedraw.com"> www.easyfacedraw.com </a> website (“Cookie Policy”) stipulates the rules of using the cookies on the aforementioned website.
                                <br />
                            </div>
                            <div className='innerText'>
                                Cookie Files (Cookies) are small information text files sent by a server and saved on the computer of a person browsing <a href="www.easyfacedraw.com"> www.easyfacedraw.com </a>. You will find more details on Cookies for example at <a href="https://en.wikipedia.org/wiki/HTTP_cookie"> https://en.wikipedia.org/wiki/HTTP_cookie </a>.
                            </div>
                            <br />

                            <div className='innerText'>
                                The Cookies allow to tailor <a href="www.easyfacedraw.com"> www.easyfacedraw.com </a> to the Users’ needs.  The Administrator may process data stored in the Cookies when Users browse <a href="www.easyfacedraw.com"> www.easyfacedraw.com </a> in order to:
                                <ul>
                                    <li>
                                        identify the Users and gather information on whether they are logged in to Facebook;
                                    </li>
                                    <li>
                                        keep anonymous statistics reflecting the way <a href="www.easyfacedraw.com"> www.easyfacedraw.com </a> is used.
                                    </li>
                                </ul>
                            </div>
                            <br />

                            <div className='innerText'>
                                Normally, the majority of web browsers available on the market accept saving the Cookies by default. The User may define the way the Cookies will be used in the settings of his/her web browser. This means that saving the Cookies may be partly limited (e.g. for a certain period of time) or disabled – in the latter case, it may influence some of the website’s functionalities.
                            </div>
                            <br />

                            <div className='innerText'>
                                Web browser cookie settings are important as far as the consent to the Administrator’s use of the Cookies is concerned – according to the provisions of law such a consent may be also expressed through web browser settings.  In the event that no such consent is expressed, web browser cookie settings have to be changed accordingly.
                            </div>
                            <br />

                            <div className='innerText'>
                                More detailed information on changing cookie settings and the way they can be deleted by the users in the most popular web browsers are available in the “Help” section of the web browser you are currently using.
                            </div>
                            <br />



                            <div className='innerText'>
                                <br />Last Edited on 2020-02-26.
                            </div>
                        </div>
                    </div> */}
                </section>
                <br />
                <br />
            </div>
        );
    }
}

export default CookiesPolicy;
