import React, { Component } from 'react';
import Header from "./Header.jsx";
import { Link } from 'react-router-dom'
import { PAGE } from './../globals';
import { sendEventToGoogleAnalytics } from '../functions';
import { Helmet } from "react-helmet";

class About extends Component {

    render() {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>What Is Easy Face Draw? Learn to Draw with Our Art Tutor</title>
                    <link rel="canonical" href="https://www.easyfacedraw.com/about" />
                    <meta name="description" content="Learn more about Easy Face Draw. Video drawing tutorials for beginners by a trained art instructor." />
                    <meta name="keywords" content="drawing tutorials, video art tutorials, how to draw, pencil sketch, basic drawing" />
                </Helmet>
                <Header {...this.props} ></Header>
                <section id="nino-whatWeDo">
                    <div className="container">
                        <h3 className="nino-sectionHeading">
                            <span className="nino-subHeading big-font">EasyFaceDraw.com</span>
                        </h3>
                        <p className="nino-sectionDesc read-more-description">
                            Drawing is more than just an artistic expression; it’s a way of connecting with the world around you. It allows you to see the details you otherwise miss and celebrate them with others. Whether you’re drawing a modern building or a beautiful ocean, you’re striving to transport your audience to a different location-perhaps even a different world!
                            <br />
                            <br />
                            But perhaps the most impactful concept you could ever draw is a portrait. There’s a lot of meaning behind each one, from emotion to personal stories, memories, or even love. One look at the eyes alone and you have the potential for deep emotional connection.
                            <br />
                            <br />
                            That’s why Easy Face Draw is the perfect course. Using these video tutorials, you can dive right in, learning how to draw a compelling portrait.
                            <br />
                            <br />
                            Designed for complete beginners, this comprehensive class is perfect for people who want to learn a valuable new skill but want to do it from the comfort of their own homes. Simply get your materials ready, click on a video, and follow along. By the end, you’ll have learned new tips and tricks that make all the difference.
                            <br />
                            <br />
                            Led by a talented artist, architect, and instructor, Easy Face Draw is an approachable way to delve into drawing. If you’re looking for something easy, simple, yet full of potential, these step-by-step tutorials are for you.
                            <br />
                            <br />
                            So what are you waiting for? Gather up your pencils, erasers, and paper. It’s time to develop your drawing skills, see faces in a new light, and inspire others to see the world differently. Let’s get started!
			            </p>
                        {/* <Link to={`/${PAGE.SHOWTUTORIALS}`} onClick={() => sendEventToGoogleAnalytics("Watch for free", "about")} className="action-btn"></Link> */}
                        <Link to={`/${PAGE.SHOWTUTORIALS}`} onClick={() => sendEventToGoogleAnalytics("Watch now", "about")} className="action-btn"></Link>

                        <h3 className="faq-help">
                            If you have questions, go to <Link to={"/help"}>FAQ</Link> or contact us at <a>contact@easyfacedraw.com</a> and we’ll get back to you as soon as possible.
                        </h3>
                    </div>
                </section>
                <br />
                <br />
            </div>
        );
    }
}

export default About;
