import React, { Component } from 'react';
import Header from "./Header.jsx";
import { Link } from 'react-router-dom'
import { PAGE } from './../globals';
import { sendEventToGoogleAnalytics } from '../functions';
import { Helmet } from "react-helmet";

class FrequentlyAskedQuestions extends Component {
    render() {
        const questions = [
            {
                question: "Why Easy Face Draw?",
                answer: "Our platform and tutorial videos are focused on clarity and professionalism. Most importantly, we support personal art reviews by our instructor."
            },
            {
                question: "What is Free Review?",
                answer: "Free Review is the option to send us your drawing (created using the tutorial) for review by our instructor. He will send feedback as soon as possible. You can send your drawing at any time, but is best if you finish it with the given tutorial."
            },
            {
                question: "Where can I use Free Review?",
                answer: "Check under the tutorial video for the place to send your drawing to us."
            },
            {
                question: "Where will I find my feedback?",
                answer: "Check under the tutorial video for feedback from our instructor."
            },
            {
                question: "How many drawings can I send for review?",
                answer: "For each Free Review, you can send one drawing. You can send your drawings for review as long as you have Free Reviews. You can check the number of available Free Reviews under the tutorial video."
            },
            {
                question: "What will I get as feedback?",
                answer: "Our instructor will send you an audio opinion (in mp3 format) and also an image with corrections. Sometimes you will also get an opinion by text."
            },
            // {
            //     question: "Can I use Free Review from tutorial e.g. 'How to draw na eye?' in 'How to draw an ear?'?",
            //     answer: "Each Free Review is linked with specific tutorial. You can not use Free Review from tutorial e.g. 'How to draw na eye?' to "
            // },           
           
            {
                question: "What is your refund policy?",
                answer: "We offer no-questions-asked refunds to all customers within 30 days of your purchase. If you are not satisfied with our product, then simply send us an email and we will refund your purchase right away. Our goal has always been to create a happy, thriving community. If you are not thrilled with the product or are not enjoying the experience, then we have no interest in forcing you to stay an unhappy member. Please contact contact@easyfacedraw.com if you have a problem or want a refund."
            },
            {
                question: "Do I need an account to place an order?",
                answer: "No. You can buy a tutorial as a guest. In this case, your tutorial will only be accessible from the link sent to your email. However, for better security and accessibility, we suggest to registering an account."
            },
            {
                question: "What payment do you accept?",
                answer: "We accept debit or credit cards."
            }
        ]
        const items = questions.map((e, i) => {
            return <React.Fragment>
                <div className="faq-question">
                    <button type="button" className="question-button" data-toggle="collapse" data-target={`#question_${i}`}>
                        <h3>
                            {e.question}
                            <span className="glyphicon glyphicon-chevron-down" aria-hidden="true"></span>
                        </h3>
                    </button>
                    <div id={`question_${i}`} className="collapse question-answer">
                        {e.answer}
                    </div>
                </div>
            </React.Fragment>
        })
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Frequently Asked Questions about Our Easy Drawing Courses</title>
                    <link rel="canonical" href="https://www.easyfacedraw.com/help" />
                    <meta name="description" content="Everything you want to know about Easy Face Draw. Learn to draw with simple, step-by-step instruction." />
                    <meta name="keywords" content="drawing courses, art tutorial, draw online, how to draw, realistic face sketching" />
                </Helmet>
                <Header {...this.props}></Header>
                <section id="nino-services">
                    <div className="container">
                        <h2 className="nino-sectionHeading">
                            Frequently asked questions
			            </h2>
                        <div className="faq-questions">
                            {items}
                        </div>
                        <br/>
                        <h3 className="faq-help">
                            If you have any questions not listed above contact us at <a>contact@easyfacedraw.com</a> and we’ll get back to you as soon as possible.
                        </h3>
                        {/* <Link to={`/${PAGE.SHOWTUTORIALS}`} className="action-btn" onClick={() => sendEventToGoogleAnalytics("Watch for free","faq")}></Link> */}
                        <Link to={`/${PAGE.SHOWTUTORIALS}`} className="action-btn" onClick={() => sendEventToGoogleAnalytics("Watch now","faq")}></Link>
                    </div>
                </section>
            </div>
        );
    }
}

export default FrequentlyAskedQuestions;

