import React, { Component, useEffect } from 'react';
import Header from "./Header.jsx";
import { SERVER_URL, PAGE } from '../globals.js';
import ConfettiGenerator from "confetti-js";
import { Link } from 'react-router-dom';
import {fetch as fetch} from 'whatwg-fetch'
import { sendEventToGoogleAnalytics } from '../functions';

const Confetti = () => {
    useEffect(() => {
        const confettiSettings = { "target": "confetti-holder", "max": "320", "size": "1", "animate": true, "props": ["circle", "square", "triangle"], "colors": [[165, 104, 246], [230, 61, 135], [0, 199, 228], [253, 214, 126]], "clock": "50", "rotate": false, "width": "", "height": "" };
        const confetti = new ConfettiGenerator(confettiSettings);
        confetti.render();
        return () => confetti.clear();
    }, []);
    return (
        <canvas id="confetti-holder" style={{ position: "absolute", top: 0 }}></canvas>
    );
}

class EmailVerification extends Component {

    constructor(props) {
        super(props);
        this.state = {
            warningMessage: "",
            successMessage: ""
        }
    }

    getDataFromDb = () => {
        fetch(`${SERVER_URL}emailVerification/${this.props.match.params.username}/${this.props.match.params.key}`)
            .then((data) => data.json())
            .then((res) => {
                if (res.done) {
                    this.setState({ successMessage: res.message })
                }
                else {
                    this.setState({ warningMessage: res.message })
                }
            });
    };

    componentDidMount() {
        this.getDataFromDb();
    }

    render() {

        return (
            <div>
                <Header {...this.props}></Header>
                {this.state.warningMessage && <div className="verification-email-warning-box"> {this.state.warningMessage} </div>}
                {this.state.successMessage &&
                    <React.Fragment>
                        <Confetti></Confetti>
                        <div className="verification-email-success-box"> {this.state.successMessage}   <a href="#" className="nino-login" style={{textDecoration: "underline"}} onClick={() => this.props.loginFormOpen()}> Log in.</a></div>
                        {/* <Link to={`/${PAGE.SHOWTUTORIALS}`} className="action-btn" onClick={() => sendEventToGoogleAnalytics("Watch for free","email verification")}></Link> */}
                        <Link to={`/${PAGE.SHOWTUTORIALS}`} className="action-btn" onClick={() => sendEventToGoogleAnalytics("Watch now","email verification")}></Link>
                    </React.Fragment>
                }
            </div>
        );
    }
}

export default EmailVerification;
