import React, { Component } from 'react';
import Header from "./Header.jsx";
import { getTeacherFromDb, sendEventToGoogleAnalytics } from '../functions';
import { WEBPAGE_URL_IMAGES, PAGE } from '../globals.js';
import { Link } from 'react-router-dom'
import ImageModal from './ImageModal';
import { Helmet } from "react-helmet";
import { LazyLoadImage } from 'react-lazy-load-image-component';

class TeacherPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            teacher: {
                number: 0,
                name: '',
                surname: '',
                position: '',
                photo: '',
                description: ''
            }
        }
    }

    componentDidMount() {
        getTeacherFromDb(this.props.match.params.number, this);
    }

    render() {
        let artisticWorkItems = []
        for (let index = 1; index < 9; index++) {
            artisticWorkItems.push(
                <div key={"item-artwork-" + index} className="col-md-3 col-sm-4 col-xs-12">
                    <ImageModal key={index} number={index} title="" author="" imagePrefix="/tutor-arts/img-"></ImageModal>
                    {/* <a href={`${WEBPAGE_URL_IMAGES}/our-work/img-${1}.jpg`}>
                        <img src={`${WEBPAGE_URL_IMAGES}/our-work/img-${1}.webp`}
                            alt={"Artistic work " + 1}
                            onError={(e) => { e.target.onerror = null; e.target.src = `${WEBPAGE_URL_IMAGES}/our-work/img-${1}.jpg` }}
                        />
                    </a> */}
                </div>
            )

        }
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Meet the Instructor for Easy Drawing Tutorials for Beginners</title>
                    <link rel="canonical" href="https://www.easyfacedraw.com/teacher/1" />
                    <meta name="description" content="Art Tutor Jakub Lewicki teaches easy, step-by-step drawing courses for beginners." />
                    <meta name="keywords" content="drawing tutorial, art tutor, how to draw, face sketch, learn to draw" />
                </Helmet>
                <Header {...this.props}></Header>
                <section id="nino-whatWeDo">
                    <div className="container">
                        <div className="sectionContent">
                            <div className="row">
                                <div className="col-md-6">
                                    <LazyLoadImage src={WEBPAGE_URL_IMAGES + "/our-team/" + this.state.teacher.photo} alt={`${this.state.teacher.name} ${this.state.teacher.surname}`} />
                                </div>
                                <div className="col-md-6">
                                    <div className="info">

                                        <h4 className="name">{`${this.state.teacher.name} ${this.state.teacher.surname}`}</h4>
                                        <span className="regency">{this.state.teacher.position}</span>
                                        <hr />
                                        <p className="desc">{this.state.teacher.description}</p>
                                        <div className="community-icons-container">
                                            {/* <a href="#" className="nino-icon"><i className="mdi mdi-facebook"></i></a> */}
                                            {/* <a href="#" className="nino-icon"><i className="mdi mdi-twitter"></i></a> */}
                                            {/* <a href="#" className="nino-icon"><i className="mdi mdi-pinterest"></i></a> */}
                                            {/* <a href="#" className="nino-icon"><i className="mdi mdi-instagram"></i></a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <br />
                <br />
                <br />
                <h2 className="nino-sectionHeading">My artistic works</h2>
                <section className="image-gallery">
                    <div className="container">
                        <div className="row">
                            {artisticWorkItems}
                        </div>
                        {/* <Link to={`/${PAGE.SHOWTUTORIALS}`} onClick={() => sendEventToGoogleAnalytics("Watch for free", "teacher page")} className="action-btn"></Link> */}
                        <Link to={`/${PAGE.SHOWTUTORIALS}`} onClick={() => sendEventToGoogleAnalytics("Watch now", "teacher page")} className="action-btn"></Link>

                    </div>
                </section>
            </div>
        );
    }
}

export default TeacherPage;
