import React, { Component } from 'react';
import { WEBPAGE_URL_IMAGES } from '../globals.js';
import { LazyLoadImage } from 'react-lazy-load-image-component';

class HorizontalSlidePanel extends Component {
    render() {
        const items = this.props.items.map( (e,i) => 
        <li key={i}>
            <div layout="row" className="verticalCenter">
                <div className="nino-avatar fsr">
                    <LazyLoadImage className="img-circle img-thumbnail" src={WEBPAGE_URL_IMAGES + "/testimonial/" + e.img} alt={e.name + " " + e.surname} />
                </div>
                <div>
                    <p className="quote">{e.textContent}</p>
                    <span className="name">{e.name + " " + e.surname}</span>
                </div>
            </div>
        </li>)
        return (
            <section className="nino-testimonial bg-white">
                <div className="container">
                    <div className="nino-testimonialSlider">
                        <ul>
                            {items}
                        </ul>
                    </div>
                </div>
            </section>
        );
    }
}

export default HorizontalSlidePanel;

