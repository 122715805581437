import React, { Component } from 'react';
import Header from "./Header.jsx";
import { Link } from 'react-router-dom'
import { PAGE, WEBPAGE_URL_IMAGES } from '../globals';
import ImageModal from './ImageModal';
import { sendEventToGoogleAnalytics } from '../functions';
import { Helmet } from "react-helmet";

class Article extends Component {

    render() {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>The Evolution of Drawing for the Beginner Artist</title>
                    <link rel="canonical" href="https://www.easyfacedraw.com/article-drawing-is-everything" />
                    <meta name="description" content="Learn about the history of drawing as it evolved throughout history, from rock carvings to hand sketches to architectural and design drawings." />
                    <meta name="keywords" content="history of drawing, art history, how to draw, art of drawing, pencil shading, human sketch, hand drawing, realistic drawing, design drawing, architectural drawing, contour drawing" />
                </Helmet>
                <Header {...this.props} ></Header>
                <article>
                    <section id="nino-whatWeDo">
                        <div className="container">
                            <h1 className="nino-sectionHeading">
                                <span className="nino-subHeading big-font">DRAWING</span>
                                Drawing is Everything
                            </h1>
                            <h2 className="article-author">
                                Alan McNairn
                                <br />
                                March 18, 2020
                                </h2>
                            <br />
                            <p className="nino-sectionDesc read-more-description">
                                &quot;Drawing is not what one sees but what one can make others see.&quot; So said the great French,
                                Impressionist artist Edgar Degas.
			            </p>
                            <p className="nino-sectionDesc read-more-description">
                                Degas really could make us see what he saw. What magic he accomplished with charcoal and a
                                sheet of yellow tracing paper!
			            </p>

                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="Edgar Degas"
                                    title="After the Bath, c. 1895. Kimbell Art Museum"
                                    src={`${WEBPAGE_URL_IMAGES}/article/1/Edgar_Degas_-_After_the_Bath,_Woman_Drying_Her_Hair_-_Google_Art_Project.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p><a href="https://en.wikipedia.org/wiki/Edgar_Degas">Edgar Degas</a>, After the Bath, c. 1895. Kimbell Art Museum</p>
                            </div>

                            <p className="nino-sectionDesc read-more-description">
                                Degas was a painter, so he knew the truth in what Jean-Auguste-Dominique Ingres, his
                                predecessor in the history of French art said. “Drawing includes three and a half quarters of the
                                content of painting…Drawing contains everything, except the hue.” When we look at a detail of
                                one of Ingres’ drawings, we can see with our own eyes that he was right.
			            </p>

                            <div className="article-picture">
                                {/* <img src={`${WEBPAGE_URL_IMAGES}/article/1/Edgar_Degas_-_After_the_Bath,_Woman_Drying_Her_Hair_-_Google_Art_Project.jpg`} alt="Edgar Degas, After the Bath, c. 1895. Kimbell Art Museum" /> */}
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="Jean-Auguste-Dominique Ingres"
                                    title="Portrait of Delphine Rammel, 1855. Fog Art Museum"
                                    src={`${WEBPAGE_URL_IMAGES}/article/1/Ramel_Ingres_dessin.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p><a href="https://en.wikipedia.org/wiki/Jean-Auguste-Dominique_Ingres">Jean-Auguste-Dominique Ingres</a>, Portrait of Delphine Rammel, 1855. Fog Art Museum</p>
                            </div>

                            <p className="nino-sectionDesc read-more-description">
                                Drawing is like talking or singing. Everyone does it, but only a few do it well. It takes practice to
                                make others see what we see. The good thing with drawing is that almost everyone has already
                                begun their practice. There is probably not a person on earth who hasn’t taken a brush, or a pen,
                                or a pencil or a piece of charcoal and made a drawing on a piece of paper, or wood, or even a
wall.            </p>

                            <h3 className="nino-sectionHeading">
                                A Very Short History of Drawing                            </h3>

                            <p className="nino-sectionDesc read-more-description">
                                Humans first efforts in the art of drawing, were almost certainly scratched made by the
                                scratching of a stick in sand. While these were ephemeral, some very early drawings have been
                                preserved. 30,000 years ago, the inhabitants of caves in southern Europe used charcoal sticks to
                                draw images of wild animals.
        </p>


                            <div className="article-picture">
                                {/* <img src={`${WEBPAGE_URL_IMAGES}/article/1/Edgar_Degas_-_After_the_Bath,_Woman_Drying_Her_Hair_-_Google_Art_Project.jpg`} alt="Edgar Degas, After the Bath, c. 1895. Kimbell Art Museum" /> */}
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author=""
                                    title="Lions, Chauvet Cave, France"
                                    src={`${WEBPAGE_URL_IMAGES}/article/1/lions.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Lions, <a href="https://en.wikipedia.org/wiki/Chauvet_Cave">Chauvet Cave, France</a></p>
                            </div>


                            <p className="nino-sectionDesc read-more-description">
                                Very early in the history of humanity, artists used hard stones to scrape drawings onto the
                                surface of rocks. These drawings are called petroglyphs (petro=stone and glyph=carve). There
                                are examples of this kind of drawing from all over the world. For example, in the Côa Valley in
                                Portugal, artists some 20,000 years ago made drawings by making incisions on the surface of
                                rock.
        </p>


                            <div className="article-picture">
                                {/* <img src={`${WEBPAGE_URL_IMAGES}/article/1/Edgar_Degas_-_After_the_Bath,_Woman_Drying_Her_Hair_-_Google_Art_Project.jpg`} alt="Edgar Degas, After the Bath, c. 1895. Kimbell Art Museum" /> */}
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author=""
                                    title="Antelope, Côa Valley, Portugal"
                                    src={`${WEBPAGE_URL_IMAGES}/article/1/Prehistoric_Rock-Art_Site_of_the_Côa_Valley_-_Penascosa_-_Bull_@_2011-08-06.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Antelope, <a href="https://en.wikipedia.org/wiki/Prehistoric_Rock_Art_Sites_in_the_C%C3%B4a_Valley">Côa Valley, Portugal</a></p>
                            </div>



                            <p className="nino-sectionDesc read-more-description">
                                With the evolution of civilizations around the world a variety of tools were put to use in drawing.
                                The ancient Greeks created drawings on pottery by brushing black glaze on the surface of clay
                                and then firing their work. This drawing of a scene from Greek mythology, dating from about
                                470 BCE, shows the use of lines to represent the contours or outlines of the figures and the folds
                                of their robes.
        </p>


                            <div className="article-picture">
                                {/* <img src={`${WEBPAGE_URL_IMAGES}/article/1/Edgar_Degas_-_After_the_Bath,_Woman_Drying_Her_Hair_-_Google_Art_Project.jpg`} alt="Edgar Degas, After the Bath, c. 1895. Kimbell Art Museum" /> */}
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author=""
                                    title="Greek Red-figure Vase 470-460 BCE"
                                    src={`${WEBPAGE_URL_IMAGES}/article/1/Greek Red-figure Vase 470-460 BCE.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Greek Red-figure Vase 470-460 BCE</p>
                            </div>



                            <p className="nino-sectionDesc read-more-description">
                                In China the techniques of drawing, primarily using a brush and black ink on paper, became
                                refined over several centuries. By the 14 th century CE, landscapes were depicted in breathtaking
                                detail and artists sought to recreate on paper the feeling of the natural world.
        </p>


                            <div className="article-picture">
                                {/* <img src={`${WEBPAGE_URL_IMAGES}/article/1/Edgar_Degas_-_After_the_Bath,_Woman_Drying_Her_Hair_-_Google_Art_Project.jpg`} alt="Edgar Degas, After the Bath, c. 1895. Kimbell Art Museum" /> */}
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author=""
                                    title="Detail of Chinese Scroll. Dwelling in the Fuchan Mountains, 1348-1420. Provincial Museum, Hangzhou"
                                    src={`${WEBPAGE_URL_IMAGES}/article/1/Dwelling_in_the_Fuchun_Mountains.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Detail of Chinese Scroll. <a href="https://en.wikipedia.org/wiki/Dwelling_in_the_Fuchun_Mountains">Dwelling in the Fuchan Mountains</a>, 1348-1420. Provincial Museum, Hangzhou</p>
                            </div>

                            <p className="nino-sectionDesc read-more-description">
                                In Europe, during the Middle Ages, from the 5 th to the 15 th century, the art of drawing was refined
                                by artists illustrating manuscripts. By the end of this period of art history the techniques of
                                draughtsmanship had evolved to include skills in shading parts of forms to create the illusion of
                                three-dimensional space.
        </p>


                            <div className="article-picture">
                                {/* <img src={`${WEBPAGE_URL_IMAGES}/article/1/Edgar_Degas_-_After_the_Bath,_Woman_Drying_Her_Hair_-_Google_Art_Project.jpg`} alt="Edgar Degas, After the Bath, c. 1895. Kimbell Art Museum" /> */}
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author=""
                                    title="Fortune Turns Her Wheel, French 15th Century Illuminated Manuscript, British Library"
                                    src={`${WEBPAGE_URL_IMAGES}/article/1/BL_Royal_Vincent_of_Beauvais.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Fortune Turns Her Wheel, French 15th Century <a href="https://en.wikipedia.org/wiki/Illuminated_manuscript"> Illuminated Manuscript</a>, British Library </p>
                            </div>


                            <p className="nino-sectionDesc read-more-description">
                                In the period of the Renaissance in Europe in the 16 th century, artists used drawing as a means to
                                work out how they would portray the human form in paintings. The idea of drawing a
                                preparatory sketch, often from direct observation of a human model, became an important part of
                                the processes of the creation of visual art.
        </p>


                            <div className="article-picture">
                                {/* <img src={`${WEBPAGE_URL_IMAGES}/article/1/Edgar_Degas_-_After_the_Bath,_Woman_Drying_Her_Hair_-_Google_Art_Project.jpg`} alt="Edgar Degas, After the Bath, c. 1895. Kimbell Art Museum" /> */}
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author=""
                                    title="Albrecht Durer, Study of Hands, Albertina, Vienna"
                                    src={`${WEBPAGE_URL_IMAGES}/article/1/albrecht-durer-studies-of-dc3bcrers-left-hand-recto-1493-94-albertina.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p><a href="https://en.wikipedia.org/wiki/Albrecht_D%C3%BCrer"> Albrecht Durer</a>, Study of Hands, Albertina, Vienna </p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                                Not only did drawing serve as a way to experiment with various possibilities for the
                                representation of forms, it also was used to work out the best way to compose the various
                                elements of a picture. This became a necessity when in the 17 th century, in the Baroque era,
                                artists created complex scenes involving the interaction of multiple figures.
        </p>


                            <div className="article-picture">
                                {/* <img src={`${WEBPAGE_URL_IMAGES}/article/1/Edgar_Degas_-_After_the_Bath,_Woman_Drying_Her_Hair_-_Google_Art_Project.jpg`} alt="Edgar Degas, After the Bath, c. 1895. Kimbell Art Museum" /> */}
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author=""
                                    title="Anthony van Dyck, The Mystic Marriage of St. Catherine, Morgan Library and Museum, New York"
                                    src={`${WEBPAGE_URL_IMAGES}/article/1/The_Mystic_Marriage_of_St._Catherine_by_Anthony_van_Dyck,_ca._1618-20,_pen_and_ink.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p><a href="https://en.wikipedia.org/wiki/Anthony_van_Dyck">Anthony van Dyck</a>, The Mystic Marriage of St. Catherine, Morgan Library and Museum, New York</p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                                In the 18 th and 19 th centuries when artists became fascinated with capturing nature as it appears to
                                the eye, they recorded what they saw outside their studios. The most efficient way of doing this
                                was to make drawings out of doors. These were then used as a source to create finished paintings
                                in which a convincing representation of a landscape was required.
        </p>


                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author=""
                                    title="Hubert Robert, Villa d&#39;Este, Tivoli, Italy, National Gallery of Art, Washington"
                                    src={`${WEBPAGE_URL_IMAGES}/article/1/Hubert Robert, Villa.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p><a href="https://en.wikipedia.org/wiki/Hubert_Robert">Hubert Robert</a>, Villa d&#39;Este, Tivoli, Italy, National Gallery of Art, Washington</p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                                In the 19 th century, the development of new means of mechanical reproduction of images, created
                                a demand for drawings by illustrators. Drawing were sent to the printers of books and posters so
                                that they could be reproduced in massive numbers for public consumption.
        </p>


                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author=""
                                    title="Aubrey Beardsley, The Peacock Skirt, 1893"
                                    src={`${WEBPAGE_URL_IMAGES}/article/1/Aubrey Beardsley, The Peacock Skirt, 1893.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p><a href="https://en.wikipedia.org/wiki/Aubrey_Beardsley">Aubrey Beardsley</a>, The Peacock Skirt, 1893</p>
                            </div>
                            <h3 className="nino-sectionHeading">
                                Why Draw?                         </h3>

                            <p className="nino-sectionDesc read-more-description">
                                We draw for many reasons. Among them are recreation, study and work.
        </p>
                            <p className="nino-sectionDesc read-more-description">
                                Designers use drawings to test various aspects of clothing that will be made by others. Using
                                drawings is a cheap way to work out details and make changes. Fashion designers and costume
                                designers sketch out ideas and refine them. Their drawings serve as guides to others who will
                                take the process of making clothing to the next stage. Before and after photography became
                                common in magazines, marketers of fashion used designers’ drawings in their advertisements.
        </p>


                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author=""
                                    title="Fashion Drawing, 1927"
                                    src={`${WEBPAGE_URL_IMAGES}/article/1/Fashion Drawing, 1927.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p><a href="https://en.wikipedia.org/wiki/Fashion_illustration">Fashion Drawing</a>, 1927</p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                                Like clothing designers, architects use drawings to devise ways to experiment with how a
                                finished building will look. Some architectural drawings are merely rough sketches often drawn
                                on scraps of paper. Others are more finished and detailed. These can be used to present to clients
                                for their approval. Detailed architectural drawings form the basis of blueprints from which
                                builders will work.
        </p>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author=""
                                    title="Jacques-Germain Soufflot, Architectural Drawing, Design for the Panthéon, 1758, Paris"
                                    src={`${WEBPAGE_URL_IMAGES}/article/1/Jacques-Germain Soufflot, pantheon.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Jacques-Germain Soufflot, <a href="https://en.wikipedia.org/wiki/Architectural_drawing">Architectural Drawing</a>, Design for the Panthéon, 1758, Paris</p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                                Engineers, like architects, create drawings to establish the relationships between elements of
                                what they are designing. From such drawings, calculations can be made as to the characteristics
                                required of the materials to be used to construct a machine, or a bridge or ship. Although
                                engineers still practice drawing by hand, today most of their designing is done digitally.
        </p>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author=""
                                    title="James Watt, Engineering Drawing, Design for a Steam Pump, 1763"
                                    src={`${WEBPAGE_URL_IMAGES}/article/1/James-Watt-steam-pump.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>James Watt,<a href="https://en.wikipedia.org/wiki/Engineering_drawing"> Engineering Drawing</a>, Design for a Steam Pump, 1763</p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                                Even today in the age of photography scientists still use the processes of drawing. It allows them
                                to record the details of human and animal anatomy. Botanical drawing was once the primary tool
                                to establish the specific characteristics of species of plants.
        </p>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author=""
                                    title="Georg Dionysius Ehret, Botanical Drawing, American Turk&#39;s cap Lily, 1750"
                                    src={`${WEBPAGE_URL_IMAGES}/article/1/Georg Dionysius Ehret.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Georg Dionysius Ehret, <a href="https://en.wikipedia.org/wiki/Botanical_illustration">Botanical Drawing</a>, American Turk&#39;s cap Lily, 1750</p>
                            </div>

                            <h3 className="nino-sectionHeading">
                                Tools and Techniques of Drawing
                                                       </h3>
                            <p className="nino-sectionDesc read-more-description">
                                The great thing about drawing is that it is cheap, requiring the simplest of tools. Because it is
                                economical several drawings can be made and discarded before one arrives at a final image or
                                design.
        </p>
                            <p className="nino-sectionDesc read-more-description">
                                One of the simplest ways of drawing—almost everyone has tried this—is to use a pointed stick
                                and draw on sand. On the Pacific Island of Vanuatu the people have developed a particular skill
                                in this kind of drawing.
        </p>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author=""
                                    title="Sand Drawing from Vanuatu, 2007"
                                    src={`${WEBPAGE_URL_IMAGES}/article/1/Sand Drawing from Vanuatu, 2007.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p><a href="https://en.wikipedia.org/wiki/Sand_drawing">Sand Drawing</a> from Vanuatu, 2007</p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                                Before the widespread use of pencils in the 17 th century artists’ used a pointed silver rod which
                                when drawn over parchment or animal skin left a faint mark. This was how the illuminators of
                                manuscripts in the Middle Ages and Renaissance drew their pictures and decorative designs
                                before painting them.
        </p>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author=""
                                    title="Hans Holbein the Younger, Portrait Study of Dorothea Meyer, 1516, Silverpoint Drawing, Kunstmuseum Basel"
                                    src={`${WEBPAGE_URL_IMAGES}/article/1/Hans Holbein the Younger, Portrait Study of Dorothea Meyer, 1516, Silverpoint Drawing, Kunstmuseum Basel.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Hans Holbein the Younger, Portrait Study of Dorothea Meyer,
1516, <a href="https://en.wikipedia.org/wiki/Silverpoint"> Silverpoint Drawing</a>, Kunstmuseum Basel</p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                                Drawing with charcoal has been popular since the beginnings of art. The medium became
                                particularly popular with the rise of art schools in the 18 th century and is even more popular
                                today. Using charcoal allows an artist to create subtle shading in their work
        </p>

                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author=""
                                    title="Käthe Kollwitz, Self-Portrait, 1924, Charcoal Drawing"
                                    src={`${WEBPAGE_URL_IMAGES}/article/1/Kathe Kollwitz, Self-Portrait, 1924, Charcoal Drawing.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>HKäthe Kollwitz, Self-Portrait, 1924, <a href="https://en.wikipedia.org/wiki/Charcoal_(art)">Charcoal Drawing</a> </p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                                Drawing with pen and ink allows the artist to create refined lines. Depending on the width of the
                                nib and how it is applied to paper lines of varying width can be created.
        </p>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author=""
                                    title="Egon Schiele, Mother and Child, Pen and Ink Contour Drawing, 1910"
                                    src={`${WEBPAGE_URL_IMAGES}/article/1/Egon Schiele, Mother and Child, Pen and Ink Contour Drawing, 1910.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Egon Schiele, Mother and Child, Pen and Ink <a href="https://en.wikipedia.org/wiki/Contour_drawing">Contour Drawing</a>, 1910</p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                                Using a brush, an artist can create shading and thus create perspective or the illusion of three-
                                dimensional space on the flat surface of a piece of paper. Outlines or the contours of objects can
                                be made with a brush. These appear softer than contours made with a pen.
        </p>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author=""
                                    title="Filippo Juvarra, Church Altar, c. 1700, Brush and Ink Drawing"
                                    src={`${WEBPAGE_URL_IMAGES}/article/1/Filippo Juvarra, Church Altar, c. 1700, Brush and Ink Drawing.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Filippo Juvarra, Church Altar, c. 1700, <a href="https://en.wikipedia.org/wiki/Drawing">Brush and Ink Drawing</a></p>
                            </div>

                            <h3 className="nino-sectionHeading">
                                Drawing is the First Step in Making Art
                                                                                   </h3>
                            <p className="nino-sectionDesc read-more-description">
                                Self-taught artists and all students at art schools begin their experiments in creating by drawing.
                                Many artists continue to draw throughout their lives. Drawing is the foundation on which art is
                                built. This print, by Thomas Rowlandson, began as a pen and ink drawing. It was given to a
                                professional etcher. He transferred Rowlandson’s image to a copper plate so that it could be mass
                                produced in a printing press.
        </p>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author=""
                                    title="Thomas Rowlandson, Life Class at the Royal Academy, London, 1811, colored etching and watercolor"
                                    src={`${WEBPAGE_URL_IMAGES}/article/1/Thomas Rowlandson, Life Class at the Royal Academy, London, 1811, colored etching and watercolor.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Thomas Rowlandson, Life Class at the Royal Academy, London, 1811, colored etching and watercolor</p>
                            </div>

                            {/* <Link to={`/${PAGE.SHOWTUTORIALS}`} className="action-btn" onClick={() => sendEventToGoogleAnalytics("Watch for free", "article")}></Link> */}
                            <Link to={`/${PAGE.SHOWTUTORIALS}`} className="action-btn" onClick={() => sendEventToGoogleAnalytics("Watch now", "article")}></Link>

                        </div>
                    </section>
                </article>
            </div>
        );
    }
}

export default Article;
