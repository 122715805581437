import React, { Component } from 'react';
import {
    FacebookShareButton,
    FacebookIcon,
    WhatsappShareButton,
    WhatsappIcon,
    TwitterShareButton,
    TwitterIcon
} from 'react-share';
export class ModalPopUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: true
        }
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    openModal() {
        this.setState({
            showModal: true
        })
    }

    closeModal() {
        this.setState({
            showModal: false
        })
    }

    render() {
        if (this.state.showModal) {
            return (
                <div className="modal-pop-up">

                    <div className="modal-pop-up-content">
                        <div className="nino-close-wrapper">
                            <i className="mdi mdi-close nino-close" onClick={() => this.closeModal()}></i>

                        </div>
                        <p>Share your drawing with friends</p>
                        <FacebookShareButton className="share-button" {...this.props} quote={this.props.title} title="" desription="" hashtag={""} >
                            <div className="modal-pop-up-share-button fb-color">
                                <FacebookIcon size={50} round={true}></FacebookIcon>
                                <span>Share on Facebook</span>
                            </div>
                        </FacebookShareButton>
                        <TwitterShareButton className="share-button" {...this.props} >
                            <div className="modal-pop-up-share-button twitter-color">
                                <TwitterIcon size={50} round={true}></TwitterIcon>
                                <span>Share on Twitter</span>
                            </div>
                        </TwitterShareButton>
                        <WhatsappShareButton className="share-button" {...this.props}>
                            <div className="modal-pop-up-share-button whatsapp-color">
                                <WhatsappIcon size={50} round={true}></WhatsappIcon>
                                <span>Share on Whatsapp</span>
                            </div>
                        </WhatsappShareButton>

                    </div>
                </div>
            );
        }
        else {
            return (
                <React.Fragment></React.Fragment>
            )
        }
    }
}

export default ModalPopUp;
