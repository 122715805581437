import React, { Component } from 'react';
import Header from "./Header.jsx";
import { Link } from 'react-router-dom'
import { PAGE } from '../globals';
import { Helmet } from "react-helmet";

class Rules extends Component {

    render() {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Rules and regulations for the provision of services via the website www.easyfacedraw.com</title>
                    <link rel="canonical" href="https://www.easyfacedraw.com/rules" />
                    <meta name="description" content="Rules	and	regulations	for	the	provision	of	services	via	the	website	www.easyfacedraw.com" />
                    <meta name="keywords" content="rules easy face draw" />
                </Helmet>
                <Header {...this.props} ></Header>
                <section id="nino-whatWeDo">
                    <div className="container">
                        <h1 className="nino-sectionHeading">
                            Rules and regulations for the provision of services via the website <a href="http://www.easyfacedraw.com">www.easyfacedraw.com.</a>
                        </h1>
                        <div className="privacy-policy">
                            <div class='innerText'>
                                <ol style={{ listStyleType: 'upper-roman' }}>
                                    <li>General Provisions</li>
                                    <li>Registration	on	the	Website</li>
                                    <li>Conclusion	on	the	Agreement </li>
                                    <li>Services</li>
                                    <li>Terms	and	Conditions	for	the	Provision	of	Services	</li>
                                    <li>Termination	of	the	Agreement	</li>
                                    <li>Return</li>
                                    <li>Complaints</li>
                                    <li>Technical	Requirements	</li>
                                    <li>Processing	of	Personal	Data</li>
                                    <li>ModiMication	of	the	Rules	and	Regulations</li>
                                    <li>Final Provisions </li>
                                </ol>
                                <br />
                                These	 Rules	 and	 Regulations	 sets	 forth	 rights	 and	 obligations	 related	 to	 the	 use	 of	 the
                                websitewww.easyfacedraw.com	 as	 well	 as	 rules	 for	 using	 services	 provided	 via	 the	 website
                                www.easyfacedraw.com.
                                <br />
                                Rules	and	Regulations,	in	the	field	of	electronic	services,	constitute	the	rules	and	regulations	for
                                the	provision	of	electronic	services,	referred	to	in	art.	8	sec.	1(1)	of	the	Polish	Act	of	18	July	2002
                                on	 the	 provision	 of	 electronic	 services	 (Dz.U.	 [Polish	 Journal	 of	 Laws]	 of	 2002,	 no.	 144,	 item
                                1204,	as	amended).
                                <br />
                            </div>
                            <br />
                            <div class='innerText'>
                                <strong>
                                    DEFINITIONS
                                </strong>
                            </div> <br />

                            <table>
                                <tbody>
                                    <tr>
                                        <td style={{ verticalAlign: 'top' }}><strong>Administrator</strong></td>
                                        <td style={{ paddingBottom: '1em' }}>an	 entity	 providing	 service	 of	 access	 to
                                            resources	on	the	Website	–	Quizony	spółka
                                            z	 ograniczoną	 odpowiedzialnością	 spółka
                                            komandytowa,	 ul.	 Krucza	 5/11,	 00-548
                                            Warsaw,	 entered	 into	 the	 Register	 of
                                            Entrepreneurs	 of	 the	 National	 Court
                                            Register	kept	by	 the	District	Court	 for	 the
                                            Capital	 City	 of	 Warsaw	 under	 KRS	 no.:
                                            0000718147,	 Tax	 Identification	 Number
                                            (NIP):	 5213813853,	 Polish	 National
                                            Business	 Registry	 Number	 (REGON):
                                            3 6 9 5 0 0 5 3 8 ,	 e - m a i l :
                                            contact@easyfacedraw.com</td>
                                    </tr>
                                    <tr>
                                        <td style={{ verticalAlign: 'top' }}><strong>Contact	form</strong></td>
                                        <td style={{ paddingBottom: '1em' }}>an	 electronic	 document	 on	 the	 Website
                                            enabling	the	User	to	contact	the	Instructor
                                            and	 send	 a	 scan	 or	 photo	 of	 the	 User's
                                            drawing	</td>
                                    </tr>
                                    <tr>
                                        <td style={{ verticalAlign: 'top' }}><strong>Payment	form </strong></td>
                                        <td style={{ paddingBottom: '1em' }}>an	 electronic	 document	 on	 the	 Website
                                            enabling	 the	 payment	 of	 the	 price	 for	 the
                                            Service	using	a	credit	or	debit	card </td>
                                    </tr>
                                    <tr>
                                        <td style={{ verticalAlign: 'top' }}><strong>Registration	form</strong></td>
                                        <td style={{ paddingBottom: '1em' }}>an	 electronic	 document	 on	 the	 Website
                                            that	allows	 for	creating	an	Account	on	the
                                            Website</td>
                                    </tr>
                                    <tr>
                                        <td style={{ verticalAlign: 'top' }}><strong>Instructor</strong></td>
                                        <td style={{ paddingBottom: '1em' }}>a	person	conducting	the	course,	providing
                                            an	opinion	on	the	User's	drawing </td>
                                    </tr>
                                    <tr>
                                        <td style={{ verticalAlign: 'top' }}><strong>Account</strong></td>
                                        <td style={{ paddingBottom: '1em' }}>an	electronic	document	created	as	a	result
                                            of	the	User's	registration	on	the	Website	</td>
                                    </tr>
                                    <tr>
                                        <td style={{ verticalAlign: 'top' }}><strong>Course</strong></td>
                                        <td style={{ paddingBottom: '1em' }}>a	Service	provided	by	the	Administrator	to
                                            Users	 against	 payment,	 described	 in
                                            section	IV	of	the	Rules	and	Regulations		</td>
                                    </tr>
                                    <tr>
                                        <td style={{ verticalAlign: 'top' }}><strong>Privacy	Policy</strong></td>
                                        <td style={{ paddingBottom: '1em' }}>a	 separate	 document	 regulating	 general
                                            principles	 of	 operation	 of	 the	 Websitefor
                                            all	visitors	 to	 the	Website,	including	 those
                                            who	 are	 not	 Users,	 available	 at	 the
                                            following	 Internet	 address:	 	
                                            <Link to={"/" + PAGE.PRIVACY_POLICY}>  https://www.easyfacedraw.com/privacy-policy.  </Link>

                                            </td>
                                    </tr>
                                    <tr>
                                        <td style={{ verticalAlign: 'top' }}><strong>Rules	and	Regulations</strong></td>
                                        <td style={{ paddingBottom: '1em' }}>this	document	</td>
                                    </tr>
                                    <tr>
                                        <td style={{ verticalAlign: 'top' }}><strong>Website</strong></td>
                                        <td style={{ paddingBottom: '1em' }}>Website	 www.easyfacedraw.com	 managed
                                            and	administered	by	the	Administrator		</td>
                                    </tr>
                                    <tr>
                                        <td style={{ verticalAlign: 'top' }}><strong>Services</strong></td>
                                        <td style={{ paddingBottom: '1em' }}>Services	provided	by	 the	Administrator	 to
                                            Users	 against	 payment,	 described	 in
                                            section	IV	of	the	Rules	and	Regulations		</td>
                                    </tr>
                                    <tr>
                                        <td style={{ verticalAlign: 'top' }}><strong>User</strong></td>
                                        <td style={{ paddingBottom: '1em' }}>an	entity	using	the	services	of	the	Website
                                            after	accepting	 the	Rules	and	Regulations,
                                            understood	as	a	User	logged	in	and	a	User
                                            not	logged	in		</td>
                                    </tr>
                                    <tr>
                                        <td style={{ verticalAlign: 'top' }}><strong>User	not	logged	in</strong></td>
                                        <td style={{ paddingBottom: '1em' }}>User	 using	 the	 Website	 Services	 who	 has
                                            not	created	an	Account		</td>
                                    </tr>
                                    <tr>
                                        <td style={{ verticalAlign: 'top' }}><strong>User	logged	in</strong></td>
                                        <td style={{ paddingBottom: '1em' }}>User	 using	 the	 Website	 Services	 who	 has
                                            created	 an	 Account	 in	 accordance	 with
                                            Section	II	of	the	Rules	and	Regulations		</td>
                                    </tr>
                                </tbody>
                            </table>

                            <br />
                            <div class='grayText'>
                                <strong>I. General Provisions </strong>
                            </div> <br />
                            <div class='innerText'>
                                <ol>
                                    <li>The	Rules	and	Regulations	define	in	particular	 the	conditions	of	using	 the	Website,	 the
                                        type	 and	 scope	 of	 the	 Services,	 the	 conditions	 for	 the	 provision	 of	 Services,	 the
                                        conditions	for	concluding	and	terminating	agreements	for	the	provision	of	Services,	the
                                        conditions	for	the	processing	of	personal	data	as	well	as	the	complaint	procedure.	</li>
                                    <li>In	 connection	 with	 the	 provision	 of	 the	 Services,	 the	 Administrator	is	liable	 under	 the
                                        general	rules	specified	in	the	Polish	Act	of	23	April	1964	Civil	Code	(Dz.U.	[Polish	Journal
                                        Of	Laws]	of	1964	No.	16,	item	9,	as	amended).	</li>
                                    <li>The	 materials	 contained	 on	 the	 Website,	 in	 particular	 sketches,	 articles,	 layout	 and
                                        composition	 of	 these	elements	may	 be	 subject	 to	 copyright	 protection	and	may	 not	 be
                                        distributed,	modified	or	copied	in	whole	or	in	part,	without	the	prior	written	consent	of
                                        the	 authorised	 entity	 (excluding	 the	 use	 within	 fair	 use,	 in	 accordance	 with	 the
                                        provisions	of	law).	</li>
                                    <li>User	is	solely	responsible	 for	any	infringements	of	 third	party	copyrights	related	 to	 the
                                        use	of	the	Website.	</li>
                                    <li>Before	using	 the	Website,	 the	User	is	obliged	 to	 first	accept	 the	provisions	contained	in
                                        the	Rules	and	Regulations	or	the	Privacy	Policy.	</li>
                                    <li>Acceptance	of	the	Rules	and	Regulations	or	the	Privacy	Policy	is	done	by	clicking	the	field
                                        "I	 accept	 the	 Rules	 and	 Regulations	 of	 the	 Website	 www.easyfacedraw.com”	 or,
                                        respectively,	 "I	accept	 the	Privacy	Policy	of	 the	Website	www.easyfacedraw.com”,	which
                                        will	 be	 included	 in	 the	 message	 displayed	 during	 the	 first	 visit	 to	 the	Website's	 home
                                        page.	</li>
                                    <li>Only	persons	over	16	years	of	age	may	use	the	Website.	</li>
                                    <li>Before	using	the	Website,	the	User	is	obliged	to	declare	their	age.	The	declaration	is	made
                                        by	clicking	on	 the	 field	"Yes,	 I	am	over	16",	which	is	included	in	 the	message	displayed
                                        during	the	first	visit	on	the	main	page	of	the	Website.	</li>
                                </ol>
                            </div>

                            <br />
                            <div class='grayText'>
                                <strong>II. Registration	on	the	Website	</strong>
                            </div> <br />
                            <div class='innerText'>
                                <ol>
                                    <li>User	may	use	the	Website	using	the	Account	or	without	logging	in,	i.e.	without	the	need
                                        to	create	an	Account.	</li>
                                    <li>Creating	 an	 account	 is	 done	 by	 registering	 with	 the	 use	 of	 the	 Registration	 Form
                                        consisting	of	the	fields	"login",	"password",	"repeat	password"	and	"e-mail".	</li>
                                    <li>After	 filling	in	 the	Registration	Form,	 the	logged	in	User	will	receive	a	message	with	an
                                        activation	link	to	the	provided	e-mail	address.	Opening	the	link	is	necessary	to	create	an
                                        Account.		</li>
                                    <li>If	 the	activation	link	is	not	received,	one	should	click	on	“send	activation	link	one	more
                                        time”	in	the	Registration	Form.</li>
                                    <li>The	logged	in	User	has	the	right	to	change	the	password	later.	To	do	this,	open	a	tab	on
                                        the	Website	with	 the	name	 of	 the	logged	in	User	in	which	 there	is	a	 tab	 "My	Account".
                                        The	user	logged	in	after	entering	the	old	password,	new	password	and	repeating	the	new
                                        password	confirms	the	change	by	clicking	on	"change	my	password".	</li>
                                    <li>Registration	on	the	Website	can	also	be	made	via	a	Google	account.		</li>
                                    <li>Within	the	Account,	the	logged	in	User	will	receive	access	to	the	following	functions:
                                        <ol style={{ listStyleType: 'lower-latin' }}>
                                            <li>Access	 to	 the	 logged	 in	 user	 profile,	 including	 the	 possibility	 of	 editing	 it	 by
                                                changing	the	password;</li>
                                            <li>Access	to	purchased	Courses;	</li>
                                            <li>Access	 to	 the	 Contact	 Form	 that	 allows	 for	 sending	 a	 photo	 or	 scanning	 of	 a
                                                drawing,	as	well	as	a	text	or	audio	message.		</li>
                                        </ol>
                                    </li>
                                    <li>Registration	 on	 the	 Website	 is	 voluntary	 and	 possible	 at	 any	 time,	 however,	 without
                                        registration,	the	functionality	of	the	Website	is	limited,	in	particular,	a	not	logged	in	User
                                        will	have	access	to	the	purchased	Courses	only	via	the	link	provided	at	the	e-mail	address
                                        indicated	in	the	Payment	Form.	</li>
                                </ol>
                            </div>


                            <br />
                            <div class='grayText'>
                                <strong>III. Conclusion	on	the	Agreement	 </strong>
                            </div> <br />
                            <div class='innerText'>
                                <ol>
                                    <li>The	 Rules	 and	 Regulations	 constitute	 an	 integral	 part	 of	 agreements	 concluded	 with
                                        Users	for	the	provision	of	electronic	services.	</li>
                                    <li>Upon	 acceptance	 of	 the	 Rules	 and	 Regulations,	 the	 User	 concludes	 with	 the
                                        Administrator	an	agreement	 for	 the	provision	 of	Website	Services,	without	 the	need	 to
                                        prepare	a	separate	agreement,	with	 the	proviso	 that	 the	User	will	receive	 full	access	 to
                                        selected	Services	only	after	paying	the	fee.	</li>
                                </ol>
                            </div>

                            <br />
                            <div class='grayText'>
                                <strong>IV. Services </strong>
                            </div> <br />
                            <div class='innerText'>
                                <ol>
                                    <li>Within	the	Website,	the	User	may	use	the	following	Services:
                                        <ol style={{ listStyleType: 'lower-latin' }}>
                                            <li>Drawing	courses;</li>
                                            <li>Access	to	art-related	articles,	in	particular	drawing	lessons;</li>
                                            <li>Sharing	Courses	via	Facebook,	Twitter,	Pinterest,	LinkedIn	and	WhatsApp.	</li>
                                        </ol>
                                    </li>
                                    <li>Within	the	Website,	the	User	has	the	option	of	using	the	 following	Courses	constituting
                                        separate	sets	of	training	materials:
                                        <ol style={{ listStyleType: 'lower-latin' }}>
                                            <li>"How	to	draw	an	eye";	</li>
                                            <li>"How	to	draw	hair";	</li>
                                            <li>"How	to	draw	a	nose";	</li>
                                            <li>"How	to	draw	a	face";	</li>
                                            <li>"How	to	draw	lips";	</li>
                                            <li>"How	to	draw	an	ear";	</li>
                                        </ol>
                                    </li>
                                    <li>The	Administrator	reserves	the	right	to	modify	the	catalogue	of	the	Courses	indicated	in
                                        section	2.		</li>
                                    <li>Within	the	Course,	the	User	receives:
                                        <ol style={{ listStyleType: 'lower-latin' }}>
                                            <li>unlimited	 access	 to	 instructional	 videos	 discussing	 in	 particular	 techniques,
                                                materials	and	tools	used	to	create	face	sketches	or	facial	features;</li>
                                            <li>possibility	of	obtaining	the	Instructor's	opinion	on	the	User's	drawing;	</li>
                                            <li>Course	completion	certificate;	</li>
                                            <li>unlimited	access	to	the	Course	on	mobile	devices;</li>
                                            <li>possibility	to	comment	on	the	Course</li>
                                        </ol>
                                    </li>
                                    <li>The	number	and	content	of	tutorial	videos	may	vary	depending	on	the	chosen	Course.		</li>
                                    <li>Service	of	obtaining	the	Instructor's	opinion	is	available	to	the	User	once.		</li>
                                    <li>Instructor's	opinion,	indicated	in	sec.	4(b),	is	available	at	the	request	of	the	User.	Within
                                        one	 Course,	 the	 User	 is	 entitled	 to	 one	 Instructor's	 opinion.	 In	 order	 to	 obtain	 the
                                        opinion,	the	User	sends	a	scan	or	photo	of	their	drawing	via	the	Contact	Form,	placed	on
                                        the	website	of	the	Course	purchased	by	the	User,	under	the	instructional	video.	</li>
                                    <li>The	 logged-in	 User	 gains	 access	 to	 the	 Contact	 Form	 also	 on	 their	 profile,	 under	 the
                                        Reviews	tab.	The	logged-in	User	can	send	a	photo	or	scan	of	their	drawing,	as	well	as	a
                                        text	or	audio	message.		</li>
                                    <li>The	 Instructor's	 opinion	 is	 placed	 in	 the	 same	 place	 where	 the	 User	 uploaded	 their
                                        drawing.	The	opinion	may	be	prepared	in	the	form	of	an	audio	recording	(.mp3)	or	text.
                                        The	opinion	is	also	accompanied	by	the	User's	drawing	with	comments	or	corrections	by
                                        the	Instructor.	</li>
                                    <li>All	 Course	 Services	 are	 provided	 against	 payment.	 The	 course	 price	 is	 quoted	 in	 US
                                        dollars	(USD)	and	includes	taxes.	</li>
                                    <li>The	 Administrator	 reserves	 the	 right	 to	 change	 the	 prices	 of	 the	 Courses.	 The
                                        Administrator	 will	 inform	 the	 User	 about	 the	 change	 in	 the	 price	 of	 the	 Course	 by
                                        changing	 the	 price	 visible	 on	 the	website	 of	 the	 selected	 Course.	 Changing	 the	 price	 of
                                        the	Course	does	not	affect	the	content	of	previously	concluded	Agreements	between	the
                                        User	and	the	Administrator.	</li>
                                    <li>In	 order	 to	 gain	 access	 to	 individual	 Courses,	 the	 User	 is	 obliged	 to	 pay	 the	 price.
                                        Payment	is	made	by	clicking	on	"buy	now"	on	the	Course	website.	After	clicking,	the	User
                                        is	 redirected	 to	 the	 Payment	 Form.	 At	 the	 moment	 of	 payment,	 an	 agreement	 for	 the
                                        provision	of	electronic	services	is	concluded	regarding	the	Course	selected	by	the	User</li>
                                    <li>Payment	of	the	price	via	the	Payment	Form	is	possible	with	a	credit	or	debit	card.	In	the
                                        Payment	Form,	one	provide	e-mail	address,	card	number,	card	expiry	date	and	CVV	code.	</li>
                                    <li>After	 the	 payment	 is	 made,	 the	 User	 will	 receive	 a	 message	 to	 the	 e-mail	 address
                                        provided	 in	 the	 Payment	 Form	 with	 information	 about	 the	 successful	 or	 unsuccessful
                                        payment.	</li>
                                    <li>After	successful	payment,	the	User	will	receive	access	data	to	the	selected	Course	to	the
                                        e-mail	address	provided	in	the	Payment	Form.	If	the	User	has	an	Account	on	the	Website,
                                        these	data	will	also	be	visible	on	this	Account.
                                        The	User	may	use	the	purchased	Course	at	any	time.		</li>
                                    <li>Other	services,	with	 the	exception	of	 the	service	indicated	in	section	1(a),	are	provided
                                        free	of	charge.			</li>
                                </ol>
                            </div>


                            <br />
                            <div class='grayText'>
                                <strong>V. Terms	and	Conditions	for	the	Provision	of	Services </strong>
                            </div> <br />
                            <div class='innerText'>
                                <ol>
                                    <li>The	 Administrator	 reserves	 the	 right	 to	 change	 the	 functionality	 of	 the	Website	 at	 any
                                        time,	in	particular	by	introducing	new	functions	and	amenities	for	Users.	</li>
                                    <li>The	User	is	obliged	to	use	the	Services	on	the	terms	set	out	in	the	Rules	and	Regulations,
                                        in	accordance	with	applicable	law	and	the	principles	of	social	coexistence.	</li>
                                    <li>The	Administrator	is	not	liable	for	the	User's	use	of	the	Website	in	a	manner	inconsistent
                                        with	the	provisions	of	law,	the	principles	of	social	coexistence	and	the	provisions	of	the
                                        Rules	and	Regulations.		</li>
                                    <li>The	User	is	prohibited	from:
                                        <ol style={{ listStyleType: 'lower-latin' }}>
                                            <li>providing	illegal	content	within	the	use	of	the	Website	and	the	Services;</li>
                                            <li>using	the	Website	in	a	way	that	interferes	with	its	proper	functioning	or	exposes
                                                it	to	damage;	</li>
                                            <li>providing	false	personal	data	or	information,	content	or	materials	of	third	parties
                                                without	their	consent;	</li>
                                            <li>disseminating	 content	 that	 contains	 computer	 viruses	 or	 other	 computer	 ;iles
                                                that	compromise	computers	or	computer	networks;	</li>
                                            <li>capturing,	 recording,	 reproducing,	 making	 available	 to	 third	 parties	 or
                                                distributing	in	any	 form	 the	Course	or	any	 training	material	presented	or	made
                                                available	in	connection	with	the	Course.	</li>
                                        </ol>
                                    </li>
                                    <li>The	 Administrator	 reserves	 the	 right	 to	 extend	 the	 catalogue	included	in	 section	 4	 for
                                        other	User	behaviour.	</li>
                                    <li>The	User	grants	the	Administrator,	for	an	indefinite	period	of	time,	a	non-exclusive,	free
                                        license	to	record,	copy	and	distribute	the	materials	posted	by	the	User	on	the	Website,	in
                                        particular	 the	 drawings	made	 available	 for	 evaluation	 by	 the	 Instructor,	in	 all	 fields	 of
                                        use,	also	for	promotional	and	advertising	purposes.	</li>
                                    <li>Without	 prejudice	 to	 section	 6,	 the	 User	 retains	 the	 ownership	 right	 to	 the	 content
                                        created	by	them.	</li>
                                    <li>There	may	be	interruptions	in	the	operation	of	the	Website	due	to	technical	reasons,	in
                                        particular	resulting	from	the	need	to	repair	or	maintain	it,	as	well	as	related	to	the	need
                                        to	create	backup	copies	or	process	the	collected	data.	</li>
                                    <li>The	 Administrator	 is	 not	 responsible	 for	 the	 temporary	 inability	 to	 use	 the	 Website,
                                        caused	by	technical	reasons.		</li>
                                    <li>The	Administrator	does	not	guarantee	the	proper	functioning	of	the	Website	if	the	User
                                        uses	 hardware	 and	 software	 that	 do	 not	 meet	 the	 technical	 requirements	 set	 out	 in
                                        Section	IX	of	the	Rules	and	Regulations.	Provision	of	the	Service	may	also	be	interrupted
                                        in	 the	 event	 of	improper	 connection	 quality,	 damage	 or	 defects	 of	 telecommunications
                                        equipment,	 power	 systems,	 computer	 equipment,	 failure	 of	 the	 telecommunications
                                        network	or	power	outages,	or	any	action	of	third	parties.		</li>
                                    <li>The	costs	of	internet	connection	with	 the	Website	are	borne	by	 the	User	in	accordance
                                        with	the	price	list	of	their	telecommunications	operator.	</li>
                                </ol>
                            </div>

                            <br />
                            <div class='grayText'>
                                <strong>VI. Termination	of	the	Agreement </strong>
                            </div> <br />
                            <div class='innerText'>
                                <ol>
                                    <li>The	user	may	 terminate	 the	agreement	with	immediate	effect,	at	any	 time	and	without
                                        giving	reasons,	by	sending	to	the	following	address	contact@easyfacedraw.com	an	e-mail
                                        containing	 a	 declaration	 of	 will	 to	 terminate	 the	 agreement.	 The	 User	 may	 use	 the
                                        withdrawal	 form,	a	 template	of	which	is	attached	as	Attachment	no.	1	 to	 the	Rules	and
                                        Regulations.	 	 After	 receiving	 the	 declaration	 of	 withdrawal	 from	 the	 agreement	 to	 the
                                        indicated	 e-mail	 address,	 the	Administrator	will	immediately	 confirm	its	 receipt	 to	 the
                                        User.	</li>
                                    <li>Sending	 a	 declaration	 of	 will	 to	 withdraw	 from	 the	 agreement	 is	 also	 possible	 via
                                        traditional	 mail	 sent	 to	 Quizony	 spółka	 z	 ograniczoną	 odpowiedzialnością	 spółka
                                        komandytowa,	 ul.	 Krucza	 5/11,	 00-548	 Warsaw,	 Poland.	 The	 Administrator	 will	 send
                                        confirmation	 of	 receipt	 of	 the	 declaration	 of	 withdrawal	 from	 the	 agreement	 to	 the
                                        mailing	address	provided	by	the	User.	</li>
                                    <li>In	 the	 case	 of	 a	 logged	 in	 User,	 withdrawal	 from	 the	 agreement	 means	 deleting	 the
                                        Account.			</li>
                                    <li>Regardless	 of	 the	 right	 to	 withdraw	 from	 the	 agreement,	 the	 User	is	 entitled	 to	 resign
                                        from	using	the	Website	at	any	time	by	ceasing	to	use	the	Services.	Ending	the	use	of	the
                                        Website	does	not	result	in	the	loss	of	access	to	the	Account.	</li>
                                    <li>The	Administrator	reserves	the	right	to	delete	the	Account	of	a	logged-in	User	who	does
                                        not	 comply	 with	 the	 provisions	 of	 the	 Rules	 and	 Regulations,	 acts	 in	 a	 manner
                                        inconsistent	 with	 generally	 applicable	 law	 or	 the	 principles	 of	 social	 coexistence,	 or
                                        when	 their	 behaviour	 is	 considered	 harmful	 to	 the	 Administrator	 or	 other	 Users.
                                        Deleting	an	account	means	the	termination	of	the	agreement.		</li>
                                    <li>For	important	reasons,	the	Administrator	may	terminate	the	Agreement	for	the	provision
                                        of	 Services	 with	 the	 User.	 Important	 reasons	 are,	in	 particular,	 significant	 economic	 or
                                        business	 reasons	 and	 the	 termination	 of	 business	 activity	 by	 the	 Administrator.
                                        Termination	of	the	agreement	means	deleting	the	Account.	</li>
                                    <li>The	Administrator	shall	inform	the	User	about	the	termination	of	the	agreement	14	days
                                        in	 advance	 by	 sending	 an	 e-mail	 to	 the	 address	 provided	 by	 the	 User	 in	 the	 Payment
                                        Form	or	in	the	Registration	Form.	</li>
                                    <li>In	 the	 event	 of	 termination	 of	 the	 agreement	 for	 the	 provision	 of	 Services	 for	 reasons
                                        attributable	to	the	Administrator,	the	User	has	the	right	to	a	refund	of	the	price/part	of
                                        the	 price	 of	 the	 purchased	 Course.	 In	 order	 to	 obtain	 a	 refund,	 the	 User	 is	 obliged	 to
                                        c o n t a c t	 t h e	 A d m i n i s t r a t o r	 v i a	 e - m a i l	 t o	 t h e	 f o l l o w i n g	 a d d r e s s :
                                        contact@easyfacedraw.com	or	traditional	mail	to	the	following	address:	Quizony	spółka
                                        z	 ograniczoną	 odpowiedzialnością	 spółka	 komandytowa,	 ul.	 Krucza	 5/11,	 00-548
                                        Warsaw.		</li>
                                    <li>After	 receiving	 the	message,	 the	Administrator	makes	 refund	within	 14	 days	 using	 the
                                        same	payment	method	that	was	used	by	the	User,	unless	the	User	has	expressly	agreed	to
                                        a	different	method	of	refund,	which	does	not	involve	any	additional	costs	 for	them.	The
                                        Administrator	shall	notify	the	User	about	the	refund	via	a	message	sent	to	the	e-mail	or
                                        mailing	address	indicated	by	the	User.	</li>
                                    <li>In	the	event	of	termination	of	the	agreement	for	reasons	attributable	to	the	User,	they	are
                                        not	entitled	to	a	refund	of	the	price	or	part	of	the	price	of	the	purchased	Course.	</li>
                                    <li>In	 the	 event	 of	 termination	 of	 the	 provision	 of	 Services	 for	 reasons	 attributable	 to	 the
                                        User	or	the	Administrator,	the	User	is	not	entitled	to	compensation.	</li>
                                </ol>
                            </div>


                            <br />
                            <div class='grayText'>
                                <strong>VII. Return		 </strong>
                            </div> <br />
                            <div class='innerText'>
                                <ol>
                                    <li>The	User	has	 the	 right	 to	 return	 the	purchased	Service	under	 the	conditions	set	 out	in
                                        this	section.		</li>
                                    <li>The	 User	 has	 the	 right	 to	 make	 a	 return	 if	 they	 are	 not	 satis;ied	 with	 the	 quality	 or
                                        content	of	the	purchased	Service.	</li>
                                    <li>The	return	is	possible	within	30	days	from	the	date	of	purchase,	that	is	from	the	date	of
                                        payment.	After	the	indicated	time	limit,	no	refund	is	possible.	</li>
                                    <li>The	 User	 makes	 the	 return	 by	 sending	 an	 e-mail	 to	 the	 following	 address:
                                        contact@easyfacedraw.com.	 The	 message	 should	 contain	 the	 User's	 designation	 and	 a
                                        declaration	of	will	to	make	a	return,	indicating	the	Service	being	returned,	along	with	the
                                        reason.	 The	 User	 may	 use	 the	 return	 form,	 the	 template	 of	 which	 is	 attached	 as
                                        Attachment	no.	2	to	the	Rules	and	Regulations.	</li>
                                    <li>After	 receiving	 the	message,	 the	Administrator	makes	 refund	within	 14	 days	 using	 the
                                        same	payment	method	that	was	used	by	the	User,	unless	the	User	has	expressly	agreed	to
                                        a	different	method	of	refund,	which	does	not	involve	any	additional	costs	 for	them.	The
                                        Administrator	 shall	 notify	 the	 User	 about	 the	 refund	 via	 a	 message	 sent	 to	 the	 e-mail
                                        address	provided	by	the	User.	</li>
                                    <li>The	Administrator	will	refuse	to	make	a	return	after	the	time	limit	specified	in	section	3.
                                        In	this	case,	the	User	will	be	notified	of	the	inability	to	make	a	return	via	a	message	sent
                                        to	the	e-mail	address	provided	by	the	User.		</li>
                                </ol>
                            </div>

                            <br />
                            <div class='grayText'>
                                <strong>VIII. Complaints </strong>
                            </div> <br />
                            <div class='innerText'>
                                <ol>
                                    <li>The	User	has	the	right	to	lodge	a	complaint	regarding	the	provision	of	Services	under	the
                                        Rules	and	Regulations	at	any	time.	</li>
                                    <li>the	complaint	may	concern	in	particular:
                                        <ol style={{ listStyleType: 'lower-latin' }}>
                                            <li>no	access	to	the	User	Account;	</li>
                                            <li>provision	 of	 Services	 by	 the	 Administrator	 in	 violation	 of	 the	 Rules	 and
                                                Regulations;	</li>
                                            <li>errors	in	the	functioning	or	availability	of	the	Services.	</li>
                                        </ol>
                                    </li>
                                    <li>The	complaint may be sent by e-mail	at the following address:
                                        contact@easyfacedraw.com	 or	 in	 writing	 at	 the	 following	 address:	 Quizony	 spółka	 z
                                        ograniczoną	odpowiedzialnością	spółka	komandytowa,	ul.	Krucza	5/11,	00-548	Warsaw.		</li>
                                    <li>The	 complaint	 should	 contain	 the	 User's	 designation	 and	 a	 detailed	 description	 of	 the
                                        circumstances	underlying	the	complaint.	</li>
                                    <li>The	 Administrator	 will	 consider	 the	 complaint	 within	 14	 days	 of	 its	 receipt.	 If	 the
                                        complaint	cannot	be	examined	within	 this	 time	limit,	 the	Administrator	shall	notify	 the
                                        person	submitting	the	complaint	about	the	reasons	for	the	delay	and	the	expected	date	of
                                        considering	the	complaint,	the	total	time	limit	may	not	be	longer	than	30	days.		</li>
                                    <li>A	response	to	the	complaint	is	sent	to	the	e-mail	or	mailing	address	provided	by	the	User,
                                        if	requested	by	the	User	when	submitting	the	complaint.	</li>
                                </ol>
                            </div>


                            <br />
                            <div class='grayText'>
                                <strong>IX. Technical	Requirements	 </strong>
                            </div> <br />
                            <div class='innerText'>
                                <ol>
                                    <li>For	the	uninterrupted	use	of	the	Website	and	the	Services,	it	is	necessary	for	the	User	to
                                        meet	the	technical	requirements	referred	to	in	this	section	of	the	Rules	and	Regulations.	</li>
                                    <li>For	 the	 proper	 use	 of	 the	Website,	 it	 is	 necessary	 to	 have	 a	 computer	 or	 other	 device
                                        connected	to	the	Internet	with	a	correctly	installed	and	configured	web	browser,	e.g.:
                                        <ol style={{ listStyleType: 'lower-latin' }}>
                                            <li>Mozilla	Firefox,	</li>
                                            <li>Microsoft	Internet	Explorer,	</li>
                                            <li>Opera,	</li>
                                            <li>Google	Chrome,	</li>
                                            <li>Safari.	</li>
                                        </ol>
                                    </li>
                                    <li>It	 is	 also	 necessary	 to	 have	 Cookies	 and	 Java	 Script	 enabled	 and	 a	 minimum	 screen
                                        resolution	of	640x480.	</li>
                                    <li>In	 order	 to	 create	 an	 Account	 on	 the	 Website,	 it	 is	 necessary	 to	 have	 an	 e-mail	 or	 an
                                        Google	account.		</li>
                                </ol>
                            </div>

                            <br />
                            <div class='grayText'>
                                <strong>X. Processing	of	Personal	Data </strong>
                            </div> <br />
                            <div class='innerText'>
                                <ol>
                                    <li>Administrator	 of	 Users'	 personal	 data	 processed	 for	 the	 purpose	 of	 using	 the	 Services
                                        provided	 through	 the	 Website	 and	 for	 marketing,	 contact,	 archiving	 and	 complaint
                                        purposes	 is	 the	 Administrator	 -	 Quizony	 spółka	 z	 ograniczoną	 odpowiedzialnością
                                        spółka	 komandytowa,	 ul.	 Krucza	 5/11,	 00-548	 Warsaw,	 entered	 into	 the	 Register	 of
                                        Entrepreneurs	 of	 the	National	 Court	Register	 kept	 by	 the	District	 Court	 for	 the	 Capital
                                        City	 of	 Warsaw	 under	 KRS	 no.:	 0000718147,	 Tax	 Identification	 Number	 (NIP):
                                        5213813853,	 Polish	National	 Business	 Registry	Number	 (REGON):	 369500538,	 e-mail:
                                        contact@easyfacedraw.com.</li>
                                    <li>The	 Administrator	 ensures	 the	 implementation	 of	 the	 requirements	 resulting	 from	 z
                                        Regulation	 (EU)	 2016/679	 of	 the	 European	 Parliament	 and	 of	 the	 Council	 of	 27	 April
                                        2016	on	the	protection	of	individuals	with	regard	to	the	processing	of	personal	data	and
                                        on	 the	 free	 movement	 of	 such	 data,	 and	 repealing	 Directive	 95/46/EC	 (	 General	 Data
                                        Protection	Regulation)	(hereinafter:	“GDPR”).	</li>
                                    <li>Contact	with	the	Administrator	is	possible:
                                        <ol style={{ listStyleType: 'lower-latin' }}>
                                            <li>via	post	-	at	the	following	address:	ul.	Krucza	5/11,	00-548	Warsaw,	Poland	or	</li>
                                            <li>via	e-mail	-	at	the	following	e-mail	address:	contact@easyfacedraw.com	</li>
                                        </ol>
                                    </li>
                                    <li>Users'	 personal	 data	 in	 the	 form	 of	 designation	 of	 a	 given	 User	 (user	 name,	 e-mail
                                        address)	 and	 data	 that	 have	 been	 made	 available	 to	 the	 Administrator	 by	 Facebook,
                                        Twitter,	Pinterest,	LinkedIn	and	WhatsApp	will	be	processed	for	the	purpose	of:
                                        <ol style={{ listStyleType: 'lower-latin' }}>
                                            <li>Concluding	 the	 agreement	 between	 the	 User	 and	 the	 Administrator	 for	 the
                                                provision	of	Services	offered	on	the	Website	and	its	use	-	pursuant	to	art.	6	sec.
                                                1(b)	of	GDPR,	i.e.	processing	is	necessary	to	conclude	and	perform	an	agreement
                                                (the	 subject	 matter	 of	 which	 is	 the	 execution	 of	 services	 provided	 by	 the
                                                Administrator)	to	which	the	User	is	a	party	or	taking	action	at	the	User's	request
                                                before	concluding	the	agreement;</li>
                                            <li>marketing	 of	 the	 Administrator's	 own	 goods	 and	 services,	 including
                                                segmentation	 of	 consumer	 behaviour,	 for	 analytical	 and	 profiling	 purposes	 -
                                                pursuant	to	art.	6	sec.	1(f)	of	GDPR,	i.e.	based	on	the	legitimate	interest	pursued
                                                by	the	Administrator,	which	is	direct	marketing	of	their	services;		</li>
                                            <li>establishing,	 pursuing	 or	 defending	 any	 claims	 between	 the	 User	 and	 the
                                                Administrator	 -	pursuant	 to	art.	6	sec.	1(f)	of	GDPR,	i.e.	based	on	 the	legitimate
                                                interest	pursued	by	the	Administrator,	which	is	the	possibility	of	pursuing	claims
                                                or	defending	against	claims;	</li>
                                            <li>taking	possible	actions	in	connection	with	counteracting	crimes	-	pursuant	to	art.
                                                6	 sec.	 1(f)	 of	 GDPR,	 i.e.	 on	 the	 basis	 of	 the	 legitimate	 interest	 pursued	 by	 the
                                                Administrator,	which	is	the	ability	to	prevent	and	prosecute	crimes	committed	to
                                                the	detriment	of	the	Administrator.</li>
                                        </ol>
                                    </li>
                                    <li>Providing	personal	data	by	the	User	is	voluntary,	however,	without	providing	it,	the	User
                                        may	not	use	all	the	Services	indicated	in	Section	IV(1)	of	the	Rules	and	Regulations.		</li>
                                    <li>Users'	personal	data	will	be	processed	for	the	period	necessary	to	achieve	the	purposes
                                        for	which	the	data	is	processed	or	until	an	objection	is	raised	(if	the	basis	for	processing
                                        is	the	legitimate	interest	of	the	Administrator)	-	depending	on	which	of	the	events	occurs
                                        first.	 Later,	 the	 Administrator	 will	 store	 it	 until	 any	 claims	 are	 time-barred	 and	 for	 a
                                        period	of	two	(2)	months	after	the	end	of	such	applicable	limitation	period.		</li>
                                    <li>For	Users	whose	personal	data	is	processed	by	the	Administrator,	decisions	will	be	made
                                        in	an	automated	manner,	including	as	a	result	of	pro;iling.	</li>
                                    <li>User	has:
                                        <ol style={{ listStyleType: 'lower-latin' }}>
                                            <li>the	right	to	access	their	personal	data;</li>
                                            <li>the	right	to	request	its	rectification;	</li>
                                            <li>the	right	to	demand	its	removal;	</li>
                                            <li>the	right	to	request	the	restriction	of	its	processing;	</li>
                                            <li>the	 right	 to	 transfer	 personal	 data,	 i.e.	 to	 receive	 personal	 data	 from	 the
                                                Administrator	 in	 a	 structured,	 commonly	 used	 machine-readable	 format.	 The
                                                User	 may	 also	 request	 the	 Administrator	 to	 send	 their	 personal	 data	 they
                                                provided	to	another	administrator;</li>
                                            <li>the	right	to	withdraw	consent	to	the	extent	that	it	is	the	basis	for	the	processing
                                                of	 personal	 data.	 Withdrawal	 of	 consent	 does	 not	 affect	 the	 lawfulness	 of	 the
                                                processing	that	was	made	on	the	basis	of	consent	before	its	withdrawal;	</li>
                                            <li>the	right	to	object	to	the	processing	of	personal	data	to	the	extent	that	the	basis
                                                for	the	processing	of	personal	data	is	the	condition	for	the	legitimate	interest	of
                                                the	Administrator.		</li>
                                        </ol>
                                        In	order	to	exercise	the	above	rights,	one	should	contact	the	Administrator,	using	the
                                        contact	details	indicated	in	section	3.
                                    </li>
                                    <li>Users'	personal	data	will	be	disclosed	to	the	following	entities:	employees	and	associates
                                        of	the	Administrator,	IT	service	providers,	hosting	providers,	entities	providing	advisory
                                        and	 legal	 services,	 to	 the	 extent	 necessary	 to	 perform	 an	 agreement	 between	 the
                                        Administrator	and	this	entity.	</li>
                                    <li>Users'	 personal	 data	 may	 also	 be	 made	 available	 to	 entities	 and	 bodies	 authorised	 to
                                        process	such	data	on	the	basis	of	legal	provisions.	</li>
                                    <li>Administrator	 may	 transfer	 Users'	 personal	 data	 to	 countries	 outside	 the	 European
                                        Economic	Area	(EEA).		</li>
                                    <li>The	User	has	the	right	to	lodge	a	complaint	with	the	supervisory	body	dealing	with	the
                                        protection	of	personal	data	 -	 the	President	of	 the	Office	 for	Personal	Data	Protection,	if
                                        the	User	believes	that	the	processing	of	data	by	the	Administrator	violates	the	GDPR.	</li>
                                    <li>The	 Officer	 of	 Personal	 Data	 Protection	 processed	 by	 the	Website	is	Quizony	 sp. z o.o. sp.k.</li>
                                    <li>The	Website	uses	cookies	-	more	information	in	this	regard	can	be	 found	in	the	Privacy
                                        Policy	on	the	website <Link to={"/" + PAGE.PRIVACY_POLICY}> https://www.easyfacedraw.com/privacy-policy.  </Link>
                                    </li>
                                    <li>All	 other	 issues	 related	 to	 the	 protection	 of	 personal	 data	 are	 included	 in	 the	 Privacy
                                        Policy.	</li>
                                </ol>
                            </div>


                            <br />
                            <div class='grayText'>
                                <strong>XI. Modification	of	the	Rules	and	Regulations	 </strong>
                            </div> <br />
                            <div class='innerText'>
                                <ol>
                                    <li>The	 Administrator	 has	 the	 right	 to	 unilaterally	modify	 the	 provisions	 of	 the	 Rules	 and
                                        Regulations	 if	 at	 least	 one	 of	 the	 important	 reasons	 indicated	 in	 the	 catalogue	 below
                                        occurs:
                                        <ol style={{ listStyleType: 'lower-latin' }}>
                                            <li>Amendment	 to	 the	 provisions	 of	 applicable	 law	 that	 regulates	 the	 conduct	 of
                                                business	by	the	Administrator;</li>
                                            <li>The	need	to	adapt	the	wording	of	the	Rules	and	Regulations	to	applicable	law	or
                                                the	need	to	make	editorial	changes	to	the	Rules	and	Regulations;	</li>
                                            <li>Modification	 of	 the	 operation	 or	 functioning	 of	 the	 website
                                                www.easyfacedraw.com	or	individual	Services,	which	will	be	caused	by	objective
                                                and	independent	reasons	of	technological	or	technical	nature;	</li>
                                            <li>Modification	 of	 the	 terms	 of	 use	 of	 the	 website	 www.easyfacedraw.com	 or
                                                individual	 Services,	 not	 deteriorating	 the	 situation	 of	 Users	 compared	 to	 the
                                                existing	ones;</li>
                                            <li>The	 need	 to	 update	 the	 Administrator's	 data	 indicated	 in	 the	 Rules	 and
                                                Regulations.	</li>
                                        </ol>
                                    </li>
                                    <li>The	modifications	come	into	force	when	the	amended	text	of	the	Rules	and	Regulations	is
                                        posted	on	the	website	https://www.easyfacedraw.com.	</li>
                                    <li>The	Administrator	shall	notify	about	any	modifications	to	the	Rules	and	Regulations	with
                                        a	message	displayed	on	the	main	page	of	the	Website	14	days	in	advance.	</li>
                                    <li>The	Administrator	will	additionally	notify	the	logged	in	User	by	means	of	a	message	sent
                                        to	the	e-mail	address	provided	in	the	Registration	Form,	14	days	in	advance.	</li>
                                    <li>The	 not	 logged	 in	 User	 who	 purchased	 the	 Course	 will	 be	 additionally	 notified	 by	 the
                                        Administrator	 via	a	message	sent	 to	 the	e-mail	address	provided	in	 the	Payment	Form,
                                        14	days	in	advance.	</li>
                                    <li>The	User	may	resign	from	using	the	Service	at	any	time,	in	accordance	with	the	principles
                                        set	out	in	Section	VI	of	the	Rules	and	Regulations,	if	they	do	not	accept	the	wording	of	the
                                        new	version	of	the	Rules	and	Regulations.	</li>
                                </ol>
                            </div>

                            <br />
                            <div class='grayText'>
                                <strong>XII. Final	Provisions	 </strong>
                            </div> <br />
                            <div class='innerText'>
                                <ol>
                                    <li>The	Rules	and	Regulations	are	valid	from	22-07-2021.</li>
                                    <li>The	Regulations	are	available	to	Users	at	any	time,	free	of	charge	on	the	website	https://www.easyfacedraw.com	and	at	the	Administrator's	office.	</li>
                                    <li>The	User	may	receive	technical	support	in	connection	with	the	functioning	of	the	Website
                                        at	the	e-mail	address	contact@easyfacedraw.com.	</li>
                                    <li>In	matters	not	covered	by	 the	Rules	and	Regulations,	generally	applicable	provisions	of
                                        Polish	law	shall	apply.</li>
                                    <li>The	choice	of	Polish	law	on	the	basis	of	the	Rules	and	Regulations	does	not	deprive	the
                                        User	who	is	a	consumer	of	the	protection	granted	to	them	on	the	basis	of	provisions	that
                                        cannot	be	excluded	by	agreement	between	the	Administrator	and	the	User,	under	the	law
                                        which,	in	accordance	with	the	relevant	regulations,	would	be	applicable	in	the	case	of	the
                                        law	of	choice.</li>
                                    <li>None	of	 the	provisions	of	 the	Rules	and	Regulations	limits	 the	consumer's	rights	under
                                        applicable	law.</li>
                                    <li>Detailed	 information	 on	 the	 possibility	 for	 the	 User	 who	 is	 a	 consumer	 to	 use	 out-ofcourt	 complaint	 and	 redress	 methods	 and	 the	 rules	 of	 access	 to	 these	 procedures	 are
                                        available	on	the	website	of	the	Polish	Office	of	Competition	and	Consumer	Protection	at:
                                        <a href="https://uokik.gov.pl/pozasadowe_rozwiazywanie_sporow_konsumenckich.php"> https://uokik.gov.pl/pozasadowe_rozwiazywanie_sporow_konsumenckich.php</a>.</li>
                                    <li>There	is	also	a	contact	point	at	the	President	of	the	Office	of	Competition	and	Consumer
                                        Protection	 (telephone:	22	55	60	333,	e-mail:	kontakt.adr@uokik.gov.pl	or	in	writing	at:
                                        Pl.	 Powstańców	 Warszawy	 1,	 00-030	 Warsaw.),	 whose	 task	 is,	 inter	 alia,	 to	 assist
                                        consumers	in	matters	relating	to	out-of-court	resolution	of	consumer	disputes.	</li>
                                    <li>The	consumer	has	 the	 following	exemplary	possibilities	of	using	extrajudicial	means	of
                                        dealing	 with	 complaints	 and	 redress:	 (1)	 application	 for	 dispute	 resolution	 to	 a
                                        permanen t	 consumer	 arbi tration	 cour t	 (more	 in formation	 at:	<a href="http://www.spsk.wiih.org.pl/">http://www.spsk.wiih.org.pl/</a>);	 (2)	application	 for	an	 out-of-court	 settlement	 of	 the	dispute	 to
                                        the	 voivodeship	inspector	of	 the	Trade	 Inspection	 (more	information	on	 the	website	of
                                        the	 inspector	 competent	 for	 the	 place	 of	 business	 by	 the	 Service	 Provider);	 and	 (3)
                                        assistance	 of	 a	 poviat	 (municipal)	 consumer	 ombudsman	 or	 a	 social	 organization
                                        statutory	 tasks	 of	 which	 include	 consumer	 protection	 (including	 the	 Consumer
                                        Federation,	Association	of	Polish	Consumers).	Advice	is	provided,	inter	alia,	via	e-mail	at
                                        tips@dlakonsumentow.pl	 and	 at	 the	 consumer	 helpline	 number	 801	 440	 220	 (the
                                        helpline	is	available	on	Business	Days,	between	8:00	a.m.	and	06:00	p.m.,	connection	fee
                                        according	to	the	operator's	tariff).</li>
                                    <li>Pursuant	to	Regulation	(EU)	No	524/2013	of	the	European	Parliament	and	of	the	Council
                                        of	 21	 May	 2013	 on	 on-line	 dispute	 resolution	 for	 consumer	 disputes	 and	 amending
                                        Regulation	 (EC)	 No	 2006/2004	 and	 Directive	 2009/22/EC	 (Regulation	 on	 ODR	 in
                                        consumer	 disputes),	 the	 User	 may	 submit	 a	 complaint	 in	 order	 to	 resolve	 any	 dispute
                                        with	the	Administrator	via	the	ODR	internet	platform	(the	ODR	platform	is	available	at	-
                                        <a href="http://ec.europa.eu/consumers/odr/"> http://ec.europa.eu/consumers/odr/</a>.</li>
                                </ol>
                            </div>


                            <br />
                            <div class='grayText'>
                                <strong>Attachment	no.	1	to	the	Rules	and	Regulations	 </strong>
                            </div> <br />
                            <div class='innerText'>
                                TEMPLATE	 FORM	 FOR	 WITHDRAWING	 FROM	 THE	 AGREEMENT	 FOR	 THE	 USE	 OF	 THE
                                WEBSITE	SERVICES <br />
                                Addressee:	Quizony	spółka	z	ograniczoną	odpowiedzialnością	spółka	komandytowa,	ul.	Krucza
                                5/11,	00-548	Warsaw,	Poland
                                e-mail:	contact@easyfacedraw.com <br />
                                I	_______________________________	hereby	inform	about	the	withdrawal	from	the	agreement	for	the	use <br />
                                of	the	Services	of	the	Website	www.easyfacedraw.com. <br />
                                User's	Full	name	_______________________________ <br />
                                User’s	e-mail	_______________________________ <br />
                                User’s	mailing	address*:	_______________________________ <br />
                                User’s	signature	and	Date*:	_______________________________ <br />
                                <br />  <br />
                                *	fill	in	when	sending	the	form	via	traditional	mail.
                            </div>

                            <br />
                            <div class='grayText'>
                                <strong>Attachment	no.	2	to	the	Rules	and	Regulations	 </strong>
                            </div> <br />
                            <div class='innerText'>
                                TEMPLATE	FORM	FOR	RETURNING	THE	WEBSITE	SERVICES <br />
                                Addressee:	Quizony	spółka	z	ograniczoną	odpowiedzialnością	spółka	komandytowa,	ul.	Krucza
                                5/11,	00-548	Warsaw,	Poland <br />
                                e-mail:	contact@easyfacedraw.com <br />
                                I	 _______________________________	 hereby	 wish	 to	 return	 the	 following	 Services	 of	 the	 Website
                                www.easyfacedraw.com: <br />
                                1) Name	 o f	 the	 ser vice:	 _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ <br />	 	 Cause	 o f	 re turn:
                                _______________________________ <br />
                                User's	Full	name	_______________________________ <br />
                                User’s	e-mail	_______________________________
                                Please,	 make	 a	 refund	 to	 the	 following	 bank	 account	 number	 *:	 __________________________________; <br />
                                bank	name*:	__________________________________ <br />
                                <br /> <br />
                                *	 fill	 in	 if	 the	 refund	 is	 to	 be	 made	 in	 a	 form	 other	 than	 the	 one	 selected	 by	 the	 User	 in	 the
                                Payment	Form.
                            </div>


                            <div className='innerText'>
                                <br />Last Edited on 2021-07-29.
                            </div>
                        </div>
                    </div>
                </section>
                <br />
                <br />
            </div>
        );
    }
}

export default Rules;
