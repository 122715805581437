import React, { Component } from 'react';

export const NUMBER_OF_TUTORIAL_FOR_FREE = 2222;

export const BEGINNER = "beginners";
export const MEDIUM = "intermediate";
export const EXPERT = "experts";

export const LOGIN_MIN_LENGTH = 2;
export const LOGIN_MAX_LENGTH = 64;

export const EMAIL_MIN_LENGTH = 7;
export const EMAIL_MAX_LENGTH = 64;

export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 64;

// export const SHOW_PAGE = true;
// export const SERVER_URL = 'http://localhost:8081/api/';
// export const WEBPAGE_URL = 'http://localhost:8081';
// export const GOOGLE_CLIENT_ID = '627625937312-npamj4994diiupjb5b8rupkopl86okcf.apps.googleusercontent.com';
// export const WEBPAGE_URL_IMAGES = '/images';

// export const SHOW_PAGE = false;
export const SERVER_URL = 'https://app.easyfacedraw.com//api/';
export const WEBPAGE_URL = 'https://www.easyfacedraw.com';
export const GOOGLE_CLIENT_ID = '273003251684-7ud2lpo76q8311kh1rfsiilfmk03okbk.apps.googleusercontent.com';
export const WEBPAGE_URL_IMAGES = 'https://s3.amazonaws.com/www.easyfacedraw.com/images';

// export const STRIPE_PK = 'pk_test_MHs9NEXWg1DKfyKgkITdnAXb00dVkdCzYA';
export const STRIPE_PK = 'pk_live_1r5sgMo8Tnp8Cxwq37nkiiOt00qHBCVRxV';

export const FB_FANPAGE_URL = 'https://www.facebook.com/easyfacedraw/';
export const YOUTUBE_FANPAGE_URL = 'https://www.youtube.com/channel/UC0RaOEhIH4Ls_zSdd7_JHzA';
export const IMAGES_FOR_REVIEW_URL = 'https://s3.amazonaws.com/www.easyfacedraw.com/imagesForReview/';
export const REVIEW_STATUS = {
    WAITING: 'WAITING',
    DONE: 'DONE'
}

export const RATINGS = [
    'Bad',
    'Average',
    'Good',
    'Very good',
    'Amazing'
]

export const PAGE = {
    READMORE: 'readmore',
    ABOUT: 'about',
    TEACHER: 'teacher',
    SHOWTUTORIALS: 'showTutorials',
    HELP: 'help',
    PRIVACY_POLICY: 'privacy-policy',
    COOKIES_POLICY: 'cookies-policy',
    RULES: 'rules',
    INFORMATION_OBLIGATION_CLAUSE: 'information-obligation-clause',
    CONTRIBUTION: 'contribution',
    ARTICLE: 'article-drawing-is-everything',
    ARTICLE_SECOND: 'article-everything-you-need-to-know-about-impressionism',
    ARTICLE_HOW_TO_DRAW_AN_EYE: 'article-how-to-draw-an-eye',
    ARTICLE_DRAWING_TECHNIQUE: 'article-drawing-technique',
    ARTICLE_LIGHT_AND_SHADOW: 'article-the-play-of-light-and-shadow',
    ARTICLE_PORTRAITURE_AN_EVER_EXPANDING_FORM: 'article-portraiture-an-ever-expanding-form',
    ARTICLE_HOW_COLOR_WORKS_IN_ART: 'article-how-color-works-in-art',
    ARTICLE_DIGITAL_PAINTING: 'article-digital-painting',
    ARTICLE_MINIMALISM: 'article-minimalism',
    ARTICLE_DA_VINCI: 'article-da-vinci',
    ARTICLE_THE_ART_OF_ILLUSION: 'article-the-art-of-illusion'

}

// export const DESKTOP_QUALITY = {
//     Q_2160p: {code:'2160p',url:'2160p',alias:'4K'},
//     Q_1080p: {code:'1080p',url:'1080p',alias:'HD'},
//     Q_720p: {code:'720p',url:'720p',alias:'HD'},
//     Q_480p: {code:'480p',url:'480p',alias:''}
// }
export const DESKTOP_QUALITY = {
    Q_2160p: { code: '2160p', url: '2160p', alias: '4K' },
    Q_1080p: { code: '1080p', url: '1080p_light', alias: 'HD' },
    Q_720p: { code: '720p', url: '720p_light', alias: 'HD' },
    Q_480p: { code: '480p', url: '480p_light', alias: '' }
}
// export const DESKTOP_QUALITY = {
//     Q_1080p: {code:'1080p',url:'1080p_mobile',alias:'HD'},
//     Q_720p: {code:'720p',url:'720p_mobile',alias:'HD'},
//     Q_480p: {code:'480p',url:'480p_mobile',alias:''}
// }
// export const MOBLIE_QUALITY = {
//     Q_1080p: {code:'1080p',url:'1080p_mobile',alias:'HD'},
//     Q_720p: {code:'720p',url:'720p_mobile',alias:'HD'},
//     Q_480p: {code:'480p',url:'480p_mobile',alias:''}
// }
export const MOBLIE_QUALITY = {
    Q_1080p: { code: '1080p', url: '1080p_light', alias: 'HD' },
    Q_720p: { code: '720p', url: '720p_light', alias: 'HD' },
    Q_480p: { code: '480p', url: '480p_light', alias: '' }
}

export const LOGIN_REVIEW_FREE_FOR_EVERYONE = "free_review_for_everyone"

export const MELODY_ICON = <figure><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
    viewBox="0 0 270.797 270.797" style={{ enableBackground: "new 0 0 270.797 270.797" }} >
    <path d="M190.255,98.528l-51.959-51.959c0.068-1.23,0.192-2.458,0.352-3.681c0.081-0.618,0.175-1.235,0.279-1.85
c0.009-0.054,0.018-0.108,0.027-0.162c0.102-0.589,0.214-1.176,0.337-1.761c0.016-0.076,0.032-0.152,0.049-0.229
c0.124-0.576,0.257-1.15,0.401-1.722c0.019-0.075,0.039-0.15,0.059-0.225c0.149-0.579,0.306-1.156,0.477-1.73
c0.013-0.044,0.028-0.088,0.041-0.133c0.713-2.38,1.614-4.716,2.694-6.987c0.06-0.125,0.115-0.252,0.175-0.376
c0.197-0.405,0.406-0.805,0.615-1.205c0.123-0.237,0.244-0.474,0.371-0.709c0.192-0.354,0.394-0.705,0.595-1.056
c0.159-0.277,0.318-0.553,0.483-0.828c0.194-0.322,0.393-0.642,0.595-0.961c0.189-0.298,0.381-0.596,0.577-0.891
c0.196-0.296,0.396-0.59,0.599-0.882c0.22-0.316,0.445-0.628,0.673-0.94c0.197-0.27,0.394-0.539,0.598-0.806
c0.255-0.334,0.518-0.663,0.782-0.992c0.193-0.24,0.383-0.481,0.58-0.718c0.308-0.369,0.628-0.731,0.949-1.093
c0.169-0.191,0.333-0.385,0.506-0.574c0.501-0.548,1.014-1.088,1.544-1.618c2.407-2.407,5.001-4.513,7.735-6.318
c2.05-1.354,4.18-2.539,6.368-3.554c2.189-1.015,4.436-1.862,6.723-2.539c9.148-2.708,18.928-2.708,28.076,0
c2.287,0.677,4.535,1.523,6.723,2.539c2.188,1.015,4.318,2.2,6.368,3.554c2.734,1.805,5.328,3.911,7.735,6.318
c19.255,19.255,19.255,50.473,0,69.728c-0.53,0.53-1.071,1.043-1.618,1.544c-0.189,0.173-0.384,0.337-0.575,0.507
c-0.361,0.32-0.723,0.64-1.091,0.947c-0.237,0.198-0.479,0.388-0.719,0.582c-0.328,0.264-0.657,0.527-0.99,0.781
c-0.267,0.204-0.537,0.401-0.807,0.599c-0.311,0.227-0.623,0.452-0.938,0.671c-0.293,0.204-0.588,0.404-0.884,0.6
c-0.294,0.195-0.591,0.387-0.888,0.575c-0.32,0.203-0.641,0.403-0.965,0.598c-0.272,0.163-0.546,0.321-0.82,0.478
c-0.354,0.203-0.707,0.406-1.065,0.6c-0.23,0.125-0.464,0.243-0.696,0.364c-0.404,0.211-0.808,0.423-1.217,0.621
c-0.118,0.058-0.239,0.11-0.357,0.167c-2.28,1.086-4.626,1.99-7.016,2.706c-0.039,0.012-0.078,0.025-0.118,0.036
c-0.578,0.172-1.16,0.33-1.743,0.48c-0.07,0.018-0.14,0.037-0.21,0.055c-0.576,0.145-1.154,0.28-1.734,0.404
c-0.072,0.016-0.145,0.031-0.217,0.046c-0.588,0.123-1.178,0.237-1.77,0.339c-0.051,0.009-0.103,0.017-0.154,0.026
c-0.617,0.104-1.236,0.199-1.856,0.279l0,0C192.711,98.336,191.485,98.46,190.255,98.528z M227.105,175.256
c-8.722-8.723-20.318-13.526-32.653-13.526s-23.932,4.804-32.653,13.525l-71.41,71.41c-5.889,5.889-13.718,9.132-22.046,9.132
s-16.158-3.243-22.052-9.137l-0.548-0.547c-12.156-12.157-12.156-31.938,0-44.094l18.966-18.966l5.383,5.383
c1.455,1.455,3.376,2.196,5.305,2.196c1.644,0,3.293-0.538,4.666-1.63l99.979-79.512c-0.13-0.121-0.268-0.231-0.394-0.356
l-51.959-51.959c-0.152-0.152-0.284-0.319-0.428-0.476l-80.088,99.344c-2.405,2.983-2.174,7.301,0.536,10.011l6.392,6.391
l-18.966,18.966c-18.004,18.005-18.004,47.302,0.005,65.312l0.548,0.548c8.723,8.722,20.319,13.525,32.654,13.525
s23.932-4.804,32.653-13.525l71.41-71.41c5.889-5.889,13.718-9.132,22.046-9.132s16.157,3.243,22.047,9.133l19.862,19.861
c2.928,2.928,7.677,2.927,10.606-0.001c2.929-2.929,2.929-7.678-0.001-10.606L227.105,175.256z"/>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
</svg></figure>

export const INFORMATION_OGLIGATION_RULES_HTML = <React.Fragment>
    <div className="privacy-policy">
        <div className='innerText'>

            Pursuant	to	Art.	13,	and	in	selected	cases	pursuant	to	Art.	14,	of	the	Regulation	(EU)	2016/679
            of	 the	 European	 Parliament	and	 of	 the	 Council	 of	 27	April	 2016	 on	 the	 protection	 of	 personal
            data	 processed	 and	 on	 the	 free	 movement	 of	 such	 data	 and	 repealing	 Directive	 95/46/EC
            ("GDPR"),	we	would	like	to	inform	you	that:                                <br />
        </div>
        <br />
        <div className='innerText'>
            <strong>
                [Controller,	Personal	Data	Officer,	Contact	Details]
            </strong>
            <ol>
                <li>The	 Controller	 of	 your	 personal	 data	 is	 Quizony	 spółka	 z	 ograniczoną
                    odpowiedzialnością	 spółka	 komandytowa,	 ul.	 Krucza	 5/11,	 00-548	 Warsaw,	 Poland,
                    (“Controller”).	</li>
                <li>Contact	with	the	Controller	is	possible	in	writing	-	at	the	following	address:	Krucza	5/11,
                    00 -548	 Warsaw,	 Poland	 or	 electronically	 –	 at	 the	 following	 address:
                    contact@easyfacedraw.com.	</li>
                <li>The	 Controller	 has	 appointed	 a	 personal	 data	 protection	 officer	 with	 whom	 you	 can
                    contact	in	all	matters	 regarding	 the	 protection	 of	 your	 personal	 data.	 Contact	with	 the
                    data	protection	officer	is	possible	in	writing	at	 the	 following	address:	Quizony	spółka	z
                    ograniczoną	odpowiedzialnością	spółka	komandytowa,	ul.	Krucza	5/11,	00-548	Warsaw
                    or	electronically	–	at	the	following	address:	contact@easyfacedraw.com.</li>

                <strong>
                    [Processing	of	Personal	Data]
                </strong>
                <li>We	 process	 the	 following	 data	 about	 you:	 name	 and	 surname,	 gender,	 year	 of	 birth,
                    mobile	phone	number,	e-mail	address,	information	about	the	Services	that	you	use	with
                    your	preferential	data.	The	indicated	data	may	come	from	the	following	sources:
                    <ol style={{ listStyleType: 'lower-latin' }}>
                        <li>www.easyfacedraw.com,</li>
                        <li>www.google.pl.	</li>
                    </ol>
                </li>
                <li>We	 will	 process	 the	 personal	 data	 that	 you	 provide	 to	 us	 when	 registering	 and
                    configuring	your	Account	at	www.easyfacedraw.com	("Website")	for	the	purposes	of:
                    <ol style={{ listStyleType: 'lower-latin' }}>
                        <li>providing	 you	 with	 Services,	 including	 for	 complaint,	 information	 and	 contact
                            purposes	 -	 the	legal	basis	 for	processing	is	 the	necessity	 to	process	data	 for	 the
                            conclusion	 and	 performance	 of	 the	 agreement,	which	is	 expressed	in	 the	 Rules
                            and	Regulations	of	www.easyfacedraw.com	(“Rules	and	Regulations”);	</li>
                        <li>marketing	 of	 the	 Controller's	 own	 goods	 and	 services,	 including	 the
                            segmentation	of	consumer	behaviour,	 for	analytical	and	profiling	purposes	 -	 the
                            legal	 basis	 for	 data	 processing	 is	 the	 implementation	 of	 the	 Controller's
                            legitimate	interest.	The	Controller's	legitimate	interest	is	direct	marketing	of	its
                            services;
                        </li>
                        <li>pursuing	claims	related	to	the	Services	-	the	legal	basis	for	data	processing	is	the
                            necessity	 of	 processing	 to	 implement	 the	 legitimate	 interests	 of	 the	 Controller.
                            The	Controller's	legitimate	interest	is	the	possibility	of	pursuing	claims	by	them;	</li>
                        <li>taking	possible	actions	in	connection	with	counteracting	crimes	-	the	legal	basis
                            for	data	processing	is	 the	necessity	of	processing	 to	implement	 the	Controller's
                            legitimate	interests,	 the	 Controller's	legitimate	interest	is	 the	 ability	 to	 prevent
                            and	prosecute	crimes	committed	to	the	detriment	of	the	Controller.
                        </li>
                    </ol>
                </li>
                <li>Decisions	regarding	the	Services	will	be	made	automatically,	based	on	profiling.	Profiling
                    methods	 are	 monitored.	 Due	 to	 automated	 decision-making,	 you	 have	 the	 right	 to
                    challenge	this	decision	and	request	that	the	data	be	re-examined	and	that	a	decision	be
                    made	by	a	human.
                </li>
                <li>
                    Providing	 personal	 data	in	 connection	with	 the	 use	 of	 the	Website	is	 necessary	 for	 the
                    conclusion	 and	 performance	 of	 the	 agreement	 (obligations	 under	 the	 Rules	 and
                    Regulations).
                </li>
                <strong>
                    [Period	of	personal	data	storage]
                </strong>
                <li>We	will	process	your	personal	data	when	you	use	the	Website.		</li>
                <li>Then,	we	will	archive	them	for	the	period	required	by	relevant	legal	provisions	(e.g.	tax
                    law).</li>
                <li>In	 the	 event	 that	 the	 basis	 for	 data	 processing	 is	 consent,	 your	 personal	 data	 will	 be
                    processed	until	it	is	withdrawn.	</li>
                <li>After	the	expiry	of	the	above	time	limit,	your	personal	data	will	be	stored	until	the	claims
                    are	 time-barred	 or	 for	 a	 period	 of	 two	 (2)	 months	 after	 the	 end	 of	 such	 applicable
                    limitation	 period,	 or	 until	 the	 obligation	 to	 store	 data	 resulting	 from	 legal	 provisions
                    expires,	in	particular	the	obligation	to	store	accounting	documents	related	to	the	Website
                    Services.</li>
                <strong>
                    [Categories	of	data	recipients]
                </strong>
                <li>Your	personal	data	may	be	made	available	 to	entities	and	bodies	authorised	 to	process
                    such	data	on	the	basis	of	legal	provisions.	</li>
                <li>Your	personal	data	may	be	transferred	to	entities	processing	personal	data	at	the	request
                    of	 the	Controller,	including	 IT	service	providers,	hosting	providers,	advertisers,	entities
                    providing	advisory	and	legal	services.	We are bound with these entities by an agreement
                    for entrus0ng the processing of personal data.</li>
                <li>Your personal data will be disclosed to authorised employees and associates of the
                    Controller, and possibly to couriers and law firms.</li>
                <strong>
                    [Rights]
                </strong>
                <li>You	are	entitled	 to	 the	access	 to	 your	 personal	 data	and	 to	 request	its	 rectification,	its
                    removal	or	limitation	of	its	processing.</li>
                <li>You	have	 the	right	 to	 transfer	personal	data,	i.e.	 to	receive	your	personal	data	 from	 the
                    Controller	in	a	structured,	commonly	used	machine-readable	 format.	You	can	send	 this
                    data	to	another	data	controller.	</li>
                <li>To	the	extent	that	the	basis	for	the	processing	of	your	personal	data	is	the	premise	of	the
                    legitimate	interest	of	the	Controller,	you	have	the	right	to	object	to	the	processing	of	your
                    personal	data.	In	particular,	you	have	the	right	to	object	to	the	processing	of	data	for	the
                    purposes	of	direct	marketing	and	profiling.		</li>
                <li>To	the	extent	that	the	basis	for	the	processing	of	your	personal	data	is	consent,	you	have
                    the	right	to	withdraw	this	consent.	Withdrawal	of	consent	does	not	affect	the	lawfulness
                    of	the	processing	that	was	made	on	the	basis	of	consent	before	its	withdrawal;		</li>
                <li>In	order	 to	exercise	 the	above	rights,	you	should	contact	 the	Controller	or	 the	Personal
                    Data	Protection	Officer,	using	the	contact	details	indicated	above</li>
                <li>You	 have	 the	 right	 to	 lodge	 a	 complaint	 with	 the	 supervisory	 body	 dealing	 with	 the
                    protection	of	personal	data.	</li>
            </ol>
        </div> <br />


    </div>
</React.Fragment>