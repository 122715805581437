import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import StarsRating from "./StarsRating.jsx";
import ShareButtons from "./ShareButtons.jsx";
import RatingByUser from "./RatingByUser";
import { checkForTutorialAccessKey, isTutorialUnlocked, sendEventToGoogleAnalytics } from '../functions';
import { WEBPAGE_URL_IMAGES, WEBPAGE_URL,NUMBER_OF_TUTORIAL_FOR_FREE } from '../globals.js';
import Loader from 'react-loader-spinner'
import TimeLeft from './TimeLeft.jsx';
import ImageModal from './ImageModal';

import { LazyLoadImage } from 'react-lazy-load-image-component';


class CourseOverView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tutorialAccessKey: null
        }
    }

    componentDidUpdate() {
        checkForTutorialAccessKey(this.state.tutorialAccessKey, this.props.loginKey,
            this.props.user, this.props.match.params.number, this);
    }

    render() {
        let contentItems = [];
        let whatCanYouLearnItems = [];

        if (this.props.tutorial.content) {
            contentItems = this.props.tutorial.content.map((e, i) => <li key={i}>{e}</li>);
        }

        if (this.props.tutorial.whatCanYouLearn) {
            whatCanYouLearnItems = this.props.tutorial.whatCanYouLearn.map((e, i) =>
                <div key={i} className="learn-advantage" layout="row">
                    <span className="glyphicon glyphicon-ok" aria-hidden="true"></span>
                    <p>{e}</p>
                </div>
            );
        }

        const screenshots = [];
        for (var index = 1; index < 7; index++) {
            screenshots.push(
                <div className="col-md-4 course-screenshot" key={`screenshots_${index}`}>
                    <ImageModal key={index} number={index} title="" author="" imagePrefix={"/tutorials/screenshots/t_" + this.props.tutorial.number + "/"} ></ImageModal>
                </div>
            )
        }
        return (
            <section id="nino-story">
                <div className="container">
                    <div className="sectionContent">
                        <div className="row nino-hoverEffect">
                            <div className="col-md-8">
                                {this.props.tutorial.title ?
                                    <React.Fragment>
                                        <h1 className="nino-sectionHeading">
                                            <span className="course-title">{this.props.tutorial.title}</span>
                                        </h1>
                                        <p id="course-description">{this.props.tutorial.description}</p>
                                        <Link to={"/player/" + this.props.tutorial.number}>
                                            <div className="item backgroundShadow opacity-on-hover" style={{ position: 'relative' }}>
                                                {
                                                    this.props.tutorial.number &&
                                                    <LazyLoadImage className="course-img" src={`${WEBPAGE_URL_IMAGES}/tutorials/${this.props.tutorial.number}-big.webp`}
                                                        alt={this.props.tutorial.title}
                                                        onError={(e) => { e.target.onerror = null; e.target.src = `${WEBPAGE_URL_IMAGES}/tutorials/${this.props.tutorial.number}-big.jpg` }}
                                                    />
                                                }
                                                <div className="img-play-button"><span className="glyphicon glyphicon-play-circle" aria-hidden="true"></span></div>
                                            </div>
                                        </Link>
                                        <div>
                                            Last actualization: {this.props.tutorial.modificationDate}
                                        </div>
                                        <div id="course-content">
                                            <h2 className="nino-sectionHeading" >
                                                What you will learn
                                            </h2>
                                            {whatCanYouLearnItems}
                                        </div>
                                        <Link to={"/player/" + this.props.tutorial.number}>
                                            <div className="green-btn-big" style={{ margin: "50px auto" }}
                                                onClick={() => sendEventToGoogleAnalytics("Start watching click", "green", this.props.tutorial.number)}>
                                                Start watching
                                            </div>
                                        </Link>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <div className="form-loading-circle">
                                            <Loader type="TailSpin" color="#95e1d3" height="150" width="150" />
                                        </div>
                                    </React.Fragment>
                                }
                            </div>
                            <div className="col-md-4">
                                <div className="price-box">
                                    {this.props.tutorial.title && <React.Fragment>
                                        <h4 className="nino-sectionHeading">
                                            {this.props.tutorial.title} - full course <br />+ 1 Free Review
                                        </h4>
                                        <StarsRating bigStars={true} rating={this.props.tutorial.rating} ratingsCount={this.props.tutorial.ratingsCount}></StarsRating>
                                        {isTutorialUnlocked(this.state.tutorialAccessKey, this.props.isLogged, this.props.loginKey) ?
                                            <RatingByUser {...this.state} {...this.props}></RatingByUser>
                                            :
                                            <React.Fragment>
                                                {(this.props.tutorial.number != NUMBER_OF_TUTORIAL_FOR_FREE) && <TimeLeft
                                                    discountEndTime={this.props.tutorial.discountEndTime}
                                                    price={this.props.tutorial.price}
                                                    discountPrice={this.props.tutorial.discountPrice}></TimeLeft>}
                                                {!this.props.tutorial.isReviewFreeForEveryone ?
                                                    <React.Fragment>
                                                        {this.props.isLogged ?
                                                            <Link className="overlay price-btn" to={"/payments/" + this.props.tutorial.number}
                                                                onClick={() => {
                                                                    document.gtag_report_conversion(document.location.href);
                                                                    sendEventToGoogleAnalytics("Buy now", "in course overview", this.props.tutorial.number);
                                                                }}>
                                                                {this.props.tutorial.number == NUMBER_OF_TUTORIAL_FOR_FREE ? "Get for free" : "Buy now"}
                                                            </Link>
                                                            :
                                                            <div className="price-btn" onClick={() => {
                                                                document.gtag_report_conversion(document.location.href);
                                                                sendEventToGoogleAnalytics("Buy now", "in course overview", this.props.tutorial.number);
                                                                this.props.registerFormOpen(true, this.props.tutorial.number)
                                                            }
                                                            }>
                                                                {this.props.tutorial.number == NUMBER_OF_TUTORIAL_FOR_FREE ? "Get for free" : "Buy now"}
                                                            </div>
                                                        }
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment>
                                                        <Link className="overlay price-btn" to={"/player/" + this.props.tutorial.number}
                                                            onClick={() => sendEventToGoogleAnalytics("Start watching click", "orange", this.props.tutorial.number)}>
                                                            Start watching
                                                             </Link>
                                                    </React.Fragment>
                                                }
                                            </React.Fragment>
                                        }

                                        <ul>
                                            {contentItems}
                                        </ul>
                                    </React.Fragment>}
                                </div>
                                <ShareButtons
                                    title={this.props.tutorial.title}
                                    description={this.props.tutorial.description}
                                    quote={this.props.tutorial.description}
                                    hashtag={["drawing", "video", "tutorial", "art"]}
                                    media={`${WEBPAGE_URL_IMAGES}/tutorials/${this.props.tutorial.number}-big.webp`}
                                    url={`${WEBPAGE_URL}/course/${this.props.tutorial.number}`}
                                ></ShareButtons>

                            </div>
                        </div>
                        <div className="row">
                            <div className="course-screenshots">
                                {screenshots}
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </section>
        );
    }
}

export default CourseOverView;
