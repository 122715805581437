import React from 'react';

const CategoriesForm = () => {
    return (
        <form action="" id="nino-categoriesForm">
            <div id="categories">
                <ul>
                    <li><a href="">Level</a></li>
                    <li><a href="">Technique</a></li>
                    <li><a href="">Subject</a></li>
                    <li><a href="">Direction</a></li>
                    <li><a href="">All</a></li>
                </ul>
            </div>
            <i className="mdi mdi-close nino-close"></i>
        </form>
    );
}

export default CategoriesForm;


