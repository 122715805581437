import React, { Component } from 'react';

class Countings extends Component {

    getItems(children) {
        const items = [];
        for (var index = 0; index < children.length; index += 2) {
            items.push(
                <div key={index} className="item">
                    <div className="number">{children[index]}</div>
                    <div className="text">{children[index + 1]}</div>
                </div>
            )
        }
        return items;
    }

    render() {
        const items = this.getItems(this.props.children);

        return (
            <section id="nino-counting">
                <div className="container">
                    <div layout="row" className="verticalStretch">
                        {items}
                    </div>
                </div>
            </section>
        );
    }
}

export default Countings;
