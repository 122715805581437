import React, { Component } from 'react';
import TutorialCard from "./TutorialCard";

class TutorialsOverview extends Component {
    constructor(props) {
        super(props)
        this.tutorialsRef = React.createRef()  
    }

    componentDidMount()
    {
        if(this.props.scrollToTutorials)
        {
            window.scrollTo(0, this.tutorialsRef.current.getBoundingClientRect().top - 90);
        }
    }

    render() {
        let tutorials = [];
        if(this.props.tutorials)
        {
            tutorials = this.props.tutorials.map((e,i) => <TutorialCard key={i} tutorial={e} isMyCourses={this.props.isMyCourses}></TutorialCard>);
        }
        [tutorials[0], tutorials[1]] = [tutorials[1], tutorials[0]];
        [tutorials[2], tutorials[1]] = [tutorials[1], tutorials[2]];
        return (
            <section id="nino-story">
                <div className="container">
                    <h2 ref={this.tutorialsRef} className="nino-sectionHeading">
                        {this.props.children}
                    </h2>
                    <div className="sectionContent">
                        <div className="row nino-hoverEffect">
                            {tutorials}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default TutorialsOverview;
