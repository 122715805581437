import React, { Component } from 'react';
import Loader from 'react-loader-spinner'
import GoogleLogin from 'react-google-login';
import { Link } from 'react-router-dom'
import {
    LOGIN_MIN_LENGTH, LOGIN_MAX_LENGTH,
    PASSWORD_MIN_LENGTH, PASSWORD_MAX_LENGTH,
    EMAIL_MIN_LENGTH, EMAIL_MAX_LENGTH,
    GOOGLE_CLIENT_ID, PAGE, INFORMATION_OGLIGATION_RULES_HTML
} from '../../globals.js';
import { LazyLoadImage } from 'react-lazy-load-image-component';

class LoginForm extends Component {

    render() {
        let paragraphStyle = { fontSize: "10px" };
        const clausesHTML = this.props.clausesToBeAccepted.map((item) => {
            let checkBoxStyle = {};
            if (item.warning) {
                checkBoxStyle["boxShadow"] = "0px 0px 2px 2px rgba(255,0,0,0.75)";
            }
            return <p style={paragraphStyle} key={item.name}>
                <input checked={this.props.clauses[item.name]}
                    style={checkBoxStyle} onChange={e => this.props.chekcBoxClick(item.name, e)}
                    className="checkbox-big" type="checkbox" name={item.name} /> {item.text}</p>
        })
        clausesHTML.push(<p style={paragraphStyle} key="accept-all"><input onChange={e => this.props.checkAcceptAllClick(e)} className="checkbox-big" type="checkbox" /> Accept all</p>);
        if (this.props.resetPasswordFormOpen) {
            return <div id="nino-loginForm" className={"container user-form-container " + (this.props.open ? "open" : "")} >
                <div className="row">
                    <div className="col-md-offset-4 col-md-4 user-form">
                        <h3>Reset password</h3>
                        <form>
                            {!this.props.successMessage && <React.Fragment>
                                {this.props.freezeForm ?
                                    <div className="form-loading-circle form-loading-center">
                                        <Loader type="TailSpin" color="#95e1d3" height="150" width="150" />
                                    </div>
                                    :
                                    <React.Fragment>
                                        <div className="form-group">
                                            <input value={this.props.email} name="email" minLength={EMAIL_MIN_LENGTH} maxLength={EMAIL_MAX_LENGTH} onChange={(event) => this.props.handleUserInput(event)} type="email" placeholder="Email" className="form-control " required />
                                        </div>
                                        <div className="form-group">
                                            <input onClick={e => this.props.handelButtonSubmit(e, "forgotpassword")} type="submit" className="btnSubmit" value="Submit" />
                                        </div>
                                    </React.Fragment>
                                }
                            </React.Fragment>}
                        </form>
                        {this.props.warningMessage &&
                            <React.Fragment>
                                <div className="validation-warning-box"> {this.props.warningMessage} </div>
                            </React.Fragment>
                        }
                        {this.props.successMessage &&
                            <React.Fragment>
                                <div className="validation-success-box"> {this.props.successMessage} </div>
                                <input onClick={e => this.props.closeAndResetForm()} type="button" className="btnSubmit" value="Close" />
                            </React.Fragment>
                        }
                        <i className="mdi mdi-close nino-close" onClick={() => this.props.closeAndResetForm()} data-toggle="collapse"
                            data-target="#nino-navbar-collapse"></i>
                    </div>
                </div>
            </div>
        }
        else {
            return <div id="nino-loginForm" className={"container user-form-container " + (this.props.open ? "open" : "")}>
                <div className="row">
                    {this.props.showClausesForm ?
                        <div className="col-md-offset-3 col-md-6 user-form">
                            <i className="mdi mdi-close nino-close" onClick={() => this.props.hideClasusesForm()} data-toggle="collapse"
                                data-target="#nino-navbar-collapse"></i>
                            <div className="form-scrollable-rules">
                                <h4 className="nino-sectionHeading">Before continuing please read below Information Obligation Clause of <a href="www.easyfacedraw.com">www.easyfacedraw.com</a> and accept all clauses</h4>
                                <div onScroll={e => this.props.handleScroll(e)} className="form-scrollable-rules-container">
                                    {/* <h4 className="nino-sectionHeading">
                            Information Obligation Clause of <a href="www.easyfacedraw.com"> www.easyfacedraw.com</a>
                        </h4> */}
                                    {INFORMATION_OGLIGATION_RULES_HTML}
                                    {clausesHTML}
                                </div>
                                <br />
                                <br />
                                <div className="form-group">
                                    <center>
                                        <input
                                            onClick={e => this.props.handelButtonSubmitClaues()}
                                            disabled={(this.props.buttonSubmitClauesDisabled) ? "disabled" : ""}
                                            type="submit" className="btnSubmit" value="Accept"
                                        />
                                    </center>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="col-md-offset-4 col-md-4 user-form">
                            <h3>Log In</h3>
                            <form>
                                {!this.props.successMessage && <React.Fragment>
                                    {this.props.freezeForm ?
                                        <div className="form-loading-circle form-loading-center">
                                            <Loader type="TailSpin" color="#95e1d3" height="150" width="150" />
                                        </div>
                                        :
                                        <React.Fragment>
                                            <div className="form-group">
                                                <input value={this.props.login} name="login" minLength={LOGIN_MIN_LENGTH} maxLength={LOGIN_MAX_LENGTH} onChange={(event) => this.props.handleUserInput(event)} type="text" placeholder="Login" className="form-control" required />
                                            </div>
                                            <div className="form-group has-feedback has-feedback-right">
                                                <input value={this.props.password} name="password" minLength={PASSWORD_MIN_LENGTH} maxLength={PASSWORD_MAX_LENGTH} onChange={(event) => this.props.handleUserInput(event)} type={this.props.passwordVisible ? "text" : "password"} placeholder="Password" className="form-control" required />
                                                {this.props.passwordVisible ?
                                                    <span onClick={() => { this.props.switchPasswordVisibility() }} className="glyphicon glyphicon-eye-open form-control-feedback" aria-hidden="true"></span>
                                                    :
                                                    <span onClick={() => { this.props.switchPasswordVisibility() }} className="glyphicon glyphicon-eye-close form-control-feedback" aria-hidden="true"></span>
                                                }
                                            </div>
                                            <div className="form-group">
                                                <input onClick={e => this.props.handelButtonSubmit(e, "loginuser")} type="submit" className="btnSubmit" value="Submit" />
                                            </div>
                                            <div className="form-group">
                                                Don't have an account? <a href="#" className="ForgetPwd" onClick={() => this.props.switchOpenForms()}>Sign up</a>
                                            </div>
                                            <div className="form-group">
                                                <a href="#" className="ForgetPwd" onClick={() => this.props.openResetPasswordForm()}>Forgot your password?</a>
                                            </div>
                                            <div className="one-line-breaker">
                                                <hr />
                                                <span>or</span>
                                                <hr />
                                            </div>
                                            <GoogleLogin
                                                clientId={GOOGLE_CLIENT_ID}
                                                buttonText="Login"
                                                render={renderProps => (
                                                    // <button onClick={renderProps.onClick} disabled={renderProps.disabled}>This is my custom Google button</button>
                                                    <button onClick={e => this.props.googleBtnClick(e, renderProps.onClick)} disabled={renderProps.disabled} type="button" className="form-control google-btn">
                                                        <LazyLoadImage style={{ display: "inline" }} width="20px" alt="Google &quot;G&quot; Logo" src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png" /><span>Sign in</span>
                                                    </button>
                                                )}
                                                onSuccess={this.props.logInGoogleSuccess}
                                                onFailure={this.props.logInGoogleFailure}
                                                cookiePolicy={'single_host_origin'}
                                            />

                                        </React.Fragment>
                                    }
                                </React.Fragment>}
                            </form>
                            {this.props.warningMessage &&
                                <React.Fragment>
                                    <div className="validation-warning-box"> {this.props.warningMessage} </div>
                                </React.Fragment>
                            }
                            {this.props.successMessage &&
                                <React.Fragment>
                                    <div className="validation-success-box"> {this.props.successMessage} </div>
                                    <input onClick={e => this.props.closeAndResetForm()} type="button" className="btnSubmit" value="Close" data-toggle="collapse"
                                        data-target="#nino-navbar-collapse" />
                                </React.Fragment>
                            }
                            <p className="register-form-privacy-info">
                                By logging in, you agree to EasyFaceDraw's Clauses and confirm that you have read EasyFaceDraw's <Link onClick={e => this.props.closeAndResetForm()} to={"/" + PAGE.PRIVACY_POLICY}>Privacy Policy.</Link> and <Link onClick={e => this.props.closeAndResetForm()} to={"/" + PAGE.INFORMATION_OBLIGATION_CLAUSE}>Information Obligation Clause.</Link>
                            </p>

                            <i className="mdi mdi-close nino-close" onClick={() => this.props.closeAndResetForm()} data-toggle="collapse"
                                data-target="#nino-navbar-collapse"></i>
                        </div>
                    }
                </div>
            </div>
        }
    }
}

export default LoginForm;


