import React, { Component } from 'react';
import StarsRating from "./StarsRating.jsx";
import { Link } from 'react-router-dom'
import { WEBPAGE_URL_IMAGES } from '../globals.js';
import { sendEventToGoogleAnalytics } from '../functions';
import { LazyLoadImage } from 'react-lazy-load-image-component';

class TutorialCard extends Component {
    constructor(props) {
        super(props);
        this.isTutorialAvailable = this.isTutorialAvailable.bind(this);
    }
    isTutorialAvailable() {
        // return this.props.tutorial.number == 2 ||
        //     this.props.tutorial.number == 3 ||
        //     this.props.isMyCourses;
        return true;
    }
    render() {
        return (
            <div className="col-md-4 col-sm-4">
                <div className={this.isTutorialAvailable() ? "item backgroundShadow" : "item no-hover-item"}>
                    <Link className="overlay" onClick={() => sendEventToGoogleAnalytics("Go to tutorial overview","tutorial number " + this.props.tutorial.number)} 
                    to={this.isTutorialAvailable() ? "/course/" + this.props.tutorial.number : "#"}>
                        {this.props.tutorial.is_new && <div className="new-ribbon">New</div>}
                        {!this.isTutorialAvailable() && <div className="unavailable-tutorial">available soon</div>}
                        {this.isTutorialAvailable() && <span className="content">
                            <span className="glyphicon glyphicon-hourglass" aria-hidden="true"></span>
                            <span> {this.props.tutorial.hours}</span>
                            <StarsRating rating={this.props.tutorial.rating}  ratingsCount={this.props.tutorial.ratingsCount} ></StarsRating>
                            <p>{this.props.tutorial.difficulty}</p>
                        </span>}

                        <LazyLoadImage width={"auto"} height={"240px"}
                            src={WEBPAGE_URL_IMAGES + "/tutorials/" + this.props.tutorial.number + "-small.webp"}
                            alt={this.props.tutorial.title + " image"}
                            onError={(e) => { e.target.onerror = null; e.target.src = WEBPAGE_URL_IMAGES + "/tutorials/" + this.props.tutorial.number + "-small.jpg" }} />

                    </Link>
                </div>
                <div className="articleMeta" style={{ opacity: this.isTutorialAvailable() ? "1" : "0" }}>
                    <a><span className="glyphicon glyphicon-eye-open" aria-hidden="true"></span> {this.props.tutorial.views} </a>
                    <a><i className="mdi mdi-comment-multiple-outline nino-icon"></i> {this.props.tutorial.commentsCount}</a>
                    {/* <a><span className="glyphicon glyphicon-education" aria-hidden="true"></span> {this.props.tutorial.purchased} </a>
                    <a><span className="glyphicon glyphicon-comment" aria-hidden="true"></span> {this.props.tutorial.commentsCount}</a> */}
                </div>
                <h2 className="nino-sectionHeading" style={{ opacity: this.isTutorialAvailable() ? "1" : "0.3" }}>
                    {this.isTutorialAvailable() ?
                        <Link to={"/course/" + this.props.tutorial.number} className="link-course-title">
                            <span className="nino-subHeading">{this.props.tutorial.title}</span>
                        </Link>
                        :
                        <span className="nino-subHeading">{this.props.tutorial.title}</span>
                    }
                </h2>
            </div>
        );
    }
}

export default TutorialCard;
