import React, { Component } from 'react';
import Header from "./Header.jsx";
import { Link } from 'react-router-dom'
import { PAGE, WEBPAGE_URL_IMAGES } from '../globals';
import ImageModal from './ImageModal';
import { sendEventToGoogleAnalytics } from '../functions';
import { Helmet } from "react-helmet";

class ArticleLeonardoDaVinci extends Component {

    render() {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Leonardo da Vinci, Draftsman and Illustrator</title>
                    <link rel="canonical" href="https://www.easyfacedraw.com/article-digital-painting" />
                    <meta name="description" content="Leonardo da Vinci was born on April 15, 1452, in the town of Vinci, Italy. Today, he is one of the symbols
                        and embodiments of the Renaissance’s ideals." />
                    <meta name="keywords" content="Leonardo da Vinci, renaissance, drawing tutorial, video tutorials, how to draw" />
                </Helmet>
                <Header {...this.props} ></Header>
                <article>
                    <section id="nino-whatWeDo">
                        <div className="container">
                            <h1 className="nino-sectionHeading">
                                <span className="nino-subHeading big-font">Leonardo da Vinci, Draftsman and Illustrator</span>
                            </h1>
                            <h2 className="article-author">
                                Claudia Preza
                                <br />
                                June 4, 2021
                                </h2>
                            <br />
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="Leonardo da Vinci"
                                    title="Self-portrait of Leonardo da Vinci."
                                    src={`${WEBPAGE_URL_IMAGES}/article/10/1.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Leonardo da Vinci, Self-portrait of Leonardo da Vinci, c. 1510-1515, sanguine (red chalk) on paper. Collection of
Royal Library of Turin, Italy.</p>
                            </div>

                            <p className="nino-sectionDesc read-more-description">
                                Leonardo da Vinci was born on April 15, 1452, in the town of Vinci, Italy. Today, he is one of the symbols
                                and embodiments of the Renaissance’s ideals. Alongside Raphael and Michelangelo during the golden
                                age of creativity, he worked as a painter, draftsman, sculptor, architect, and engineer. As both an artist
                                and an inventor, da Vinci applied his unique genius to all his endeavors. His never-ending curiosity and
                                hunger for knowledge led him to constantly observe, invent, draw, and experiment.
                            </p>
                            <h3 className="nino-sectionHeading">
                                Artistic Background
                            </h3>
                            <p className="nino-sectionDesc read-more-description">
                                At the young age of fourteen, da Vinci became an apprentice to Andrea del Verrocchio. Verrocchio
                                studied under Donatello and as the official sculptor for the Medici Family, who ruled Italy at the time,
                                was an important figure in sculpture, painting, and goldsmithing. Da Vinci Spent his nine-year-long
                                apprenticeship in the busy Florence studio initially doing menial tasks around the studio such as mixing
                                paints and preparing the canvases. From there he proceeded to study and copy Verrocchio’s works,
                                eventually assisting him in the production of artworks.
                            </p>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="Leonardo da Vinci"
                                    title="Landscape drawing for Santa Maria Della Neve."
                                    src={`${WEBPAGE_URL_IMAGES}/article/10/2.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Leonardo da Vinci, Landscape drawing for Santa Maria Della Neve, 1473, ink on paper. Collection of Galleria degli
Uffizi, Florence, Italy.</p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                                As an apprentice not only did da Vinci develop his drawing, painting, and sculpting skills, he also became
                                proficient in mechanics, carpentry, metallurgy, architectural drafting, and even chemistry. Da Vinci
                                joined the Florence painter’s guild in 1472 but remained an apprentice under Verrocchio. In 1473, he
                                completed Landscape drawing for Santa Maria Della Neve, a pen and ink drawing considered his earliest
                                work. Verrocchio’s influence is evident in his early paintings and drawings through their vitality and
                                anatomical accuracy which today characterizes his body of work.
                            </p>

                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="Leonardo da Vinci"
                                    title="Study of Arms and Hands."
                                    src={`${WEBPAGE_URL_IMAGES}/article/10/3.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Leonardo da Vinci, Study of Arms and Hands, after 1474, silverpoint and white highlights on pink prepared paper.</p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                                The majority of da Vinci’s artistic practice centered around the depiction of religious subjects, a common
                                theme at the time. Da Vinci innovated painting by improving the standards of an object within a
                                composition. He achieved this by changing the clarity and color of objects according to their perceived
                                distance. Da Vinci would continue to innovate the field of a painting by applying his inquisitiveness and
                                developing his style and paint application techniques.
                            </p>
                            <h3 className="nino-sectionHeading">
                                Da Vinci’s Notebooks
                            </h3>
                            <p className="nino-sectionDesc read-more-description">
                                Though mainly known for his paintings and inventions, da Vinci was also a skilled draftsman and
                                illustrator. In 1506 he spent seven years in Milan working on an official commission for an equestrian
                                statue. While in Milan, da Vinci produced numerous drawings focusing on a variety of topics such as
                                human anatomy, botany, weaponry inventories, and studies of birds in flight. The sketches reflected his
                                interest in how objects worked and their assembly.
                            </p>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="Leonardo da Vinci"
                                    title="Studies of the Arm showing the Movements made by the Biceps."
                                    src={`${WEBPAGE_URL_IMAGES}/article/10/4.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Leonardo da Vinci, Studies of the Arm showing the Movements made by the Biceps, c. 1510, ink on paper.
Collection of the Royal Library, Windsor Castle, United Kingdom.</p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                                Da Vinci drew the world he observed, he drew plans for inventions, exploration of the human anatomy,
                                and landscapes. Additionally, he was also interested in the engineering of machines and used
                                illustrations to explore his interest in inventing devices. Today only a few of his completed paintings
                                survived, however, the artist left a large body of drawings, around 2,500 gathered in notebooks,
                                recording his ideas for his art and inventions. Da Vinci kept notebooks filled with quick sketches and
                                observations which captured his inquisitiveness. The artist gave precedence to pictures over his writing.
                                His drawings do not illustrate the text, instead, they do the opposite with the text describing the picture.
                            </p>
                            <h3 className="nino-sectionHeading">
                                Drawings
                            </h3>
                            <p className="nino-sectionDesc read-more-description">
                                Da Vinci’s illustrations and sketches are essential to his legacy and serve as a record of his ideas. Some of
                                his sketches were the plans and studies for some of his most famous works such as The Virgin of the
                                Rocks and The Last Supper. Drawings allowed da Vinci to work out various poses and facial gestures.
                                Furthermore, they allowed da Vinci to explore his fascination with physiognomy and served to help
                                develop the overall composition.
                            </p>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="Leonardo da Vinci"
                                    title="The Last Supper."
                                    src={`${WEBPAGE_URL_IMAGES}/article/10/5.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Leonardo da Vinci, The Last Supper, from 1495 until 1498, tempera on gesso, pitch and mastic. Collection of
Santa Maria delle Grazie, Milan, Italy.</p>
                            </div>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="Leonardo da Vinci"
                                    title="Study for The Last Supper."
                                    src={`${WEBPAGE_URL_IMAGES}/article/10/6.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Leonardo da Vinci, study for The Last Supper, 1494-95, red chalk on paper. Collection of Gallerie
dell’Accademia, Venice, Italy.</p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                            Da Vinci’s illustrations range from fast pen and ink sketches to detailed drawings completed with red
                            and black chalks. For his drawings, the artist used the hatching technique which combines straight and
                            curved lines drawn close to each other to create value. Being left-handed, Da Vinci’s hatching style
                            started at the upper left of his drawings and went to the lower right, as opposed to right-handed artists
                            whose hatching started at the upper right and went down to the lower right. Additionally, his use of
                            gestural lines embodied movement and resulted in highly expressive works.
                            </p>
                            <h3 className="nino-sectionHeading">
                            Vitruvian Man
                            </h3>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="Leonardo da Vinci"
                                    title="Vitruvian Man."
                                    src={`${WEBPAGE_URL_IMAGES}/article/10/7.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Leonardo da Vinci, Vitruvian Man, circa 1492, pen, ink, and wash on paper. Collection of Gallerie dell’Accademia,
Venice, Italy.</p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                            One of da Vinci’s most recognized drawing is the Vitruvian Man, also known as The Canon of Proportions
or The Proportions of Man. Completed around 1492, the pen and ink drawing depicts a male nude in two
simultaneous positions inside a circle and a square. Through this illustration, the artist attempts to relate
man to nature. Serving as an analogy to how the universe works, Da Vinci’s drawing highlights the
proportional relationship between human body parts and the equilibrium of a stable structure.
Furthermore, it showcases the artist’s interest in proportions and symmetry.
                            </p>
                            <h3 className="nino-sectionHeading">
                            Portrait Drawings
                            </h3>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="Leonardo da Vinci"
                                    title="The Head of the Virgin in Three-Quarter View Facing Right"
                                    src={`${WEBPAGE_URL_IMAGES}/article/10/8.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Leonardo da Vinci, The Head of the Virgin in Three-Quarter View Facing Right, 1510-1513, black chalk,
charcoal, and red chalk, with some traces of white chalk on paper. Collection of The Metropolitan Museum of Art,
New York.</p>
                            </div>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="Leonardo da Vinci"
                                    title="Woman’s Head."
                                    src={`${WEBPAGE_URL_IMAGES}/article/10/9.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Leonardo da Vinci, Woman’s Head, 1470-1476, pen, ink and white pigment on paper. Collection of Galleria
degli Uffizi, Florence, Italy.
Venice, Italy.</p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                            When it came to illustrations, da Vinci rarely used sharp lines, only using them to accent and bring out
the depth of certain characteristics. This preference is evident in his numerous sketches of portraits.
Most of his face drawings display pure white juxtaposed with pitch-black. Such an extreme difference in
tones created a softness around the general illustration with only the focal points of the face being
highlighted. By using such sharp contrast, da Vinci’s drawings have a dreamlike and ethereal quality to
them. Additionally, da Vinci liked to exaggerate certain qualities in human faces to add character. This
slight caricaturing in his portrait sketches highlight the artist’s interest in the contrast between youth
and old age, beauty and ugliness.
                            </p>
                            <h3 className="nino-sectionHeading">
                            Da Vinci’s Influence Today
                                                        </h3>
                            <p className="nino-sectionDesc read-more-description">
                            Today Da Vinci’s work continues to inspire artists centuries after his death. Countless reproductions of
his iconic depictions of Mona Lisa and The Last Super exist, embodying his influence on culture. His
significant legacy in art symbolized an innovative departure from the standards of the time. Da Vinci’s
improvements in perspective and unique placement of key figures revolutionized painting. The
extensive records of such developments in da Vinci’s drawings further serve to illustrate the genius of
the artist.
                            </p>
                            {/* <Link to={`/${PAGE.SHOWTUTORIALS}`} className="action-btn" onClick={() => sendEventToGoogleAnalytics("Watch for free", "article")}></Link> */}
                            <Link to={`/${PAGE.SHOWTUTORIALS}`} className="action-btn" onClick={() => sendEventToGoogleAnalytics("Watch now", "article")}></Link>

                        </div>
                    </section>
                </article>
            </div>
        );
    }
}

export default ArticleLeonardoDaVinci;
