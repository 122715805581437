import React from 'react';

const SearchForm = () => {
    return (
        <form action="" id="nino-searchForm">
            <input type="text" placeholder="Search..." className="form-control nino-formInput " />
            <i className="mdi mdi-close nino-close"></i>
        </form>
    );
}

export default SearchForm;


