import React, { Component } from 'react';
import {
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon,
    PinterestShareButton,
    PinterestIcon,
} from 'react-share';

export class ShareButtons extends Component {
    render() {
        return (
            <React.Fragment>
                <FacebookShareButton className="share-button" {...this.props} quote={this.props.quote} title="" desription="" hashtag={""} >
                    <FacebookIcon size={50} round={true}></FacebookIcon>
                </FacebookShareButton>
                <TwitterShareButton className="share-button" {...this.props} >
                    <TwitterIcon size={50} round={true}></TwitterIcon>
                </TwitterShareButton>
                <WhatsappShareButton className="share-button" {...this.props}>
                    <WhatsappIcon size={50} round={true}></WhatsappIcon>
                </WhatsappShareButton>
                <PinterestShareButton className="share-button" {...this.props} >
                    <PinterestIcon size={50} round={true}></PinterestIcon>
                </PinterestShareButton>
                <LinkedinShareButton className="share-button" {...this.props}>
                    <LinkedinIcon size={50} round={true}></LinkedinIcon>
                </LinkedinShareButton>
            </React.Fragment>
        );
    }
}

export default ShareButtons;
