import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import {
    PASSWORD_MIN_LENGTH, PASSWORD_MAX_LENGTH
} from '../../globals.js';
class ChangePasswordForm extends Component {
    render() {
        return (
            <form action="">
                {this.props.freezeForm ?
                    <div className="form-loading-circle">
                        <Loader type="TailSpin" color="#95e1d3" height="150" width="150" />
                    </div>
                    :
                    <React.Fragment>
                        {!this.props.successMessage &&
                            <React.Fragment>
                                <input value={this.props.oldPassword} minLength={PASSWORD_MIN_LENGTH} maxLength={PASSWORD_MAX_LENGTH} type="password" name="oldPassword" placeholder="Old password" className="input" onChange={(event) => this.props.handleUserInput(event)} required/>
                                <input value={this.props.password} minLength={PASSWORD_MIN_LENGTH} maxLength={PASSWORD_MAX_LENGTH} type="password" name="password" placeholder="New password" className="input" onChange={(event) => this.props.handleUserInput(event)} required/>
                                <input value={this.props.repeatPassword} minLength={PASSWORD_MIN_LENGTH} maxLength={PASSWORD_MAX_LENGTH} type="password" name="repeatPassword" placeholder="Repeat new password" className="input" onChange={(event) => this.props.handleUserInput(event)} required/>
                            <br/>
                            <br/>
                            </React.Fragment>}
                        {this.props.warningMessage &&
                            <React.Fragment>
                                <div className="validation-warning-box validation-warning-box-small"> {this.props.warningMessage} </div>
                            </React.Fragment>
                        }
                        {this.props.successMessage &&
                            <React.Fragment>
                                <div className="validation-success-box validation-success-box-small"> {this.props.successMessage} </div>
                            </React.Fragment>
                        }
                        {(!this.props.successMessage && this.props.password && this.props.repeatPassword && this.props.oldPassword) &&
                            <input onClick={e => this.props.handelButtonSubmit(e, "changepassword")} type="submit" className="green-btn" value="SUBMIT" />
                        }
                    </React.Fragment>
                }

            </form>
        );
    }
}

export default ChangePasswordForm;
