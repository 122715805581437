import React, { Component } from 'react';
import Header from "./Header.jsx";
import CourseOverView from "./CourseOverView";
import { getTutorialFromDb,sendEventToGoogleAnalytics, isTutorialUnlocked, checkForTutorialAccessKey } from '../functions';
import Comments from './Comments.jsx';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { NUMBER_OF_TUTORIAL_FOR_FREE } from '../globals.js';

class Course extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tutorial: {},
            tutorialAccessKey: null
        }
        this.refreshTutorial = this.refreshTutorial.bind(this);
    }

    componentDidMount() {
        getTutorialFromDb(this.props.match.params.number, this);
        document.onscroll = () => {
            if(this.props.isMoblieDevice)
            {
                if (document.documentElement.scrollTop < 1200) {
                    this.setState({
                        showBottomCallToAction: true,
                    })
                }
                else if (document.documentElement.scrollTop < 1900) {
                    this.setState({
                        showBottomCallToAction: false,
                    })
                }
                else{
                    this.setState({
                        showBottomCallToAction: true,
                    })
                }
            }
            else
            {
                if (document.documentElement.scrollTop > 600) {
                    this.setState({
                        showBottomCallToAction: true,
                    })
                }
                else {
                    this.setState({
                        showBottomCallToAction: false,
                    })
                }
            }
        }
    }

    componentWillUpdate() {
        checkForTutorialAccessKey(this.state.tutorialAccessKey, this.props.loginKey, this.props.user, this.props.match.params.number, this);
    }

    refreshTutorial() {
        getTutorialFromDb(this.props.match.params.number, this);
    }

    render() {
        return (
            <div>

                {
                    (this.props.match.params.number == 1) &&
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Learn How to Draw a Nose with Step-by-Step Tutorials</title>
                        <link rel="canonical" href="https://www.easyfacedraw.com/course/1" />
                        <meta name="description" content="Want to learn how to draw a nose? Grab your art materials, and use our step-by-step tutorial for beginner artists." />
                        <meta name="keywords" content="drawing tutorial, art instruction, how to draw, draw a nose, basic drawing" />
                    </Helmet>
                }
                {
                    (this.props.match.params.number == 2) &&
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Learn How to Draw Eyes with Step-by-Step Tutorials</title>
                        <link rel="canonical" href="https://www.easyfacedraw.com/course/2" />
                        <meta name="description" content="Ready to learn realistic eye drawing? Sharpen your pencils, and join our art instructor for simple, step-by-step drawing tutorials." />
                        <meta name="keywords" content="drawing tutorial, art tutor, how to draw, draw eyes, learn to draw, realistic eye drawing" />

                    </Helmet>
                }
                {
                    (this.props.match.params.number == 3) &&
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Learn How to Draw Lips with Step-by-Step Tutorials</title>
                        <link rel="canonical" href="https://www.easyfacedraw.com/course/3" />
                        <meta name="description" content="Think it’s hard to draw realistic lips? Our easy drawing tutorials for beginners will teach you basic drawing and pencil shading." />
                        <meta name="keywords" content="drawing tutorial, art instructor, how to draw, draw lips, pencil art" />

                    </Helmet>
                }
                {
                    (this.props.match.params.number == 4) &&
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Learn How to Draw Hair with Step-by-Step Tutorials</title>
                        <link rel="canonical" href="https://www.easyfacedraw.com/course/4" />
                        <meta name="description" content="Want to learn how to draw hair? Warm up and learn to draw with our trained art tutor." />
                        <meta name="keywords" content="drawing tutorial, beginner art, how to draw, draw hair, pencil art" />

                    </Helmet>
                }
                {
                    (this.props.match.params.number == 5) &&
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Learn How to Draw Ears with Step-by-Step Tutorials</title>
                        <link rel="canonical" href="https://www.easyfacedraw.com/course/5" />
                        <meta name="description" content="Ready to learn how to draw an ear? Join our art instructor for easy drawing tutorials for beginners." />
                        <meta name="keywords" content="drawing tutorial, art instruction, how to draw, draw ears, pencil drawing" />

                    </Helmet>
                }
                {
                    (this.props.match.params.number == 6) &&
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Learn How to Draw a Face with Step-by-Step Tutorials</title>
                        <link rel="canonical" href="https://www.easyfacedraw.com/course/6" />
                        <meta name="description" content="Ever wanted to learn how to draw a face? Learn basic drawing and pencil shading with our step-by-step video tutorials." />
                        <meta name="keywords" content="drawing tutorial, face sketch, how to draw, draw a face, easy drawing" />

                    </Helmet>
                }
                <Header {...this.props} ></Header>
                <CourseOverView {...this.props} tutorial={this.state.tutorial} refreshTutorial={this.refreshTutorial}></CourseOverView>
                <Comments {...this.state} {...this.props} parent={this} commentsFromDB={this.state.comments} wideComments={true} showCommentForm={isTutorialUnlocked(this.state.tutorialAccessKey, this.props.isLogged, this.props.loginKey)} registerFormOpen={this.props.registerFormOpen}>
                    <h2 className="nino-sectionHeading">
                        {/* Comments
                        <br />
                        <br /> 
                        <span className="nino-subHeading">What our students' says about this course</span>*/}
                        What our students say<br />about this course
                    </h2>
                </Comments>
                {!this.props.isRegisterFormOpen && this.state.showBottomCallToAction && !isTutorialUnlocked(this.state.tutorialAccessKey, this.props.isLogged, this.props.loginKey) &&
                    <div className="footer-call-to-action">
                        {!this.state.tutorial.isReviewFreeForEveryone ?
                            <React.Fragment>
                                {this.props.isLogged ?
                                    <Link className="price-btn" style={{width: "200px"}} to={"/payments/" + this.state.tutorial.number}
                                        onClick={() => {
                                            document.gtag_report_conversion(document.location.href);
                                            sendEventToGoogleAnalytics("Buy now", "footer", this.state.tutorial.number);
                                        }}>
                                        {this.state.tutorial.number == NUMBER_OF_TUTORIAL_FOR_FREE ? "Get for free" : "Buy now"}
                                    </Link>
                                    :
                                    <div className="price-btn" style={{width: "200px"}} onClick={() => {
                                        document.gtag_report_conversion(document.location.href);
                                        sendEventToGoogleAnalytics("Buy now", "footer", this.state.tutorial.number);
                                        this.props.registerFormOpen(true, this.state.tutorial.number)
                                    }
                                    }>
                                        {this.state.tutorial.number == NUMBER_OF_TUTORIAL_FOR_FREE ? "Get for free" : "Buy now"}
                                    </div>
                                }
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <Link className="overlay price-btn" to={"/player/" + this.state.tutorial.number}
                                    onClick={() => sendEventToGoogleAnalytics("Start watching click", "orange-footer", this.state.tutorial.number)}>
                                    Start watching
                                                             </Link>
                            </React.Fragment>
                        }
                    </div>
                }
            </div>
        );
    }
}

export default Course;
