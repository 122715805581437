import React, { Component } from 'react';
import { sendRating, getUserTutorialRateFromDb } from '../functions';
import { WEBPAGE_URL_IMAGES, RATINGS } from '../globals.js';
import { LazyLoadImage } from 'react-lazy-load-image-component';

class RatingByUser extends Component {

    constructor(props) {
        super(props);
        this.state =
            {
                'starsHighlighted': 0,
                'starsSelectedByUser': 0,
                'mouseOver': false,
                'rateAnimation': false
            };
        this.onMouseOverStarHandler = this.onMouseOverStarHandler.bind(this);
        this.onMouseOverStarsHandler = this.onMouseOverStarsHandler.bind(this);
        this.onMouseLeaveStarsHandler = this.onMouseLeaveStarsHandler.bind(this);
        this.onClickHandler = this.onClickHandler.bind(this);
    }
    componentDidMount() {
        getUserTutorialRateFromDb(this.props.user.login, this.props.tutorial.number, this);
    }
    onMouseOverStarHandler(starsHighlighted) {
        this.setState({
            starsHighlighted: starsHighlighted
        });
    }
    onMouseOverStarsHandler() {
        this.setState({
            mouseOver: true
        });
    }
    onMouseLeaveStarsHandler() {
        this.setState({
            mouseOver: false
        });
    }
    onClickHandler(starsSelectedByUser) {
        this.setState({
            starsSelectedByUser: starsSelectedByUser,
            rateAnimation: true
        });
        setTimeout(() => {
            this.setState({
                rateAnimation: false
            })
        }, 2000);
        sendRating(this.props.user.login,
            this.props.tutorialAccessKey,
            this.props.tutorial.number,
            starsSelectedByUser,
            this).then(() => {
                this.props.refreshTutorial();
            });
    }

    render() {
        let avatar_url = WEBPAGE_URL_IMAGES + "/blank-avatar.jpg";
        // const user_avatar_url = WEBPAGE_URL_IMAGES + "/users-avatars/" + this.props.username;
        // if (this.props.username) {
        //     urlExists(user_avatar_url, function (err, exists) {
        //         if (exists) {
        //             avatar_url = user_avatar_url;
        //         }
        //     });
        // }
        let stars = [];
        let numberOfStarsToShow;
        if (this.state.mouseOver) {
            numberOfStarsToShow = this.state.starsHighlighted;
        }
        else {
            numberOfStarsToShow = this.state.starsSelectedByUser;
        }
        for (let index = 0; index < 5; index++) {
            stars.push(<span key={index}
                onClick={() => this.onClickHandler(index + 1)}
                onMouseOver={() => this.onMouseOverStarHandler(index + 1)}
                className={"glyphicon medium-star glyphicon-star" + (index < numberOfStarsToShow ? "" : "-empty")}
                aria-hidden="true"></span>)
        }
        return (
            <div className="rating-by-user">
                <div>
                    <LazyLoadImage className="img-circle" src={avatar_url} alt="blank avatar" />
                </div>
                <div>
                    {
                        this.state.rateAnimation ? 
                        <p key="p4">Thank you.</p>
                        :                            
                        <React.Fragment>
                            {this.state.mouseOver ? 
                            <p key="p1">Your rating: {RATINGS[this.state.starsHighlighted - 1]}  </p>                            
                            :
                            [(this.state.starsSelectedByUser ? 
                                <p key="p2">Your rating: {RATINGS[this.state.starsSelectedByUser - 1]}  </p>
                                :
                                <p key="p3">Rate this course   </p>
                            )]
                        }
                        </React.Fragment>    
                    }
                    
                    <div onMouseOver={() => this.onMouseOverStarsHandler()} onMouseLeave={() => this.onMouseLeaveStarsHandler()}>
                        {stars}
                    </div>
                </div>
            </div>
        );
    }
}

export default RatingByUser;
