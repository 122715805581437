import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { WEBPAGE_URL_IMAGES } from '../globals.js';
import { getUserName, generateHeaderNavigation } from '../functions.js';
import { LazyLoadImage } from 'react-lazy-load-image-component';

class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
      mouseOverLogin: false,
      showSmallHeader: false
    }

    this.onMouseLoginEnter = this.onMouseLoginEnter.bind(this);
    this.onMouseLoginLeave = this.onMouseLoginLeave.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  onMouseLoginEnter() {
    this.setState({
      mouseOverLogin: true
    });
  }

  onMouseLoginLeave() {
    this.setState({
      mouseOverLogin: false
    });
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    if (window.pageYOffset > 300) {
      this.setState(
        {
          showSmallHeader: true
        }
      )
    }
    else {
      this.setState(
        {
          showSmallHeader: false
        }
      )
    }
  }

  render() {
    return (
      <header id="nino-header">
        <div id="nino-headerInner">
          <nav id="nino-navbar" className={"navbar navbar-default" + (this.state.showSmallHeader && " mobile-small-header")} role="navigation">
            <div className="container">
              <div className="navbar-header">
                {
                  this.props.isLogged ?
                    <button type="button" className="navbar-toggle collapsed"
                      style={{ color: "white" }} onClick={() => this.props.userPanelOpen()}>
                      <div className="mobile-menu-icon" >
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                      </div>
                      <div className="mobile-menu-icon">
                        <span className="glyphicon glyphicon-user" ></span>
                      </div>

                      {/* <span style={{ textTransform: "none" }}>
                          {getUserName(this.props.user)} </span>
                          <span className="glyphicon glyphicon-chevron-down"></span> */}
                    </button>
                    :
                    <button type="button" className="navbar-toggle collapsed" data-toggle="collapse"
                      data-target="#nino-navbar-collapse">
                      <span className="sr-only">Toggle navigation</span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                    </button>
                }
                <Link className="navbar-brand" to={"/#"}>
                  {
                    this.props.isH1 ?
                      <h1 style={{ margin: 0 }}>
                        <LazyLoadImage id="main-logo" src={WEBPAGE_URL_IMAGES + "/logo.webp"}
                          // alt="EASY FACE DRAW"
                          onError={(e) => { e.target.onerror = null; e.target.src = WEBPAGE_URL_IMAGES + "/logo-small.png" }} />
                        <span className="td-visual-hidden">EasyFaceDraw.com</span>
                      </h1>
                      :
                      <LazyLoadImage id="main-logo" src={WEBPAGE_URL_IMAGES + "/logo.webp"}
                        // alt="EASY FACE DRAW "
                        onError={(e) => { e.target.onerror = null; e.target.src = WEBPAGE_URL_IMAGES + "/logo-small.png" }} />
                  }
                </Link>
              </div>
              {/* 
              <ul className="nav navbar-nav">
                <li><a href="#" className="nino-categories"><span className="glyphicon glyphicon-th"
                  aria-hidden="true"></span> Courses</a></li>
              </ul> */}
              {/* <div className="nino-menuItem pull-left" >
                <div className="collapse navbar-collapse pull-left">
                  <ul className="nav navbar-nav">
                    {generateHeaderNavigation(this)}
                  </ul>
                </div>
              </div> */}
              <div className="nino-menuItem pull-right">
                {/* <ul className="nino-iconsGroup nav navbar-nav">
                  <li><a href="#" className="nino-search"><i className="mdi mdi-magnify nino-icon"></i></a></li>
                </ul> */}
                <div className="collapse navbar-collapse" id="nino-navbar-collapse">
                  <ul className="nav navbar-nav" onMouseEnter={this.onMouseLoginEnter} onMouseLeave={this.onMouseLoginLeave}>
                    {generateHeaderNavigation(this)}
                    <li className="empty-list-item"></li>
                    {!this.props.isLogged && <li className="user-panel-link">
                      <a href="#" className="nino-register" onClick={() => this.props.registerFormOpen()}>
                        <span className="glyphicon glyphicon-edit hide-on-desktop" aria-hidden="true"></span>
                        <span style={{ fontWeight: 900 }}>  Sign up</span></a></li>}
                    {!this.props.isLogged && <li className="user-panel-link">
                      <a href="#" className="nino-login" onClick={() => this.props.loginFormOpen()}>
                        <span className="glyphicon glyphicon-log-in hide-on-desktop" aria-hidden="true"></span>  Log in</a></li>}
                    {this.props.isLogged && <li className="user-panel-link">
                      <a href="#" onClick={() => this.props.userPanelOpen()}>
                        <span className="glyphicon glyphicon-user"></span>
                        <span style={{ textTransform: "none" }}> {getUserName(this.props.user)} </span>
                        <span className="glyphicon glyphicon-chevron-down"
                          style={{ opacity: (this.state.mouseOverLogin ? 1 : 0) }}></span>
                      </a>
                    </li>}
                  </ul>
                </div>
              </div>
            </div>
          </nav>
          {this.props.children}
        </div>
      </header>
    );
  }
}

export default Header;
