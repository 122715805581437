import React, { Component } from 'react';
import Header from "./Header.jsx";
import { Link } from 'react-router-dom'
import { PAGE, WEBPAGE_URL_IMAGES } from '../globals';
import ImageModal from './ImageModal';
import { sendEventToGoogleAnalytics } from '../functions';
import { Helmet } from "react-helmet";

class AtricleTheArtOfIllusion extends Component {

    render() {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Digital Painting: Sign of Our Times</title>
                    <link rel="canonical" href="https://www.easyfacedraw.com/article-digital-painting" />
                    <meta name="description" content="Digital painting is a subcategory of digital art. It imitates a variety of traditional painting techniques with
                        the use of a computer, graphics tablet, stylus, and specialized software." />
                    <meta name="keywords" content="digital painting, computer drawing, drawing tutorial, video tutorials, how to draw" />
                </Helmet>
                <Header {...this.props} ></Header>
                <article>
                    <section id="nino-whatWeDo">
                        <div className="container">
                            <h1 className="nino-sectionHeading">
                                <span className="nino-subHeading big-font">Digital Painting: Sign of Our Times</span>
                            </h1>
                            <h2 className="article-author">
                                Claudia Preza
                                <br />
                                May 23, 2021
                                </h2>
                            <br />

                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author=""
                                    title="Example of a painting rendered by digital painting software"
                                    src={`${WEBPAGE_URL_IMAGES}/article/8/1.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Example of a painting rendered by digital painting software</p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                                Digital painting is a subcategory of digital art. It imitates a variety of traditional painting techniques with
                                the use of a computer, graphics tablet, stylus, and specialized software.
                            </p>
                            <h3 className="nino-sectionHeading">
                                Defining Digital Art
                            </h3>
                         
                            {/* <Link to={`/${PAGE.SHOWTUTORIALS}`} className="action-btn" onClick={() => sendEventToGoogleAnalytics("Watch for free", "article")}></Link> */}
                            <Link to={`/${PAGE.SHOWTUTORIALS}`} className="action-btn" onClick={() => sendEventToGoogleAnalytics("Watch now", "article")}></Link>

                        </div>
                    </section>
                </article>
            </div>
        );
    }
}

export default AtricleTheArtOfIllusion;
