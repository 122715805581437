import React, { Component } from 'react';
import Header from "./Header.jsx";
import { addViews, getVideoURL, getTutorialFromDb, checkForTutorialAccessKey, isTutorialUnlocked, sendEventToGoogleAnalytics } from '../functions';
import { Link } from 'react-router-dom';
import ReviewImageForm from './ReviewImageForm';
import StarsRating from "./StarsRating.jsx";
import RatingByUser from "./RatingByUser";
import Comments from './Comments.jsx';
import { WEBPAGE_URL_IMAGES, WEBPAGE_URL, DESKTOP_QUALITY, MOBLIE_QUALITY, NUMBER_OF_TUTORIAL_FOR_FREE } from '../globals.js';
import ShareButtons from "./ShareButtons.jsx";
import TimeLeft from './TimeLeft.jsx';

class CoursePlayer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeVideo: 0,
            isVideoLocked: true,
            tutorial: {},
            tutorialAccessKey: null,
            tutorialURL: "",
            videoQuality: this.props.isMoblieDevice ? MOBLIE_QUALITY.Q_1080p.url : DESKTOP_QUALITY.Q_1080p.url,
            getVideoUrlRequestDone: false,
        }
        this.changeActiveVideo = this.changeActiveVideo.bind(this);
        this.updateVideoURL = this.updateVideoURL.bind(this);
        this.changeVideoQuality = this.changeVideoQuality.bind(this);
        this.goToNextVideo = this.goToNextVideo.bind(this);

        this.reviewImageFormRef = React.createRef()
    }

    updateVideoURL(promise) {
        if (promise) {
            promise.then(() => {
                getVideoURL(this.props.match.params.number,
                    this.state.activeVideo + 1,
                    this.state.videoQuality,
                    (this.props.user.login || this.state.guestLogin) ? (this.props.user.login || this.state.guestLogin) : "-",
                    this.state.tutorialAccessKey ? this.state.tutorialAccessKey : "-",
                    this, this.props.isGuest);
            });
        }
        else if (!this.state.tutorialURL) {
            getVideoURL(this.props.match.params.number,
                this.state.activeVideo + 1,
                this.state.videoQuality,
                (this.props.user.login || this.state.guestLogin) ? (this.props.user.login || this.state.guestLogin) : "-",
                this.state.tutorialAccessKey ? this.state.tutorialAccessKey : "-",
                this, this.props.isGuest);
        }
    }

    componentDidMount() {
        getTutorialFromDb(this.props.match.params.number, this).then(() => {
            const guestLoginKey = this.props.match.params.guestloginkey;
            const guestPassword = this.props.match.params.guestpassword;
            if (this.props.isGuest && guestLoginKey && guestPassword) {
                return checkForTutorialAccessKey(this.state.tutorialAccessKey, "", "", this.props.match.params.number, this, true, guestLoginKey, guestPassword);
            }
            else {
                return checkForTutorialAccessKey(this.state.tutorialAccessKey, this.props.loginKey, this.props.user, this.props.match.params.number, this);
            }
        }).then(promise => {
            this.updateVideoURL(promise);
        });
        setTimeout(() => {addViews(this.props.match.params.number)},5000);
    }

    componentDidUpdate() {
        let promise = false;
        const guestLoginKey = this.props.match.params.guestloginkey;
        const guestPassword = this.props.match.params.guestpassword;
        if (this.props.isGuest && guestLoginKey && guestPassword) {
            promise = checkForTutorialAccessKey(this.state.tutorialAccessKey, "", "", this.props.match.params.number, this, true, guestLoginKey, guestPassword);
        }
        else {
            promise = checkForTutorialAccessKey(this.state.tutorialAccessKey, this.props.loginKey, this.props.user, this.props.match.params.number, this);
        }
        if (!this.state.getVideoUrlRequestDone) {
            this.setState({
                getVideoUrlRequestDone: true
            })
            this.updateVideoURL(promise);
        }
    }

    changeActiveVideo(videoNumber) {
        sendEventToGoogleAnalytics("Video changed", "to number " + (this.state.activeVideo + 1), this.props.match.params.number);
        if (videoNumber < this.state.tutorial.videos.length) {
            this.setState(
                {
                    activeVideo: videoNumber,
                    isVideoLocked: this.state.tutorial.videos[videoNumber].freeForEveryone || (this.state.tutorialAccessKey ? true : false)
                }
            )

            getVideoURL(this.props.match.params.number,
                videoNumber + 1,
                this.state.videoQuality,
                (this.props.user.login || this.state.guestLogin) ? (this.props.user.login || this.state.guestLogin) : "-",
                this.state.tutorialAccessKey ? this.state.tutorialAccessKey : "-",
                this, this.props.isGuest);
        }
    }
    changeVideoQuality(videoQuality) {
        sendEventToGoogleAnalytics("Change Video Quality", "to " + videoQuality, this.props.match.params.number);
        this.setState({
            videoQuality: videoQuality
        });
        getVideoURL(this.props.match.params.number,
            this.state.activeVideo + 1,
            videoQuality,
            (this.props.user.login || this.state.guestLogin) ? (this.props.user.login || this.state.guestLogin) : "-",
            this.state.tutorialAccessKey ? this.state.tutorialAccessKey : "-",
            this, this.props.isGuest);
    }
    goToNextVideo() {
        sendEventToGoogleAnalytics("Go to video", "number " + (this.state.activeVideo + 1) + " automatically", this.props.match.params.number);
        this.changeActiveVideo(this.state.activeVideo + 1)
    }
    render() {
        const that = this;
        // const qualityButtons = <div className="quality-video-buttons dropdown">
        //     <button class="btn btn-default dropdown-toggle"
        //         type="button" id="dropdownMenu1" data-toggle="dropdown"
        //         aria-haspopup="true" aria-expanded="true">
        //         {this.state.videoQuality}
        //     </button>
        //     <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">

        //         {Object.keys(QUALITY).map(function (key, index) {
        //             return <li><a onClick={() => that.changeVideoQuality(QUALITY[key])}>
        //                 {QUALITY[key]}
        //             </a></li>
        //         })}
        //     </ul>
        // </div>
        const qualityButtons = <div className="quality-video-buttons">
            <div className="quality-video-buttons-content">
                <p>Video quality:</p>
                <ul>
                    {this.props.isMoblieDevice ?
                        Object.keys(MOBLIE_QUALITY).map(function (key, index) {
                            return <li key={"quality_" + index}><button onClick={() => that.changeVideoQuality(MOBLIE_QUALITY[key].url)}
                                className={that.state.videoQuality == MOBLIE_QUALITY[key].url ? "active-quality-button" : ""}
                            >
                                {MOBLIE_QUALITY[key].code}<sup>{MOBLIE_QUALITY[key].alias}</sup>
                            </button></li>
                        })
                        :
                        Object.keys(DESKTOP_QUALITY).map(function (key, index) {
                            return <li key={"quality_" + index}><button onClick={() => that.changeVideoQuality(DESKTOP_QUALITY[key].url)}
                                className={that.state.videoQuality == DESKTOP_QUALITY[key].url ? "active-quality-button" : ""}
                            >
                                {DESKTOP_QUALITY[key].code}<sup>{DESKTOP_QUALITY[key].alias}</sup>
                            </button></li>
                        })
                    }
                </ul>
            </div>
        </div>

        let timeLineItems = [];
        if (this.state.tutorial.videos) {
            timeLineItems = this.state.tutorial.videos.map((e, i) => {
                return <tr key={i} onClick={() => this.changeActiveVideo(i)} className={(i == this.state.activeVideo) ? "active-video" : ""}>
                    <td> {(e.freeForEveryone || isTutorialUnlocked(
                        this.state.tutorialAccessKey, this.props.isLogged, this.props.loginKey,
                        this.props.isGuest, this.props.match.params.guestloginkey, this.props.match.params.guestpassword)) ? (i + 1) : <span className="glyphicon glyphicon-lock" aria-hidden="true"></span>}</td>
                    <td><div>{e.title}</div></td>
                    <td>{e.time}</td>
                </tr>
            });
            if (this.state.tutorial.isReviewFreeForEveryone || isTutorialUnlocked(this.state.tutorialAccessKey, this.props.isLogged, this.props.loginKey,
                this.props.isGuest, this.props.match.params.guestloginkey, this.props.match.params.guestpassword)) {
                timeLineItems.push(
                    <tr key={"send-drawing-for-review"} onClick={() => {
                        window.scrollTo(0, this.reviewImageFormRef.current.getBoundingClientRect().top);
                    }}>
                        <td style={{ backgroundColor: "black", borderColor: "black" }}> <span className="glyphicon glyphicon-pencil" aria-hidden="true" style={{ color: "white" }}></span></td>
                        <td><div>Upload your drawing for review</div></td>
                        <td></td>
                    </tr>
                )
            }
        }

        let contentItems = [];

        if (this.state.tutorial.content) {
            contentItems = this.state.tutorial.content.map((e, i) => <li key={i}>{e}</li>);
        }
        return (
            <div>
                <Header {...this.props} ></Header>
                <section id="nino-story">
                    <div className="container-fluid">
                        <div className="sectionContent">
                            <div className="row nino-hoverEffect">
                                <div className="col-md-9">
                                    
                                    <video poster={!this.state.isVideoLocked ? (WEBPAGE_URL_IMAGES + "/locked-video.jpg") : null}
                                        onLoadedData={() => that.refs.vidRef.play()}
                                        autoPlay
                                        playsInline
                                        ref="vidRef"
                                        width="100%" height="auto"
                                        controls={this.state.isVideoLocked}
                                        controlsList="nodownload noremoteplayback"
                                        key={this.state.activeVideo + this.state.tutorialURL + this.state.videoQuality}
                                        onEnded={this.goToNextVideo}>
                                        <source src={this.state.tutorialURL} type="video/mp4" />
                                        Your browser does not support HTML5 video.
                                    </video>
                                    {qualityButtons}
                                    <div className="hidden-xs hidden-sm hidden-lg" ref={this.reviewImageFormRef}>
                                        <ReviewImageForm tutorialNumber={this.props.match.params.number}
                                            user={this.props.isGuest ? { login: this.props.match.params.guestloginkey } : this.props.user}
                                            tutorial={this.state.tutorial}
                                            tutorialAccessKey={this.state.tutorialAccessKey}
                                            isGuest={this.props.isGuest}
                                            isLogged={this.props.isLogged} >
                                        </ReviewImageForm>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="player-videos-timeline">
                                        <table>
                                            <tbody>
                                                {timeLineItems}
                                            </tbody>
                                        </table>
                                    </div>
                                    <br />
                                    <br />
                                    <div className="price-box">
                                        {this.state.tutorial.title && <React.Fragment>
                                            <Link to={"/course/" + this.state.tutorial.number}>
                                                {isTutorialUnlocked(this.state.tutorialAccessKey, this.props.isLogged, this.props.loginKey, this.props.isGuest, this.props.match.params.guestloginkey, this.props.match.params.guestpassword) ?
                                                    <h4>
                                                        <span className="course-title course-title-small">
                                                            {this.state.tutorial.title}
                                                        </span>
                                                    </h4>
                                                    :
                                                    <h4 className="nino-sectionHeading">
                                                        {this.state.tutorial.title} - full course
                                                </h4>
                                                }
                                            </Link>
                                            <StarsRating bigStars={true} rating={this.state.tutorial.rating}  ratingsCount={this.state.tutorial.ratingsCount}></StarsRating>
                                            {isTutorialUnlocked(this.state.tutorialAccessKey, this.props.isLogged, this.props.loginKey, this.props.isGuest, this.props.match.params.guestloginkey, this.props.match.params.guestpassword) ?
                                                <React.Fragment>
                                                    <br />
                                                    {!this.props.isGuest && <RatingByUser {...this.state} {...this.props}></RatingByUser>}

                                                </React.Fragment>
                                                :
                                                <React.Fragment>
                                                    {(this.state.tutorial.number != NUMBER_OF_TUTORIAL_FOR_FREE) && <TimeLeft discountEndTime={this.state.tutorial.discountEndTime}
                                                        price={this.state.tutorial.price}
                                                        discountPrice={this.state.tutorial.discountPrice}></TimeLeft>}
                                                    {!this.state.tutorial.isReviewFreeForEveryone &&
                                                        <React.Fragment>
                                                            {this.props.isLogged ?
                                                                <Link className="overlay price-btn" to={"/payments/" + this.state.tutorial.number}
                                                                onClick={() => {
                                                                    document.gtag_report_conversion(document.location.href);
                                                                    sendEventToGoogleAnalytics("Buy now", "in course player", this.props.match.params.number);
                                                                }}>
                                                                    {this.state.tutorial.number == NUMBER_OF_TUTORIAL_FOR_FREE ? "Get for free" : "Buy now"}
                                                                </Link>
                                                                :
                                                                <div className="price-btn" onClick={() => {
                                                                    document.gtag_report_conversion(document.location.href);
                                                                    sendEventToGoogleAnalytics("Buy now", "in course player", this.props.match.params.number);
                                                                    this.props.registerFormOpen(true, this.state.tutorial.number)
                                                                }}>
                                                                    {this.state.tutorial.number == NUMBER_OF_TUTORIAL_FOR_FREE ? "Get for free" : "Buy now"}
                                                                </div>
                                                            }

                                                        </React.Fragment>

                                                    }
                                                    <ul>
                                                        {contentItems}
                                                    </ul>
                                                </React.Fragment>
                                            }
                                        </React.Fragment>}
                                    </div>
                                    <ShareButtons
                                        title={this.state.tutorial.title}
                                        description={this.state.tutorial.description}
                                        quote={this.state.tutorial.description}
                                        hashtag={["drawing", "video", "tutorial", "art"]}
                                        media={`${WEBPAGE_URL_IMAGES}/tutorials/${this.state.tutorial.number}-big.webp`}
                                        url={`${WEBPAGE_URL}/course/${this.state.tutorial.number}`}
                                    ></ShareButtons>


                                    {/* <h2 className="nino-sectionHeading">
                                        Try also
						            </h2>
                                    <div className="sectionContent">
                                        <div className="nino-hoverEffect">
                                            <div className="item">
                                                <a className="overlay" href="#">
                                                    <span className="content">
                                                        <i className="mdi mdi-account-multiple nino-icon"></i>
                                                        super team
										</span>
                                                    <img src="images/story/img-1.jpg" alt="" />
                                                </a>
                                            </div>
                                            <div className="articleMeta">
                                                <a href="#"><i className="mdi mdi-eye nino-icon"></i> 543</a>
                                                <a href="#"><i className="mdi mdi-comment-multiple-outline nino-icon"></i> 15</a>
                                            </div>
                                            <h2 className="nino-sectionHeading">
                                                <span className="nino-subHeading">How to draw a face?</span>
                                            </h2>

                                            <div className="item">
                                                <a className="overlay" href="#">
                                                    <span className="content">
                                                        <i className="mdi mdi-image-filter-center-focus-weak nino-icon"></i>
                                                        Creativity
										</span>
                                                    <img src="images/story/img-2.jpg" alt="" />
                                                </a>
                                            </div>
                                            <div className="articleMeta">
                                                <a href="#"><i className="mdi mdi-eye nino-icon"></i> 543</a>
                                                <a href="#"><i className="mdi mdi-comment-multiple-outline nino-icon"></i> 15</a>
                                            </div>
                                            <h2 className="nino-sectionHeading">
                                                <span className="nino-subHeading">How to draw an eye?</span>
                                            </h2>

                                            <div className="item">
                                                <a className="overlay" href="#">
                                                    <span className="content">
                                                        <i className="mdi mdi-airplay nino-icon"></i>
                                                        Digital
										</span>
                                                    <img src="images/story/img-3.jpg" alt="" />
                                                </a>
                                            </div>
                                            <div className="articleMeta">
                                                <a href="#"><i className="mdi mdi-eye nino-icon"></i> 543</a>
                                                <a href="#"><i className="mdi mdi-comment-multiple-outline nino-icon"></i> 15</a>
                                            </div>
                                            <h2 className="nino-sectionHeading">
                                                <span className="nino-subHeading">How to draw a body?</span>

                                            </h2>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            {(isTutorialUnlocked(this.state.tutorialAccessKey, this.props.isLogged, this.props.loginKey, this.props.isGuest, this.props.match.params.guestloginkey, this.props.match.params.guestpassword) || this.state.tutorial.isReviewFreeForEveryone) &&
                                <div className="row hidden-md" ref={this.reviewImageFormRef}>
                                    <ReviewImageForm tutorialNumber={this.props.match.params.number}
                                        user={this.props.isGuest ? { login: this.props.match.params.guestloginkey } : this.props.user}
                                        tutorial={this.state.tutorial}
                                        tutorialAccessKey={this.state.tutorialAccessKey}
                                        isGuest={this.props.isGuest}
                                        isLogged={this.props.isLogged} >
                                    </ReviewImageForm>
                                </div>
                            }
                        </div>
                    </div>
                </section>
                <Comments {...this.state} {...this.props} parent={this} commentsFromDB={this.state.comments} wideComments={true} showCommentForm={isTutorialUnlocked(this.state.tutorialAccessKey, this.props.isLogged, this.props.loginKey)}>
                    <h2 className="nino-sectionHeading">
                        What our students say<br />about this course
                    </h2>
                </Comments>
            </div>
        );
    }
}

export default CoursePlayer;
