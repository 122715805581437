import React, { Component } from 'react';
import { sendFeedback } from '../../functions';
import Loader from 'react-loader-spinner'

export class FeedbackForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            feedback: "",
            successMessage: "",
            warningMessage: "",
            inProgress: false
        };
        this.handleUserInput = this.handleUserInput.bind(this);
    }

    handleUserInput(e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value });
    }

    render() {
        return (
            <React.Fragment>
                {this.state.inProgress ?
                    <div className="form-loading-circle">
                        <Loader type="TailSpin" color="#95e1d3" height="150" width="150" />
                    </div>
                    :
                    <React.Fragment>
                        <form action="" className="nino-subscribeForm">
                            <div className="input-group input-group-lg">
                                {/* <input value={this.state.email} name="email" onChange={this.handleUserInput} type="email" className="form-control" placeholder="Your Email" required /> */}
                                <textarea value={this.state.email} name="feedback" onChange={this.handleUserInput} 
                                   maxLength="1000" cols="40" rows="10" className="form-control" placeholder="Give us feedback..." required ></textarea>
                                <span className="input-group-btn">
                                    <button onClick={() => sendFeedback(this.state.feedback, this)} className="btn btn-success" type="button">Send</button>
                                </span>
                            </div>
                        </form>
                        {this.state.warningMessage &&
                            <React.Fragment>
                                <br />
                                <div className="validation-warning-box nino-formInput"> {this.state.warningMessage} </div>
                            </React.Fragment>
                        }
                        {this.state.successMessage &&
                            <React.Fragment>
                                <br />
                                <div className="validation-success-box nino-formInput"> {this.state.successMessage} </div>
                            </React.Fragment>
                        }
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

export default FeedbackForm;
