import React, { Component } from 'react';
import Header from "./Header.jsx";
import { Link } from 'react-router-dom'
import { PAGE, INFORMATION_OGLIGATION_RULES_HTML } from '../globals';
import { Helmet } from "react-helmet";

class InformationObligationClause extends Component {

    render() {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Information Obligation Clause for Easy Face Draw</title>
                    <link rel="canonical" href="https://www.easyfacedraw.com/information-obligation-clause" />
                    <meta name="description" content="Please review our Information Obligation Clause, and then join us for our fun and easy art tutorials." />
                    <meta name="keywords" content="Information Obligation Clause, easy face draw,easyfacedraw" />
                </Helmet>
                <Header {...this.props} ></Header>
                <section id="nino-whatWeDo">
                    <div className="container">
                        <h1 className="nino-sectionHeading">
                            Information Obligation Clause of <a href="www.easyfacedraw.com"> www.easyfacedraw.com</a>
                        </h1>
                        {INFORMATION_OGLIGATION_RULES_HTML}

                        <div className='innerText'>
                            <br />Last Edited on 2021-08-04.
                        </div>
                    </div>
                </section>
                <br />
                <br />
            </div>
        );
    }
}

export default InformationObligationClause;
