import React, { Component } from 'react';
import Header from "./Header.jsx";
import { Link } from 'react-router-dom'
import { PAGE } from '../globals';
import ContactForm from './Forms/ContactForm.jsx';

class Contribution extends Component {

    render() {
        return (
            <div>
                <Header {...this.props} ></Header>
                
                <section id="nino-whatWeDo">
                    <div className="container">
                        <h1 className="nino-sectionHeading">
                            Join our <a href="www.easyfacedraw.com"> easyfacedraw.com</a> team
                        </h1>
                        <p className="nino-sectionDesc read-more-description" style={{maxWidth: '450px'}}>
                        Want to share your knowledge and skills through our platform? We'd love to hear from you.
                            <br />
                            <br />
                            We can add your video tutorials to our website and help you reach a broader audience.
                            <br />
                            <br />
                            There is no cost to you, and you can set the price for your tutorials.
                            <br />
                            <br />
                            Write to us at <span className="highlighted-text">contact@easyfacedraw.com</span> 
                        
                        </p>                        
                    </div>
                    <ContactForm></ContactForm>
                </section>
                <br />
                <br />
            </div>
        );
    }
}

export default Contribution;
