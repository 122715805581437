import React, { Component } from 'react';
import Header from "./Header.jsx";
import { getTutorialFromDb, isTutorialUnlocked, sendEventToGoogleAnalytics, checkForTutorialAccessKey } from '../functions';
import Comments from './Comments.jsx';
import { Helmet } from "react-helmet";
import PhotosGalery from "./PhotosGalery";
import TeachersContainer from "./TeachersContainer";
import ImageModal from './ImageModal';
import TimeLeft from './TimeLeft.jsx';
import { Link } from 'react-router-dom';
import { WEBPAGE_URL_IMAGES, WEBPAGE_URL, NUMBER_OF_TUTORIAL_FOR_FREE } from '../globals.js';
import Loader from 'react-loader-spinner'
import StarsRating from "./StarsRating.jsx";
import ShareButtons from "./ShareButtons.jsx";
import RatingByUser from "./RatingByUser";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const staticComments = [
    {
        name: "Emma",
        surname: "van der Kooij",
        rating: 5,
        date: "1583033110504",
        img: "13332878_1745423599037753_5410044909154124661_n.jpg",
        comment: '"The tutorial was nice to watch and had a comforting vibe, the music was nice to listen to while drawing. I like that the video is divided into topics. It makes it really easy to rewatch a certain step. I think it’s a good video for beginners."'
    }
    ,
    {
        name: "Cassandra",
        surname: "Leigh",
        rating: 5,
        date: "1583033110504",
        img: "292855_1942949054102_1084862470_n.jpg",
        comment: '"The tutorial was great, I tried to forget everything and not want to do other things I’ve learned along the way... lol!"'
    }
]

class SellingLandingPageForCourse extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showBottomCallToAction: false,
            tutorial: {},
            tutorialAccessKey: null
        }
        this.refreshTutorial = this.refreshTutorial.bind(this);
    }

    componentDidMount() {
        getTutorialFromDb(this.props.match.params.number, this);
        document.onscroll = () => {
            if(this.props.isMoblieDevice)
            {
                if (document.documentElement.scrollTop < 1200) {
                    this.setState({
                        showBottomCallToAction: true,
                    })
                }
                else if (document.documentElement.scrollTop < 1900) {
                    this.setState({
                        showBottomCallToAction: false,
                    })
                }
                else{
                    this.setState({
                        showBottomCallToAction: true,
                    })
                }
            }
            else
            {
                if (document.documentElement.scrollTop > 600) {
                    this.setState({
                        showBottomCallToAction: true,
                    })
                }
                else {
                    this.setState({
                        showBottomCallToAction: false,
                    })
                }
            }
        }
    }

    componentWillUpdate() {
        checkForTutorialAccessKey(this.state.tutorialAccessKey, this.props.loginKey, this.props.user, this.props.match.params.number, this);
    }

    refreshTutorial() {
        getTutorialFromDb(this.props.match.params.number, this);
    }

    render() {
        let contentItems = [];
        let whatCanYouLearnItems = [];

        if (this.state.tutorial.content) {
            contentItems = this.state.tutorial.content.map((e, i) => <li key={i}>{e}</li>);
        }

        if (this.state.tutorial.whatCanYouLearn) {
            whatCanYouLearnItems = this.state.tutorial.whatCanYouLearn.map((e, i) =>
                <div key={i} className="learn-advantage" layout="row">
                    <span className="glyphicon glyphicon-ok" aria-hidden="true"></span>
                    <p>{e}</p>
                </div>
            );
        }

        const screenshots = [];
        for (var index = 1; index < 7; index++) {
            screenshots.push(
                <div className="col-md-4 course-screenshot" key={`screenshots_${index}`}>
                    <ImageModal key={index} number={index} title="" author="" imagePrefix={"/tutorials/screenshots/t_" + this.state.tutorial.number + "/"} ></ImageModal>
                </div>
            )
        }
        return (
            <div className="selling-landing-page">

                {
                    (this.props.match.params.number == 1) &&
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Learn How to Draw a Nose with Step-by-Step Tutorials</title>
                        <link rel="canonical" href="https://www.easyfacedraw.com/course/1" />
                        <meta name="description" content="Want to learn how to draw a nose? Grab your art materials, and use our step-by-step tutorial for beginner artists." />
                        <meta name="keywords" content="drawing tutorial, art instruction, how to draw, draw a nose, basic drawing" />
                    </Helmet>
                }
                {
                    (this.props.match.params.number == 2) &&
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Learn How to Draw Eyes with Step-by-Step Tutorials</title>
                        <link rel="canonical" href="https://www.easyfacedraw.com/course/2" />
                        <meta name="description" content="Ready to learn realistic eye drawing? Sharpen your pencils, and join our art instructor for simple, step-by-step drawing tutorials." />
                        <meta name="keywords" content="drawing tutorial, art tutor, how to draw, draw eyes, learn to draw, realistic eye drawing" />

                    </Helmet>
                }
                {
                    (this.props.match.params.number == 3) &&
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Learn How to Draw Lips with Step-by-Step Tutorials</title>
                        <link rel="canonical" href="https://www.easyfacedraw.com/course/3" />
                        <meta name="description" content="Think it’s hard to draw realistic lips? Our easy drawing tutorials for beginners will teach you basic drawing and pencil shading." />
                        <meta name="keywords" content="drawing tutorial, art instructor, how to draw, draw lips, pencil art" />

                    </Helmet>
                }
                {
                    (this.props.match.params.number == 4) &&
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Learn How to Draw Hair with Step-by-Step Tutorials</title>
                        <link rel="canonical" href="https://www.easyfacedraw.com/course/4" />
                        <meta name="description" content="Want to learn how to draw hair? Warm up and learn to draw with our trained art tutor." />
                        <meta name="keywords" content="drawing tutorial, beginner art, how to draw, draw hair, pencil art" />

                    </Helmet>
                }
                {
                    (this.props.match.params.number == 5) &&
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Learn How to Draw Ears with Step-by-Step Tutorials</title>
                        <link rel="canonical" href="https://www.easyfacedraw.com/course/5" />
                        <meta name="description" content="Ready to learn how to draw an ear? Join our art instructor for easy drawing tutorials for beginners." />
                        <meta name="keywords" content="drawing tutorial, art instruction, how to draw, draw ears, pencil drawing" />

                    </Helmet>
                }
                {
                    (this.props.match.params.number == 6) &&
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Learn How to Draw a Face with Step-by-Step Tutorials</title>
                        <link rel="canonical" href="https://www.easyfacedraw.com/course/6" />
                        <meta name="description" content="Ever wanted to learn how to draw a face? Learn basic drawing and pencil shading with our step-by-step video tutorials." />
                        <meta name="keywords" content="drawing tutorial, face sketch, how to draw, draw a face, easy drawing" />

                    </Helmet>
                }
                <Header {...this.props} ></Header>

                <section id="nino-story">
                    <div className="container">
                        <div className="sectionContent">
                            <div className="row nino-hoverEffect">
                                <div className="col-md-8">
                                    {this.state.tutorial.title ?
                                        <React.Fragment>
                                            <h1 className="nino-sectionHeading">
                                                <span className="course-title">{this.state.tutorial.title}</span>
                                            </h1>
                                            <p id="course-description">{this.state.tutorial.description}</p>
                                            <Link to={"/player/" + this.props.match.params.number}>
                                                <div className="item backgroundShadow opacity-on-hover" style={{ position: 'relative' }}>
                                                    {
                                                        this.props.match.params.number &&
                                                        <LazyLoadImage className="course-img" src={`${WEBPAGE_URL_IMAGES}/tutorials/${this.state.tutorial.number}-big.webp`}
                                                            alt={this.state.tutorial.title}
                                                            onError={(e) => { e.target.onerror = null; e.target.src = `${WEBPAGE_URL_IMAGES}/tutorials/${this.state.tutorial.number}-big.jpg` }}
                                                        />
                                                    }
                                                    <div className="img-play-button"><span className="glyphicon glyphicon-play-circle" aria-hidden="true"></span></div>
                                                </div>
                                            </Link>
                                            <div>
                                                Last actualization: {this.state.tutorial.modificationDate}
                                            </div>

                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            <div className="form-loading-circle">
                                                <Loader type="TailSpin" color="#95e1d3" height="150" width="150" />
                                            </div>
                                        </React.Fragment>
                                    }
                                </div>
                                <div className="col-md-4">
                                    <div className="price-box">
                                        {this.state.tutorial.title && <React.Fragment>
                                            <h4 className="nino-sectionHeading">
                                                {this.state.tutorial.title} - full course <br />+ 1 Free Review
                                        </h4>
                                            <StarsRating bigStars={true} rating={this.state.tutorial.rating} ratingsCount={this.state.tutorial.ratingsCount}></StarsRating>
                                            {isTutorialUnlocked(this.state.tutorialAccessKey, this.props.isLogged, this.props.loginKey) ?
                                                <RatingByUser {...this.state} {...this.props}></RatingByUser>
                                                :
                                                <React.Fragment>
                                                    {(this.state.tutorial.number != NUMBER_OF_TUTORIAL_FOR_FREE) && <TimeLeft
                                                        discountEndTime={this.state.tutorial.discountEndTime}
                                                        price={this.state.tutorial.price}
                                                        discountPrice={this.state.tutorial.discountPrice}></TimeLeft>}
                                                    {!this.state.tutorial.isReviewFreeForEveryone ?
                                                        <React.Fragment>
                                                            {this.props.isLogged ?
                                                                <Link className="overlay price-btn" to={"/payments/" + this.state.tutorial.number}
                                                                    onClick={() => {
                                                                        document.gtag_report_conversion(document.location.href);
                                                                        sendEventToGoogleAnalytics("Buy now", "in course overview", this.state.tutorial.number);
                                                                    }}>
                                                                    {this.state.tutorial.number == NUMBER_OF_TUTORIAL_FOR_FREE ? "Get for free" : "Buy now"}
                                                                </Link>
                                                                :
                                                                <div className="price-btn" onClick={() => {
                                                                    document.gtag_report_conversion(document.location.href);
                                                                    sendEventToGoogleAnalytics("Buy now", "in course overview", this.state.tutorial.number);
                                                                    this.props.registerFormOpen(true, this.state.tutorial.number)
                                                                }
                                                                }>
                                                                    {this.state.tutorial.number == NUMBER_OF_TUTORIAL_FOR_FREE ? "Get for free" : "Buy now"}
                                                                </div>
                                                            }
                                                        </React.Fragment>
                                                        :
                                                        <React.Fragment>
                                                            <Link className="overlay price-btn" to={"/player/" + this.state.tutorial.number}
                                                                onClick={() => sendEventToGoogleAnalytics("Start watching click", "orange", this.state.tutorial.number)}>
                                                                Start watching
                                                             </Link>
                                                        </React.Fragment>
                                                    }
                                                </React.Fragment>
                                            }

                                            <ul>
                                                {contentItems}
                                            </ul>
                                        </React.Fragment>}
                                    </div>
                                    <ShareButtons
                                        title={this.state.tutorial.title}
                                        description={this.state.tutorial.description}
                                        quote={this.state.tutorial.description}
                                        hashtag={["drawing", "video", "tutorial", "art"]}
                                        media={`${WEBPAGE_URL_IMAGES}/tutorials/${this.state.tutorial.number}-big.webp`}
                                        url={`${WEBPAGE_URL}/course/${this.state.tutorial.number}`}
                                    ></ShareButtons>

                                </div>

                            </div>
                            <div id="course-content">
                                <h2 className="nino-sectionHeading" >
                                    What you will learn
                                            </h2>
                                {whatCanYouLearnItems}
                                <br />
                                <Link className="overlay price-btn" style={{ width: "fit-content" }} to={"/player/" + this.state.tutorial.number}
                                    onClick={() => sendEventToGoogleAnalytics("Start watching click", "bottom-orange", this.state.tutorial.number)}>
                                    Start watching
                                                             </Link>
                                <div className="course-content-background"></div>
                            </div>
                            <br />
                            <br />
                            <div className="row">
                                <div className="course-screenshots">
                                    {screenshots}
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                </section>

                <section id="nino-ourTeam">
                    <div className="container what-people-say">
                        <h2 className="nino-sectionHeading">
                            what people say
                        </h2>

                        <p> <span>“</span> I am most impressed with this Drawing site! It has well-paced lessons that are professionally filmed and evocative of university drawing lessons. The voiceover is not patronising, but gives clear, intelligent instruction for classic drawing technique. I also appreciate the background of art history provided. Many similar sites promise the world if only you pay to join, but I can listen to very little because the voice and pushy sales technique irritate me. This site treats you with respect and I would wholeheartedly recommend it.</p>
                        <p>---  Jenny Lishman  ---</p>
                        <p><a href="https://www.facebook.com/groups/309827932557849">Art Resources for Visual Art Teachers  <i className="mdi mdi-facebook"></i></a></p>
                    </div>
                    <br />
                    <br />
                </section>
                <PhotosGalery></PhotosGalery>
                <br />
                <Comments comments={staticComments}>
                    <h2 className="nino-sectionHeading">
                        Happy Students
		    	</h2>
                </Comments>
                <br />
                <TeachersContainer></TeachersContainer>
                <br />
                <br />
                {/* <Comments {...this.state} {...this.props} parent={this} commentsFromDB={this.state.comments} wideComments={true} showCommentForm={isTutorialUnlocked(this.state.tutorialAccessKey, this.props.isLogged, this.props.loginKey)} registerFormOpen={this.props.registerFormOpen}>
                    <h2 className="nino-sectionHeading">
                        What our students say<br />about this course
                    </h2>
                </Comments> */}
                {!this.props.isRegisterFormOpen && this.state.showBottomCallToAction && !isTutorialUnlocked(this.state.tutorialAccessKey, this.props.isLogged, this.props.loginKey) &&
                    <div className="footer-call-to-action">
                        {!this.state.tutorial.isReviewFreeForEveryone ?
                            <React.Fragment>
                                {this.props.isLogged ?
                                    <Link className="price-btn" style={{width: "200px"}} to={"/payments/" + this.state.tutorial.number}
                                        onClick={() => {
                                            document.gtag_report_conversion(document.location.href);
                                            sendEventToGoogleAnalytics("Buy now", "footer", this.state.tutorial.number);
                                        }}>
                                        {this.state.tutorial.number == NUMBER_OF_TUTORIAL_FOR_FREE ? "Get for free" : "Buy now"}
                                    </Link>
                                    :
                                    <div className="price-btn" style={{width: "200px"}} onClick={() => {
                                        document.gtag_report_conversion(document.location.href);
                                        sendEventToGoogleAnalytics("Buy now", "footer", this.state.tutorial.number);
                                        this.props.registerFormOpen(true, this.state.tutorial.number)
                                    }
                                    }>
                                        {this.state.tutorial.number == NUMBER_OF_TUTORIAL_FOR_FREE ? "Get for free" : "Buy now"}
                                    </div>
                                }
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <Link className="overlay price-btn" to={"/player/" + this.state.tutorial.number}
                                    onClick={() => sendEventToGoogleAnalytics("Start watching click", "orange-footer", this.state.tutorial.number)}>
                                    Start watching
                                                             </Link>
                            </React.Fragment>
                        }
                    </div>
                }
            </div>
        );
    }
}

export default SellingLandingPageForCourse;
