import React, { Component } from 'react';
import Header from "./Header.jsx";
import { Link } from 'react-router-dom'
import { PAGE, WEBPAGE_URL_IMAGES } from '../globals';
import ImageModal from './ImageModal';
import { sendEventToGoogleAnalytics } from '../functions';
import { Helmet } from "react-helmet";

class ArticleHowColorWorksInArt extends Component {

    render() {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>How Color Works in Art</title>
                    <link rel="canonical" href="https://www.easyfacedraw.com/article-how-color-works-in-art" />
                    <meta name="description" content="Visual art in the form of painting on canvas or paper is based on line and color. Both successfully arranged on a two-dimensional surface, contribute to the illusion of depth or space." />
                    <meta name="keywords" content="How Color Works, Art, how to draw, art of drawing" />
                </Helmet>
                <Header {...this.props} ></Header>
                <article>
                    <section id="nino-whatWeDo">
                        <div className="container">
                            <h1 className="nino-sectionHeading">
                                How Color Works in Art
                            </h1>
                            <h2 className="article-author">
                                Alan McNairn
                                <br />
                                May 20, 2021
                                </h2>
                            <br />
                            <p className="nino-sectionDesc read-more-description">
                                Visual art in the form of painting on canvas or paper is based on line and color. Both
                                successfully arranged on a two-dimensional surface, contribute to the illusion of depth or space.
			                </p>
                            <p className="nino-sectionDesc read-more-description">
                                Art is pure illusion. It is a noble lie. It is this illusion that delights our eyes, stimulates our brains,
                                and incites emotions.
			                </p>
                            <p className="nino-sectionDesc read-more-description">
                                Depth, three-dimensional space, or the volume of objects which our eyes perceive in the natural
                                world is recreated in visual art. It is the illusion of three-dimensionality of things in art that fools
                                us into believing that an image is “real.” The urge to reach out and touch a work of art is
                                evidence of the power of this illusion.
			                </p>
                            <p className="nino-sectionDesc read-more-description">
                                Depth, three-dimensional space, or the volume of objects which our eyes perceive in the natural
                                world is recreated in visual art. It is the illusion of three-dimensionality of things in art that fools
                                us into believing that an image is “real.” The urge to reach out and touch a work of art is
                                evidence of the power of this illusion.
			                </p>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="Albrecht Dürer"
                                    title="Albrecht Dürer, (1471-1528). An artist drawing a model with the aid of a grid."
                                    src={`${WEBPAGE_URL_IMAGES}/article/7/1.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Albrecht Dürer, (1471-1528). An artist drawing a model with the aid of a grid.</p>
                            </div>
                            <h3 className="nino-sectionHeading">
                                Color Fools the Eye: Volume and Light
                            </h3>
                            <p className="nino-sectionDesc read-more-description">
                                Line is not the only device used in art to fool the eye. Artists also use color to create the illusion
                                of a three-dimensional object in space.
			                </p> 
                            <p className="nino-sectionDesc read-more-description">
                                The illusions an artist can create on a flat surface, using color, depend on how the eye sees color
                                and how the brain processes the information it receives from the eye. For instance, we see a
                                white object as something closer to us than something that is black. The darker, or closer to black
                                something is, the further away it seems to be.
			                </p>
                            <p className="nino-sectionDesc read-more-description">
                                The effect of darkness and lightness—the illusion of three-dimensional space in art—is why
                                artists are so meticulous in mixing their paints so they can create just the right effect.
			                </p>
                            <p className="nino-sectionDesc read-more-description">
                                After selecting the hue or color required by a form, say green for a leaf, an artist will decide what
                                tint or shade of green should be used. This will depend on whether the leaf is close to the viewer,
                                in which case a light or white tinted green is used. If the leaf is to be interpreted as more distant
                                from the viewer, a darker shade of green will be used.
			                </p>
                            <p className="nino-sectionDesc read-more-description">
                                Not only do the tints or shades of a hue or color create depth in art, they are used to represent the
                                effects of light shining on an object. An artist will lighten the tint of a color to represent nearness
                                to a light source. The darkness of a shade of a color, the further it will appear to be from a source
                                of illumination.
			                </p>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="Joseph Wright of Derby"
                                    title="Joseph Wright of Derby, An Experiment on a Bird in the Air Pump, 1768, National Gallery, London."
                                    src={`${WEBPAGE_URL_IMAGES}/article/7/2.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Joseph Wright of Derby, An Experiment on a Bird in the Air Pump, 1768, National Gallery, London.</p>
                            </div>
                            <h3 className="nino-sectionHeading">
                                Color and Representation
                            </h3>
                            <p className="nino-sectionDesc read-more-description">
                                From the earliest straightforward application of tints and shades created from organic materials
                                to the use of modern pigments created from complex chemical compounds, color has fascinated
                                artists. Color not only reflects the appearance of nature, but it also has innate qualities that mimic
                                and induce human emotions. Marc Chagall, the Russian born, modernist painter, said that color
                                is all. “When color is right, form is right. Color is everything, color is vibration like music;
                                everything is vibration.”
			                </p>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="Marc Chagall"
                                    title="Marc Chagall, Self-Portrait with Seven Fingers, 1913, Stedelijk Museum, Amsterdam"
                                    src={`${WEBPAGE_URL_IMAGES}/article/7/3.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>JMarc Chagall, Self-Portrait with Seven Fingers, 1913, Stedelijk Museum, Amsterdam</p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                                Color in art represents not just the appearance of things in the natural world—a leaf will be
                                pictured in some tint or shade of green—but it also is used to evoke emotion or feeling. For
                                example, blue will suggest melancholy or sadness.
			                </p>
                            <p className="nino-sectionDesc read-more-description">
                                Because color is essential to art, artists over the centuries have devised many theories as to how
                                color works on the eye and on the mind. How to mix colors to achieve the desired effect is a
                                critical skill in the artist’s toolbox.
			                </p>
                            <p className="nino-sectionDesc read-more-description">
                                Most artists use a simplified color wheel to learn, in a hands-on way, the
                                techniques of mixing color. Many painters do not bother themselves with
                                acquiring a deep understanding of the complex physics of optics or color
                                theory behind their experiments on the palette. Mixing colors by trial and
                                error often, by chance, contribute to furthering a creator’s inspiration.
			                </p>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author=""
                                    title=""
                                    src={`${WEBPAGE_URL_IMAGES}/article/7/4.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                                As the French 19th-century art critic Charles Baudelaire said, “color
                                thinks by itself, independently of the object it clothes.” And so, the artist’s choice of what she
                                will use in her work will depend upon an inner feeling. “Who told you that one paints with
                                colors?” asked the painter Jean-Baptiste-Simeon Chardin in the 18th century. “One makes use of
                                colors, but one paints with emotions.”
			                </p>
                            <p className="nino-sectionDesc read-more-description">
                                Paul Gaugin, who was one of the most masterful among a throng of 19th-century colorists, said,
                                “Color! What a deep and mysterious language, the language of dreams.” And later, Wassily
                                Kandinsky demonstrated the truth of this statement in his musical paintings that were physical
                                manifestations of his contention that “color provokes a psychic vibration. Color hides a power
                                still unknown but real, which acts on every part of the human body.”
			                </p>
                            <p className="nino-sectionDesc read-more-description">
                                In color, the artist can say things which are inexpressible in any other medium including words.
			                </p>
                            <p className="nino-sectionDesc read-more-description">
                                For most artists getting color just right - manipulating color to do exactly what they want it do -
                                is a special skill acquired over time. This skill does not normally come easily. It can take a
                                lifetime of exercise to achieve a satisfactory fitness in choosing color, mixing color, and applying
                                color.
			                </p>
                            <h3 className="nino-sectionHeading">
                                Warm and Cool Colors
                            </h3>
                            <p className="nino-sectionDesc read-more-description">
                                The use of the adjectives “warm” and “cool” to describe colors is not based on objective science.
                                The words warm and cool are subjective and come from human experience of the world. They
                                are a useful way to add another dimension to a color wheel by dividing it into two segments. The
                                line dividing the wheel is not an absolute, and it can rotate one way or the other depending on the
                                predisposition of the viewer.
			                </p>
                            <p className="nino-sectionDesc read-more-description">
                                The attribution of the characteristic “warm” to a color is entirely
                                subjective and only makes sense as a comparative term. That is, the colors
                                on the right of the illustrated color wheel are warmer than those on the left. If this is the case, can
                                the colors on the right of the wheel be arranged on a scale? Which color is warm, which one is
                                warmer and which one is warmest? Or to make matters even more confusing which color on the
                                right is cool, which one is warm and which ones fall between these two in terms of relative
                                warmth or coolness. Here is where we run into the differences in perception. The choices that are
                                made in the little experiment will reveal that perception is based on experience. Some will say
                                that red is the warmest color. It is even said to be hot by some. Others will say that yellow is the
                                warmest color, and still others will choose orange.
			                </p>
                            <h3 className="nino-sectionHeading">
                                Color and the Surface of a Painting
                            </h3>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author=""
                                    title=""
                                    src={`${WEBPAGE_URL_IMAGES}/article/7/5.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                                Paint on a canvas or paper will have an effect on the viewer’s eye that depends on how it is
                                applied. A broad or wide brush with lots of paint will give a rough quality to the surface of a
                                painting. From a distance, the colors will blend, and the appearance of the forms represented will
                                be quite different from colors applied with a fine brush loaded with very liquid paint. Achieving
                                the desired effect involves experimenting with various techniques of applying color. Different
                                media such as watercolor, pastel crayons, oil paint, acrylic paint all have their unique qualities.
			                </p>
                            <p className="nino-sectionDesc read-more-description">
                                Artists, as well as trying out the unlimited ways of applying color, spend considerable time
                                looking at works by other artists. They may find a painting in an art gallery that appeals to them.
                                They will inspect the surface up close to see how an artist has achieved specific effects. They
                                may even attempt to copy the work to master techniques of applying color.
			                </p>
                            <p className="nino-sectionDesc read-more-description">
                                Acquiring skills in the choice and application of color in art is a slow process. Every success will
                                lead to yet more successes.
			                </p>
                            {/* <Link to={`/${PAGE.SHOWTUTORIALS}`} className="action-btn" onClick={() => sendEventToGoogleAnalytics("Watch for free", "article")}></Link> */}
                            <Link to={`/${PAGE.SHOWTUTORIALS}`} className="action-btn" onClick={() => sendEventToGoogleAnalytics("Watch now", "article")}></Link>

                        </div>
                    </section>
                </article>
            </div>
        );
    }
}

export default ArticleHowColorWorksInArt;
