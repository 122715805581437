import React, { Component } from 'react';
import TeacherCard from "./TeacherCard";
import { getTeachersFromDb } from '../functions';


class TeachersContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teachers: []
    }
  }

  componentDidMount()
  {
    getTeachersFromDb(this);
  }

  render() {

    const teachersCards = this.state.teachers.map((e, index) => <TeacherCard key={index} teacher={e}></TeacherCard>);

    return (
      <section id="nino-ourTeam">
        <div className="container">
          <h2 className="nino-sectionHeading">
            Your instructor
                    </h2>
          {/* <p className="nino-sectionDesc">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
            ex ea commodo consequat.
                </p> */}
          <div className="sectionContent">
            <div className="row nino-hoverEffect">
              {teachersCards}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default TeachersContainer;
