import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import StripeCheckout from 'react-stripe-checkout';
import { sendTutorialPayment, getDiscountTimeLeft, isDiscount, getActualPrice } from '../../functions';
import { STRIPE_PK } from '../../globals';
import { stat } from 'fs';

class PaymentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      successMessage: "",
      warningMessage: "",
      inProgress: false
    }
    this.handleToken = this.handleToken.bind(this);
    this.isGuestBuyer = this.isGuestBuyer.bind(this);
  }
  isGuestBuyer() {
    if (this.props.match.params.guest === "true") {
      return true;
    }
    else {
      return false;
    }
  }
  handleToken(token) {
    sendTutorialPayment(token, this.props.user.login, this.props.tutorial.number, this.props.loginKey, this.isGuestBuyer(), this, this.state.actualPrice);
  }

  render() {
    const timeLeft = getDiscountTimeLeft(this.props.tutorial.discountEndTime);
    const is_discount = isDiscount(timeLeft);
    const actualPrice = getActualPrice(this.props.tutorial.price, this.props.tutorial.discountPrice, is_discount);

    return (

      <React.Fragment>
        {
          this.state.warningMessage &&
          <React.Fragment>
            <br />
            <br />
            <div className="messages-wrapper">
              <div className="validation-warning-box validation-warning-box-small nino-formInput"> {this.state.warningMessage} </div>
            </div>
          </React.Fragment>
        }
        {
          this.state.successMessage &&
          <React.Fragment>
            <br />
            <br />
            <div className="messages-wrapper">
              <div className="validation-success-box validation-success-box-small nino-formInput"> {this.state.successMessage} </div>
            </div>
          </React.Fragment>
        }
        {(!this.state.successMessage && !this.state.warningMessage) &&
          <div className="payment-card-wrapper">
            {this.state.inProgress ?
              <div className="form-loading-center">
                <Loader type="TailSpin" color="#95e1d3" height="150" width="150" />
              </div>
              :
              <StripeCheckout
                stripeKey={STRIPE_PK}
                token={this.handleToken}
                email={this.isGuestBuyer() ? null : this.props.user.email}
                description={"Price: " + (actualPrice) + "$"}
                // billingAddress
                // shippingAddress
                amount={actualPrice * 100}
                name={`Full course - ${this.props.tutorial.title}`}
              >
                <button className="pay-button" onClick={() => this.setState({
                  actualPrice: actualPrice
                })}>
                  Pay with card
                  </button>
              </StripeCheckout>
            }
          </div>
        }
      </React.Fragment>

    )
  }
}
export default PaymentForm;
