import React, { Component } from 'react';
import Header from "./Header.jsx";
import PaymentForm from "./Forms/PaymentForm";
import { getTutorialFromDb, getGuestAccessForFreeTutorial } from '../functions';
import { Link } from 'react-router-dom';
import { WEBPAGE_URL_IMAGES, EMAIL_MIN_LENGTH, EMAIL_MAX_LENGTH, NUMBER_OF_TUTORIAL_FOR_FREE } from '../globals.js';
import TimeLeft from './TimeLeft.jsx';
import Loader from 'react-loader-spinner';
import { LazyLoadImage } from 'react-lazy-load-image-component';

class Payments extends Component {

    constructor(props) {
        super(props)
        this.state = {
            tutorial: {},
            tutorialPurchased: false,
            email: '',
            warningMessage: "",
            successMessage: "",
            inProgress: false
        }
        this.setTutorialPurchased = this.setTutorialPurchased.bind(this);
        this.isTutorialForFree = this.isTutorialForFree.bind(this);
        this.handleUserInput = this.handleUserInput.bind(this);
        this.getGuestAccessForFree = this.getGuestAccessForFree.bind(this);
    }
    getGuestAccessForFree() {
        getGuestAccessForFreeTutorial(this.state.email, this);
    }
    isTutorialForFree() {
        return this.state.tutorial.number == NUMBER_OF_TUTORIAL_FOR_FREE;
    }
    setTutorialPurchased(tutorialPurchased) {
        this.setState({
            tutorialPurchased: tutorialPurchased
        });
    }
    handleUserInput(e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value });
    }

    componentDidMount() {
        getTutorialFromDb(this.props.match.params.number, this);
    }

    render() {

        const shoppingCart =
            <div id="shopping-cart">
                <h4>Your cart</h4>
                <hr />
                <div className="shopping-cart-image">
                    <Link to={"/course/" + this.state.tutorial.number}>
                        <LazyLoadImage className="course-img backgroundShadow"
                            src={`${WEBPAGE_URL_IMAGES}/tutorials/${this.state.tutorial.number}-small.webp`}
                            onError={(e) => { e.target.onerror = null; e.target.src = WEBPAGE_URL_IMAGES + "/tutorials/" + this.state.tutorial.number + "-small.jpg" }}
                            alt={this.state.tutorial.title} />

                    </Link>
                </div>

                <div className="shopping-cart-info">
                    <Link to={"/course/" + this.state.tutorial.number}>
                        <h5>{this.state.tutorial.title} - full course <br /> + 1 Free Review</h5>
                    </Link>
                    {!this.isTutorialForFree() &&
                        <TimeLeft
                            discountEndTime={this.state.tutorial.discountEndTime}
                            price={this.state.tutorial.price}
                            discountPrice={this.state.tutorial.discountPrice}
                            isPaymentForm={true}
                        ></TimeLeft>
                    }
                </div>
            </div>
        const openPurchasedTutorial =
            <Link to={"/player/" + this.state.tutorial.number}>
                <div id="open-purchased-tutorial">
                    <div>
                        <LazyLoadImage className="course-img" src={`${WEBPAGE_URL_IMAGES}/tutorials/${this.state.tutorial.number}-small.webp`} alt={this.state.tutorial.title} />
                    </div>
                    <div>
                        <h5>{this.state.tutorial.title}</h5>
                        <div className="green-btn">
                            Go to tutorial
                        </div>
                    </div>
                </div>
            </Link>
        return (
            <div>
                <Header {...this.props}></Header>
                <br />

                {
                    (this.state.tutorial.number) &&
                    <React.Fragment>
                        {this.state.tutorialPurchased ?
                            openPurchasedTutorial
                            :
                            shoppingCart
                        }
                        {this.isTutorialForFree() ?
                            <div className={"get-for-free-container"}>
                                <input value={this.props.email} name="email" minLength={EMAIL_MIN_LENGTH} maxLength={EMAIL_MAX_LENGTH} onChange={(event) => this.handleUserInput(event)} type="email" placeholder="Enter an email for tutorial access link" className="form-control" required />
                                {this.state.warningMessage &&
                                    <React.Fragment>
                                        <br />
                                        <div className="validation-warning-box"> {this.state.warningMessage} </div>
                                    </React.Fragment>
                                }
                                {this.state.successMessage &&
                                    <React.Fragment>
                                        <br />
                                        <div className="validation-success-box"> {this.state.successMessage} </div>
                                    </React.Fragment>
                                }
                                <br />
                                {this.state.inProgress ?
                                    <div className="form-loading-circle">
                                        <Loader type="TailSpin" color="#95e1d3" height="150" width="150" />
                                    </div>
                                    :
                                    <button className="pay-button" onClick={() => this.getGuestAccessForFree()}>
                                        Get for free
                                </button>
                                }
                            </div>
                            :
                            <PaymentForm {...this.state} {...this.props} setTutorialPurchased={this.setTutorialPurchased} />
                        }
                    </React.Fragment>
                }
                {!this.isTutorialForFree() && <React.Fragment>
                    <LazyLoadImage className="money-back-guarantee" src={WEBPAGE_URL_IMAGES + "/money-back-guarantee.png"}
                        alt="/money-back-guarantee" />
                    <LazyLoadImage className="stripe-security"
                        src={`${WEBPAGE_URL_IMAGES}/stripe-security.webp`}
                        onError={(e) => { e.target.onerror = null; e.target.src = WEBPAGE_URL_IMAGES + "/stripe-security.jpg" }}
                        alt="powered by stripe - security" />
                </React.Fragment>
                }

            </div>
        );
    }
}

export default Payments;
