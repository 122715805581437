import React, { Component } from 'react';
import { getDiscountTimeLeft, isDiscount, getActualPrice } from '../functions';

export class TimeLeft extends Component {
    constructor(props) {
        super(props);
        this.state = {
            timeLeftText: "",
            timeLeft: 0
        }
        this.updateTimeLeft = this.updateTimeLeft.bind(this);
    }
    updateTimeLeft() {
        var diff = getDiscountTimeLeft(this.props.discountEndTime);
        var msec = diff;
        var hh = Math.floor(msec / 1000 / 60 / 60);
        msec -= hh * 1000 * 60 * 60;
        var mm = Math.floor(msec / 1000 / 60);
        msec -= mm * 1000 * 60;
        var ss = Math.floor(msec / 1000);
        msec -= ss * 1000;
        var dd = Math.round(hh / 24);
        hh -= dd * 24;
        hh = (hh < 0 ? 0 : hh)
        mm = (mm < 0 ? 0 : mm)
        ss = (ss < 0 ? 0 : ss)
        mm = (mm < 10 ? '0' + mm : mm)
        ss = (ss < 10 ? '0' + ss : ss)
        if (dd == 0) {
            this.setState({
                timeLeftText: " " + hh + ":" + mm + ":" + ss,
                timeLeft: diff
            })
        }
        else if (dd == 1) {
            this.setState({
                timeLeftText: " " + dd + " day " + hh + ":" + mm + ":" + ss,
                timeLeft: diff
            })
        }
        else {
            this.setState({
                timeLeftText: " " + dd + " days " + hh + ":" + mm + ":" + ss,
                timeLeft: diff
            })
        }
    }

    intervalID = 0;
    componentDidMount() {
        this.intervalID = setInterval(() => this.updateTimeLeft(), 1000);
    }
    componentWillUnmount() {
        clearInterval(this.intervalID);
    }
    render() {
        const is_discount = isDiscount(this.state.timeLeft);
        return (
            <React.Fragment>
                {this.props.isPaymentForm ?
                    <React.Fragment>
                        {is_discount ?
                            <React.Fragment>
                                <span className="shopping-cart-normal">{getActualPrice(this.props.price, this.props.discountPrice, true)} $</span>
                                <span className="shopping-cart-discount">{this.props.price} $</span>
                                <br />
                            </React.Fragment>
                            :
                            <span className="shopping-cart-normal">{getActualPrice(this.props.price, this.props.discountPrice, false)} $</span>}
                    </React.Fragment>
                    :
                    <React.Fragment>
                        {is_discount ?
                            <React.Fragment>
                                <span className="price-normal">{getActualPrice(this.props.price, this.props.discountPrice, true)} $</span>
                                <span className="price-discount">{this.props.price} $</span>
                            </React.Fragment>
                            :
                            <span className="price-normal">{getActualPrice(this.props.price, this.props.discountPrice, false)} $</span>}
                    </React.Fragment>
                }

                {
                    (this.state.timeLeft > 0) &&
                    <React.Fragment>
                       {!this.props.isPaymentForm && <br/>}
                        <nobr>
                            <span className="price-discount-time">Discount time left:
                            {this.state.timeLeftText}
                            </span>
                        </nobr>
                    </React.Fragment>

                }
            </React.Fragment>

        );
    }
}

export default TimeLeft;
