import React, { Component } from 'react';
import Header from "./Header.jsx";
import { Link } from 'react-router-dom'
import { PAGE, WEBPAGE_URL_IMAGES } from '../globals';
import ImageModal from './ImageModal';
import { sendEventToGoogleAnalytics } from '../functions';
import { Helmet } from "react-helmet";

class ArticleHowToDrayAnEye extends Component {

    render() {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Digital Painting: Sign of Our Times</title>
                    <link rel="canonical" href="https://www.easyfacedraw.com/article-digital-painting" />
                    <meta name="description" content="Digital painting is a subcategory of digital art. It imitates a variety of traditional painting techniques with
                        the use of a computer, graphics tablet, stylus, and specialized software." />
                    <meta name="keywords" content="digital painting, computer drawing, drawing tutorial, video tutorials, how to draw" />
                </Helmet>
                <Header {...this.props} ></Header>
                <article>
                    <section id="nino-whatWeDo">
                        <div className="container">
                            <h1 className="nino-sectionHeading">
                                <span className="nino-subHeading big-font">Digital Painting: Sign of Our Times</span>
                            </h1>
                            <h2 className="article-author">
                                Claudia Preza
                                <br />
                                May 23, 2021
                                </h2>
                            <br />

                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author=""
                                    title="Example of a painting rendered by digital painting software"
                                    src={`${WEBPAGE_URL_IMAGES}/article/8/1.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Example of a painting rendered by digital painting software</p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                                Digital painting is a subcategory of digital art. It imitates a variety of traditional painting techniques with
                                the use of a computer, graphics tablet, stylus, and specialized software.
                            </p>
                            <h3 className="nino-sectionHeading">
                                Defining Digital Art
                            </h3>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="Živko Kondić"
                                    title="Untitled, 2012, digital painting."
                                    src={`${WEBPAGE_URL_IMAGES}/article/8/2.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Živko Kondić, Untitled, 2012, digital painting.</p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                                Digital art is an art stylistic movement consisting of art created and produced with the use of digital
                                technology. It refers to any type of art that is computer-generated, digitally manipulated, scanned, or
                                drawn with the help of multiple technologies. This type of art challenges the boundaries of traditional
                                mediums by incorporating the continuous and constant change of technological advancements and

                                innovations. Digital art encompasses various mediums such as painting, illustration, video, sculpture,
                                and graphic design.
                            </p>
                            <h3 className="nino-sectionHeading">
                                Defining Digital Painting
                            </h3>
                            <p className="nino-sectionDesc read-more-description">
                                Digital painting does not follow the rules of traditional painting. However, it simulates painting quite
                                closely. The main difference is that digitally created paintings are not limited by the material world.
                                There are various types of digital painting styles such as impressionism, realism, and watercolor. It
                                digitally reproduces the look of oils, acrylics, pastels, charcoal, pen, and even airbrushing. Additionally, it
                                allows artists access to colors and brushes through a convenient and portable manner.
                            </p>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author=""
                                    title="Example of a painting rendered by digital painting software."
                                    src={`${WEBPAGE_URL_IMAGES}/article/8/3.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Example of a painting rendered by digital painting software.</p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                                Digital painting differs from other forms of digital art as it does not involve a computer-rendered
                                graphics. it does not use controlled lines created by computer programs or mathematical models.
                                Instead, digital painting relies on artists to create the graphics themselves. Digital paintings are direct
                                creations from artists, that incorporate “blots” and “patches” to create a digital image. The two main
                                materials used to create digital paintings are a graphics tablet and painting software.
                            </p>
                            <h3 className="nino-sectionHeading">
                                A Brief History of Computer Created Art
                            </h3>
                            <p className="nino-sectionDesc read-more-description">
                                Since the 1950s, artists and designers have used analog computers and mechanical devices to produce
                                artworks. During the 1960s, since access to computers was limited as they were heavy and expensive,
                                artists focused on geometric forms as the available output was restrictive. Created in 1963 by Ivan
                                Sutherland, Sketchpad was the earliest graphics manipulation program. Sketchpad pioneered the
                                creation of the first tablet in 1968. By the 1970s, artists started to learn how to program themselves
                                rather than reaching out to computer programmers.
                            </p>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author=""
                                    title="Ivan Sutherland using Sketchpad."
                                    src={`${WEBPAGE_URL_IMAGES}/article/8/4.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Ivan Sutherland using Sketchpad.</p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                                In the 1980s, everyday life fully incorporated digital technology. The term digital art became popular
                                after being used to describe the paint program used by Harold Cohen, a pioneering digital artist.
                                Additionally, the early 1980s saw the development of one of the first digital painting software was
                                MacPaint, referred to as MacSketch and LisaSketch in its early iterations. By the 1990s, computer art
                                mixed traditional media with technology. Additionally, as the scope of medium expanded and
                                technology developed, artists were not exclusively labeled as digital painters.
                            </p>
                            <h3 className="nino-sectionHeading">
                                Graphic Tablets
                            </h3>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author=""
                                    title="Example of a graphics tablet."
                                    src={`${WEBPAGE_URL_IMAGES}/article/8/5.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Example of a graphics tablet.</p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                                A graphics tablet mimics a drawing pad and pencil through a touchpad and stylus. Tablets allow for
                                artists to paint as if using real drawing tools. The tablet’s touchpad translates the movement, pressure,
                                and tilt of a stylus into digital strokes to the screen. There are three types of drawing tablets: graphic,

                                display, and all-in-one. A simple graphics tablet has no screen and requires a connection to a computer
                                or laptop to see the image created. With a display tablet, artists draw directly on the screen, allowing for
                                a more intuitive way of creating an image. An all-in-one tablet does not need to connect to a laptop or
                                computer as it combines the characteristic of a tablet and computer.
                            </p>
                            <h3 className="nino-sectionHeading">
                                Digital Painting Software
                            </h3>
                            <p className="nino-sectionDesc read-more-description">
                                Digital painting software mimics the use of physical media through a variety of paint and brush effects.
                                Early commercial programs allowed users to design, draw, and manipulate objects. In the beginning,
                                early digital painting programs colored pixels individually. Today they are capable of blending and mixing
                                digital paints more naturally and organically. Additionally, they provide artists the ability to switch
                                between colors, textures, and brush type effortlessly.
                            </p>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="Carlos Lozano"
                                    title="Digital nude painting process."
                                    src={`${WEBPAGE_URL_IMAGES}/article/8/6.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Digital nude painting process by Carlos Lozano.</p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                                Through the varied brushes available in software, artists can easily change the size and shape to
                                simulate a variety of tips and effects. Furthermore, programs allow for the manipulation of the thickness
                                and consistency of digital paint, allow artists to achieve a painterly look. Most digital painting programs
                                use layers to create different elements of an image, making it easy to erase or remove details. Some
                                programs treat every brushstroke as a different element while others let them interact with each other,
                                closely imitating traditional mediums.
                            </p>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="Tyson Tan"
                                    title="Krita's version 4.0 stock brush presets."
                                    src={`${WEBPAGE_URL_IMAGES}/article/8/7.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Krita's version 4.0 stock brush presets. Image by Tyson Tan created in 2017.</p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                                The most popular software programs are Adobe Photoshop, SketchBook Pro, Krita, and Procreate. Other
                                options include Gimp, Clip Studio Paint Pro, Art Studio Pro, ArtRage, and Corel Painter.
                            </p>
                            <h3 className="nino-sectionHeading">
                                Real-Life Application
                            </h3>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="Elif Ayiter"
                                    title="The 3rd “Cemre”, 1997, Photoshop and Wacom tablet."
                                    src={`${WEBPAGE_URL_IMAGES}/article/8/8.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Elif Ayiter , The 3rd “Cemre”, 1997, Photoshop and Wacom tablet.</p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                                Digital painting allows artists to create fantastical and photorealistic settings through digital means.
                                There is an ease of creating imaginary creatures and worlds as digital innovation offers unlimited
                                mediums to use all in one place. Today, the entertainment world uses digital painting across multiple
                                disciplines such as webcomics, animated movies, video game graphics, and advertisements.
                            </p>
                            {/* <Link to={`/${PAGE.SHOWTUTORIALS}`} className="action-btn" onClick={() => sendEventToGoogleAnalytics("Watch for free", "article")}></Link> */}
                            <Link to={`/${PAGE.SHOWTUTORIALS}`} className="action-btn" onClick={() => sendEventToGoogleAnalytics("Watch now", "article")}></Link>

                        </div>
                    </section>
                </article>
            </div>
        );
    }
}

export default ArticleHowToDrayAnEye;
