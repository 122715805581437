import React, { Component } from 'react';
import TutorialsOverview from "./TutorialsOverview";
import Countings from "./Countings";
import TeachersContainer from "./TeachersContainer";
import PhotosGalery from "./PhotosGalery";
import HorizontalSlidePanel from "./HorizontalSlidePanel";
import Comments from "./Comments.jsx";
import Header from "./Header.jsx";
import HeaderCarousel from "./HeaderCarousel";
import { getTutorialsFromDb, sendEventToGoogleAnalytics } from '../functions';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";

class Home extends Component {

    componentDidMount() {
        getTutorialsFromDb(this);
        document.onscroll = () => {
            if (document.documentElement.scrollTop > 600) {
                this.setState({
                    showBottomCallToAction: true,
                })
            }
            else {
                this.setState({
                    showBottomCallToAction: false,
                })
            }
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            showBottomCallToAction: false,
            tutorials: [{
                "_id": {
                    "$oid": "5d4981047c213e60b8ec11c3"
                },
                "number": 1,
                "purchased": 0,
                "difficulty": "beginners",
                "title": "How to draw a nose?",
                "description": "In this tutorial, you will learn how to draw a nose. I will tell you everything you need to know about a human nose: how its anatomy can be simplified to forms and lines, and how to draw it without a reference. Once you finish the tutorial you will have the opportunity to contact the tutor to discuss your work.",
                "videos": [
                    {
                        "fileName": "t_1_p_1",
                        "freeForEveryone": true,
                        "title": "Introduction",
                        "time": "5:23"
                    },
                    {
                        "fileName": "t_1_p_2",
                        "freeForEveryone": false,
                        "title": "title2 lorem ipsun",
                        "time": "7:08"
                    },
                    {
                        "fileName": "t_1_p_3",
                        "freeForEveryone": true,
                        "title": "title3 lorem ipsun",
                        "time": "11:13"
                    },
                    {
                        "fileName": "t_1_p_4",
                        "freeForEveryone": false,
                        "title": "title4 lorem ipsun",
                        "time": "5:23"
                    },
                    {
                        "fileName": "t_1_p_5",
                        "freeForEveryone": false,
                        "title": "title5 lorem ipsun",
                        "time": "7:08"
                    },
                    {
                        "fileName": "t_1_p_6",
                        "freeForEveryone": false,
                        "title": "title3 lorem ipsun",
                        "time": "11:13"
                    }
                ],
                "whatCanYouLearn": [
                    "Jak rysować zgodnie z zasadami perspektywy, z odpowiednim światłocieniem i podstawowymi zasadami rysunku.",
                    "Jak rysować BEZ komputera czyli od czego zacząc przygodę z rysunkiem odręcznym.",
                    "Jakie ćwiczenia ręki wykonywać żeby rysować pewnej, lepiej i efektywniej.",
                    "Jak rysować NA tablecie wykorzystując narzędzia w Photoshopie."
                ],
                "price": 3.99,
                "discountPrice": 8.99,
                "modificationDate": "06/26/2017",
                "content": [
                    "4.5 video hours",
                    "6 extra downloadable content",
                    "Lifetime access",
                    "Our teacher result review",
                    "Completion certificate"
                ],
                "views": 0,
                "rating": 3.5,
                "hours": "4.5",
                "commentsCount": 30
            },
            {
                "_id": {
                    "$oid": "5d4981237c213e60b8ec11cb"
                },
                "number": 2,
                "purchased": 0,
                "difficulty": "beginners",
                "title": "How to draw an eye?",
                "description": "In this tutorial, you will learn how to draw an eye without a reference. Drawing an eye can be difficult for beginners but it doesn't mean you can't do it! It may take a few tries to get right, so keep trying! Once you finish the tutorial you will have the opportunity to contact the tutor to discuss your work.",
                "videos": [
                    {
                        "fileName": "t_2_p_1",
                        "freeForEveryone": "true",
                        "title": "title1",
                        "time": "5:23"
                    },
                    {
                        "fileName": "t_2_p_2",
                        "freeForEveryone": "false",
                        "title": "title2",
                        "time": "7:08"
                    },
                    {
                        "fileName": "t_2_p_3",
                        "freeForEveryone": "false",
                        "title": "title3",
                        "time": "11:13"
                    }
                ],
                "whatCanYouLearn": [
                    "Jak rysować zgodnie z zasadami perspektywy, z odpowiednim światłocieniem i podstawowymi zasadami rysunku.",
                    "Jak rysować BEZ komputera czyli od czego zacząc przygodę z rysunkiem odręcznym.",
                    "Jakie ćwiczenia ręki wykonywać żeby rysować pewnej, lepiej i efektywniej.",
                    "Jak rysować NA tablecie wykorzystując narzędzia w Photoshopie."
                ],
                "price": 5.99,
                "discountPrice": 9.99,
                "modificationDate": "06/26/2019",
                "content": [
                    "4.5 video hours",
                    "6 extra downloadable content",
                    "Lifetime access",
                    "Our teacher result review",
                    "Completion certificate"
                ],
                "views": 0,
                "rating": 4,
                "hours": "4.5",
                "commentsCount": 3
            },
            {
                "_id": {
                    "$oid": "5d4981467c213e60b8ec11d3"
                },
                "is_new": true,
                "number": 3,
                "purchased": 0,
                "difficulty": "beginners",
                "title": "How to draw a lips?",
                "description": "In this tutorial you will learn how to properly draw lips, obtaining depth and volume. (I hope that) after watching it you'll be able to draw realistic lips from scratch! Knowing how to do it is a useful skill to have, especially if you like doing portraits. Once you finish the tutorial you will have the opportunity to contact the tutor to discuss your work.",
                "videos": [
                    {
                        "fileName": "t_3_p_1",
                        "freeForEveryone": "true",
                        "title": "title1",
                        "time": "5:23"
                    },
                    {
                        "fileName": "t_3_p_2",
                        "freeForEveryone": "false",
                        "title": "title2",
                        "time": "7:08"
                    },
                    {
                        "fileName": "t_3_p_3",
                        "freeForEveryone": "false",
                        "title": "title3",
                        "time": "11:13"
                    }
                ],
                "whatCanYouLearn": [
                    "Jak rysować zgodnie z zasadami perspektywy, z odpowiednim światłocieniem i podstawowymi zasadami rysunku.",
                    "Jak rysować BEZ komputera czyli od czego zacząc przygodę z rysunkiem odręcznym.",
                    "Jakie ćwiczenia ręki wykonywać żeby rysować pewnej, lepiej i efektywniej.",
                    "Jak rysować NA tablecie wykorzystując narzędzia w Photoshopie."
                ],
                "price": 6.99,
                "discountPrice": 9.99,
                "modificationDate": "06/26/2018",
                "content": [
                    "4.5 video hours",
                    "6 extra downloadable content",
                    "Lifetime access",
                    "Our teacher result review",
                    "Completion certificate"
                ],
                "views": 0,
                "rating": 1,
                "hours": "3.5",
                "commentsCount": 1
            },
            {
                "_id": {
                    "$oid": "5de62821e7179a0d18de0175"
                },
                "number": 4,
                "purchased": 0,
                "difficulty": "beginners",
                "title": "How to draw a hair?",
                "description": "In this tutorial, you will learn how to draw hair. Drawing hair can be difficult for beginners, but I'll teach you methods to better deal with this subject. If you're struggling with hair, practice will help you overcome the challenges. Once you finish the tutorial you will have the opportunity to contact the tutor to discuss your work.",
                "videos": [
                    {
                        "fileName": "t_4_p_1",
                        "freeForEveryone": true,
                        "title": "Introduction",
                        "time": "5:23"
                    },
                    {
                        "fileName": "t_4_p_2",
                        "freeForEveryone": false,
                        "title": "title2 lorem ipsun",
                        "time": "7:08"
                    },
                    {
                        "fileName": "t_4_p_3",
                        "freeForEveryone": true,
                        "title": "title3 lorem ipsun",
                        "time": "11:13"
                    },
                    {
                        "fileName": "t_4_p_4",
                        "freeForEveryone": false,
                        "title": "title4 lorem ipsun",
                        "time": "5:23"
                    },
                    {
                        "fileName": "t_4_p_5",
                        "freeForEveryone": false,
                        "title": "title5 lorem ipsun",
                        "time": "7:08"
                    },
                    {
                        "fileName": "t_4_p_6",
                        "freeForEveryone": false,
                        "title": "title3 lorem ipsun",
                        "time": "11:13"
                    }
                ],
                "whatCanYouLearn": [
                    "Jak rysować zgodnie z zasadami perspektywy, z odpowiednim światłocieniem i podstawowymi zasadami rysunku.",
                    "Jak rysować BEZ komputera czyli od czego zacząc przygodę z rysunkiem odręcznym.",
                    "Jakie ćwiczenia ręki wykonywać żeby rysować pewnej, lepiej i efektywniej.",
                    "Jak rysować NA tablecie wykorzystując narzędzia w Photoshopie."
                ],
                "price": 3.99,
                "discountPrice": 8.99,
                "modificationDate": "06/26/2017",
                "content": [
                    "4.5 video hours",
                    "6 extra downloadable content",
                    "Lifetime access",
                    "Our teacher result review",
                    "Completion certificate"
                ],
                "views": 0,
                "rating": 3.5,
                "hours": "4.5",
                "commentsCount": 30
            },
            {
                "_id": {
                    "$oid": "5de62874e7179a0d18de0182"
                },
                "is_new": "true",
                "number": 5,
                "purchased": 0,
                "difficulty": "beginners",
                "title": "How to draw a ear?",
                "description": "In this tutorial, you will learn how to draw an ear from a side view. It includes examples for each step going from basic construction lines to a pencil-shaded drawing. Drawing the ear can be a little bit confusing. If you find yourself struggling don\u2019t be discouraged, keep practicing. Once you finish the tutorial you will have the opportunity to contact the tutor to discuss your work.",
                "videos": [
                    {
                        "fileName": "t_5_p_1",
                        "freeForEveryone": true,
                        "title": "Introduction",
                        "time": "5:23"
                    },
                    {
                        "fileName": "t_5_p_2",
                        "freeForEveryone": false,
                        "title": "title2 lorem ipsun",
                        "time": "7:08"
                    },
                    {
                        "fileName": "t_5_p_3",
                        "freeForEveryone": true,
                        "title": "title3 lorem ipsun",
                        "time": "11:13"
                    },
                    {
                        "fileName": "t_5_p_4",
                        "freeForEveryone": false,
                        "title": "title4 lorem ipsun",
                        "time": "5:23"
                    },
                    {
                        "fileName": "t_5_p_5",
                        "freeForEveryone": false,
                        "title": "title5 lorem ipsun",
                        "time": "7:08"
                    },
                    {
                        "fileName": "t_5_p_6",
                        "freeForEveryone": false,
                        "title": "title3 lorem ipsun",
                        "time": "11:13"
                    }
                ],
                "whatCanYouLearn": [
                    "Jak rysować zgodnie z zasadami perspektywy, z odpowiednim światłocieniem i podstawowymi zasadami rysunku.",
                    "Jak rysować BEZ komputera czyli od czego zacząc przygodę z rysunkiem odręcznym.",
                    "Jakie ćwiczenia ręki wykonywać żeby rysować pewnej, lepiej i efektywniej.",
                    "Jak rysować NA tablecie wykorzystując narzędzia w Photoshopie."
                ],
                "price": 3.99,
                "discountPrice": 8.99,
                "modificationDate": "06/26/2017",
                "content": [
                    "4.5 video hours",
                    "6 extra downloadable content",
                    "Lifetime access",
                    "Our teacher result review",
                    "Completion certificate"
                ],
                "views": 0,
                "rating": 3.5,
                "hours": "4.5",
                "commentsCount": 30
            },
            {
                "_id": {
                    "$oid": "5de628b3e7179a0d18de0185"
                },
                "number": 6,
                "purchased": 0,
                "difficulty": "beginners",
                "title": "How to draw a face?",
                "description": "In this tutorial, you will learn how to draw a face. It is essential for the beginner artist to learn about facial proportions and effective placement of facial elements before moving on to a more realistic drawing style. I use a step by step guided process to teach you the skills you need to draw flawlessly. Once you finish the tutorial you will have the opportunity to contact the tutor to discuss your work.",
                "videos": [
                    {
                        "fileName": "t_6_p_1",
                        "freeForEveryone": true,
                        "title": "Introduction",
                        "time": "5:23"
                    },
                    {
                        "fileName": "t_6_p_2",
                        "freeForEveryone": false,
                        "title": "title2 lorem ipsun",
                        "time": "7:08"
                    },
                    {
                        "fileName": "t_6_p_3",
                        "freeForEveryone": true,
                        "title": "title3 lorem ipsun",
                        "time": "11:13"
                    },
                    {
                        "fileName": "t_6_p_4",
                        "freeForEveryone": false,
                        "title": "title4 lorem ipsun",
                        "time": "5:23"
                    },
                    {
                        "fileName": "t_6_p_5",
                        "freeForEveryone": false,
                        "title": "title5 lorem ipsun",
                        "time": "7:08"
                    },
                    {
                        "fileName": "t_6_p_6",
                        "freeForEveryone": false,
                        "title": "title3 lorem ipsun",
                        "time": "11:13"
                    }
                ],
                "whatCanYouLearn": [
                    "Jak rysować zgodnie z zasadami perspektywy, z odpowiednim światłocieniem i podstawowymi zasadami rysunku.",
                    "Jak rysować BEZ komputera czyli od czego zacząc przygodę z rysunkiem odręcznym.",
                    "Jakie ćwiczenia ręki wykonywać żeby rysować pewnej, lepiej i efektywniej.",
                    "Jak rysować NA tablecie wykorzystując narzędzia w Photoshopie."
                ],
                "price": 3.99,
                "discountPrice": 8.99,
                "modificationDate": "06/26/2017",
                "content": [
                    "4.5 video hours",
                    "6 extra downloadable content",
                    "Lifetime access",
                    "Our teacher result review",
                    "Completion certificate"
                ],
                "views": 0,
                "rating": 3.5,
                "hours": "4.5",
                "commentsCount": 30
            }],
            comments: [
                {
                    name: "Emma",
                    surname: "van der Kooij",
                    rating: 5,
                    date: "1583033110504",
                    img: "13332878_1745423599037753_5410044909154124661_n.jpg",
                    comment: '"The tutorial was nice to watch and had a comforting vibe, the music was nice to listen to while drawing. I like that the video is divided into topics. It makes it really easy to rewatch a certain step. I think it’s a good video for beginners."'
                }
                ,
                {
                    name: "Cassandra",
                    surname: "Leigh",
                    rating: 5,
                    date: "1583033110504",
                    img: "292855_1942949054102_1084862470_n.jpg",
                    comment: '"The tutorial was great, I tried to forget everything and not want to do other things I’ve learned along the way... lol!"'
                }
                // ,
                // {
                //   name: "Jon",
                //   surname: "Doe",
                //   rating: 5,
                //   date: "1562902110504",
                //   img: "img-3.jpg",
                //   comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation."
                // },
                // {
                //   name: "Jon",
                //   surname: "Doe",
                //   rating: 3,
                //   date: "1562512110504",
                //   img: "img-4.jpg",
                //   comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation."
                // }
            ]
        }
    }

    render() {
        // const mostPopularTutorials = this.state.tutorials.sort((a, b) => a.rating > b.raintg).slice(0, 6);
        // const otherTutorials = this.state.tutorials.sort((a, b) => a.rating > b.raintg).slice(3, 6);
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Step-by-Step Drawing Tutorials for Realistic Face Sketching</title>
                    <link rel="canonical" href="https://www.easyfacedraw.com/" />
                    <meta name="description" content="Gather your art supplies, and learn how to sketch a face with our easy drawing tutorials for beginners." />
                    <meta name="keywords" content="drawing tutorials, how to draw, face sketch, draw online, eyes, nose, ears, lips, hair" />
                </Helmet>
                <Header isH1={true} {...this.props}>
                    <HeaderCarousel></HeaderCarousel>
                </Header>
                <TutorialsOverview tutorials={this.state.tutorials} scrollToTutorials={this.props.scrollToTutorials}>
                    {/* Most popular tutorials */}
                    Tutorials
        </TutorialsOverview>
                {/* <TutorialsOverview tutorials={otherTutorials}>
          Other tutorials
        </TutorialsOverview> */}
                {/* <Countings>
          <span>42</span>
          <span>Drawing tutorials</span>
          <span>4532</span>
          <span>Happy clients</span>
          <span>1500</span>
          <span>Daily visits</span>
          <span>5312</span>
          <span>Used pencils</span>
          <span>9</span>
          <span>Teachers</span>
        </Countings> */}
                <section id="nino-ourTeam">
                    <div className="container what-people-say">
                        <h2 className="nino-sectionHeading">
                            what people say
                        </h2>

                        <p> <span>“</span> I am most impressed with this Drawing site! It has well-paced lessons that are professionally filmed and evocative of university drawing lessons. The voiceover is not patronising, but gives clear, intelligent instruction for classic drawing technique. I also appreciate the background of art history provided. Many similar sites promise the world if only you pay to join, but I can listen to very little because the voice and pushy sales technique irritate me. This site treats you with respect and I would wholeheartedly recommend it.</p>
                        <p>---  Jenny Lishman  ---</p>
                        <p><a href="https://www.facebook.com/groups/309827932557849">Art Resources for Visual Art Teachers  <i className="mdi mdi-facebook"></i></a></p>
                    </div>
                    <br />
                    <br />
                </section>
                <TeachersContainer></TeachersContainer>
                <PhotosGalery></PhotosGalery>
                {/* <HorizontalSlidePanel items={this.state.people}></HorizontalSlidePanel> */}
                <Comments comments={this.state.comments}>
                    <h2 className="nino-sectionHeading">
                        Happy Students
		    	</h2>
                </Comments>
                {this.state.showBottomCallToAction &&
                    <div className="footer-call-to-action">
                        {/* <Link to={`/player/2`} onClick={() => sendEventToGoogleAnalytics("Watch for free", "footer")} className="action-btn"></Link> */}
                        <Link to={`/player/2`} onClick={() => sendEventToGoogleAnalytics("Watch now", "footer")} className="action-btn"></Link>

                    </div>
                }
            </div>
        );
    }
}

export default Home;
