import React, { Component } from 'react';

class StarsRating extends Component {
    render() {
        if(this.props.rating)
        {
            let stars = [];
            for (let index = 0; index < 5; index++) {
                if (index < this.props.rating) {
                    stars.push(<span key={index} className={"glyphicon glyphicon-star " + (this.props.bigStars ? "large-star" : "medium-star")} aria-hidden="true"></span>)
                }
                else {
                    stars.push(<span key={index} className={"glyphicon glyphicon-star-empty " + (this.props.bigStars ? "large-star" : "medium-star")} aria-hidden="true"></span>)
                }
            }
            return (
                (this.props.bigStars ? 
                <div>
                    <span className="rating-number">{this.props.rating}   </span>
                    {stars}
                    {this.props.ratingsCount && <span className="ratings-count">({this.props.ratingsCount} ratings)</span>}
                </div>
                :
                <div>
                    {stars}
                </div>
                )
            );
        }
        else
        {
            return(
                <React.Fragment></React.Fragment>
            )
        }
        
    }
}

export default StarsRating;
