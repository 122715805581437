import React, { Component } from 'react';
import axios from 'axios';
import { SERVER_URL } from '../../globals';
import { isAdmin, getAdminAccessKey, sendEventToGoogleAnalytics } from '../../functions';
const CLAUSES_ARE_ACCEPTED_KEY = "CLAUSES_ARE_ACCEPTED_KEY";

const initialState = {
    login: '',
    email: '',
    password: '',
    repeatPassword: '',
    oldPassword: '',
    warningMessage: '',
    successMessage: '',
    resetPasswordFormOpen: false,
    freezeForm: false,
    passwordVisible: false,
    secondPasswordVisible: false,
    createAnAccountButtonClicked: false,
    allClausesAreAccepted: false,
    showClausesForm: false,
    buttonSubmitClauesDisabled: true

}
let CLAUSES_TO_BE_ACCEPTED = [
    { name: "accepted_rules_and_regulations", warning: false, text: "I	hereby	accept	the	rules	and	regulations	of	the	Website	www.quizony.com" },
    { name: "accepted_privacy_policy", warning: false, text: "I hereby accept the Privacy Policy of the Website www.easyfacedraw.com." },
    { name: "accepted_decisions", warning: false, text: "I	 hereby	 consent	 that	 Quizony	 spółka	 z	 ograniczoną	 odpowiedzialnością	 spółka	 komandytowa,	ul.	Krucza	5/11,	00-548	Warsaw,	Poland,	to	make	decisions	based	on	automated	 processing,	 including	 profiling	 based	 on	 my	 preferential	 data	 and	 other	 data	 provided	 by	 me	 during	the	configuration	of	the	Account	on	the	Website	and	when	using	the	Services.	" },
    { name: "accepted_direct_marketing", warning: false, text: "I	 hereby	 consent	 being	 directed	with	 direct	marketing	 by	Quizony	 spółka	 z	 ograniczoną	odpowiedzialnością	spółka	komandytowa,	ul.	Krucza	5/11,	00-548	Warsaw,	Poland,	with	the	use	of	telecommunication	end	devices	(e.g.	telephone)	and	automatic	calling	systems." },
    { name: "accepted_comercial_information", warning: false, text: "I hereby consent that Quizony spółka z ograniczoną odpowiedzialnością spółka komandytowa, ul. Krucza 5/11, 00-548 Warsaw, Poland (hereinafter: “Administrator”) sends messages containing commercial information about the Administrator to the e-mail address provided during login." }]

const clausesState = CLAUSES_TO_BE_ACCEPTED.reduce((acc, item) => {
    acc[item.name] = false;
    return acc;
}, {})

class FormController extends Component {

    constructor(props) {
        super(props);
        this.state = { ...initialState, clauses: clausesState };
        this.handleUserInput = this.handleUserInput.bind(this);
        this.validateRepeatPassword = this.validateRepeatPassword.bind(this);
        this.handelButtonSubmit = this.handelButtonSubmit.bind(this);
        this.handelButtonSubmitClaues = this.handelButtonSubmitClaues.bind(this);
        this.closeAndResetForm = this.closeAndResetForm.bind(this);
        this.openResetPasswordForm = this.openResetPasswordForm.bind(this);
        this.logInGoogleSuccess = this.logInGoogleSuccess.bind(this);
        this.logInGoogleFailure = this.logInGoogleFailure.bind(this);
        this.switchPasswordVisibility = this.switchPasswordVisibility.bind(this);
        this.switchSecondPasswordVisibility = this.switchSecondPasswordVisibility.bind(this);
        this.createAnAccountButtonClick = this.createAnAccountButtonClick.bind(this);
        this.chekcBoxClick = this.chekcBoxClick.bind(this);
        this.allClausesAreAccepted = this.allClausesAreAccepted.bind(this);
        this.hideClasusesForm = this.hideClasusesForm.bind(this);
        this.googleBtnClick = this.googleBtnClick.bind(this);
        this.checkAcceptAllClick = this.checkAcceptAllClick.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }
    handleScroll = (e) => {
        const bottom = (e.target.scrollHeight - e.target.scrollTop) < e.target.clientHeight + 40;
        if (bottom) {
            this.setState({
                "buttonSubmitClauesDisabled": false
            })
        }
    }
    googleBtnClick(event, googleLogInFunction) {
        if (!sessionStorage.getItem(CLAUSES_ARE_ACCEPTED_KEY)) {
            this.setState({
                showClausesForm: true
            })
            this.forceUpdate()
        }
        else {
            googleLogInFunction();
        }
    }
    handelButtonSubmitClaues() {
        if (this.allClausesAreAccepted()) {
            this.hideClasusesForm(true);
        }
        else {

            for (let index = 0; index < Object.keys(this.state.clauses).length; index++) {
                let key = Object.keys(this.state.clauses)[index];
                CLAUSES_TO_BE_ACCEPTED[index]["warning"] = !this.state.clauses[key];
            }
            this.forceUpdate();
        }
    }

    hideClasusesForm(clausesAreAccepted = false) {
        if (clausesAreAccepted) {
            sessionStorage.setItem(CLAUSES_ARE_ACCEPTED_KEY, true);
        }
        this.setState({
            showClausesForm: false
        })
    }

    chekcBoxClick(inputName) {
        CLAUSES_TO_BE_ACCEPTED.forEach(e => {
            if (e["name"] === inputName) {
                e["warning"] = false;
            }
        })
        console.table(CLAUSES_TO_BE_ACCEPTED)
        var clauses = this.state.clauses;
        clauses[inputName] = !clauses[inputName];
        this.setState({
            clauses: clauses
        })
    }
    checkAcceptAllClick(event) {
        CLAUSES_TO_BE_ACCEPTED.forEach(e => {
            e["warning"] = false;
        })
        var clauses = this.state.clauses;
        Object.keys(clauses).forEach(v => clauses[v] = event.target.checked)

        this.setState({
            clauses: clauses
        })
    }
    allClausesAreAccepted() {
        return Object.values(this.state.clauses).every(e => e === true);
    }
    switchPasswordVisibility() {
        this.setState({
            passwordVisible: !this.state.passwordVisible
        })
    }
    switchSecondPasswordVisibility() {
        this.setState({
            secondPasswordVisible: !this.state.secondPasswordVisible
        })
    }
    createAnAccountButtonClick() {
        this.setState({
            createAnAccountButtonClicked: true
        })
    }

    logInGoogleSuccess(response) {
        axios.post(SERVER_URL + "loginuser", {
            user: response
        }).then(e => {
            if (e.data.done) {
                sendEventToGoogleAnalytics("user", "loginuser-success", "google")
                this.setState({
                    freezeForm: false,
                    successMessage: e.data.message,
                    warningMessage: ""
                });
                if (this.props.logInUser) {
                    this.props.logInUser(e.data.loginKey, e.data.user);
                }
            }
            else {
                this.setState({
                    freezeForm: false,
                    warningMessage: e.data.message,
                    successMessage: ""
                });
            }
        })
    }
    logInGoogleFailure(response) {

    }

    validation() {
        return {
            "password": this.validatePassword,
            "repeatPassword": this.validateRepeatPassword,
            "email": this.validateEmail,
            "login": this.validateLogin
        };
    }

    handleUserInput(e) {
        if (!this.state.freezeForm) {
            const name = e.target.name;
            const value = e.target.value;
            this.setState({ [name]: value });
        }
    }

    openResetPasswordForm() {
        this.setState({
            ...initialState,
            resetPasswordFormOpen: true
        });
    }

    handelButtonSubmit(e, url, doAfterSubmit) {
        if (url === "registeruser" && !sessionStorage.getItem(CLAUSES_ARE_ACCEPTED_KEY)) {
            this.setState({
                showClausesForm: true
            })
        }
        else {
            e.preventDefault();
            let warningMessage = "";
            for (var property in this.validation()) {
                if (this.validation().hasOwnProperty(property)) {
                    warningMessage = this.validation()[property] ? this.validation()[property](this.state[property]) : "";
                }
                if (warningMessage != "") {
                    break;
                }
            }
            this.setState({ warningMessage: warningMessage });
            if (warningMessage == "") {
                this.setState({ freezeForm: true });
                let user = this.state;
                if (this.props.match.params.key) {
                    user.key = this.props.match.params.key;
                }
                if (this.props.match.params.email) {
                    user.email = this.props.match.params.email;
                }
                if (this.props.user.login) {
                    user.login = this.props.user.login;
                }
                axios.post(SERVER_URL + url, {
                    user: this.state
                }).then(e => {
                    if (e.data.done) {
                        sendEventToGoogleAnalytics("user", url + "-success", "normal-efd")
                        this.setState({
                            freezeForm: false,
                            successMessage: e.data.message,
                            warningMessage: ""
                        });
                        if (this.props.logInUser) {
                            this.props.logInUser(e.data.loginKey, e.data.user);
                        }
                        if (doAfterSubmit) {
                            setTimeout(() => {
                                doAfterSubmit();
                            }, 1000);
                        }
                    }
                    else {
                        this.setState({
                            freezeForm: false,
                            warningMessage: e.data.message,
                            successMessage: ""
                        });
                    }
                }).then(
                    () => {
                        getAdminAccessKey(this.props.appObject, this.props.user.login, this.props.loginKey).then(() => {
                            isAdmin(this.props.user.login, this.props.loginKey, this.props.adminAccessKey, this.props.appObject);
                        })
                    }
                );

            }
        }

    }

    closeAndResetForm() {
        this.props.closeForms();
        this.setState(initialState);
    }

    validateLogin(value) {
        // if (value.length < 8)
        //     return "Login too short.";
        // if (value.length > 50)
        //     return "Login too long.";
        return "";
    }

    validateEmail(value) {
        // if (value.includes("@"))
        //     return 'Missing "@" in email adress.';
        return "";
    }

    validatePassword(value) {
        // if (value.length < 8)
        //     return "Password too short.";
        return "";
    }

    validateRepeatPassword(value) {
        if (value.length > 0 && value != this.state.password)
            return "Passwords are not the same.";
        return "";
    }

    render() {
        return (
            <div>
                {React.cloneElement(this.props.children,
                    {
                        ...this.state, openResetPasswordForm: this.openResetPasswordForm,
                        closeForms: this.props.closeForms,
                        handleUserInput: this.handleUserInput,
                        handelButtonSubmit: this.handelButtonSubmit,
                        handelButtonSubmitClaues: this.handelButtonSubmitClaues,
                        closeAndResetForm: this.closeAndResetForm,
                        logInGoogleSuccess: this.logInGoogleSuccess,
                        logInGoogleFailure: this.logInGoogleFailure,
                        switchPasswordVisibility: this.switchPasswordVisibility,
                        switchSecondPasswordVisibility: this.switchSecondPasswordVisibility,
                        createAnAccountButtonClick: this.createAnAccountButtonClick,
                        allClausesAreAccepted: this.allClausesAreAccepted,
                        chekcBoxClick: this.chekcBoxClick,
                        hideClasusesForm: this.hideClasusesForm,
                        handleScroll: this.handleScroll,
                        googleBtnClick: this.googleBtnClick,
                        checkAcceptAllClick: this.checkAcceptAllClick,
                        clausesToBeAccepted: CLAUSES_TO_BE_ACCEPTED
                    })}
            </div>
        );
    }
}

export default FormController;
