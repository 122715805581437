import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App.jsx';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import rootReducer from './Reducers';
import * as serviceWorker from './serviceWorker';
// let initialStore = {
//    itemId: 1
// };

// const store = createStore(rootReducer, initialStore);

ReactDOM.render((
  <BrowserRouter>
    {/* <Provider store={store}> */}
    <App />
    {/* </Provider> */}
  </BrowserRouter>
), document.getElementById('root'));

// serviceWorker.register();