import React, { Component } from 'react';
import {
    uploadImageForReview,
    getReviewsForUsers,
    dateWithoutHour,
    getImageForReviewUrl,
    uploadImageForReviewFreeForEveryone
} from '../functions';
import ImageModal from './ImageModal';
import ModalPopUp from './ModalPopUp';

import { WEBPAGE_URL, LOGIN_REVIEW_FREE_FOR_EVERYONE } from '../globals';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const MAX_FILE_SIZE = 15;

const initialState = {
    imgPreviewSrc: null,
    selectedFile: null,
    warningMessage: '',
    successMessage: '',
    inProgress: false,
    reviewsForUser: undefined,
    numberOfAvailableReviews: 0,
    getReviewsRequestDone: false,
    uploadProgress: 0,
    showModalPopUp: false
}

class ReviewImageForm extends Component {
    constructor(props) {
        super(props);
        this.readURL = this.readURL.bind(this);
        this.state = initialState;
        this.deletePreviewImage = this.deletePreviewImage.bind(this);
        this.uploadHandler = this.uploadHandler.bind(this);
        this.getNumberOfAvailableReviews = this.getNumberOfAvailableReviews.bind(this);
    }
    readURL(files) {

        let that = this;
        if (files && files[0]) {
            if ((files[0].size / (1024 * 1024)) > MAX_FILE_SIZE) {
                alert('Image size is too big. Use smaller than ' + MAX_FILE_SIZE + ' mb.')
            }
            else {
                var reader = new FileReader();
                reader.onload = function (e) {
                    that.setState({
                        imgPreviewSrc: e.target.result,
                        selectedFile: files[0]
                    });
                };
                reader.readAsDataURL(files[0]);
            }
        }
    }
    uploadHandler() {
        if (this.props.tutorial.isReviewFreeForEveryone) {
            uploadImageForReviewFreeForEveryone(this, this.state.selectedFile, this.props.tutorial.number);
        }
        else {
            uploadImageForReview(this, this.state.selectedFile, this.props.user.login,
                this.props.user.name, this.props.user.surname, this.props.tutorialAccessKey,
                this.props.tutorial.number, this.props.isGuest);
        }
    }

    deletePreviewImage() {
        this.setState({
            imgPreviewSrc: null,
            selectedFile: null
        });
    }

    getNumberOfAvailableReviews() {
        return (this.props.isLogged || this.props.isGuest) ? this.state.numberOfAvailableReviews :
            ((this.props.tutorial.isReviewFreeForEveryone && !localStorage.getItem(LOGIN_REVIEW_FREE_FOR_EVERYONE)) ? 1 : 0)
    }

    componentDidMount() {
        const loginReviewFreeForEveryone = localStorage.getItem(LOGIN_REVIEW_FREE_FOR_EVERYONE);
        if (this.props.user.login &&
            this.props.tutorialAccessKey &&
            this.props.tutorial.number &&
            !this.state.getReviewsRequestDone) {
            getReviewsForUsers(this.props.user.login, this.props.tutorialAccessKey, this.props.tutorial.number, this.props.isGuest, this);
        }
        else if( loginReviewFreeForEveryone && this.props.tutorial.number && !this.state.getReviewsRequestDone)
        {
            getReviewsForUsers(loginReviewFreeForEveryone, null, this.props.tutorial.number, false, this);
        }
    }
    componentDidUpdate() {
        const loginReviewFreeForEveryone = localStorage.getItem(LOGIN_REVIEW_FREE_FOR_EVERYONE);
        if (this.props.user.login &&
            this.props.tutorialAccessKey &&
            this.props.tutorial.number &&
            !this.state.getReviewsRequestDone) {
            getReviewsForUsers(this.props.user.login, this.props.tutorialAccessKey, this.props.tutorial.number, this.props.isGuest, this);
        }
        else if( loginReviewFreeForEveryone && this.props.tutorial.number && !this.state.getReviewsRequestDone)
        {
            getReviewsForUsers(loginReviewFreeForEveryone, null, this.props.tutorial.number, false, this);
        }
    }

    render() {
        return (
            <div className="send-image-form-bg">
                <div className="send-image-form">
                    {
                        this.state.reviewsForUser &&
                        <React.Fragment>

                            {this.state.reviewsForUser.map((review, number) => {
                                return <React.Fragment key={"review_" + number}>
                                    <h3>Review #{number + 1} <span style={{ marginTop: '7px', fontSize: '13px', float: 'right' }}>status: {review.status}</span></h3>
                                    {/* {(review.status != REVIEW_STATUS.DONE) && <i onClick={() => this.deletePreviewImage()} className="mdi mdi-close nino-close img-preview-close" ></i>} */}
                                    {this.state.showModalPopUp && <ModalPopUp
                                        title={"My drawing based on EasyFaceDraw.com"}
                                        description={this.props.tutorial.title + " - video tutorial"}
                                        quote={this.props.tutorial.description}
                                        hashtag={["drawing", "video", "tutorial", "art"]}
                                        media={`${getImageForReviewUrl(review.username, review.reviewedImage)}`}
                                        url={`${WEBPAGE_URL}`}
                                    ></ModalPopUp>}
                                    <LazyLoadImage src={getImageForReviewUrl(review.username, review.userImage)} alt="Image for review" />
                                    <table className='review-table'>
                                        <tbody>
                                            <tr>
                                                <td>Date: </td>
                                                <td><h4>{dateWithoutHour(review.createDate)}</h4></td>
                                            </tr>
                                            {
                                                review.message ?
                                                    <React.Fragment>
                                                        <br />
                                                        <tr>
                                                            <td style={{ verticalAlign: "text-top" }}>Review: </td>
                                                            <td>{review.message}</td>
                                                        </tr>
                                                        <br />
                                                    </React.Fragment>

                                                    :
                                                    <tr>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>
                                    {
                                        review.reviewedImage &&
                                        <React.Fragment>
                                            <ImageModal hideHoverAnimation={true} author="" title="" src={getImageForReviewUrl(this.props.user.login, review.reviewedImage)} alt="Image from reviewer"></ImageModal>
                                        </React.Fragment>

                                        // <img src={getImageForReviewUrl(this.props.user.login, review.reviewedImage)} alt="Image from reviewer" />
                                    }
                                    {
                                        review.reviwerAudio &&
                                        <React.Fragment>
                                            <p>Listen your instructor review</p>
                                            <audio controls>
                                                <source src={getImageForReviewUrl(this.props.user.login, review.reviwerAudio)} type="audio/mpeg" />
                                                Your browser does not support the audio element.
                                        </audio>
                                        </React.Fragment>
                                    }
                                    {/* {
                                        review.reviewedImage &&
                                        <div className="download-btn">
                                            <a href={getImageForReviewUrl(this.props.user.login, review.reviewedImage)} download>
                                                <span className="glyphicon glyphicon-picture" aria-hidden="true"></span> Download reviewer image .jpg</a>
                                        </div>
                                    }
                                    {
                                        review.reviwerAudio &&
                                        <div className="download-btn">
                                            <a href={getImageForReviewUrl(this.props.user.login, review.reviwerAudio)} download>
                                                <span className="glyphicon glyphicon-music" aria-hidden="true"></span> Download reviewer audio .mp3</a>
                                        </div>
                                    } */}
                                    <br />
                                </React.Fragment>
                            })}
                        </React.Fragment>
                    }
                    {
                        this.getNumberOfAvailableReviews() ?
                            <React.Fragment>
                                {this.state.inProgress ?
                                    <React.Fragment>
                                        <h2>Uploading!</h2>
                                        {/* <div className="form-loading-center">
                                            <Loader type="TailSpin" color="#95e1d3" height="150" width="150" />
                                        </div> */}
                                        <div className="uploading-file-progress">
                                            <div className="uploading-file-progress-bar">
                                                <div style={{ width: `${this.state.uploadProgress}%` }}></div>
                                            </div>
                                            {`${this.state.uploadProgress} %`}</div>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <h2>Upload your drawing for review!
                                        <br />
                                            <span style={{ fontSize: "14px", textTransform: "none" }}><nobr>( Available Free Reviews: {this.getNumberOfAvailableReviews()} )</nobr></span>
                                        </h2>

                                        {this.state.imgPreviewSrc &&
                                            <React.Fragment>
                                                <i onClick={() => this.deletePreviewImage()} className="mdi mdi-close nino-close img-preview-close" ></i>
                                                <div className="img-preview-wrapper">
                                                    <LazyLoadImage className="img-preview" src={this.state.imgPreviewSrc} alt="result image preview" />
                                                    {(!this.state.successMessage && this.state.selectedFile) && <button className="green-btn" onClick={this.uploadHandler}>Upload!</button>}
                                                </div>
                                            </React.Fragment>
                                        }
                                        {!this.state.selectedFile && <div className="box">
                                            <input onChange={(e) => this.readURL(e.target.files)} type="file" name="file-5[]" id="file-5" accept="image/x-png,image/jpeg" className="inputfile inputfile-4" data-multiple-caption="{count} files selected" />
                                            <label htmlFor="file-5"><figure><svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17"><path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" /></svg></figure> <span>Choose a file&hellip;</span></label>
                                        </div>}

                                        {this.state.warningMessage &&
                                            <React.Fragment>
                                                <div className="warning-message-box"> {this.state.warningMessage} </div>
                                            </React.Fragment>
                                        }
                                        {this.state.successMessage &&
                                            <React.Fragment>
                                                <div className="success-message-box"> {this.state.successMessage} </div>
                                            </React.Fragment>
                                        }
                                    </React.Fragment>
                                }
                            </React.Fragment>
                            :
                            <React.Fragment>
                            </React.Fragment>
                    }
                </div>
            </div>
        );
    }
}

export default ReviewImageForm;
