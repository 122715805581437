import React, { Component } from 'react';
import Header from "./Header.jsx";
import ChangePasswordForm from './Forms/ChangePasswordForm';
import FormController from "./Forms/FormController";
import { getUserName } from '../functions.js';

class User extends Component {
    
    render() {
        return (
            <div>
                <Header {...this.props}></Header>
                <section id="nino-services">
                    <div className="container">
                        <h2 className="nino-sectionHeading">
                            <span className="nino-subHeading">Welcome {getUserName(this.props.user)}</span>
                            Your account settings
                        </h2>
                        <div className="sectionContent">
                            <div className="fw" layout="row">
                                <div className="col-md-4 col-sm-6 item ">
                                    <div layout="row">
                                        <div>
                                            <h4 className="nino-serviceTitle">Change password</h4>
                                            <p>You can change you password at any time. For better security, use a longer password, minimum 16 characters.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 item ">
                                    <FormController appObject={this.props.appObject} {...this.props}>
                                        <ChangePasswordForm></ChangePasswordForm>
                                    </FormController>
                                </div>
                            </div>
                            <br />
                            <br />
                            <br />
                            <div className="fw" layout="row">
                                <div className="col-md-4 col-sm-6 item ">
                                    <div layout="row">
                                        <div>
                                            <h4 className="nino-serviceTitle">Email associated with your account</h4>
                                            <p>You will receive newsletters and payment confirmations.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 item ">
                                    <p>{this.props.user.email}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default User;
