import React, { Component } from 'react';
import StarsRating from "./StarsRating.jsx";
import { sendComment, getTutorialRatings, getUserName } from '../functions';
import Loader from 'react-loader-spinner'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import { WEBPAGE_URL_IMAGES } from '../globals.js';
import { LazyLoadImage } from 'react-lazy-load-image-component';

TimeAgo.addLocale(en)
const timeAgo = new TimeAgo('en-US')

class Comments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            successMessage: "",
            warningMessage: "",
            inProgress: false,
            maxShowComments: this.props.wideComments ? 3 : 10,
            submitBtnShow: false,
            ratings: []
        };
        this.myRef = React.createRef();
        this.submitFormHandler = this.submitFormHandler.bind(this);
        this.textareaOnChange = this.textareaOnChange.bind(this);
        this.showMoreComments = this.showMoreComments.bind(this);
    }
    showMoreComments() {
        this.setState({
            maxShowComments: this.state.maxShowComments + 10
        });
    }
    // componentWillUpdate() {
    //     if (!this.props.comments && !this.state.comments) {
    //         getCommentsFromDb(this.props.tutorial.number, this);
    //     }
    // }
    componentDidMount() {
        if (this.props.wideComments) {
            getTutorialRatings(this.props.match.params.number, this);
        }
    }
    submitFormHandler() {
        if (Boolean(this.myRef.current.value)) {
            sendComment(this.props.user.login, this.props.user.name, this.props.user.surname,
                this.props.tutorialAccessKey, this.props.tutorial.number, this.myRef.current.value, this, this.props.parent);
        }
    }

    textareaOnChange() {
        this.setState({
            submitBtnShow: Boolean(this.myRef.current.value)
        })
    }

    render() {
        let comments = [];
        let commentsCount = 0;
        if (this.props.comments) {
            comments = this.props.comments;
        }
        else {
            comments = this.props.commentsFromDB;
        }
        if (comments) {
            commentsCount = comments.length;
            comments = comments.sort((a, b) => {
                return - parseInt(a.date) + parseInt(b.date);
            }).slice(0, this.state.maxShowComments).map((e, id) => {
                let avatar_url = WEBPAGE_URL_IMAGES + "/blank-avatar.jpg";
                // const user_avatar_url = WEBPAGE_URL_IMAGES + "/users-avatars/" + e.username;
                // if(e.username)
                // {
                //     urlExists(user_avatar_url, function (err, exists) {
                //         if (exists) {
                //             avatar_url = user_avatar_url;
                //         }
                //     });
                // }

                return (
                    <div key={id} className={"col-md-" + (this.props.wideComments ? "12" : "6")}>
                        <div layout="row" className="item">
                            <div className="nino-avatar fsr">
                                {
                                    (
                                        this.props.wideComments ?
                                            <LazyLoadImage className="img-circle" src={avatar_url} alt="blank avatar" />
                                            :
                                            <LazyLoadImage className="img-circle" src={WEBPAGE_URL_IMAGES + "/happy-client/" + e.img} alt="happy client avatar" />
                                    )
                                }
                            </div>
                            <div className="info">
                                <h4 className="name">{getUserName({
                                    login: e.username,
                                    name: e.name,
                                    surname: e.surname
                                })}</h4>
                                {(this.state.ratings.length != 0) && <StarsRating rating={this.state.ratings[e.username]}></StarsRating>}
                                <span className="regency">{timeAgo.format(parseInt(e.date))}</span>
                                <p className="desc">
                                    {e.comment}
                                </p>
                            </div>
                        </div>
                    </div>
                )
            });
        }

        return (
            <section id="nino-happyClient" style={{ 'paddingTop': '20px' }} className={this.props.wideComments && 'gray-background'} >
                <div className="container comments">
                    {(comments && commentsCount > 0) &&
                        <React.Fragment>
                            {this.props.children}
                        </React.Fragment>}

                    {this.props.wideComments && <p className="comments-count">{commentsCount} Comments</p>}
                    {(this.props.showCommentForm && comments && (comments.length > 3)) &&
                        <a href="#comment-form-textarea">
                            <div className="red-btn add-comment-btn">Add comment <span className="glyphicon glyphicon-chevron-down" aria-hidden="true"></span></div>
                        </a>
                    }
                    <div className="sectionContent">
                        <div className={"row comments"}>
                            {comments}
                        </div>
                    </div>
                    { (this.state.maxShowComments < commentsCount) && 
                            <div className="green-btn" onClick={() => this.showMoreComments()} style={{ margin: "0 auto 70px auto", width: "170px", display: "block" }}>
                                Show more comments
                            </div>
                    }
                    {this.props.showCommentForm &&
                        <React.Fragment>
                            {this.state.inProgress ?
                                <React.Fragment>
                                    <div className="form-loading-center">
                                        <Loader type="TailSpin" color="#95e1d3" height="150" width="150" />
                                    </div>
                                </React.Fragment>
                                :
                                <form id="comment-form">
                                    <h2 className="nino-sectionHeading">
                                        <span className="nino-subHeading">Your opinion about this course</span>
                                    </h2>
                                    <textarea id="comment-form-textarea" ref={this.myRef} onChange={() => { this.textareaOnChange() }} name="comment" rows="4" placeholder="Enter your opinion here..."></textarea>
                                    {this.state.submitBtnShow
                                        && <input onClick={() => { this.submitFormHandler() }} type="button" className="green-btn" value="Submit" />}
                                </form>
                            }
                        </React.Fragment>
                    }
                </div>
            </section>
        );
    }
}

export default Comments;
