import React, { Component } from 'react';
import Header from "./Header.jsx";
import { Link } from 'react-router-dom'
import { PAGE } from './../globals';
import { sendEventToGoogleAnalytics } from '../functions';
import { Helmet } from "react-helmet";

class ReadMore extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Six Easy Drawing Tutorials for Beginner Face Sketching</title>
                    <link rel="canonical" href="https://www.easyfacedraw.com/readmore" />
                    <meta name="description" content="How does Easy Face Draw work? Learn more about our simple video drawing tutorials." />
                    <meta name="keywords" content="drawing tutorials, how to draw, face sketch, basic drawing, simple art instruction" />
                </Helmet>
                <Header {...this.props}></Header>
                <section id="nino-services">
                    <div className="container">
                        <h2 className="nino-sectionHeading">
                            How it works
			            </h2>
                        <div className="sectionContent">
                            <div className="fw" layout="row">
                                <div className="col-md-9 col-md-offset-1 col-sm-12 item">
                                    <div layout="row">
                                        <span className="glyphicon glyphicon-heart nino-icon" aria-hidden="true"></span>
                                        <div>
                                            <h4 className="nino-serviceTitle">Choose what you like</h4>
                                            <p>Let’s get started! There are six (6) tutorials to choose from, each perfectly designed for beginners. All you need to do is select the one that suits you the best. Which one will you go for? Click it and start learning! It’s time to feel like an artist.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-9 col-md-offset-1 col-sm-12 item">
                                    <div layout="row">
                                        <span className="glyphicon glyphicon-facetime-video nino-icon" aria-hidden="true"></span>
                                        <div>
                                            <h4 className="nino-serviceTitle">Take our video lessons</h4>
                                            <p>It’s time for some more advanced content. But don’t worry! Everything is paced out and ready to rewatch as many times as you need to. No judgment here. Simply select one and start watching. Are you ready?</p>                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-9 col-md-offset-1 col-sm-12 item ">
                                    <div layout="row">
                                        <span className="glyphicon glyphicon-pencil nino-icon" aria-hidden="true"></span>
                                        <div>
                                            <h4 className="nino-serviceTitle">Try for yourself</h4>
                                            <p>It’s time to start practicing! You didn’t think you could just watch your way into Picasso’s shoes, did you? Exercising all your newfound knowledge is the only way you’re really going to master it. But don’t worry, you’re not expected to be perfect yet. Just do your very best. Are you ready to feel like a true artist?</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-9 col-md-offset-1 col-sm-12 item ">
                                    <div layout="row">
                                        <span className="glyphicon glyphicon-picture nino-icon" aria-hidden="true"></span>
                                        <div>
                                            <h4 className="nino-serviceTitle">Send your result to us</h4>
                                            <p>You have a very unique opportunity here! Unlike other online drawing courses, this one encourages student/tutor communication. If you need a guide, correction, or even just an assessment, he can help. Feel free to ask your burning questions, and he’ll answer! What are you waiting for? Send in your work.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <Link to={`/${PAGE.SHOWTUTORIALS}`} className="action-btn" onClick={() => sendEventToGoogleAnalytics("Watch for free","read more")}></Link> */}
                        <Link to={`/${PAGE.SHOWTUTORIALS}`} className="action-btn" onClick={() => sendEventToGoogleAnalytics("Watch now","read more")}></Link>

                        <h3 className="faq-help">
                            If you have questions, go to <Link to={"/help"}>FAQ</Link> or contact us at <a>contact@easyfacedraw.com</a> and we’ll get back to you as soon as possible.
                        </h3>
                    </div>
                </section>
                {/* <section id="nino-whatWeDo">
                    <div className="container">
                        <h3 className="nino-sectionHeading">
                            <span className="nino-subHeading big-font">EasyFaceDraw.com</span>
                        </h3>

                        <h2 className="nino-sectionHeading">
                            How it works
			            </h2>

                        <div className="sectionContent">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="text-center">
                                        <img src="images/what-we-do/img-1.jpg" alt="" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                        <div className="panel panel-default">
                                            <div className="panel-heading" role="tab" id="headingOne">
                                                <h4 className="panel-title">
                                                    <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        <i className="mdi mdi-chevron-up nino-icon arrow"></i>
                                                        <span className="glyphicon glyphicon-heart nino-icon" aria-hidden="true"></span>
                                                        Choose what you like
										            </a>
                                                </h4>
                                            </div>
                                            <div id="collapseOne" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                                                <div className="panel-body mCustomScrollbar _mCS_1">
                                                    <div id="mCSB_1" className="mCustomScrollBox mCS-default mCSB_vertical mCSB_inside" style={{ maxHeight: 'none' }} tabindex="0">
                                                        <div id="mCSB_1_container" className="mCSB_container" style={{ position: 'relative', top: '0px', left: '0px' }} dir="ltr">
                                                            Let’s get started! There are six (6) tutorials to choose from, each perfectly designed for beginners. All you need to do is select the one that suits you the best. Which one will you go for? Click it and start learning! It’s time to feel like an artist.
                                                            </div>
                                                        <div id="mCSB_1_scrollbar_vertical" className="mCSB_scrollTools mCSB_1_scrollbar mCS-default mCSB_scrollTools_vertical" style={{ display: 'block' }}>
                                                            <div className="mCSB_draggerContainer">
                                                                <div id="mCSB_1_dragger_vertical" className="mCSB_dragger" style={{ position: 'absolute', minHeight: '30px', display: 'block', height: '72px', maxHeight: '144px', top: '0px' }}>
                                                                    <div className="mCSB_dragger_bar" style={{ lineHeight: '30px' }}></div>
                                                                </div>
                                                                <div className="mCSB_draggerRail">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="panel panel-default">
                                            <div className="panel-heading" role="tab" id="headingTwo">
                                                <h4 className="panel-title">
                                                    <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        <i className="mdi mdi-chevron-up nino-icon arrow"></i>
                                                        <span className="glyphicon glyphicon-facetime-video nino-icon" aria-hidden="true"></span>
                                                        Take our video lessons
										            </a>
                                                </h4>
                                            </div>
                                            <div id="collapseTwo" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                                                <div className="panel-body mCustomScrollbar _mCS_1">
                                                    <div id="mCSB_1" className="mCustomScrollBox mCS-default mCSB_vertical mCSB_inside" style={{ maxHeight: 'none' }} tabindex="0">
                                                        <div id="mCSB_1_container" className="mCSB_container" style={{ position: 'relative', top: '0px', left: '0px' }} dir="ltr">
                                                            It’s time for some more advanced content. But don’t worry! Everything is paced out and ready to rewatch as many times as you need to. No judgment here. Simply select one and start watching. Are you ready?
                                                            </div>
                                                        <div id="mCSB_1_scrollbar_vertical" className="mCSB_scrollTools mCSB_1_scrollbar mCS-default mCSB_scrollTools_vertical" style={{ display: 'block' }}>
                                                            <div className="mCSB_draggerContainer">
                                                                <div id="mCSB_1_dragger_vertical" className="mCSB_dragger" style={{ position: 'absolute', minHeight: '30px', display: 'block', height: '72px', maxHeight: '144px', top: '0px' }}>
                                                                    <div className="mCSB_dragger_bar" style={{ lineHeight: '30px' }}></div>
                                                                </div>
                                                                <div className="mCSB_draggerRail">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="panel panel-default">
                                            <div className="panel-heading" role="tab" id="headingThree">
                                                <h4 className="panel-title">
                                                    <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        <i className="mdi mdi-chevron-up nino-icon arrow"></i>
                                                        <span className="glyphicon glyphicon-pencil nino-icon" aria-hidden="true"></span>
                                                        Try for yourself
										            </a>
                                                </h4>
                                            </div>
                                            <div id="collapseThree" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                                                <div className="panel-body mCustomScrollbar _mCS_1">
                                                    <div id="mCSB_1" className="mCustomScrollBox mCS-default mCSB_vertical mCSB_inside" style={{ maxHeight: 'none' }} tabindex="0">
                                                        <div id="mCSB_1_container" className="mCSB_container" style={{ position: 'relative', top: '0px', left: '0px' }} dir="ltr">
                                                            It’s time to start practicing! You didn’t think you could just watch your way into Picasso’s shoes, did you? Exercising all your newfound knowledge is the only way you’re really going to master it. But don’t worry, you’re not expected to be perfect yet. Just do your very best. Are you ready to feel like a true artist?
                                                            </div>
                                                        <div id="mCSB_1_scrollbar_vertical" className="mCSB_scrollTools mCSB_1_scrollbar mCS-default mCSB_scrollTools_vertical" style={{ display: 'block' }}>
                                                            <div className="mCSB_draggerContainer">
                                                                <div id="mCSB_1_dragger_vertical" className="mCSB_dragger" style={{ position: 'absolute', minHeight: '30px', display: 'block', height: '72px', maxHeight: '144px', top: '0px' }}>
                                                                    <div className="mCSB_dragger_bar" style={{ lineHeight: '30px' }}></div>
                                                                </div>
                                                                <div className="mCSB_draggerRail">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="panel panel-default">
                                            <div className="panel-heading" role="tab" id="headingFour">
                                                <h4 className="panel-title">
                                                    <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                        <i className="mdi mdi-chevron-up nino-icon arrow"></i>
                                                        <span className="glyphicon glyphicon-picture nino-icon" aria-hidden="true"></span>
                                                        Send your result to us
										            </a>
                                                </h4>
                                            </div>
                                            <div id="collapseFour" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFour">
                                                <div className="panel-body mCustomScrollbar _mCS_1">
                                                    <div id="mCSB_1" className="mCustomScrollBox mCS-default mCSB_vertical mCSB_inside" style={{ maxHeight: 'none' }} tabindex="0">
                                                        <div id="mCSB_1_container" className="mCSB_container" style={{ position: 'relative', top: '0px', left: '0px' }} dir="ltr">
                                                            You have a very unique opportunity here! Unlike other online drawing courses, this one encourages student/tutor communication. If you need a guide, correction, or even just an assessment, he can help. Feel free to ask your burning questions, and he’ll answer! What are you waiting for? Send in your work.
                                                            </div>
                                                        <div id="mCSB_1_scrollbar_vertical" className="mCSB_scrollTools mCSB_1_scrollbar mCS-default mCSB_scrollTools_vertical" style={{ display: 'block' }}>
                                                            <div className="mCSB_draggerContainer">
                                                                <div id="mCSB_1_dragger_vertical" className="mCSB_dragger" style={{ position: 'absolute', minHeight: '30px', display: 'block', height: '72px', maxHeight: '144px', top: '0px' }}>
                                                                    <div className="mCSB_dragger_bar" style={{ lineHeight: '30px' }}></div>
                                                                </div>
                                                                <div className="mCSB_draggerRail">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                <br />
                <br />

            </div>
        );
    }
}

export default ReadMore;
