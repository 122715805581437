import { SERVER_URL, IMAGES_FOR_REVIEW_URL, PAGE, LOGIN_REVIEW_FREE_FOR_EVERYONE } from './globals';
import axios from 'axios';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import { fetch as fetch } from 'whatwg-fetch'
import ReactGA from 'react-ga';

ReactGA.initialize('UA-29536042-10');
ReactGA.pageview(window.location.pathname + window.location.search);

export function sendEventToGoogleAnalytics(category, action, label = "") {
    ReactGA.event({
        category: category,
        action: action,
        label: label.toString()
    });
}

export const sendTutorialPayment = (token, username, tutorialNumber, loginKey, isGuest, ob, price) => {
    const data = {
        "username": username,
        "tutorialNumber": tutorialNumber,
        "loginKey": loginKey,
        "isGuest": isGuest,
        "price": price
    }

    ob.setState({
        inProgress: true
    });
    axios.post(`${SERVER_URL}process-payment`, { token, data })
        .then(res => {
            if (res.data.done) {
                ob.setState({
                    successMessage: res.data.message,
                    warningMessage: "",
                    inProgress: false
                });
                ob.props.setTutorialPurchased(res.data.tutorialPurchased);
                window.fbq('track', 'Purchase');
            }
            else {
                ob.setState({
                    warningMessage: res.data.message,
                    successMessage: "",
                    inProgress: false
                });
                ob.props.setTutorialPurchased(res.data.tutorialPurchased);
            }
        });
};

export const getTutorialRatings = (tutorialNumber, ob) => {
    fetch(SERVER_URL + 'tutorialRatings/' + tutorialNumber)
        .then((data) => data.json())
        .then((res) => { ob.setState({ ratings: res.data }) });
};

export const getTutorialFromDb = (number, ob) => {
    return fetch(SERVER_URL + 'tutorials/' + number)
        .then((data) => data.json())
        .then((res) => {
            ob.setState({ tutorial: res.data });
            getCommentsFromDb(res.data.number, ob);
        });
};

export const getTutorialsFromDb = (ob) => {
    fetch(SERVER_URL + 'tutorials')
        .then((data) => data.json())
        .then((res) => { ob.setState({ tutorials: res.data }) });
};

export const getTeacherFromDb = (number, ob) => {
    fetch(SERVER_URL + 'teachers/' + number)
        .then((data) => data.json())
        .then((res) => {
            ob.setState({ teacher: res.data });
        });
};

export const getTeachersFromDb = (ob) => {
    fetch(SERVER_URL + 'teachers')
        .then((data) => data.json())
        .then((res) => {
            ob.setState({ teachers: res.data });
        });
};

export const getImagesForReview = (ob) => {
    fetch(SERVER_URL + 'getimagesforreview')
        .then((data) => data.json())
        .then((res) => { ob.setState({ imgaesForReview: res.data }) });
};

export const getCommentsFromDb = (number, ob) => {
    fetch(`${SERVER_URL}getcomments/${number}`)
        .then((data) => data.json())
        .then((res) => {
            ob.setState({ comments: res.data });
        });
};

export const getUserTutorialRateFromDb = (username, number, ob) => {
    fetch(`${SERVER_URL}getusertutorialrate/${username}/${number}`)
        .then((data) => data.json())
        .then((res) => {
            ob.setState({ starsSelectedByUser: res.data });
        });
};

export const getBoughtTutorialsFromDb = (user, ob) => {
    fetch(`${SERVER_URL}boughttutorials/${user}`)
        .then((data) => data.json())
        .then((res) => { ob.setState({ tutorials: res.data.tutorials }) });
};

export const getIsTutorialBoughtFromDb = (number, login, key, ob, isGuest = false) => {
    return fetch(`${SERVER_URL}boughttutorial/${number}/${login}/${key}/${isGuest}`)
        .then((data) => data.json())
        .then((res) => {
            ob.setState({ guestLogin: login, tutorialAccessKey: res.data.tutorialAccessKey })
        });
};

export const isUserLogged = (ob, key, username, email, name, surname, isGoogleUser) => {
    return fetch(`${SERVER_URL}isuserlogged/${username}/${key}`)
        .then((data) => data.json())
        .then((res) => {
            if (res.data.isUserLogged) {
                ob.logInUser(key, { login: username, email: email, name: name, surname: surname, isGoogleUser: isGoogleUser });
            }
            else {
                ob.logOutUser();
            }
        });
};

export const getAdminAccessKey = (ob, username, key) => {
    return fetch(`${SERVER_URL}getadminaccesskey/${username}/${key}`)
        .then((data) => data.json())
        .then((res) => {
            ob.setState({ adminAccessKey: res.adminAccessKey });
        });
};

export const isAdmin = (username, loginKey, adminAccessKey, ob) => {
    fetch(`${SERVER_URL}isadmin/${username}/${loginKey}/${adminAccessKey}`)
        .then((data) => data.json())
        .then((res) => {
            ob.setState({ isAdmin: res.isAdmin });
        });
};

export const getReviewsForUsers = (login, tutorialAccessKey, tutorialNumber, isGuest = false, ob) => {
    fetch(`${SERVER_URL}getreviewsforuser/${login}/${tutorialAccessKey}/${tutorialNumber}/${isGuest}`)
        .then((data) => data.json())
        .then((res) => {
            ob.setState({
                getReviewsRequestDone: true,
                reviewsForUser: res.data.reviewsForUser,
                numberOfAvailableReviews: res.data.numberOfAvailableReviews
            })
        }).catch(() => {
            ob.setState({
                getReviewsRequestDone: true
            });
        })
};



export const sendImageReview = (ob, authorUsername, adminUsername, tutorialNumber, message, imageFile, audioFile, loginKey, adminAccessKey) => {
    let data = new FormData();
    data.append("authorUsername", authorUsername);
    data.append("adminUsername", adminUsername);
    data.append("tutorialNumber", tutorialNumber);
    data.append("message", message);
    data.append("adminAccessKey", adminAccessKey);
    data.append("loginKey", loginKey);
    data.append('audioFile', audioFile ? true : false);
    data.append('imageFile', imageFile ? true : false);

    ob.setState({
        successMessage: "",
        warningMessage: "",
        inProgress: true
    });
    return axios.post(`${SERVER_URL}sendimagereview`, data).then(e => {
        if (e.data.done) {

            var promise = new Promise(function (resolve, reject) {
                if (e.data.signedUrlsArray) {
                    const file = e.data.signedUrlsArray.pop();
                    const signedUrl = file.signedUrl;
                    const fileName = file.fileName;
                    const fileType = fileName.split(".")[1];
                    uploadFileToS3(null, null, null, fileType == 'jpg' ? imageFile : audioFile, signedUrl, fileType,
                        null, null, ob, resolve, reject, e.data.signedUrlsArray);
                }
            });
            promise.then((signedUrlsArray) => {
                if (signedUrlsArray.length) {
                    const file = e.data.signedUrlsArray.pop();
                    const signedUrl = file.signedUrl;
                    const fileName = file.fileName;
                    const fileType = fileName.split(".")[1];
                    uploadFileToS3(null, null, null, fileType == 'jpg' ? imageFile : audioFile, signedUrl, fileType, null, null, ob, null, null, null);
                }
            }).catch((error) => {
                ob.setState({
                    warningMessage: "Something went wrong. Try again.",
                    successMessage: "",
                    inProgress: false
                });
            });
        }
        else {
            ob.setState({
                warningMessage: e.data.message,
                successMessage: "",
                inProgress: false
            });
        }
    });
}

export const uploadFileToS3 = (login, tutorialAccessKey, tutorialNumber, file, signedUrl, fileType,
    isGuest, doAfter, ob, resolve, reject, signedUrlsArray) => {

    var options = {
        headers: {
            'Content-Type': fileType
        },
        onUploadProgress: function (progressEvent) {
            var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            ob.setState({
                uploadProgress: percentCompleted
            })
        }
    };


    axios.put(signedUrl, file, options)
        .then(result => {
            ob.setState({
                successMessage: "Done!",
                warningMessage: "",
                inProgress: false
            });
            setTimeout(() => {
                ob.setState({
                    showModalPopUp: true
                });
            }, 3000);
            if (doAfter) {
                doAfter(login, tutorialAccessKey, tutorialNumber, isGuest, ob);
            } else if (resolve) {
                resolve(signedUrlsArray);
            }
        })
        .catch(error => {
            ob.setState({
                warningMessage: "Something went wrong. Try again.",
                successMessage: "",
                inProgress: false
            });
            if (reject) {
                reject(error);
            }
        })
}

export const uploadImageForReview = (ob, file, login, name, surname, tutorialAccessKey, tutorialNumber, isGuest) => {
    if (file) {
        const data = new FormData();
        data.append("login", login);
        data.append("name", name);
        data.append("surname", surname);
        data.append("tutorialAccessKey", tutorialAccessKey);
        data.append("tutorialNumber", tutorialNumber);
        // data.append("isGuest", isGuest);
        ob.setState({
            inProgress: true
        });
        axios.post(`${SERVER_URL}imageforreview`, data).then(e => {
            if (e.data.done) {
                sendEventToGoogleAnalytics("Image for review send", "User sent image for review successfully", tutorialNumber);

                uploadFileToS3(login, tutorialAccessKey, tutorialNumber, file, e.data.signedUrl, "jpg", isGuest,
                    getReviewsForUsers, ob);
            }
            else {
                ob.setState({
                    warningMessage: e.data.message,
                    successMessage: "",
                    inProgress: false
                });
            }
        });
    }
}

export const uploadImageForReviewFreeForEveryone = (ob, file, tutorialNumber) => {
    if (file) {
        const data = new FormData();
        data.append("tutorialNumber", tutorialNumber);
        ob.setState({
            inProgress: true
        });
        axios.post(`${SERVER_URL}imageForReviewFreeForEveryone`, data).then(e => {
            if (e.data.done) {
                localStorage.setItem(LOGIN_REVIEW_FREE_FOR_EVERYONE, e.data.login)
                uploadFileToS3(e.data.login, false, tutorialNumber, file, e.data.signedUrl, "jpg", false,
                    getReviewsForUsers, ob);
            }
            else {
                ob.setState({
                    warningMessage: e.data.message,
                    successMessage: "",
                    inProgress: false
                });
            }
        });
    }
}

export const sendComment = (username, name, surname, tutorialAccessKey, tutorialNumber, comment, ob, parent) => {
    const data = new FormData();
    data.append("username", username);
    if (name) {
        data.append("name", name);
    }
    if (surname) {
        data.append("surname", surname);
    }
    data.append("tutorialAccessKey", tutorialAccessKey);
    data.append("tutorialNumber", tutorialNumber);
    data.append("comment", comment);

    ob.setState({
        inProgress: true
    });
    axios.post(`${SERVER_URL}sendcomment`, data)
        .then(res => {
            if (res.data.done) {
                ob.setState({
                    successMessage: res.data.message,
                    warningMessage: "",
                    inProgress: false,
                    maxShowComments: ob.state.maxShowComments + 1
                });
                getCommentsFromDb(tutorialNumber, parent);
            }
            else {
                ob.setState({
                    warningMessage: res.data.message,
                    successMessage: "",
                    inProgress: false,
                });
            }
        });
};

export const addSubscriber = (email, ob) => {
    const data = new FormData();

    data.append("email", email);
    ob.setState({
        inProgress: true
    });
    axios.post(`${SERVER_URL}addsubscriber`, data)
        .then(res => {
            if (res.data.done) {
                ob.setState({
                    successMessage: res.data.message,
                    warningMessage: "",
                    inProgress: false
                });
            }
            else {
                ob.setState({
                    warningMessage: res.data.message,
                    successMessage: "",
                    inProgress: false
                });
            }
        });
};

export const addViews = (tutorialNumber) => {
    const data = new FormData();
    data.append("tutorialNumber", tutorialNumber);
    axios.post(`${SERVER_URL}addviews`, data); 
};

export const sendFeedback = (feedback, ob) => {

    if (feedback) {

        sendEventToGoogleAnalytics("User feedback", "User pressed the big 'send' feedback button");
        const data = new FormData();

        data.append("feedback", feedback);
        ob.setState({
            inProgress: true
        });
        axios.post(`${SERVER_URL}sendfeedback`, data)
            .then(res => {
                if (res.data.done) {
                    ob.setState({
                        successMessage: res.data.message,
                        warningMessage: "",
                        inProgress: false,
                        feedback: ""
                    });
                }
                else {
                    ob.setState({
                        warningMessage: res.data.message,
                        successMessage: "",
                        inProgress: false
                    });
                }
            });
    }
    else {
        ob.setState({
            warningMessage: "Empty feedback.",
            successMessage: ""
        });
    }
};

export const sendMessageContactForm = (message, email, ob) => {
    if (message && email) {
        sendEventToGoogleAnalytics("User contact", "User pressed the 'send' contact button - contribution");
        const data = new FormData();

        data.append("message", message);
        data.append("email", email);
        ob.setState({
            inProgress: true
        });
        axios.post(`${SERVER_URL}sendMessageContactForm`, data)
            .then(res => {
                if (res.data.done) {
                    ob.setState({
                        successMessage: res.data.message,
                        warningMessage: "",
                        inProgress: false,
                        message: "",
                        email: ""
                    });
                }
                else {
                    ob.setState({
                        warningMessage: res.data.message,
                        successMessage: "",
                        inProgress: false
                    });
                }
            });
    }
    else {
        ob.setState({
            warningMessage: "Empty email or message.",
            successMessage: ""
        });
    }
};

export const getGuestAccessForFreeTutorial = (email, ob) => {
    const data = new FormData();

    data.append("email", email);
    ob.setState({
        inProgress: true
    });
    axios.post(`${SERVER_URL}getGuestAccessForFreeTutorial`, data)
        .then(res => {
            if (res.data.done) {
                ob.setState({
                    successMessage: res.data.message,
                    warningMessage: "",
                    inProgress: false
                });
                ob.setTutorialPurchased(res.data.tutorialPurchased);
            }
            else {
                ob.setState({
                    warningMessage: res.data.message,
                    successMessage: "",
                    inProgress: false
                });
                ob.setTutorialPurchased(res.data.tutorialPurchased);
            }
        });
};

export const sendRating = (username, tutorialAccessKey, tutorialNumber, rating, ob) => {
    const data = new FormData();

    data.append("username", username);
    data.append("tutorialAccessKey", tutorialAccessKey);
    data.append("tutorialNumber", tutorialNumber);
    data.append("rating", rating);
    ob.setState({
        inProgress: true
    });
    return axios.post(`${SERVER_URL}ratetutorial`, data)
        .then(res => {

            // if (res.data.done) {
            //     ob.setState({
            //         successMessage: res.data.message,
            //         warningMessage: "",
            //         inProgress: false
            //     });
            //     getCommentsFromDb(tutorialNumber, ob);
            // }
            // else {
            //     ob.setState({
            //         warningMessage: res.data.message,
            //         successMessage: "",
            //         inProgress: false,
            //     });
            // }
        });
};

export const isTutorialUnlocked = (tutorialAccessKey, isLogged, loginKey, isGuest, guestLoginKey, guestPassword) => {
    if (isGuest) {
        return tutorialAccessKey && guestLoginKey && guestPassword;
    }
    else {
        return tutorialAccessKey && isLogged && loginKey;
    }
}

export const checkForTutorialAccessKey = (tutorialAccessKey, loginKey, user, tutorialNumber, ob, isGuest, guestLoginKey, guestPassword) => {
    if (tutorialAccessKey === null) {
        if (isGuest) {
            return getIsTutorialBoughtFromDb(tutorialNumber, guestLoginKey, guestPassword, ob, true);
        }
        else {
            if (loginKey && user.login) {
                return getIsTutorialBoughtFromDb(tutorialNumber, user.login, loginKey, ob, false);
            }
        }
    }
    return null;
}

export const dateWithoutHour = (date) => {
    return date.split(" ")[0];
}

export const getImageForReviewUrl = (username, fileName) => {
    return `${IMAGES_FOR_REVIEW_URL}${username}/${fileName}`;
}

export const getVideoURL = (number, activeVideo, quality, login, tutorialAccessKey, ob, isGuest) => {
    return fetch(`${SERVER_URL}video/${number}/${activeVideo}/${quality}/${login}/${tutorialAccessKey}/${isGuest}`)
        .then((data) => data.json())
        .then((res) => {
            ob.setState({ tutorialURL: res.url });
        });
}

export const getUserName = (user) => {
    return user.name && user.surname ? (user.name + " " + user.surname) : user.login;
}

export const generateHeaderNavigation = (ob) => {

    const pageName = ob.props.location ? ob.props.location.pathname.split('/')[1] : "/";
    return <React.Fragment>
        <li className="user-panel-link show-only-on-desktop">
            <Link to={`/${PAGE.SHOWTUTORIALS}`} onClick={() => ob.props.closeForms && ob.props.closeForms()}>
                <span className={((pageName == "") || (pageName == PAGE.SHOWTUTORIALS)) ? "link-form" : ""}>
                    Tutorials
                </span>
            </Link>
        </li>
        <li className="user-panel-link show-only-on-desktop">
            <Link to={"/" + PAGE.READMORE} onClick={() => ob.props.closeForms && ob.props.closeForms()}>
                <span className={(pageName == PAGE.READMORE) ? "link-form" : ""}>
                    How it works
                </span>
            </Link>
        </li>
        <li className="user-panel-link show-only-on-desktop">
            <Link to={"/" + PAGE.TEACHER + "/1"} onClick={() => ob.props.closeForms && ob.props.closeForms()}>
                <span className={(pageName == PAGE.TEACHER) ? "link-form" : ""}>
                    Instructor
                </span>
            </Link>
        </li>
        <li className="user-panel-link show-only-on-desktop">
            <Link to={"/" + PAGE.ABOUT} onClick={() => ob.props.closeForms && ob.props.closeForms()}>
                <span className={(pageName == PAGE.ABOUT) ? "link-form" : ""}>
                    Idea
                </span>
            </Link>
        </li>
        <li className="user-panel-link show-only-on-desktop">
            <Link to={"/" + PAGE.CONTRIBUTION} onClick={() => ob.props.closeForms && ob.props.closeForms()}>
                <span className={(pageName == PAGE.CONTRIBUTION) ? "link-form" : ""}>
                    Contribution
                </span>
            </Link>
        </li>
    </React.Fragment>
}

export const getDiscountTimeLeft = (discountEndTime) => {
    var date1 = new Date(discountEndTime).getTime();
    var date2 = Date.now();
    return date1 - date2;
}

export const isDiscount = (timeLeft) => {
    return timeLeft > 0
}

export const getActualPrice = (price, discountPrice, isDiscount) => {
    return isDiscount ? discountPrice : price;
}