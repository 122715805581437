import React, { Component } from 'react';
import Header from "./Header.jsx";
import TutorialsOverview from "./TutorialsOverview";
import { getBoughtTutorialsFromDb, sendEventToGoogleAnalytics } from '../functions';
import { Link } from 'react-router-dom'
import { PAGE } from './../globals';

class MyCourses extends Component {
    componentDidMount() {
        getBoughtTutorialsFromDb(this.props.user.login, this);
    }

    constructor(props) {
        super(props);
        this.state = {
            tutorials: []
        }
    }

    render() {
        return (
            <div>
                <Header {...this.props}></Header>
                <TutorialsOverview tutorials={this.state.tutorials} isMyCourses={true}>
                    {
                        this.state.tutorials.length == 0 
                        ? 
                        <React.Fragment>
                        "You have no courses bought yet."
                        {/* <Link to={`/${PAGE.SHOWTUTORIALS}`} className="action-btn" onClick={() => sendEventToGoogleAnalytics("Watch for free","my courses")}></Link> */}
                        <Link to={`/${PAGE.SHOWTUTORIALS}`} className="action-btn" onClick={() => sendEventToGoogleAnalytics("Watch now","my courses")}></Link>

                        </React.Fragment>
                        : 
                        "Your courses"
                    }
                </TutorialsOverview>
            </div>
        );
    }
}

export default MyCourses;
