import React, { Component } from 'react';
import { sendMessageContactForm } from '../../functions';
import Loader from 'react-loader-spinner'

export class ContactForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            message: "",
            email: "",
            successMessage: "",
            warningMessage: "",
            inProgress: false
        };
        this.handleUserInput = this.handleUserInput.bind(this);
    }

    handleUserInput(e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value });
    }

    render() {
        return (
            <React.Fragment>
                {this.state.inProgress ?
                    <div className="form-loading-circle form-loading-center">
                        <Loader type="TailSpin" color="#95e1d3" height="150" width="150" />
                    </div>
                    :
                    <React.Fragment>
                        <form action="" className="contact-form">
                                <h3 className="nino-sectionHeading">Contact us</h3>
                                <input value={this.state.email} name="email" onChange={this.handleUserInput} type="email" className="form-control" placeholder="Your email" required />
                                <textarea value={this.state.message} name="message" onChange={this.handleUserInput}
                                    maxLength="1000" cols="40" rows="10" className="form-control" placeholder="Message..." required ></textarea>
                                <button onClick={() => sendMessageContactForm(this.state.message,this.state.email, this)} className="btn green-btn" type="button">Send</button>
                        </form>
                        <div className="messages-wrapper">
                            {this.state.warningMessage &&
                                <React.Fragment>
                                    <br />
                                    <div className="validation-warning-box nino-formInput"> {this.state.warningMessage} </div>
                                </React.Fragment>
                            }
                            {this.state.successMessage &&
                                <React.Fragment>
                                    <br />
                                    <div className="validation-success-box nino-formInput"> {this.state.successMessage} </div>
                                </React.Fragment>
                            }
                        </div>
                       
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

export default ContactForm;
