import React, { Component } from "react";
import Footer from "./Components/Footer.jsx";
import UserPanel from "./Components/Forms/UserPanel.jsx";
import SearchForm from "./Components/Forms/SearchForm.jsx";
import LoginForm from "./Components/Forms/LoginForm.jsx";
import RegisterForm from "./Components/Forms/RegisterForm";
import FormController from "./Components/Forms/FormController.jsx";
import CategoriesForm from "./Components/Forms/CategoriesForm.jsx";
import CookiesPopup from "./Components/Forms/CookiesPopup.jsx";
import Home from "./Components/Home.jsx";
import { Switch, Route } from 'react-router-dom'
import Course from "./Components/Course.jsx";
import SellingLandingPageForCourse from "./Components/SellingLandingPageForCourse.jsx";
import CoursePlayer from "./Components/CoursePlayer";
import User from "./Components/User.jsx";
import Payments from "./Components/Payments";
import MyCourses from "./Components/MyCourses.jsx";
import ReadMore from "./Components/ReadMore.jsx";
import About from "./Components/About.jsx";
import Article from "./Components/Article.jsx";
import ArticleSecond from "./Components/ArticleSecond.jsx";
import ArticleHowToDrayAnEye from "./Components/ArticleHowToDrayAnEye.jsx";
import ArticleDrawingTechnique from "./Components/ArticleDrawingTechnique.jsx";
import ArticleThePlayOfLightAndShadow from "./Components/ArticleThePlayOfLightAndShadow.jsx";
import ArtilcePortraitureAnEverExpandingForm from "./Components/ArtilcePortraitureAnEverExpandingForm.jsx";
import ArticleHowColorWorksInArt from "./Components/ArticleHowColorWorksInArt.jsx";
import ArticleDigitalPainting from "./Components/ArticleDigitalPainting.jsx";
import ArticleMinimalism from "./Components/ArticleMinimalism.jsx";
import ArticleLeonardoDaVinci from "./Components/ArticleLeonardoDaVinci.jsx";
import AtricleTheArtOfIllusion from "./Components/AtricleTheArtOfIllusion.jsx";
import PrivacyPolicy from "./Components/PrivacyPolicy.jsx";
import CookiesPolicy from "./Components/CookiesPolicy.jsx";
import InformationObligationClause from "./Components/InformationObligationClause.jsx";
import Contribution from "./Components/Contribution.jsx";
import FrequentlyAskedQuestions from "./Components/FrequentlyAskedQuestions.jsx";
import TeacherPage from "./Components/TeacherPage.jsx";
import ImagesReviews from "./Components/ImagesReviews";
import UnderConstructionSite from "./Components/UnderConstructionSite";
// import { connect } from 'react-redux';
// import { setActionTemplate } from './Actions';
import { withRouter } from 'react-router-dom';
import EmailVerification from "./Components/EmailVerification.jsx";
import { isUserLogged, getAdminAccessKey, isAdmin } from './functions';
import ResetPassword from "./Components/ResetPassword.jsx";
import { PAGE, SHOW_PAGE } from './globals';
import { isMobile } from 'react-device-detect';
import Rules from "./Components/Rules.jsx";

const userInitialState = { login: "", email: "" };

class App extends Component {

  constructor(props) {
    super(props);
    // var unlockedWithUrl = false;
    // if( window.location.href.includes("912ec803b2ce49e4a541068d495ab570"))
    // {
    //   localStorage.setItem("showPage", "true");
    //   unlockedWithUrl = true;
    // }
    this.state = {
      // showPage: (localStorage.getItem('showPage') || unlockedWithUrl) ? true : SHOW_PAGE,
      user: userInitialState,
      isLogged: false,
      loginKey: "",
      isRegisterFormOpen: false,
      isLoginFormOpen: false,
      isUserPanelOpen: false,
      adminAccessKey: null,
      isAdmin: null,
      pageName: null,
      isMoblieDevice: isMobile
    }

    this.scrollToTopOnLocationChange = this.scrollToTopOnLocationChange.bind(this);
    this.registerFormOpen = this.registerFormOpen.bind(this);
    this.loginFormOpen = this.loginFormOpen.bind(this);
    this.userPanelOpen = this.userPanelOpen.bind(this);
    this.closeForms = this.closeForms.bind(this);
    this.logInUser = this.logInUser.bind(this);
    this.logOutUser = this.logOutUser.bind(this);
    this.switchOpenForms = this.switchOpenForms.bind(this);
    this.checkUserIsLogged = this.checkUserIsLogged.bind(this);
    // this.showPage = this.showPage.bind(this);


  }

  componentDidMount() {
    let promise = this.checkUserIsLogged();
    if (promise) {
      promise.then(
        () => {
          getAdminAccessKey(this, this.state.user.login, this.state.loginKey).then(() => {
            isAdmin(this.state.user.login, this.state.loginKey, this.state.adminAccessKey, this);
          })
        }
      );
    }
  }

  componentDidUpdate(prevProps) {
    this.scrollToTopOnLocationChange(prevProps);
  }

  // showPage() {
  //   this.setState({
  //     showPage: true
  //   })
  //   localStorage.setItem("showPage", "true");
  // }

  checkUserIsLogged() {
    const key = localStorage.getItem("key");
    const username = localStorage.getItem("username");
    const email = localStorage.getItem("email");
    const name = localStorage.getItem("name");
    const surname = localStorage.getItem("surname");
    const isGoogleUser = localStorage.getItem("isGoogleUser");

    if (key && username) {
      return isUserLogged(this, key, username, email, name, surname, isGoogleUser);
    }
    return false;
  }

  logInUser(key, user) {
    if (key) {
      this.setState(
        {
          isLogged: true,
          loginKey: key,
          user: user
        }
      )

      localStorage.setItem("key", key);
      localStorage.setItem("username", user.login);
      localStorage.setItem("email", user.email);
      if (user.name) {
        localStorage.setItem("name", user.name);
      }
      if (user.surname) {
        localStorage.setItem("surname", user.surname);
      }
      if (user.isGoogleUser) {
        localStorage.setItem("isGoogleUser", user.isGoogleUser);
      }
    }
  }

  logOutUser() {
    this.setState(
      {
        isLogged: false,
        loginKey: "",
        user: userInitialState,
        adminAccessKey: null,
        isAdmin: null
      }
    )
    localStorage.setItem("key", "");
    localStorage.setItem("username", "");
    localStorage.setItem("email", "");
    localStorage.removeItem("name");
    localStorage.removeItem("surname");
    localStorage.removeItem("isGoogleUser");
    this.closeForms();
  }

  scrollToTopOnLocationChange(prevProps) {
    if ((this.props.location.pathname !== prevProps.location.pathname)
      && (this.props.location.pathname !== `/${PAGE.SHOWTUTORIALS}`)) {
      window.scrollTo(0, 0);
    }
  }

  userPanelOpen() {
    this.setState({
      isUserPanelOpen: !this.state.isUserPanelOpen
    })
  }
  registerFormOpen(createAccountFirstHeader, tutorialNumberToBuyAsAGuest) {
    this.setState({
      isRegisterFormOpen: true,
      createAccountFirstHeader: createAccountFirstHeader,
      tutorialNumberToBuyAsAGuest: tutorialNumberToBuyAsAGuest
    })
  }

  loginFormOpen() {
    this.setState({
      isLoginFormOpen: true
    })
  }

  switchOpenForms() {
    this.setState({
      isLoginFormOpen: !this.state.isLoginFormOpen,
      isRegisterFormOpen: !this.state.isRegisterFormOpen,
      isUserPanelOpen: false
    })
  }

  closeForms() {
    this.setState({
      isLoginFormOpen: false,
      isRegisterFormOpen: false,
      isUserPanelOpen: false
    })
  }

  render() {
    // if (!this.state.showPage)
    //   return     <UnderConstructionSite showPage={this.showPage}></UnderConstructionSite>

    // else
    return <div>
      <Switch>
        <Route exact path='/' render={props => <Home {...props} userPanelOpen={this.userPanelOpen} registerFormOpen={this.registerFormOpen} loginFormOpen={this.loginFormOpen} {...this.state} />} />
        <Route path={`/${PAGE.SHOWTUTORIALS}`} render={props => <Home scrollToTutorials={true} userPanelOpen={this.userPanelOpen} registerFormOpen={this.registerFormOpen} loginFormOpen={this.loginFormOpen} {...this.state} />} />
        <Route path='/index' render={props => <Home userPanelOpen={this.userPanelOpen} registerFormOpen={this.registerFormOpen} loginFormOpen={this.loginFormOpen} {...this.state} />} />
        <Route path='/index.html' render={props => <Home userPanelOpen={this.userPanelOpen} registerFormOpen={this.registerFormOpen} loginFormOpen={this.loginFormOpen} {...this.state} />} />
        <Route path='/course/:number' render={props => <Course {...props} userPanelOpen={this.userPanelOpen} registerFormOpen={this.registerFormOpen} loginFormOpen={this.loginFormOpen} {...this.state} />} />
        <Route path='/course-start-now/:number' render={props => <SellingLandingPageForCourse {...props} userPanelOpen={this.userPanelOpen} registerFormOpen={this.registerFormOpen} loginFormOpen={this.loginFormOpen} {...this.state} />} />
        <Route path='/player/:number/:guestloginkey/:guestpassword' render={props => <CoursePlayer {...props} isGuest={true} userPanelOpen={this.userPanelOpen} registerFormOpen={this.registerFormOpen} loginFormOpen={this.loginFormOpen} {...this.state} />} />
        <Route path='/player/:number' render={props => <CoursePlayer {...props} userPanelOpen={this.userPanelOpen} registerFormOpen={this.registerFormOpen} loginFormOpen={this.loginFormOpen} {...this.state} />} />
        <Route path={`/${PAGE.READMORE}`} render={props => <ReadMore {...props} userPanelOpen={this.userPanelOpen} registerFormOpen={this.registerFormOpen} loginFormOpen={this.loginFormOpen} {...this.state} />} />
        <Route path={`/${PAGE.ABOUT}`} render={props => <About {...props} userPanelOpen={this.userPanelOpen} registerFormOpen={this.registerFormOpen} loginFormOpen={this.loginFormOpen} {...this.state} />} />
        <Route path={`/${PAGE.HELP}`} render={props => <FrequentlyAskedQuestions {...props} userPanelOpen={this.userPanelOpen} registerFormOpen={this.registerFormOpen} loginFormOpen={this.loginFormOpen} {...this.state} />} />
        <Route path={`/${PAGE.ARTICLE}`} render={props => <Article {...props} userPanelOpen={this.userPanelOpen} registerFormOpen={this.registerFormOpen} loginFormOpen={this.loginFormOpen} {...this.state} />} />
        <Route path={`/${PAGE.ARTICLE_SECOND}`} render={props => <ArticleSecond {...props} userPanelOpen={this.userPanelOpen} registerFormOpen={this.registerFormOpen} loginFormOpen={this.loginFormOpen} {...this.state} />} />
        <Route path={`/${PAGE.ARTICLE_HOW_TO_DRAW_AN_EYE}`} render={props => <ArticleHowToDrayAnEye {...props} userPanelOpen={this.userPanelOpen} registerFormOpen={this.registerFormOpen} loginFormOpen={this.loginFormOpen} {...this.state} />} />
        <Route path={`/${PAGE.ARTICLE_DRAWING_TECHNIQUE}`} render={props => <ArticleDrawingTechnique {...props} userPanelOpen={this.userPanelOpen} registerFormOpen={this.registerFormOpen} loginFormOpen={this.loginFormOpen} {...this.state} />} />
        <Route path={`/${PAGE.ARTICLE_LIGHT_AND_SHADOW}`} render={props => <ArticleThePlayOfLightAndShadow {...props} userPanelOpen={this.userPanelOpen} registerFormOpen={this.registerFormOpen} loginFormOpen={this.loginFormOpen} {...this.state} />} />
        <Route path={`/${PAGE.ARTICLE_HOW_COLOR_WORKS_IN_ART}`} render={props => <ArticleHowColorWorksInArt {...props} userPanelOpen={this.userPanelOpen} registerFormOpen={this.registerFormOpen} loginFormOpen={this.loginFormOpen} {...this.state} />} />
        <Route path={`/${PAGE.ARTICLE_PORTRAITURE_AN_EVER_EXPANDING_FORM}`} render={props => <ArtilcePortraitureAnEverExpandingForm {...props} userPanelOpen={this.userPanelOpen} registerFormOpen={this.registerFormOpen} loginFormOpen={this.loginFormOpen} {...this.state} />} />
        <Route path={`/${PAGE.ARTICLE_DIGITAL_PAINTING}`} render={props => <ArticleDigitalPainting {...props} userPanelOpen={this.userPanelOpen} registerFormOpen={this.registerFormOpen} loginFormOpen={this.loginFormOpen} {...this.state} />} />
        <Route path={`/${PAGE.ARTICLE_MINIMALISM}`} render={props => <ArticleMinimalism {...props} userPanelOpen={this.userPanelOpen} registerFormOpen={this.registerFormOpen} loginFormOpen={this.loginFormOpen} {...this.state} />} />
        <Route path={`/${PAGE.ARTICLE_DA_VINCI}`} render={props => <ArticleLeonardoDaVinci {...props} userPanelOpen={this.userPanelOpen} registerFormOpen={this.registerFormOpen} loginFormOpen={this.loginFormOpen} {...this.state} />} />
        <Route path={`/${PAGE.ARTICLE_THE_ART_OF_ILLUSION}`} render={props => <AtricleTheArtOfIllusion {...props} userPanelOpen={this.userPanelOpen} registerFormOpen={this.registerFormOpen} loginFormOpen={this.loginFormOpen} {...this.state} />} />
        <Route path={`/${PAGE.PRIVACY_POLICY}`} render={props => <PrivacyPolicy {...props} userPanelOpen={this.userPanelOpen} registerFormOpen={this.registerFormOpen} loginFormOpen={this.loginFormOpen} {...this.state} />} />
        <Route path={`/${PAGE.COOKIES_POLICY}`} render={props => <CookiesPolicy {...props} userPanelOpen={this.userPanelOpen} registerFormOpen={this.registerFormOpen} loginFormOpen={this.loginFormOpen} {...this.state} />} />
        <Route path={`/${PAGE.RULES}`} render={props => <Rules {...props} userPanelOpen={this.userPanelOpen} registerFormOpen={this.registerFormOpen} loginFormOpen={this.loginFormOpen} {...this.state} />} />
        <Route path={`/${PAGE.INFORMATION_OBLIGATION_CLAUSE}`} render={props => <InformationObligationClause {...props} userPanelOpen={this.userPanelOpen} registerFormOpen={this.registerFormOpen} loginFormOpen={this.loginFormOpen} {...this.state} />} />
        <Route path={`/${PAGE.CONTRIBUTION}`} render={props => <Contribution {...props} userPanelOpen={this.userPanelOpen} registerFormOpen={this.registerFormOpen} loginFormOpen={this.loginFormOpen} {...this.state} />} />
        <Route path='/teacher/:number' render={props => <TeacherPage {...props} userPanelOpen={this.userPanelOpen} registerFormOpen={this.registerFormOpen} loginFormOpen={this.loginFormOpen} {...this.state} />} />
        {(this.state.isLogged && !this.state.user.isGoogleUser) && <Route path='/user' render={props => <User {...props} appObject={this} userPanelOpen={this.userPanelOpen} registerFormOpen={this.registerFormOpen} loginFormOpen={this.loginFormOpen} {...this.state} />} />}
        {this.state.isLogged && <Route path='/mycourses' render={props => <MyCourses {...props} userPanelOpen={this.userPanelOpen} registerFormOpen={this.registerFormOpen} loginFormOpen={this.loginFormOpen} {...this.state} />} />}
        {(this.state.isLogged && this.state.isAdmin) && <Route path='/imagesreviews' render={props => <ImagesReviews {...props} userPanelOpen={this.userPanelOpen} registerFormOpen={this.registerFormOpen} loginFormOpen={this.loginFormOpen} {...this.state} />} />}
        {this.state.isLogged && <Route path='/payments/:number' render={props => <Payments {...props} userPanelOpen={this.userPanelOpen} registerFormOpen={this.registerFormOpen} loginFormOpen={this.loginFormOpen} {...this.state} />} />}
        {(!this.state.isLogged && this.state.tutorialNumberToBuyAsAGuest) && <Route path='/payments/:number/:guest' render={props => <Payments {...props} userPanelOpen={this.userPanelOpen} registerFormOpen={this.registerFormOpen} loginFormOpen={this.loginFormOpen} {...this.state} />} />}
        {!this.state.isLogged && <Route path='/emailverification/:username/:key' render={props => <EmailVerification {...props} userPanelOpen={this.userPanelOpen} registerFormOpen={this.registerFormOpen} loginFormOpen={this.loginFormOpen} />} {...this.state} />}
        {!this.state.isLogged && <Route path='/resetpassword/:email/:key' render={props => <ResetPassword {...props} userPanelOpen={this.userPanelOpen} registerFormOpen={this.registerFormOpen} loginFormOpen={this.loginFormOpen} {...this.state} />} />}
        <Route render={props => <Home userPanelOpen={this.userPanelOpen} registerFormOpen={this.registerFormOpen} loginFormOpen={this.loginFormOpen} {...this.state} />} />
      </Switch>


      <Footer></Footer>

      <UserPanel {...this.state} pageName={this.state.pageName} logOutUser={this.logOutUser} open={this.state.isUserPanelOpen} closeForms={this.closeForms}></UserPanel>
      <SearchForm></SearchForm>
      <FormController appObject={this} {...this.props} {...this.state} closeForms={this.closeForms} logInUser={this.logInUser}>
        <LoginForm open={this.state.isLoginFormOpen} switchOpenForms={this.switchOpenForms}></LoginForm>
      </FormController>
      <FormController {...this.props} {...this.state} closeForms={this.closeForms} logInUser={this.logInUser}>
        <RegisterForm createAccountFirstHeader={this.state.createAccountFirstHeader} tutorialNumberToBuyAsAGuest={this.state.tutorialNumberToBuyAsAGuest} open={this.state.isRegisterFormOpen} switchOpenForms={this.switchOpenForms} {...this.props} ></RegisterForm>
      </FormController>
      <CategoriesForm></CategoriesForm>
      <CookiesPopup></CookiesPopup>
      {/* Scroll to top */}
      <a href="#" id="nino-scrollToTop">Go to Top</a>
    </div>;

  }
}
//redux configuration:
// const mapStateToProps = (state, ownProps) => {
//   return ({
//     itemId: state.itemId
//   });
// };
// const mapDispatchToProps = (dispatch, ownProps) => {
//   return ({
//     handleOnClick: itemState => dispatch(setActionTemplate(ownProps.prop))
//   });
// };

// export const MyComponentContainer = connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(App)


export default withRouter(props => <App {...props} />);
