import React, { Component } from 'react';
import Header from "./Header.jsx";
import { Link } from 'react-router-dom'
import { PAGE, WEBPAGE_URL_IMAGES } from '../globals';
import ImageModal from './ImageModal';
import { sendEventToGoogleAnalytics } from '../functions';
import { Helmet } from "react-helmet";

class ArticleSecond extends Component {

    render() {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Learn about Impressionism and Impressionist Artists</title>
                    <link rel="canonical" href="https://www.easyfacedraw.com/article-everything-you-need-to-know-about-impressionism" />
                    <meta name="description" content="Learn about the history of Impressionism and the Impressionist Artists, such as Monet, Degas, Renoir, and Cassatt." />
                    <meta name="keywords" content="impressionist art, art history, impressionism, impressionist" />
                    
                </Helmet>
                <Header {...this.props} ></Header>
                <article>
                    <section id="nino-whatWeDo">
                        <div className="container">
                            <h1 className="nino-sectionHeading">
                                <span className="nino-subHeading big-font">Everything you need to <br /> know about Impressionism</span>

                            </h1>
                            <h2 className="article-author">
                                Claudia Preza
                                <br />
                                April 10, 2020
                                </h2>
                            <br />


                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="Claude Monet"
                                    title="The Japanese Footbridge,"
                                    src={`${WEBPAGE_URL_IMAGES}/article/2/Japanese_Footbridge-Claude_Monet.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Claude Monet (French, 1840-1926), The Japanese Footbridge, 1899, oil on canvas. Courtesy National Gallery of Art, Washington.</p>
                            </div>
                            <h3 className="nino-sectionHeading">
                                How Impressionism started
                          </h3>
                            <p className="nino-sectionDesc read-more-description">
                                Impressionism was a nineteenth-century art movement that originated in France. It started with the rejection and active defiance of the art establishment and the hierarchal structure imposed on the types of paintings. Impressionism was an outgrowth of Realism and Romanticism stemming from the influence of French Modernist painter Édouard Manet, who championed creative endeavors that broke away from traditional standards.
			            </p>
                            <p className="nino-sectionDesc read-more-description">
                                Impressionist artists preferred to paint what they saw, rather than what an audience would like to see. Furthermore, the movement gave artists a platform to challenge the methods and techniques popular at the time. Impressionism rejected the portrayal of mythical, religious, and historical figures and stories in favor of capturing everyday life and its fleeting moments. 			            </p>

                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="Camille Pissarro (French, 1830-1903)"
                                    title="The Road from Versailles to Louveciennes,"
                                    src={`${WEBPAGE_URL_IMAGES}/article/2/Camille_Pissarro_The_Road_from_Versailles_to_Louveci.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Camille Pissarro (French, 1830-1903), The Road from Versailles to Louveciennes, c. 1872, watercolor over graphite. Courtesy National Gallery of Art, Washington. </p>
                            </div>
                            <h3 className="nino-sectionHeading">
                                Origins and Struggles
                           </h3>
                            <p className="nino-sectionDesc read-more-description">
                                Impressionism had its roots when traditional salon standards shunned artists due to their unconventional style. At the time, exhibiting at the Official Showroom of Painting and Sculpture was a necessary aspect towards achieving a successful career as an artist. However, the jury of the salon reproached techniques employed that did not meet scholarly standards. Additionally, modern subjects were considered unworthy as themes to portray.
</p>



                            <p className="nino-sectionDesc read-more-description">
                                Having their works rejected often by the Official Showroom of Painting and Sculpture, several artists pooled their money together to form their society and organize their exhibitions. In 1863, the Salon des Refusés was established to counter the official Paris Salon and its rigid rules of artistic standards. The members of the Salon des Refusés, which translates to the exhibition of rejects, did not let the persistent criticism dictate which style they should paint in.

        </p>
                            <h3 className="nino-sectionHeading">
                                How Impressionists got their name
                           </h3>
                            <p className="nino-sectionDesc read-more-description">
                                Before using the term of Impressionism to describe themselves and their work, members of the Salon de Refusés called themselves the Société Anonyme des Artistes Peintres, Sculpteurs, Graveurs, etc. (Anonymous Society of Painters, Sculptors, and Printmakers). The term Impressionism was coined by art critic Louis Leroy in his article describing their first exhibition in 1874. In his article, The Impressionists’ Exhibition, the term was meant to be derogatory and critique the style being used. Leroy fully intended to use satire to criticize the spontaneous and sketch-like qualities of the art he saw. </p>

                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="Claude Monet (French, 1840-1926)"
                                    title="Impression, Sunrise,"
                                    src={`${WEBPAGE_URL_IMAGES}/article/2/Monet_Impression_Sunrise.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Claude Monet (French, 1840-1926), Impression, Sunrise, 1872, oil on canvas. Collection of Musée Marmottan Monet, Paris.  </p>
                            </div>


                            <p className="nino-sectionDesc read-more-description">
                                More specifically, Leroy criticized the painting Impression, Sunrise by Claude Monet writing: “What does this painting represent? Impression, I was certain of it. I was just telling myself that since I was impressed, there had to be some impression in it.” However, the artists embraced the term instead of becoming offended by it or being influenced by its negative undertones. And between the years 1874-1886, the Impressionists put on eight exhibitions.

        </p>
                            <h3 className="nino-sectionHeading">
                                Defining characteristics
                           </h3>
                            <p className="nino-sectionDesc read-more-description">
                                Impressionism can be best described as a direct contrast to Realism, which sought to depict people and landscapes as accurately to real life as possible. It conveys the elusiveness and impermanence of a moment in time. The style became widely known for its unfinished qualities that captured the artists’ unique perception of their composition. Impressionist artworks appeared to be preliminary works rather than a finished piece. Impressionism was all about the abbreviation, speed, and spontaneity of a moment. Impressionist artists often worked en plein air or outdoors and usually completed their paintings on site. With the ability to easily paint anywhere thanks to the recent innovation of ready-made paint, Impressionists were able to travel and paint with an accurate focus of a single moment. Furthermore, artists were interested in optical truth and usually portrayed a combination of what they saw and what they felt in the moment. Paintings were direct responses to nature and treated as sensations. Artists wanted to portray what they saw while focusing on a specific point in time.
        </p>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="Camille Pissarro (French, 1830-1903)"
                                    title="Poplars, Éragny,"
                                    src={`${WEBPAGE_URL_IMAGES}/article/2/CamillePissarroPoplarsEragny.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Camille Pissarro (French, 1830-1903), Poplars, Éragny, 1895, oil on canvas. Collection of The Metropolitan Museum of Art, New York. </p>
                            </div>
                            <h3 className="nino-sectionHeading">
                                How to identify an Impressionist work
                           </h3>


                            <p className="nino-sectionDesc read-more-description">
                                Impressionist works are easily identifiable due to their lack of interest to depict a scene realistically. Up close, an Impressionist painting does not appear to be about anything. It is not until one views the painting from a distance that the scene becomes visible. There were several techniques used by artists to achieve their impression-like results. The first one was the use of short and choppy brushstrokes. The quick movement of a paintbrush gave the works their characteristic feature of appearing to be done on the spot. Additionally, there is no attempt to disguise or blend brush strokes to create smooth transitions, giving the painting a rough texture.
        </p>


                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="Berthe Morisot (French, 1841-1895)"
                                    title="Young Woman Knitting,"
                                    src={`${WEBPAGE_URL_IMAGES}/article/2/BertheMorisot YoungWomanKnitting.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Berthe Morisot (French, 1841-1895), Young Woman Knitting, c. 1883, oil on canvas. Collection of The Metropolitan Museum of Art, New York. </p>
                            </div>



                            <p className="nino-sectionDesc read-more-description">
                                Another technique that easily identifies Impressionist works is the use of color. Artists often juxtaposed complementary colors, those colors with the highest contrast when placed side by side. Some of the color combinations included blue with orange, violet with yellow, and red with green. Additionally, there was a focus on how light works to break down form. Impressionists were interested in how the human mind interpreted a crowded place or vast landscape, specifically how the initial focus was not on the small details found in nature or people’s faces but rather in light and color.
        </p>

                            <h3 className="nino-sectionHeading">
                                Themes and subject matter
                           </h3>
                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="Pierre-Auguste Renoir (French, 1841-1919)"
                                    title="Luncheon of the Boating Party,"
                                    src={`${WEBPAGE_URL_IMAGES}/article/2/Pierre-Auguste_Renoir_-_Luncheon_of_the_Boating_Party_-_Google_Art_Project.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Pierre-Auguste Renoir (French, 1841-1919), Luncheon of the Boating Party, 1880-81, oil on canvas. The Phillips Collection, Washington, DC.
</p>
                            </div>

                            <p className="nino-sectionDesc read-more-description">
                                Another way to identify an Impressionist work is the type of scenes depicted in the painting. Impressionism is the art of and industrialized and urban Paris. Therefore, artists most captured the new bourgeois leisure lifestyle stemming from the Industrial Revolution. Their preferred subjects were landscapes and urban middle-class leisure activities that fully embraced the feel of modern life. For example, Impressionism scenes often included dining scenes at balls, cafes, or concerts. As well as scenes depicting outdoor activities such as boating or picnics and entertainment activities including ballet or people at the opera. Additionally, Japanese prints, more specifically Ukiyo-e woodcut prints, heavily inspired Impressionist artists. The tones and simplified imagery in the prints influenced them to do the same. The elegant portrayal of daily life led to several Impressionists to adopt elements of Japanese prints into their styles.
        </p>
                            <h3 className="nino-sectionHeading">
                                Key artists
                           </h3>
                            <p className="nino-sectionDesc read-more-description">
                                Some of the key artists of the Impressionism movement included Claude Monet, Camille Pissarro, Pierre-Auguste Renoir, Edgar Degas, Berthe Morisot, and Mary Cassatt. Each artist had a preference in themes and played different roles in the development of the movement. The Impressionists often reserved tables at their favorite meeting spot at the Café Guerbois to host discussions and informal salons. Unfortunately, the female Impressionists were not allowed inside the cafe due to the social norms of the time. They would have to wait to get information afterward by their fellow male contemporaries.
        </p>


                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="Edgar Degas (French, 1834-1917)"
                                    title="The Rehearsal of the Ballet Onstage,"
                                    src={`${WEBPAGE_URL_IMAGES}/article/2/EdgarDegasTheRehearsaloftheBalletOnstage.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Edgar Degas (French, 1834-1917), The Rehearsal of the Ballet Onstage, c. 1874, Oil colors freely mixed with turpentine, with traces of watercolor and pastel over pen-and-ink drawing on cream-colored wove paper, laid down on bristol board and mounted on canvas. Collection of The Metropolitan Museum of Art, New York. </p>
                            </div>


                            <p className="nino-sectionDesc read-more-description">
                                Monet was the most prolific artist of the movement with his depictions of landscapes portrayed en plein air. Pissarro, also known as the “Father of Impressionism,” was a primary contributor to the movement’s theory and technique. Pissarro was known for depicting scenes of rural and urban life in France. Renoir, perhaps one of the best-known names of Impressionism, usually depicted candid scenes of people with vibrant light and saturated colors. Degas commonly portrayed ballet scenes and ballerinas in both paintings and sculptures. Morisot considered one of the grande dames of Impressionism, favored the depiction of domestic and private scenes that captured the life of a woman. Cassatt was the only American artist formally invited to join the French Impressionist group. She even exhibited in the Salon de Refusés four times. Her work often showcased the intimate bonds of mothers and children.
        </p>


                            <div className="article-picture">
                                <ImageModal hideHoverAnimation={true} hideDescription={true}
                                    author="Mary Cassatt (American, 1844-1926)"
                                    title="Mother and Child,"
                                    src={`${WEBPAGE_URL_IMAGES}/article/2/Mother_and_Child_Cassatt_1905.jpg`}
                                    isSrcSmall={true}
                                ></ImageModal>
                                <p>Mary Cassatt (American, 1844-1926), Mother and Child, c. 1905, oil on canvas. Collection of the Musée Marmottan Monet, Paris. Courtesy National Gallery of Art, Washington. </p>
                            </div>
                            <p className="nino-sectionDesc read-more-description">
                                Today, Impressionism is one of the most known and beloved movements by audiences and collectors. It has made its mark in history by reinventing the conceptions of how artists depicted reality.
        </p>




                            {/* <Link to={`/${PAGE.SHOWTUTORIALS}`} className="action-btn" onClick={() => sendEventToGoogleAnalytics("Watch for free", "article")}></Link> */}
                            <Link to={`/${PAGE.SHOWTUTORIALS}`} className="action-btn" onClick={() => sendEventToGoogleAnalytics("Watch now", "article")}></Link>

                        </div>
                    </section>
                </article>
            </div>
        );
    }
}

export default ArticleSecond;
