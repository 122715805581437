import React, { Component } from 'react';
import { WEBPAGE_URL_IMAGES } from '../globals.js';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

class TeacherCard extends Component {
    render() {
        return (<React.Fragment>
            <div className="col-md-6">
                <Link to={"/teacher/" + this.props.teacher.number}>
                    <div className="item">
                        <div className="overlay" >
                            <LazyLoadImage src={WEBPAGE_URL_IMAGES + "/our-team/img-" + this.props.teacher.number + ".webp"} alt={`${this.props.teacher.name} ${this.props.teacher.surname}`}
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = WEBPAGE_URL_IMAGES + "/our-team/img-" + this.props.teacher.number + ".jpg"
                                }} />
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-md-6">
                <div className="info">
                    <h4 className="name">{`${this.props.teacher.name} ${this.props.teacher.surname}`}</h4>

                    {/* <span className="regency">{this.props.teacher.position}</span> */}
                    <hr />
                    <p className="desc">{(this.props.teacher.description).substring(0, 223)}...</p>
                    <Link to={"/teacher/" + this.props.teacher.number}>
                        Continue reading -->
                    </Link>
                    <div className="community-icons-container">
                        {/* <a href="#" className="nino-icon"><i className="mdi mdi-facebook"></i></a> */}
                        {/* <a href="#" className="nino-icon"><i className="mdi mdi-twitter"></i></a> */}
                        {/* <a href="#" className="nino-icon"><i className="mdi mdi-pinterest"></i></a> */}
                        {/* <a href="#" className="nino-icon"><i className="mdi mdi-instagram"></i></a> */}
                    </div>
                </div>
            </div>

        </React.Fragment>
            // <Link to={"/teacher/" + this.props.teacher.number}>
            // <div className="col-md-4 col-sm-4">
            //     <div className="item">
            //         <div className="overlay" >
            //             <div className="content">
            //                 <a href="#" className="nino-icon"><i className="mdi mdi-facebook"></i></a>
            //                 <a href="#" className="nino-icon"><i className="mdi mdi-twitter"></i></a>
            //                 <a href="#" className="nino-icon"><i className="mdi mdi-pinterest"></i></a>
            //                 <a href="#" className="nino-icon"><i className="mdi mdi-instagram"></i></a>
            //             </div>
            //             <img src={WEBPAGE_URL_IMAGES + "/our-team/" + this.props.teacher.photo} alt="" />
            //         </div>
            //     </div>
            //     <div className="info">
            //         <h4 className="name">{this.props.teacher.name} {this.props.teacher.surname}</h4>
            //         <span className="regency">{this.props.teacher.position}</span>
            //     </div>
            // </div>
            // </Link>
        );
    }
}

export default TeacherCard;
